import { observable, makeObservable, action, computed, runInAction } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { apolloClientInstance } from "src/network/apolloClientInstance";
import i18next from "i18next";
import { GET_ACTIVE_MANAGER_USERS, INSERT_WORKFLOW_USER, UPDATE_USER_ID_FOR_WORKFLOW_USER } from "src/api/cred";
import { WORKFLOWUSER_ROLE } from "./InvoiceEnums";
import { InsertWorkflowUser, InsertWorkflowUserVariables } from "src/api/generated/InsertWorkflowUser";
import {
    UpdateUserIdForWorkflowUser,
    UpdateUserIdForWorkflowUserVariables
} from "src/api/generated/UpdateUserIdForWorkflowUser";
import { IWorkflowUser, IWorkflowUserDetails } from "./InvoiceTypes";
import { GetActiveManagerUsers } from "src/api/generated/GetActiveManagerUsers";

export enum ASSIGN_INVOICE_STATE {
    INIT,
    ASSIGNING,
    FINISHED,
    SUCCESS,
    ERROR
}

export interface IUserToAssign {
    userid: string;
    name: string;
}

export class AssignInvoiceStore {
    rootStore: RootStore;

    isModalDisplayed = false;
    modalCurrentlyUsedForRole: WORKFLOWUSER_ROLE = WORKFLOWUSER_ROLE.VISA_1;

    assignInvoiceState: ASSIGN_INVOICE_STATE = ASSIGN_INVOICE_STATE.INIT;

    usersToAssignData: IUserToAssign[] = [];
    currentUserToAssignSearchQuery: string = "";
    selectedUserToAssign?: IUserToAssign = undefined;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            assignInvoiceState: observable,
            isModalDisplayed: observable,
            usersToAssignData: observable,
            currentUserToAssignSearchQuery: observable,
            selectedUserToAssign: observable,
            modalCurrentlyUsedForRole: observable,

            init: action,
            resetAssignInvoiceModal: action,
            setIsModalDisplayed: action,
            setAssignUserData: action,
            setCurrentAssignUserSearchQuery: action,
            setSelectedAssignUser: action,
            setAssignInvoiceState: action,
            assignInvoiceToWorkflowUser: action,
            loadUsersToAssign: action,
            insertWorkflowUser: action,
            updateWorkflowUserForRole: action,

            filteredUsersToAssignData: computed
        });
    }

    init = async () => {
        await this.loadUsersToAssign();
    };

    resetAssignInvoiceModal = () => {
        this.assignInvoiceState = ASSIGN_INVOICE_STATE.INIT;
        this.currentUserToAssignSearchQuery = "";
        this.selectedUserToAssign = undefined;
    };

    loadUsersToAssign = async () => {
        const { data }: { data: GetActiveManagerUsers } = await apolloClientInstance.query<GetActiveManagerUsers>({
            query: GET_ACTIVE_MANAGER_USERS
        });

        const usersToAssignInvoice: IUserToAssign[] = data.ums_v_customer_users.map((user) => {
            const assignPerson: IUserToAssign = {
                userid: user.userid,
                name: user.name ?? ""
            };

            return assignPerson;
        });

        this.setAssignUserData(usersToAssignInvoice);
    };

    get filteredUsersToAssignData(): IUserToAssign[] {
        const searchQuery = this.currentUserToAssignSearchQuery ?? "";

        let filteredUsersToAssignData: IUserToAssign[] = [...this.usersToAssignData];

        const filteredUsersToApplyDataBySearchQuery = filteredUsersToAssignData.filter((userToAssign) => {
            return userToAssign.name?.toLowerCase().includes(searchQuery.toLowerCase());
        });

        if (searchQuery === "") {
            return filteredUsersToAssignData;
        } else {
            return filteredUsersToApplyDataBySearchQuery;
        }
    }

    assignInvoiceToWorkflowUser = async (
        userIdToAssign: string,
        workflowUserRole: WORKFLOWUSER_ROLE
    ): Promise<boolean> => {
        this.assignInvoiceState = ASSIGN_INVOICE_STATE.ASSIGNING;

        let wasInvoiceTicketSuccessfullyAssigned = true;

        const existingWorkflowUserForRole =
            this.rootStore.invoiceStore.currentInvoice && this.rootStore.invoiceStore.currentInvoice.workflowUsers
                ? this.rootStore.invoiceStore.currentInvoice.workflowUsers.find(
                      (workflowUser) => workflowUser.role === workflowUserRole
                  )
                : undefined;

        // if there is a user assigned to this role, update the user ie assign another user for this role
        // else insert a new workflowuser for this role
        // then update the invoice with the new or updated workflowuser
        if (existingWorkflowUserForRole) {
            const updatedWorkflowUserDetails = await this.updateWorkflowUserForRole(userIdToAssign, workflowUserRole);

            if (updatedWorkflowUserDetails) {
                runInAction(() => {
                    existingWorkflowUserForRole.user = updatedWorkflowUserDetails;
                });
            } else {
                wasInvoiceTicketSuccessfullyAssigned = false;
            }
        } else {
            const insertedWorkflowUser = await this.insertWorkflowUser(userIdToAssign, workflowUserRole);

            if (insertedWorkflowUser) {
                runInAction(() => {
                    if (
                        this.rootStore.invoiceStore.currentInvoice &&
                        this.rootStore.invoiceStore.currentInvoice.workflowUsers &&
                        insertedWorkflowUser
                    ) {
                        this.rootStore.invoiceStore.currentInvoice.workflowUsers?.push(insertedWorkflowUser);
                    }
                });
            } else {
                wasInvoiceTicketSuccessfullyAssigned = false;
            }
        }

        if (wasInvoiceTicketSuccessfullyAssigned) {
            this.setAssignInvoiceState(ASSIGN_INVOICE_STATE.FINISHED);
        } else {
            this.setAssignInvoiceState(ASSIGN_INVOICE_STATE.INIT);

            this.rootStore.uiStore.printTicketingErrorMessage(
                i18next.t("screens.tickets.action.assign_ticket.failure")
            );
        }

        return wasInvoiceTicketSuccessfullyAssigned;
    };

    insertWorkflowUser = async (
        userId: string,
        workflowUserRole: WORKFLOWUSER_ROLE
    ): Promise<IWorkflowUser | undefined> => {
        let insertWorkflowUser: IWorkflowUser | undefined = undefined;

        try {
            const { data: insertWorkflowUserResult } = await apolloClientInstance.mutate<
                InsertWorkflowUser,
                InsertWorkflowUserVariables
            >({
                mutation: INSERT_WORKFLOW_USER,
                variables: {
                    workflowuser: {
                        invoiceid: this.rootStore.invoiceStore.currentInvoice?.id,
                        userid: userId,
                        role: workflowUserRole
                    }
                }
            });

            if (insertWorkflowUserResult && insertWorkflowUserResult.insert_cred_workflowusers_one) {
                const data = insertWorkflowUserResult.insert_cred_workflowusers_one;

                insertWorkflowUser = {
                    id: data.id,
                    role: workflowUserRole,
                    signaturestamp: data.signaturestamp,
                    user: {
                        id: data.user.id,
                        name1: data.user.name1 ?? "",
                        name2: data.user.name2 ?? ""
                    }
                };
            }
        } catch (error) {
            console.error("Error inserting workflowuser: ", error);
        }

        return insertWorkflowUser;
    };

    updateWorkflowUserForRole = async (
        userId: string,
        workflowUserRole: WORKFLOWUSER_ROLE
    ): Promise<IWorkflowUserDetails | undefined> => {
        let updatedWorkflowUserDetails: IWorkflowUserDetails | undefined = undefined;

        try {
            const { data: updateWorkflowUserResult } = await apolloClientInstance.mutate<
                UpdateUserIdForWorkflowUser,
                UpdateUserIdForWorkflowUserVariables
            >({
                mutation: UPDATE_USER_ID_FOR_WORKFLOW_USER,
                variables: {
                    invoiceid: this.rootStore.invoiceStore.currentInvoice?.id,
                    userid: userId,
                    role: workflowUserRole
                }
            });

            if (
                updateWorkflowUserResult &&
                updateWorkflowUserResult.update_cred_workflowusers &&
                updateWorkflowUserResult.update_cred_workflowusers.returning.length > 0
            ) {
                const returnedUserDetails = updateWorkflowUserResult.update_cred_workflowusers.returning[0].user;

                updatedWorkflowUserDetails = {
                    id: returnedUserDetails.id,
                    name1: returnedUserDetails.name1 ?? "",
                    name2: returnedUserDetails.name2 ?? ""
                };
            }
        } catch (error) {
            console.error("Error updating workflowuser: ", error);
        }

        return updatedWorkflowUserDetails;
    };
    /* SETTERS */
    setIsModalDisplayed = (isModalDisplayed: boolean, modalDisplayedForRole?: WORKFLOWUSER_ROLE) => {
        this.isModalDisplayed = isModalDisplayed;

        if (modalDisplayedForRole) {
            this.modalCurrentlyUsedForRole = modalDisplayedForRole;
        }
    };

    setAssignUserData = (assignUserData: IUserToAssign[]) => {
        this.usersToAssignData = assignUserData;
    };

    setCurrentAssignUserSearchQuery = (currentAssignUserSearchQuery: string) => {
        this.currentUserToAssignSearchQuery = currentAssignUserSearchQuery;
    };

    setSelectedAssignUser = (selectedAssignUser: IUserToAssign) => {
        this.selectedUserToAssign = selectedAssignUser;
    };

    setAssignInvoiceState = (assignInvoiceState: ASSIGN_INVOICE_STATE) => {
        this.assignInvoiceState = assignInvoiceState;
    };

    setModalCurrentlyUsedForRole = (role: WORKFLOWUSER_ROLE) => {
        this.modalCurrentlyUsedForRole = role;
    };
}
