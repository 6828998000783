import React, { useCallback, useContext } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import styles from "./ApproveRejectInvoiceProgress.module.css";
import {
    APPROVE_REJECT_INVOICE_STATE,
    APPROVE_REJECT_MODAL_TYPE
} from "src/stores/krediflow/ApproveRejectInvoiceStore";

interface ApproveRejectInvoiceProgressProps {
    currentRejectInvoiceState: APPROVE_REJECT_INVOICE_STATE;
}

export const ApproveRejectInvoiceProgressBase = (props: ApproveRejectInvoiceProgressProps) => {
    const { t } = useTranslation();
    const { approveRejectInvoiceStore } = useContext(RootStoreContext);

    const { currentRejectInvoiceState: currentAssignInvoiceState } = props;

    const onClickFinishApproveOrRejectInvoice = useCallback(() => {
        approveRejectInvoiceStore.setIsModalDisplayed(false);
        approveRejectInvoiceStore.reset();
    }, [approveRejectInvoiceStore]);

    let icon;
    let text;

    switch (currentAssignInvoiceState) {
        case APPROVE_REJECT_INVOICE_STATE.APPROVING:
            icon = <UI.RotatingSpinner noLogo size={50} className="loading-items-spinner" />;
            text = t("screens.kredi_flow.action.approve_invoice.in_progress").toString();
            break;
        case APPROVE_REJECT_INVOICE_STATE.REJECTING:
            icon = <UI.RotatingSpinner noLogo size={50} className="loading-items-spinner" />;
            text = t("screens.kredi_flow.action.reject_invoice.in_progress").toString();
            break;
        case APPROVE_REJECT_INVOICE_STATE.FINISHED:
            icon = <UI.Icon icon={UI.SVGIcon.Check} size={50} color={"black"} />;
            text =
                approveRejectInvoiceStore.displayedModalType === APPROVE_REJECT_MODAL_TYPE.APPROVE
                    ? t("screens.kredi_flow.action.approve_invoice.success").toString()
                    : t("screens.kredi_flow.action.reject_invoice.success").toString();
            break;
    }

    return (
        <UI.Row className={"mt-2 d-flex"}>
            <UI.Col
                xs={12}
                className={`d-flex align-items-center justify-content-center ${styles.AssignInvoiceProgressContainer}`}
            >
                <div className="d-flex align-items-center flex-column">
                    <div>{icon}</div>
                    <div className="d-flex mt-2">
                        <span className={`${styles.AssignInvoiceProgressText} text-center`}>{text}</span>
                    </div>
                </div>
            </UI.Col>
            <UI.Col xs={12} className="mt-4">
                <UI.Button
                    className={`mt-2 ${styles.FinishButton}`}
                    label={t("labels.finished").toString()}
                    disabled={
                        currentAssignInvoiceState === APPROVE_REJECT_INVOICE_STATE.APPROVING ||
                        currentAssignInvoiceState === APPROVE_REJECT_INVOICE_STATE.REJECTING
                            ? true
                            : false
                    }
                    onClick={onClickFinishApproveOrRejectInvoice}
                ></UI.Button>
            </UI.Col>
        </UI.Row>
    );
};

export const ApproveRejectInvoiceProgress = Sentry.withProfiler(observer(ApproveRejectInvoiceProgressBase));
