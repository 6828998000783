import React, { useCallback, useContext, useEffect, useState, useRef } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { SCREEN_SIZE } from "src/stores/UIStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { ColorStyle } from "src/utils/Colors";
import { useSaveUnsavedChangesInForm } from "src/hooks/save-unsaved-changes-in-form/useSaveUnsavedChangesInForm";
import { FORM_STATUS } from "src/utils/Form";
import { useSaveFormByShortcut } from "src/hooks/form/useSaveFormByShortcut";
import { formatDate } from "src/stores/krediflow/Utils";
import { INVOICE_PROPERTY, LOADING_TYPE } from "src/stores/krediflow/InvoiceEnums";
import { formatCurrency } from "src/utils/Currency";
import { CreditorFormFields } from "./fields/CreditorFormFields";
import { InvoiceDetailFormFields } from "./fields/InvoiceDetailFormFields";
import { NetworkConfig } from "src/network/NetworkConfig";
import styles from "./InvoiceForm.module.css";
import { RealestateFormField } from "./fields/RealestateFormField";
import { UnitFormField } from "./fields/UnitFormField";
import { TicketFormField } from "./fields/TicketFormField";
import { OrderFormField } from "./fields/OrderFormField";

export enum ERROR_TYPE {
    NONE = 0,
    REALESTATE = 1
}

const InvoiceFormBase = () => {
    const { t } = useTranslation();
    const { navStore, uiStore, invoiceStore } = useContext(RootStoreContext);

    const [isSaving, setIsSaving] = useState(false);
    const [isSaveInvoiceWithoutDocumentModalDisplayed, setIsSaveInvoiceWithoutDocumentModalDisplayed] = useState(false);

    const invoiceFormContainerRef = useRef<HTMLDivElement>(null);
    const invoiceFormRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        navStore.setTitle("");
        navStore.setActionButtonMiddle(null);
        navStore.setActionButtonRight(null);
    }, [navStore]);

    // Use ResizeObserver to observe the height of the InvoiceForm container
    useEffect(() => {
        const invoiceFormContainerElement = invoiceFormContainerRef.current;
        const invoiceFormElement = invoiceFormRef.current;

        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === invoiceFormContainerElement) {
                    let newContainerHeight = entry.contentRect.height;

                    uiStore.setInvoiceFormContainerHeight(newContainerHeight);
                }

                if (entry.target === invoiceFormElement) {
                    uiStore.setInvoiceFormHeight(entry.contentRect.height);
                }
            }
        });

        if (invoiceFormContainerElement) {
            resizeObserver.observe(invoiceFormContainerElement);
        }

        if (invoiceFormElement) {
            resizeObserver.observe(invoiceFormElement);
        }

        return () => {
            if (invoiceFormContainerElement) {
                resizeObserver.unobserve(invoiceFormContainerElement);
            }

            if (invoiceFormElement) {
                resizeObserver.unobserve(invoiceFormElement);
            }
        };
    }, [uiStore, invoiceStore.isEditing]);

    const onChangeInvoiceField = useCallback(
        (invoiceProperty: INVOICE_PROPERTY) => (e: any) => {
            if (invoiceStore.currentInvoice) {
                let newPropertyValue: string | number = e.target.value;

                if (invoiceProperty === INVOICE_PROPERTY.INVOICE_TYPE) {
                    newPropertyValue = Number(e.target.value);
                }

                invoiceStore.currentInvoice.updateProperty(invoiceProperty, newPropertyValue);
            }
        },
        [invoiceStore.currentInvoice]
    );

    const saveForm = useCallback(
        (closeEditModeAfterSave: boolean = true) =>
            async (e: any) => {
                if (isSaving) {
                    return;
                }

                if (isSaveInvoiceWithoutDocumentModalDisplayed) {
                    setIsSaveInvoiceWithoutDocumentModalDisplayed(false);
                }

                setIsSaving(true);

                if (invoiceStore.isEditing) {
                    if (e) {
                        e.preventDefault();
                    }

                    const wasSuccessfullyValidated = invoiceStore.currentInvoice?.validate();

                    if (wasSuccessfullyValidated) {
                        await invoiceStore.saveCurrentInvoice(closeEditModeAfterSave);
                        setIsSaving(false);
                    } else {
                        setIsSaving(false);
                    }
                }

                setIsSaving(false);
            },
        [isSaving, invoiceStore, isSaveInvoiceWithoutDocumentModalDisplayed]
    );

    const toggleDisplaySaveInvoiceWithoutDocumentModal = useCallback(
        (e: any) => {
            e.preventDefault();
            e.stopPropagation(); // Stop the event from propagating further

            setIsSaveInvoiceWithoutDocumentModalDisplayed(!isSaveInvoiceWithoutDocumentModalDisplayed);
        },
        [isSaveInvoiceWithoutDocumentModalDisplayed]
    );

    const { saveFormModal, closeSaveFormModalAndGoBack } = useSaveUnsavedChangesInForm({
        saveFormFunction:
            invoiceStore.currentInvoice?.isNewInvoice && !invoiceStore.currentInvoice.isFileSelected
                ? toggleDisplaySaveInvoiceWithoutDocumentModal
                : saveForm(true),
        currentFormStatus:
            invoiceStore.loadingType === LOADING_TYPE.SAVING_INVOICE ? FORM_STATUS.SAVING_FORM : FORM_STATUS.NONE,
        isFormDirty: invoiceStore.currentInvoice?.hasChanges ?? false
    });

    useSaveFormByShortcut({
        saveFormFunction: saveForm(false),
        isFormDirty: invoiceStore.currentInvoice?.hasChanges ?? false
    });

    const onResetForm = useCallback(async () => {
        await invoiceStore.discardCurrentInvoiceChanges();
    }, [invoiceStore]);

    invoiceStore.setUserDefinedAfterSaveFunction(closeSaveFormModalAndGoBack);

    const editForm = useCallback(
        (e: any) => {
            e.preventDefault();
            invoiceStore.setIsEditing(true);
        },
        [invoiceStore]
    );

    const saveFormButtonLabel = (
        <div className="d-flex align-items-center">
            <div>{t("labels.save")}</div>
            <UI.RotatingSpinner
                className={`${styles.FromSaveButtonSpinner} ${
                    invoiceStore.loadingType !== LOADING_TYPE.SAVING_INVOICE ? "d-none" : ""
                }`}
                noLogo={true}
                size={20}
            />
        </div>
    );

    const saveFormButton =
        uiStore.invoicePaneScreenSize < SCREEN_SIZE.TABLET ? (
            <>
                {invoiceStore.loadingType !== LOADING_TYPE.NONE ? (
                    <UI.RotatingSpinner noLogo={true} size={30} />
                ) : (
                    <UI.Icon
                        onClick={
                            invoiceStore.currentInvoice?.isNewInvoice && !invoiceStore.currentInvoice.isFileSelected
                                ? toggleDisplaySaveInvoiceWithoutDocumentModal
                                : saveForm(true)
                        }
                        icon={UI.SVGIcon.Save}
                        color={ColorStyle("primary")}
                        size={"medium"}
                    />
                )}
            </>
        ) : (
            <UI.Button
                onClick={
                    invoiceStore.currentInvoice?.isNewInvoice && !invoiceStore.currentInvoice.isFileSelected
                        ? toggleDisplaySaveInvoiceWithoutDocumentModal
                        : saveForm(true)
                }
                className={styles.SaveButton}
                label={saveFormButtonLabel}
                disabled={invoiceStore.loadingType !== LOADING_TYPE.NONE}
            />
        );

    const editFormButton =
        uiStore.invoicePaneScreenSize < SCREEN_SIZE.TABLET ? (
            <UI.Icon
                className={styles.EditIcon}
                onClick={editForm}
                icon={UI.SVGIcon.Edit}
                color={ColorStyle("primary")}
                size={"medium"}
            />
        ) : (
            <UI.Button
                onClick={editForm}
                className={styles.EditButton}
                variant={"outline-primary"}
                label={t("labels.edit")}
            />
        );

    const isNewInvoice = invoiceStore.currentInvoice?.isNewInvoice;

    const formattedInvoiceNumber = invoiceStore.currentInvoice?.number
        ? invoiceStore.currentInvoice?.number.toString().padStart(5, "0")
        : "";

    const creditorName =
        (invoiceStore.currentInvoice &&
            invoiceStore.currentInvoice.creditor &&
            invoiceStore.currentInvoice.creditor.name) ??
        "";

    const formattedAmount = invoiceStore.currentInvoice?.amount
        ? `CHF ${formatCurrency(invoiceStore.currentInvoice?.amount)}`
        : "";

    let realestatePlaceholderIconSize = 170;

    if (uiStore.invoicePaneScreenSize <= SCREEN_SIZE.MOBILE_L) {
        realestatePlaceholderIconSize = 100;
    } else if (uiStore.invoicePaneScreenSize <= SCREEN_SIZE.LAPTOP) {
        realestatePlaceholderIconSize = 125;
    }

    const saveInvoiceWithoutDocumentModal = (
        <UI.Modal
            show={isSaveInvoiceWithoutDocumentModalDisplayed}
            onClose={toggleDisplaySaveInvoiceWithoutDocumentModal}
        >
            <div>
                <div>
                    <div>{t("screens.kredi_flow.saving_without_document_warning")}</div>
                </div>
                <div>
                    <UI.Button
                        className="my-2"
                        label={t("screens.kredi_flow.confirm_saving_without_document")}
                        onClick={saveForm(true)}
                    />
                    <UI.Button
                        className="FormSaveButtonCancel"
                        label={t("labels.cancel")}
                        onClick={toggleDisplaySaveInvoiceWithoutDocumentModal}
                    />
                </div>
            </div>
        </UI.Modal>
    );

    return (
        <div id="invoice-form-container" ref={invoiceFormContainerRef}>
            {saveFormModal}
            {saveInvoiceWithoutDocumentModal}
            <div ref={invoiceFormRef}>
                <UI.Form className={styles.InvoiceForm} id={"invoice-form"}>
                    {/* Invoice-Number - Keditor-Name, Amount / Creation-Date*/}
                    <UI.Row className={`mt-2 ${styles.TitleRow}`}>
                        <UI.Col
                            md={9}
                            className={`${uiStore.currentScreenSize <= SCREEN_SIZE.MOBILE_L ? "order-2" : ""} ${
                                isNewInvoice ? "d-none" : ""
                            } ${uiStore.isMobile && !invoiceStore.isEditing ? "mb-3" : ""}`}
                        >
                            <div>
                                <div className={styles.TicketNumber}>
                                    {`${formattedInvoiceNumber} - ${creditorName}, ${formattedAmount}`}
                                </div>
                                <div className={styles.CreationDate}>
                                    {invoiceStore.currentInvoice?.date &&
                                        formatDate(invoiceStore.currentInvoice.date, false, true)}
                                </div>
                            </div>
                        </UI.Col>

                        <UI.Col
                            md={3}
                            className={`d-flex justify-content-end ${styles.EditColumn} ${
                                uiStore.currentScreenSize <= SCREEN_SIZE.MOBILE_L ? "order-1 mb-3" : ""
                            } ${invoiceStore.isEditing && invoiceStore.currentInvoice?.isNewInvoice ? "ml-auto" : ""}`}
                        >
                            {invoiceStore.isEditing ? saveFormButton : editFormButton}
                        </UI.Col>
                    </UI.Row>

                    {/* Status / Invoice-Typ */}
                    <UI.Row>
                        <div className={uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP ? "col-3" : "col-6"}>
                            <UI.Input
                                label={t("screens.kredi_flow.list.state").toString()}
                                type="text"
                                autoComplete="off"
                                disabled={true}
                                value={
                                    invoiceStore.currentInvoice && invoiceStore.currentInvoice.state
                                        ? t(
                                              `screens.kredi_flow.state.${String(invoiceStore.currentInvoice.state)}`
                                          ).toString()
                                        : ""
                                }
                            />
                        </div>
                        <div className={uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP ? "col-3" : "col-6"}>
                            <UI.Input
                                label={t("screens.kredi_flow.list.type").toString()}
                                type="text"
                                as="select"
                                onChange={onChangeInvoiceField(INVOICE_PROPERTY.INVOICE_TYPE)}
                                className={`${styles.FormFieldSelect} ${invoiceStore.isEditing ? "" : styles.ReadOnly}`}
                                disabled={!invoiceStore.isEditing || !isNewInvoice}
                                value={String(invoiceStore.currentInvoice?.invoiceType)}
                            >
                                {invoiceStore.invoiceTypes.map((invoiceType) => {
                                    return (
                                        <option key={invoiceType.type} value={invoiceType.type ?? ""}>
                                            {invoiceType.name}
                                        </option>
                                    );
                                })}
                            </UI.Input>
                        </div>
                    </UI.Row>

                    {/* Creditor */}
                    <div className="mt-3">
                        <span className={styles.FormSectionTitle}>
                            {t("screens.kredi_flow.form.creditor.title").toString()}
                        </span>
                    </div>
                    <CreditorFormFields />
                    <hr />

                    {/* Rechnungsdetails */}
                    <div className="mt-3">
                        <span className={styles.FormSectionTitle}>{t("screens.kredi_flow.invoice").toString()}</span>
                    </div>
                    <InvoiceDetailFormFields />

                    <hr />

                    {/* Objekt / Auftrag */}
                    <UI.Row className="mt-3">
                        {/* Objekt */}
                        <div className={uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP ? "col-7" : "col-12"}>
                            <UI.Row className="mb-3">
                                <UI.Col>
                                    <span className={styles.FormSectionTitle}>{t("screens.unit.object")}</span>
                                </UI.Col>
                            </UI.Row>
                            <UI.Row>
                                <UI.Col lg={4}>
                                    <div className="d-flex align-items-center justify-content-center h-100">
                                        {invoiceStore.currentInvoice?.realestateThumbnailFileId ? (
                                            <UI.Thumbnail
                                                src={
                                                    NetworkConfig.openThumbnailUrl +
                                                    invoiceStore.currentInvoice?.realestateThumbnailFileId
                                                }
                                                className={styles.RealestateThumbnail}
                                            />
                                        ) : (
                                            <div
                                                className={`${styles.RealestatePlaceholderThumbnailContainer} ${
                                                    uiStore.invoicePaneScreenSize <= SCREEN_SIZE.MOBILE_L
                                                        ? styles.Small
                                                        : uiStore.invoicePaneScreenSize <= SCREEN_SIZE.LAPTOP
                                                        ? styles.Medium
                                                        : styles.Large
                                                }`}
                                            >
                                                <UI.Icon
                                                    icon={UI.SVGIcon.Consolidated}
                                                    size={realestatePlaceholderIconSize}
                                                    color={ColorStyle("grey")}
                                                    backgroundColor="#D2D5D9"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </UI.Col>
                                <UI.Col lg={8}>
                                    <UI.Row>
                                        <UI.Col lg={12} className={styles.RealestateFormFieldContainer}>
                                            <RealestateFormField />
                                        </UI.Col>
                                        <UI.Col lg={12}>
                                            <UnitFormField />
                                        </UI.Col>
                                    </UI.Row>
                                </UI.Col>
                            </UI.Row>
                        </div>

                        {/* Auftrag */}
                        <div className={uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP ? "col-5" : "col-12"}>
                            <UI.Row
                                className={`${
                                    uiStore.invoicePaneScreenSize < SCREEN_SIZE.LAPTOP ? "mt-4 mb-1" : "mt-0 mb-3"
                                }`}
                            >
                                <span
                                    className={`${styles.FormSectionTitle} ${
                                        invoiceStore.isEditing || uiStore.invoicePaneScreenSize < SCREEN_SIZE.LAPTOP
                                            ? "col"
                                            : ""
                                    }`}
                                >
                                    {t("screens.orders.order")}
                                </span>
                            </UI.Row>
                            <UI.Row>
                                <UI.Col
                                    lg={12}
                                    className={`${styles.TicketFormFieldContainer} ${
                                        !invoiceStore.isEditing && uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP
                                            ? styles.ReadOnlyMode
                                            : ""
                                    }`}
                                >
                                    <TicketFormField />
                                </UI.Col>
                                <UI.Col
                                    lg={12}
                                    className={`${styles.OrderFormFieldContainer} ${
                                        !invoiceStore.isEditing && uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP
                                            ? styles.ReadOnlyMode
                                            : ""
                                    }`}
                                >
                                    <OrderFormField />
                                </UI.Col>
                            </UI.Row>
                        </div>
                    </UI.Row>
                </UI.Form>
            </div>

            {invoiceStore.isEditing ? (
                uiStore.invoicePaneScreenSize < SCREEN_SIZE.TABLET ? (
                    <UI.Row className="d-flex mt-2 flex-column row">
                        <UI.Col xs={12} className="d-flex mt-2 justify-content-center align-items-center col-12">
                            <UI.Button
                                onClick={
                                    invoiceStore.currentInvoice?.isNewInvoice &&
                                    !invoiceStore.currentInvoice.isFileSelected
                                        ? toggleDisplaySaveInvoiceWithoutDocumentModal
                                        : saveForm(true)
                                }
                                className={"ticket-button mx-2"}
                                label={saveFormButtonLabel}
                                disabled={invoiceStore.loadingType !== LOADING_TYPE.NONE}
                            />
                        </UI.Col>
                        <UI.Col xs={12} className="d-flex mt-2 justify-content-center align-items-center col-12">
                            <UI.Button
                                label={t("labels.discard")}
                                disabled={
                                    !invoiceStore.currentInvoice?.hasChanges ||
                                    invoiceStore.loadingType === LOADING_TYPE.SAVING_INVOICE
                                }
                                onClick={onResetForm}
                                className={`${styles.DiscardButtonMobile} mx-2`}
                                variant="outline-primary"
                            />
                        </UI.Col>
                    </UI.Row>
                ) : (
                    <UI.Row className="my-2">
                        <UI.Col className={"flex-grow-0"}>
                            <UI.Button
                                onClick={
                                    invoiceStore.currentInvoice?.isNewInvoice &&
                                    !invoiceStore.currentInvoice.isFileSelected
                                        ? toggleDisplaySaveInvoiceWithoutDocumentModal
                                        : saveForm(true)
                                }
                                className={styles.SaveButton}
                                label={saveFormButtonLabel}
                                disabled={invoiceStore.loadingType !== LOADING_TYPE.NONE}
                            />
                        </UI.Col>
                        <UI.Col>
                            <UI.Button
                                label={t("labels.discard")}
                                disabled={
                                    !invoiceStore.currentInvoice?.hasChanges ||
                                    invoiceStore.loadingType === LOADING_TYPE.SAVING_INVOICE
                                }
                                onClick={onResetForm}
                                className={`${styles.DiscardButton}`}
                                variant="outline-primary"
                            />
                        </UI.Col>
                    </UI.Row>
                )
            ) : undefined}
        </div>
    );
};

export const InvoiceForm = Sentry.withProfiler(observer(InvoiceFormBase));
