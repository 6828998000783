import { observable, makeObservable, action } from "mobx";
import { RootStore } from "src/stores/RootStore";
import i18next from "i18next";
import { INVOICE_PROPERTY, INVOICE_STATE } from "./InvoiceEnums";
import { NetworkConfig } from "src/network/NetworkConfig";
import { getRoleKey } from "src/network/User";
import { MessageType } from "src/components/notifications/Notifier";
import { IConcludeHumanTaskRequestBody } from "src/network/NetworkTypes";

export enum START_WORKFLOW_STATE {
    INIT,
    STARTING,
    FINISHED,
    SUCCESS,
    ERROR
}

export class StartWorkflowStore {
    rootStore: RootStore;

    startWorkflowState: START_WORKFLOW_STATE = START_WORKFLOW_STATE.INIT;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            startWorkflowState: observable,

            reset: action,
            startWorkflow: action,
            validateStartWorkflow: action,
            setStartWorkflowState: action
        });
    }

    reset = () => {
        this.startWorkflowState = START_WORKFLOW_STATE.INIT;
    };

    startWorkflow = async (): Promise<boolean> => {
        this.setStartWorkflowState(START_WORKFLOW_STATE.STARTING);

        let wasWorkflowSuccessfullyStarted = false;

        const currentInvoice = this.rootStore.invoiceStore.currentInvoice;

        const concludeHumanTaskVariables: IConcludeHumanTaskRequestBody = {
            taskname: "AssignRealestateAndOtherData",
            workflowid: currentInvoice?.workflowInstance ?? ""
        };

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            const concludeHumanTaskResponse = await fetch(NetworkConfig.concludeHumanTaskUrl, {
                method: "POST",
                body: JSON.stringify(concludeHumanTaskVariables),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            if (concludeHumanTaskResponse.status === 200) {
                wasWorkflowSuccessfullyStarted = true;

                this.rootStore.invoiceStore.currentInvoice?.updateProperty(
                    INVOICE_PROPERTY.STATE,
                    INVOICE_STATE.READY_FOR_VISA
                );

                this.rootStore.invoiceStore.currentInvoice?.startInvoiceWorkflowUsersSubscription();

                this.rootStore.uiStore.printStatusMessage(
                    i18next.t("screens.kredi_flow.action.start_workflow.success"),
                    MessageType.SUCCESS
                );
            } else {
                console.error(
                    "Error occured while trying concluded human task 'AssignRealestateAndOtherData': ",
                    concludeHumanTaskResponse
                );
            }
        } catch (error) {
            console.error("Error concluding human task 'AssignRealestateAndOtherData': ", error);
        }

        return wasWorkflowSuccessfullyStarted;
    };

    validateStartWorkflow = (): boolean => {
        let isValid = true;

        if (
            this.rootStore.invoiceStore.isEditing ||
            !this.rootStore.invoiceStore.currentInvoice?.isRealestateSelected
        ) {
            this.rootStore.uiStore.printStatusMessage(
                i18next.t("screens.kredi_flow.action.start_workflow.no_realestate_selected"),
                MessageType.ERROR
            );

            isValid = false;
        }

        return isValid;
    };

    /* SETTERS */
    setStartWorkflowState = (startWorkflowState: START_WORKFLOW_STATE) => {
        this.startWorkflowState = startWorkflowState;
    };
}
