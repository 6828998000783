import { UI } from "@wwimmo/ui";
import { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import styles from "./AssignTicketButtonAndModal.module.css";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { IAssignedUser, LOADING_TYPE, TICKET_STATE } from "src/stores/TicketStore";
import { ColorStyle } from "src/utils/Colors";
import { ASSIGN_TICKET_STATE } from "src/stores/tickets/AssignTicketModalStore";
import { AssignTicketProgress } from "./assignTicketProgress/AssignTicketProgress";

const AssignTicketButtonAndModalBase = () => {
    const { t } = useTranslation();
    const { ticketStore, assignTicketModalStore } = useContext(RootStoreContext);

    const toggleDisplayAssignTicketModal = useCallback(() => {
        assignTicketModalStore.setIsModalDisplayed(!assignTicketModalStore.isModalDisplayed);
    }, [assignTicketModalStore]);

    const onChangeAssignUserInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const assignUser = e.target.value;
            assignTicketModalStore.setCurrentAssignUserSearchQuery(assignUser);
        },
        [assignTicketModalStore]
    );

    const onClickAssignUserItem = useCallback(
        (assignUserId: string) => () => {
            assignTicketModalStore.assignTicketToUser(assignUserId, LOADING_TYPE.ASSIGNING_TICKET_TO_USER);
        },
        [assignTicketModalStore]
    );

    const getAssignUserElement = useCallback(
        (user: IAssignedUser) => {
            const userName = user
                ? `${user.name1 ?? ""} ${
                      user.name2 && user.name2.trim() !== user.name1.trim() ? user.name2 : ""
                  }`.trim()
                : "-";

            return (
                <div key={user.id} className={styles.AssignUserItem} onClick={onClickAssignUserItem(user.id)}>
                    <UI.Icon icon={UI.SVGIcon.Person} color={ColorStyle("black")} />
                    <div className={styles.AssignUserItemName}>{userName}</div>
                    <UI.Icon className="ml-auto" icon={UI.SVGIcon.ChevronRight} color={ColorStyle("primary")} />
                </div>
            );
        },
        [onClickAssignUserItem]
    );

    return (
        <>
            <UI.Modal
                title={t("screens.tickets.action.assign_ticket.select_manager").toString()}
                size="lg"
                show={assignTicketModalStore.isModalDisplayed}
                onClose={toggleDisplayAssignTicketModal}
                centered={true}
            >
                {assignTicketModalStore.assignTicketState === ASSIGN_TICKET_STATE.INIT ? (
                    <>
                        <UI.Row className={"mt-2"}>
                            <UI.Col sm={12}>
                                <UI.Input
                                    type="text"
                                    autoComplete="off"
                                    className={styles.FormInput}
                                    id="assign-ticket-select-user-input"
                                    value={assignTicketModalStore.currentAssignUserSearchQuery ?? ""}
                                    onChange={onChangeAssignUserInput}
                                    placeholder={t("labels.search").toString()}
                                    iconRight={
                                        <UI.Icon
                                            icon={UI.SVGIcon.Search}
                                            color="grey"
                                            size={"small"}
                                            style={{ right: "5px" }}
                                        />
                                    }
                                />
                            </UI.Col>
                        </UI.Row>
                        <UI.Row className={"mt-2"}>
                            <UI.Col sm={12}>
                                <div className={styles.AssignUserContainer}>
                                    {assignTicketModalStore.filteredAssignUserData.map((user) => {
                                        return getAssignUserElement(user);
                                    })}
                                </div>
                            </UI.Col>
                        </UI.Row>
                    </>
                ) : undefined}

                {assignTicketModalStore.assignTicketState === ASSIGN_TICKET_STATE.ASSIGNING ? (
                    <AssignTicketProgress
                        key={"assign-ticket-progress-assigning"}
                        currentAssignTicketState={ASSIGN_TICKET_STATE.ASSIGNING}
                    />
                ) : undefined}
                {assignTicketModalStore.assignTicketState === ASSIGN_TICKET_STATE.FINISHED ? (
                    <AssignTicketProgress
                        key={"assign-ticket-progress-finished"}
                        currentAssignTicketState={ASSIGN_TICKET_STATE.FINISHED}
                    />
                ) : undefined}
            </UI.Modal>
            <UI.Button
                label={t("screens.tickets.action.assign_ticket.assign")}
                onClick={toggleDisplayAssignTicketModal}
                disabled={
                    ticketStore.currentTicket?.state === TICKET_STATE.COMPLETED ||
                    ticketStore.currentTicket?.state === TICKET_STATE.ARCHIVED ||
                    ticketStore.isEditing ||
                    ticketStore.loadingType === LOADING_TYPE.ASSIGNING_TICKET_TO_USER ||
                    ticketStore.currentTicket?.realestate?.id === undefined
                }
                className={`ticket-button mt-2 ${
                    ticketStore.currentTicket?.state === TICKET_STATE.COMPLETED ||
                    ticketStore.currentTicket?.state === TICKET_STATE.ARCHIVED
                        ? styles.DisableAssignButton
                        : styles.AssignButton
                }`}
            />
        </>
    );
};

export const AssignTicketButtonAndModal = Sentry.withProfiler(observer(AssignTicketButtonAndModalBase));
