import { gql } from "@apollo/client";

export const GET_AGENDAITEMS_BY_ASSEMBLYID = gql`
    query GetAgendaitemsByAssemblyId($assemblyid: uuid!, $language: String! = "de") {
        agendaitem_parents: cond_agendaitems(
            where: { assemblyid: { _eq: $assemblyid }, parentagendaitemid: { _is_null: true } }
            order_by: { sort: asc }
        ) {
            id
            sort
            agendaitems_mls(where: { language: { _eq: $language } }) {
                language
                id
                text
                description
            }
            agendaitemfiles: v_agendaitemfiles(where: { roleid: { _eq: 32 } }) {
                id
                fileid
                mimetype
                name
                filedate
            }
            agendaitem_children: agendaitems {
                id
                sort
                parentagendaitemid
                agendaitems_mls(where: { language: { _eq: $language } }) {
                    language
                    id
                    text
                    description
                }
                agendaitemfiles: v_agendaitemfiles(where: { roleid: { _eq: 32 } }) {
                    id
                    fileid
                    mimetype
                    name
                    filedate
                }
            }
        }
    }
`;

export const GET_AGENDAFILES_BY_ASSEMBLYID = gql`
    query GetAgendaFilesByAssemblyId($assemblyid: uuid) {
        assemblyfiles: file_v_assemblyfiles(
            where: { assemblyid: { _eq: $assemblyid }, _and: { type: { _neq: 314 } } }
            order_by: { name: asc }
        ) {
            fileid
            type
            id
            extension
            filedate
            name
            mimetype
        }
    }
`;

export const UPSERT_AGENDAITEMS = gql`
    mutation UpsertAgendaItems($objects: [cond_agendaitems_insert_input!]!) {
        insert_cond_agendaitems(
            objects: $objects
            on_conflict: { constraint: PK_agendaitems, update_columns: [sort] }
        ) {
            returning {
                id
                assemblyid
                agendaitems_mls {
                    text
                    id
                    description
                }
            }
        }
    }
`;

export const DELETE_AGENDAITEM = gql`
    mutation DeleteAgendaItem($agendaitemId: uuid!) {
        delete_cond_agendaitems(where: { id: { _eq: $agendaitemId } }) {
            affected_rows
        }
    }
`;

export const GET_AGENDAITEM_TEMPLATES = gql`
    query GetAgendaitemsTemplates($language: String! = "de") {
        agendaitem_parents: cond_agendaitemtemplates(
            where: { parentagendaitemtemplateid: { _is_null: true } }
            order_by: { sort: asc }
        ) {
            id
            sort
            agendaitemtemplates_mls(where: { language: { _eq: $language } }) {
                language
                id
                text
                description
            }
            agendaitem_children: subagendaitemtemplates {
                id
                sort
                parentagendaitemtemplateid
                agendaitemtemplates_mls(where: { language: { _eq: $language } }) {
                    language
                    id
                    text
                    description
                }
            }
        }
    }
`;

export const DELETE_AGENDAITEM_TEMPLATES = gql`
    mutation DeleteAgendaItemTemplates($agendaitemIds: [uuid!]) {
        delete_cond_agendaitemtemplates(where: { id: { _in: $agendaitemIds } }) {
            affected_rows
        }
    }
`;

export const UPSERT_AGENDAITEMS_TEMPLATES = gql`
    mutation UpsertAgendaItemsTemplates($objects: [cond_agendaitemtemplates_insert_input!]!, $agendaitemIds: [uuid!]) {
        delete_cond_agendaitemtemplates(where: { id: { _in: $agendaitemIds } }) {
            affected_rows
        }

        insert_cond_agendaitemtemplates(
            objects: $objects
            on_conflict: { constraint: PK_agendaitemtemplates, update_columns: [sort] }
        ) {
            returning {
                id
                agendaitemtemplates_mls {
                    text
                    id
                    description
                }
            }
        }
    }
`;
