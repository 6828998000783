import React, { useCallback, useContext, useRef } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { hasTwoDecimalPlaces } from "src/stores/krediflow/Utils";
import styles from "./QuantityField.module.css";

interface IQuantityFieldProps {
    accountingRowIndex: number;
}

const QuantityFieldBase = (props: IQuantityFieldProps) => {
    const { t } = useTranslation();
    const { invoiceStore } = useContext(RootStoreContext);

    const { accountingRowIndex } = props;

    const quantityInputRef = useRef<HTMLInputElement>(null);

    const onChangeQuantityInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const inputQuantity = e.target.value;

            invoiceStore.updateAccountingWithQuantity(inputQuantity, accountingRowIndex);
        },
        [accountingRowIndex, invoiceStore]
    );

    const onBlurQuantityInput = useCallback(() => {
        const currentQuantity = invoiceStore.currentInvoice?.getAccountingQuantity(accountingRowIndex);
        const currentAmountNumber = Number(currentQuantity);

        let amountWithTwoDecimalPlaces = undefined;

        if (currentAmountNumber && !hasTwoDecimalPlaces(currentAmountNumber)) {
            amountWithTwoDecimalPlaces = currentAmountNumber.toFixed(2);
        }

        if (invoiceStore.currentInvoice && amountWithTwoDecimalPlaces) {
            invoiceStore.currentInvoice.setAccountingQuantity(amountWithTwoDecimalPlaces, accountingRowIndex);
        }

        if (invoiceStore.currentInvoice?.isValidatingAccountingsOnFieldLevel) {
            const accountingsSuccessfullyValidated = invoiceStore.currentInvoice.validateAccountings();

            if (accountingsSuccessfullyValidated) {
                invoiceStore.currentInvoice.setValidatingAccountingsOnFieldLevel(false);
            }
        }
    }, [invoiceStore.currentInvoice, accountingRowIndex]);

    const quantityUnit = invoiceStore.currentInvoice?.getAccountingAccount(accountingRowIndex)?.unitOfMeasure;

    return (
        <div
            className={`${
                invoiceStore.currentInvoice?.canEditAccountingQuantityField(accountingRowIndex) ? "" : "d-none"
            } ${styles.QuantityFieldContainer}`}
        >
            <UI.Input
                ref={quantityInputRef}
                placeholder={t("screens.kredi_flow.accountings.quantity").toString()}
                type="number"
                autoComplete="off"
                value={invoiceStore.currentInvoice?.getAccountingQuantity(accountingRowIndex) ?? ""}
                onChange={onChangeQuantityInput}
                onBlur={onBlurQuantityInput}
            />
            <span className="ml-1">{t(`screens.kredi_flow.accountings.quantity_unit.${quantityUnit}`)}</span>
        </div>
    );
};

export const QuantityField = Sentry.withProfiler(observer(QuantityFieldBase));
