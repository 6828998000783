import React from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { getDate } from "src/utils/Date";
import { getReactElementsFromStringArray, getReactElementsFromHtmlString } from "src/utils/News";
import { UI } from "@wwimmo/ui";
import { useNewsImage, IMAGE_STATE, IMAGE_ORIENTATION } from "src/hooks/news-image/useNewsImage";
import styles from "./NewsPreview.module.css";
import { ColorStyle } from "src/utils/Colors";
import { NetworkConfig } from "src/network/NetworkConfig";
import { GetNewsPreviewInformationById_news_newsfiles } from "src/api/generated/GetNewsPreviewInformationById";

interface NewsPreviewProps {
    title: string;
    lead: string;
    date: any | null;
    text: string[] | string;
    imageUrl: string;
    className?: string;
    newsFiles: GetNewsPreviewInformationById_news_newsfiles[];
}

const NewsPreviewBase = (props: NewsPreviewProps) => {
    const { t } = useTranslation();
    const { title, lead, date, text, imageUrl, newsFiles } = props;

    const { image, imageState, imageOrientation, imageContainerMinHeight } = useNewsImage(imageUrl);

    const previewText = Array.isArray(text)
        ? getReactElementsFromStringArray(text)
        : getReactElementsFromHtmlString(text);

    const newsFileList = (
        <div className={styles.NewsFileSelectionContainer}>
            {newsFiles.map((file, index) => (
                <div className={styles.NewsFilesItem} key={file.fileid + index}>
                    <a
                        key={file.fileid}
                        href={NetworkConfig.datafileUrl + file.fileid}
                        className={`${styles.DocumentInfoItemsContainer} link-underline`}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <UI.Icon icon={UI.SVGIcon.Documents} color={ColorStyle("white")} size="small" />
                        <div className={styles.FileName}>{file.name}</div>
                    </a>
                </div>
            ))}
        </div>
    );

    return (
        <div className={`${styles.NewsPreviewContainer} news-preview-container ${props.className ?? ""}`}>
            <div className={`${styles.NewsPreviewHeaderBar} news-preview-header-bar`}>
                {t("screens.realestate.news_preview.preview")}
            </div>
            <div className={`${styles.NewsPreviewContent} news-preview-content`}>
                <div className={styles.PreviewTitle}>{title}</div>
                <div className={styles.PreviewDate}>{getDate(date)}</div>
                <div className={styles.PreviewLead}>{lead}</div>
                <div
                    className={`${styles.PreviewText} ${
                        imageOrientation === IMAGE_ORIENTATION.PORTRAIT ? styles.Portrait : styles.Landscape
                    }`}
                    style={{ minHeight: imageContainerMinHeight ?? "default" }}
                >
                    {imageState === IMAGE_STATE.ERROR ? undefined : image}
                    {imageState === IMAGE_STATE.INIT ? (
                        <UI.RotatingSpinner className={styles.ImageLoadSpinner} size={30} noLogo />
                    ) : undefined}
                    {previewText}
                </div>
            </div>
            {newsFileList}
        </div>
    );
};

export const NewsPreview = Sentry.withProfiler(observer(NewsPreviewBase));
