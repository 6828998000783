import React, { useCallback, useContext, useEffect, useRef } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { ErpType } from "src/network/User";

interface IBookingTextFieldProps {
    accountingRowIndex: number;
}

const BookingTextFieldBase = (props: IBookingTextFieldProps) => {
    const { t } = useTranslation();
    const { invoiceStore, accountingAccountSearchStore, accountingCostcenterStore, authStore } =
        useContext(RootStoreContext);

    const { accountingRowIndex } = props;

    const bookingTextInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const erpType = authStore.user?.erpType;

        if (
            accountingAccountSearchStore.triggerFocusNextInput.isActive &&
            accountingAccountSearchStore.triggerFocusNextInput.index === accountingRowIndex
        ) {
            if (erpType === ErpType.RIMO) {
                bookingTextInputRef.current?.focus();
                accountingAccountSearchStore.setTriggerFocusNextInput(false, accountingRowIndex);
            }

            if (erpType === ErpType.IT2) {
                const accountRequiredCostcenter =
                    invoiceStore.currentInvoice?.getAccountingAccount(accountingRowIndex)?.requiresCostCenter;

                if (!accountRequiredCostcenter) {
                    bookingTextInputRef.current?.focus();
                    accountingAccountSearchStore.setTriggerFocusNextInput(false, accountingRowIndex);
                }
            }
        }

        // This useEffect only listens to changes in accountingAccountSearchStore.triggerFocusNextInput
        // eslint-disable-next-line
    }, [accountingAccountSearchStore.triggerFocusNextInput]);

    useEffect(() => {
        const erpType = authStore.user?.erpType;

        if (
            erpType === ErpType.IT2 &&
            accountingCostcenterStore.triggerFocusNextInput.isActive &&
            accountingCostcenterStore.triggerFocusNextInput.index === accountingRowIndex
        ) {
            bookingTextInputRef.current?.focus();
            accountingCostcenterStore.setTriggerFocusNextInput(false, accountingRowIndex);
        }
        // This useEffect only listens to changes in accountingCostcenterStore.triggerFocusNextInput
        // eslint-disable-next-line
    }, [accountingCostcenterStore.triggerFocusNextInput]);

    const onChangeBookingTextInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const inputAccount = e.target.value;

            invoiceStore.updateAccountingWithBookingText(inputAccount, accountingRowIndex);
        },
        [accountingRowIndex, invoiceStore]
    );

    const onBlurBookingTextField = useCallback(() => {
        invoiceStore.currentInvoice?.validateAccountingsOnFieldLevel();
    }, [invoiceStore.currentInvoice]);

    return (
        <UI.Input
            ref={bookingTextInputRef}
            placeholder={t("screens.kredi_flow.accountings.text").toString()}
            type="text"
            autoComplete="off"
            value={invoiceStore.currentInvoice?.getAccountingBookingText(accountingRowIndex) ?? ""}
            onChange={onChangeBookingTextInput}
            onBlur={onBlurBookingTextField}
        />
    );
};

export const BookingTextField = Sentry.withProfiler(observer(BookingTextFieldBase));
