import { useCallback, useContext } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import styles from "./InvoiceAccountings.module.css";
import { RealestateField } from "./accounting/RealestateField";
import { InvoiceAccounting } from "./accounting/InvoiceAccounting";
import { ErpType } from "src/network/User";
import { LOADING_TYPE_ACCOUNTING } from "src/stores/krediflow/InvoiceEnums";

const InvoiceAccoutingsBase = () => {
    const { t } = useTranslation();
    const { invoiceStore, authStore } = useContext(RootStoreContext);

    const onChangeAccountingDate = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            let newValue: any = e.target.value;

            if (invoiceStore.currentInvoice) {
                invoiceStore.currentInvoice.setAccountingDate(newValue);
            }

            invoiceStore.currentInvoice?.validateAccountingsOnFieldLevel();
        },
        [invoiceStore.currentInvoice]
    );

    const addAccountingRow = useCallback(() => {
        invoiceStore.currentInvoice?.addAccountingRow();
    }, [invoiceStore]);

    const addAccountingButtonLabel = (
        <div className="d-flex align-items-center">
            <UI.Icon icon={UI.SVGIcon.Plus} color={"white"} size={25} />
            <div>{t("screens.kredi_flow.accountings.add_accounting")}</div>
        </div>
    );

    const saveInvoicesButtonLabel = (
        <div className="d-flex align-items-center">
            <div>{t("labels.save")}</div>
            <UI.RotatingSpinner
                className={`${styles.AccountingSaveButtonSpinner} ${
                    invoiceStore.loadingTypeAccounting !== LOADING_TYPE_ACCOUNTING.SAVING_ACCOUNTINGS ? "d-none" : ""
                }`}
                noLogo={true}
                size={20}
            />
        </div>
    );

    const saveAccountings = useCallback(
        (closeEditModeAfterSave: boolean = true) =>
            async (e: any) => {
                // if (invoiceStore.isAccountingEditing) {  // TODO: Uncomment once editing is implemented
                if (e) {
                    e.preventDefault();
                }

                const accountingsSuccessfullyValidated = invoiceStore.currentInvoice?.validateAccountings();

                if (accountingsSuccessfullyValidated) {
                    await invoiceStore.saveCurrentInvoiceAccountings(closeEditModeAfterSave);
                    invoiceStore.currentInvoice?.setValidatingAccountingsOnFieldLevel(false);
                } else {
                    invoiceStore.currentInvoice?.setValidatingAccountingsOnFieldLevel(true);
                }
                // }
            },
        [invoiceStore]
    );

    const onDiscardAccountingChanges = useCallback(() => {}, []);

    return (
        <>
            <UI.Row>
                <div className={`${styles.InvoiceAccountingsTitle} mb-3`}>
                    {t("screens.kredi_flow.accountings.section_title")}
                </div>
            </UI.Row>
            <UI.Row>
                <UI.Col lg={2}>
                    <UI.Input
                        label={`${t("screens.kredi_flow.accountings.date").toString()} *`}
                        type="date"
                        autoComplete="off"
                        value={
                            invoiceStore.currentInvoice && invoiceStore.currentInvoice.accountingDate
                                ? invoiceStore.currentInvoice.accountingDate
                                : ""
                        }
                        onChange={onChangeAccountingDate}
                    />
                </UI.Col>
            </UI.Row>
            {authStore.user?.erpType === ErpType.IT2 ? (
                <UI.Row>
                    <UI.Col lg={5}>
                        <RealestateField />
                    </UI.Col>
                </UI.Row>
            ) : (
                <></>
            )}

            {/* ACCOUNTING ROWS */}
            {invoiceStore.currentInvoice &&
            invoiceStore.currentInvoice.accountings &&
            invoiceStore.currentInvoice.accountings.length > 0
                ? invoiceStore.currentInvoice.accountings.map((accounting, index) => (
                      <InvoiceAccounting key={index} accounting={accounting} accountingIndex={index} />
                  ))
                : null}

            {!invoiceStore.currentInvoice?.totalAccountingAmountMatchesInvoiceAmount ? (
                <div className={`error-text ${styles.AccountingAmountErrorMessage}`}>
                    <span>{t("screens.kredi_flow.accountings.error.accounting_amounts_dont_match")}</span>
                </div>
            ) : (
                <></>
            )}

            <UI.Row>
                <UI.Button
                    onClick={addAccountingRow}
                    className={`${styles.AddAccountingRowButton} default-button-height my-4 ml-2`}
                    label={addAccountingButtonLabel}
                />
            </UI.Row>
            <UI.Row className={styles.AccountingsTotalAmountRow}>
                <UI.Col lg={{ span: 2, offset: 9 }}>
                    <div className={styles.AccountingsTotalAmountText}>
                        <span>{t("screens.kredi_flow.total")}</span>
                        <span className="ml-3">{invoiceStore.currentInvoice?.amount?.toFixed(2)}</span>
                    </div>
                </UI.Col>
            </UI.Row>
            <UI.Row className="mt-3">
                <UI.Col className={"flex-grow-0"}>
                    <UI.Button
                        onClick={saveAccountings(true)}
                        className={styles.SaveButton}
                        label={saveInvoicesButtonLabel}
                        disabled={invoiceStore.loadingTypeAccounting !== LOADING_TYPE_ACCOUNTING.NONE}
                    />
                </UI.Col>
                <UI.Col>
                    <UI.Button
                        label={t("labels.discard")}
                        disabled={
                            !invoiceStore.currentInvoice?.hasChangesAccounting ||
                            invoiceStore.loadingTypeAccounting === LOADING_TYPE_ACCOUNTING.SAVING_ACCOUNTINGS
                        }
                        onClick={onDiscardAccountingChanges}
                        className={`${styles.DiscardButton} default-button-height`}
                        variant="outline-primary"
                    />
                </UI.Col>
            </UI.Row>
        </>
    );
};

export const InvoiceAccountings = Sentry.withProfiler(observer(InvoiceAccoutingsBase));
