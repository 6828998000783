import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useSelectionListKeyHandler } from "src/hooks/ticket/selection-list-key-handler/useSelectionListKeyHandler";
import { boldenMatchedSubstring } from "src/utils/Common";
import { ICostCenter } from "src/stores/krediflow/InvoiceTypes";
import { ErpType } from "src/network/User";
import styles from "./CostcenterField.module.css";
import { LOADING_COSTCENTER_DATA_STATE } from "src/stores/krediflow/AccountingCostcenterSearchStore";

interface ICostcenterFieldProps {
    accountingRowIndex: number;
}

const CostcenterFieldBase = (props: ICostcenterFieldProps) => {
    const { t } = useTranslation();
    const { accountingCostcenterStore, accountingAccountSearchStore, invoiceStore, authStore } =
        useContext(RootStoreContext);

    const { accountingRowIndex } = props;

    const [displayCostcenterList, setDisplayCostcenterList] = useState<boolean>(false);
    const [lastScrollPositionYCostcenterItems, setLastSrollYPositionCostcenterItems] = useState<number>(0);

    const costcenterInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const erpType = authStore.user?.erpType;
        if (
            erpType === ErpType.IT2 &&
            accountingAccountSearchStore.triggerFocusNextInput.isActive &&
            accountingAccountSearchStore.triggerFocusNextInput.index === accountingRowIndex
        ) {
            const costCenterRequiresCostcenter =
                invoiceStore.currentInvoice?.getAccountingAccount(accountingRowIndex)?.requiresCostCenter;

            if (costCenterRequiresCostcenter) {
                costcenterInputRef.current?.focus();
                accountingAccountSearchStore.setTriggerFocusNextInput(false, accountingRowIndex);
            }
        }
        // This useEffect only listens to changes in accountingAccountSearchStore.triggerFocusNextInput
        // eslint-disable-next-line
    }, [accountingAccountSearchStore.triggerFocusNextInput]);

    const resetSearchQuery = useCallback(() => {
        const currentSelectedCostcenterQueryString =
            accountingCostcenterStore.getSelectedCostcenterQueryString(accountingRowIndex);

        accountingCostcenterStore.setCurrentCostcenterSearchQuery(
            currentSelectedCostcenterQueryString ?? "",
            accountingRowIndex
        );
    }, [accountingCostcenterStore, accountingRowIndex]);

    useEffect(() => {
        const handleClickOutsideAccountInput = (event: any) => {
            const target = event.target as HTMLElement;

            const isClickOutsideAccountInputOrList = () => {
                if (!displayCostcenterList || !costcenterInputRef.current) {
                    return true;
                }

                const clickedInsideInput = costcenterInputRef.current.contains(target);

                const clickedListItem =
                    target.classList.contains("list-item") && target.classList.contains("accounting-costcenter");

                const clickedIcon = target.localName === "path" || target.localName === "svg";

                const clickedHighlightedText =
                    target.classList.contains("highlighted-text") && target.classList.contains("accounting-costcenter");

                const clickedListItemChildElement =
                    target.parentElement?.classList.contains("list-item") &&
                    target.parentElement.classList.contains("accounting-costcenter");

                return !(
                    clickedInsideInput ||
                    clickedListItem ||
                    clickedListItemChildElement ||
                    clickedIcon ||
                    clickedHighlightedText
                );
            };

            if (isClickOutsideAccountInputOrList()) {
                setDisplayCostcenterList(false);

                if (
                    invoiceStore.currentInvoice?.isAccountingCostCenterSelected(accountingRowIndex) &&
                    accountingCostcenterStore.getCurrentCostcenterSearchQuery(accountingRowIndex) === ""
                ) {
                    invoiceStore.resetAccountingCostcenterFormFields(accountingRowIndex);
                } else {
                    resetSearchQuery();
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutsideAccountInput);

        return () => {
            document.removeEventListener("mousedown", handleClickOutsideAccountInput);
        };
    }, [
        displayCostcenterList,
        resetSearchQuery,
        accountingCostcenterStore,
        invoiceStore.currentInvoice?.isAccountingRealestateSelected,
        accountingRowIndex,
        invoiceStore
    ]);

    const updateAccountingWithSelectedCostcenter = useCallback(
        async (costCenter: ICostCenter) => {
            invoiceStore.updateAccountingWithSelectedCostcenter(costCenter, true, accountingRowIndex);
        },
        [invoiceStore, accountingRowIndex]
    );

    const onSelectCostcenterItem = useCallback(
        (activeListItemIndex: number) => {
            const selectedCostcenterItem =
                accountingCostcenterStore.filteredCostcenterData(accountingRowIndex)[activeListItemIndex];

            const costcenter: ICostCenter = {
                id: selectedCostcenterItem.id,
                name: selectedCostcenterItem.name,
                short: selectedCostcenterItem.short
            };

            updateAccountingWithSelectedCostcenter(costcenter);
        },
        [updateAccountingWithSelectedCostcenter, accountingCostcenterStore, accountingRowIndex]
    );

    const focusOnNextAccountingField = useCallback(() => {
        accountingCostcenterStore.setTriggerFocusNextInput(true, accountingRowIndex);
    }, [accountingCostcenterStore, accountingRowIndex]);

    const { onKeyDownFunction, setActiveListItemIndex, NO_LIST_ITEM_SELECTED } = useSelectionListKeyHandler({
        listName: "accounting-costcenter",
        totalNumberOfDisplayedItems: invoiceStore.numberOfDisplayedItems,
        setIsListDisplayed: setDisplayCostcenterList,
        isListDisplayed: displayCostcenterList,
        onSelectItem: onSelectCostcenterItem,
        resetSearchQuery: resetSearchQuery,
        additionalTabActionWithNoItemSelected: focusOnNextAccountingField
    });

    const resetAmountOfItemsDisplayedInCostcenterList = useCallback(() => {
        setLastSrollYPositionCostcenterItems(0);
        invoiceStore.setNumberOfDisplayedItems(invoiceStore.sliceSizeOfDisplayedItems);
    }, [invoiceStore]);

    const onChangeCostcenterInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            resetAmountOfItemsDisplayedInCostcenterList();

            const inputAccount = e.target.value;
            accountingCostcenterStore.setCurrentCostcenterSearchQuery(inputAccount, accountingRowIndex);
        },
        [accountingCostcenterStore, resetAmountOfItemsDisplayedInCostcenterList, accountingRowIndex]
    );

    const onFocusCostcenterInput = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            if (
                invoiceStore.currentInvoice?.isAccountingCostCenterSelected(accountingRowIndex) &&
                costcenterInputRef.current
            ) {
                costcenterInputRef.current.select();
            }

            accountingCostcenterStore.loadCostcenterData(accountingRowIndex);

            resetAmountOfItemsDisplayedInCostcenterList();
            setDisplayCostcenterList(true);
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
        },
        [
            resetAmountOfItemsDisplayedInCostcenterList,
            setActiveListItemIndex,
            NO_LIST_ITEM_SELECTED,
            invoiceStore.currentInvoice,
            accountingRowIndex,
            accountingCostcenterStore
        ]
    );

    const onClickCostcenterItem = useCallback(
        (costCenterData: ICostCenter) => async (e: any) => {
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
            setDisplayCostcenterList(false);

            const costCenter: ICostCenter = {
                id: costCenterData.id,
                name: costCenterData.name,
                short: costCenterData.short
            };

            updateAccountingWithSelectedCostcenter(costCenter);
        },
        [setActiveListItemIndex, NO_LIST_ITEM_SELECTED, updateAccountingWithSelectedCostcenter]
    );

    const onScrollCostcenterList = useCallback(
        (e: React.BaseSyntheticEvent) => {
            const windowHeight = e.target.clientHeight;
            const pixelsFromTop = e.target.scrollTop;
            const totalHeight = e.target.scrollHeight;

            const is150PixelsFromBottom = totalHeight - pixelsFromTop < windowHeight + 150;
            const isScrollingDown = pixelsFromTop > lastScrollPositionYCostcenterItems;

            const hasMoreResulsToDisplay =
                invoiceStore.numberOfDisplayedItems <
                accountingCostcenterStore.filteredCostcenterData(accountingRowIndex).length;

            if (is150PixelsFromBottom && isScrollingDown && hasMoreResulsToDisplay) {
                invoiceStore.setNumberOfDisplayedItems(
                    invoiceStore.numberOfDisplayedItems + invoiceStore.sliceSizeOfDisplayedItems
                );
            }

            setLastSrollYPositionCostcenterItems(pixelsFromTop);
        },
        [lastScrollPositionYCostcenterItems, invoiceStore, accountingCostcenterStore, accountingRowIndex]
    );

    const costcenterSelectionList = (
        <ul
            id="accounting-costcenter-dropdown-list"
            className={`dropdown-list accounting-costcenter krediflow ${
                authStore.user?.erpType === ErpType.IT2 ? "IT2" : "RIMO"
            }`}
            onScroll={onScrollCostcenterList}
        >
            {accountingCostcenterStore.loadingCostcenterDataState === LOADING_COSTCENTER_DATA_STATE.LOADING ? (
                <li className="d-flex align-items-center justify-content-center list-item accounting-costcenter loading">
                    <div>
                        <UI.RotatingSpinner noLogo size={20} />
                    </div>
                </li>
            ) : (
                accountingCostcenterStore
                    .filteredCostcenterData(accountingRowIndex)
                    .slice(0, invoiceStore.numberOfDisplayedItems)
                    .map((costCenter, index) => {
                        const costcenterName = accountingCostcenterStore.getFormattedCostcenterFieldValue(costCenter);

                        const currentCostcenterSearchQuery =
                            accountingCostcenterStore.getCurrentCostcenterSearchQuery(accountingRowIndex);

                        const formattedCostcenterName = boldenMatchedSubstring(
                            currentCostcenterSearchQuery ?? "",
                            costcenterName,
                            "highlighted-text accounting-costcenter"
                        );

                        return (
                            <li
                                key={index}
                                className="d-flex align-items-center list-item accounting-costcenter"
                                onClick={onClickCostcenterItem(costCenter)}
                                id={`accounting-costcenter-list-item-${index}`}
                            >
                                <div
                                    className={`accounting-costcenter-text ${styles.AccountingAccountText}`}
                                    dangerouslySetInnerHTML={{
                                        __html: formattedCostcenterName
                                    }}
                                />
                            </li>
                        );
                    })
            )}
        </ul>
    );

    return (
        <>
            <UI.Input
                ref={costcenterInputRef}
                className={
                    invoiceStore.currentInvoice?.canEditAccountingCostcenterField(accountingRowIndex) ? "" : "d-none"
                }
                autoComplete="off"
                placeholder={t("screens.kredi_flow.accountings.costcenter").toString()}
                type="text"
                value={accountingCostcenterStore.getCurrentCostcenterSearchQuery(accountingRowIndex) ?? ""}
                onChange={onChangeCostcenterInput}
                onFocus={onFocusCostcenterInput}
                onKeyDown={onKeyDownFunction}
                disabled={!invoiceStore.currentInvoice?.canEditAccountingCostcenterField(accountingRowIndex)}
            />
            {displayCostcenterList ? costcenterSelectionList : undefined}
        </>
    );
};

export const CostcenterField = Sentry.withProfiler(observer(CostcenterFieldBase));
