import { action, makeObservable, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { IAccount, ICostCenter } from "./InvoiceTypes";
import { ErpType } from "src/network/User";
import { GET_COSTCENTER_DATA } from "src/api/cred";
import { apolloClientInstance } from "src/network/apolloClientInstance";
import { GetCostCenterData, GetCostCenterDataVariables } from "src/api/generated/GetCostCenterData";

export enum LOADING_COSTCENTER_DATA_STATE {
    INIT,
    LOADING
}

export class AccountingCostcenterStore {
    rootStore: RootStore;

    erpType: ErpType = ErpType.IT2;

    costcenterData: ICostCenter[] = [];
    loadingCostcenterDataState: LOADING_COSTCENTER_DATA_STATE = LOADING_COSTCENTER_DATA_STATE.INIT;
    loadedCostcenterDataWithVariables: { accountId: string } | undefined = undefined;

    // This is an array because every IT2 Accounting Row has its own costcenter value
    currentCostcenterSearchQuery: string[] = [];
    selectedCostcenterQueryString: string[] = [];
    selectedCostcenterName: string[] = [];

    triggerFocusNextInput: { isActive: boolean; index: number } = { isActive: false, index: 0 };

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            costcenterData: observable,
            currentCostcenterSearchQuery: observable,
            selectedCostcenterQueryString: observable,
            selectedCostcenterName: observable,
            triggerFocusNextInput: observable,
            loadingCostcenterDataState: observable,
            loadedCostcenterDataWithVariables: observable,

            init: action,
            reset: action,
            loadCostcenterData: action,
            setLoadingCostcenterDataState: action,
            setCurrentCostcenterSearchQuery: action,
            setSelectedCostcenterQueryString: action,
            setSelectedCostcenterName: action,
            setTriggerFocusNextInput: action,
            filteredCostcenterData: action,
            setCostcenterData: action
        });
    }

    init = () => {};

    reset = () => {
        this.currentCostcenterSearchQuery = [];
        this.selectedCostcenterQueryString = [];
        this.selectedCostcenterName = [];
    };

    loadCostcenterData = async (accountingRowIndex: number): Promise<ICostCenter[] | undefined> => {
        this.setLoadingCostcenterDataState(LOADING_COSTCENTER_DATA_STATE.LOADING);

        let accountData: ICostCenter[] = [];

        const currentInvoice = this.rootStore.invoiceStore.currentInvoice;
        const accountId = currentInvoice?.getAccountingAccount(accountingRowIndex)?.id;

        const hasAlreadyLoadedCostcenterData = this.loadedCostcenterDataWithVariables?.accountId === accountId;

        if (accountId && !hasAlreadyLoadedCostcenterData) {
            this.loadedCostcenterDataWithVariables = {
                accountId: accountId
            };

            const { data }: { data: GetCostCenterData } = await apolloClientInstance.query<
                GetCostCenterData,
                GetCostCenterDataVariables
            >({
                query: GET_COSTCENTER_DATA,
                variables: {
                    accountid: accountId
                }
            });

            accountData =
                data.accountcostcenters && data.accountcostcenters.length > 0
                    ? data.accountcostcenters.map((accountCostcenter) => {
                          const costCenter = accountCostcenter.costcenter;

                          return {
                              id: costCenter?.id,
                              short: costCenter?.short,
                              name: costCenter?.name
                          };
                      })
                    : [];

            this.setCostcenterData(accountData);
        }

        this.setLoadingCostcenterDataState(LOADING_COSTCENTER_DATA_STATE.INIT);

        return accountData;
    };

    // Getters
    getFormattedCostcenterFieldValue = (costCenter: ICostCenter): string => {
        return `${costCenter.short}, ${costCenter.name}`;
    };

    getCurrentCostcenterSearchQuery(accountingRowIndex: number) {
        return this.currentCostcenterSearchQuery[accountingRowIndex];
    }

    getSelectedCostcenterQueryString(accountingRowIndex: number) {
        return this.selectedCostcenterQueryString[accountingRowIndex];
    }

    getSelectedCostcenterName(accountingRowIndex: number) {
        return this.selectedCostcenterName[accountingRowIndex];
    }

    // Setters
    setLoadingCostcenterDataState(loadingCostcenterDataState: LOADING_COSTCENTER_DATA_STATE) {
        this.loadingCostcenterDataState = loadingCostcenterDataState;
    }

    setCostcenterData(data: IAccount[]) {
        this.costcenterData = data;
    }

    setCurrentCostcenterSearchQuery(currentCostcenterSearchQuery: string, accountingRowIndex: number) {
        this.currentCostcenterSearchQuery[accountingRowIndex] = currentCostcenterSearchQuery;
    }

    setSelectedCostcenterQueryString(selectedCostcenterQueryString: string, accountingRowIndex: number) {
        this.selectedCostcenterQueryString[accountingRowIndex] = selectedCostcenterQueryString;
    }

    setSelectedCostcenterName(selectedCostcenterName: string, accountingRowIndex: number) {
        this.selectedCostcenterName[accountingRowIndex] = selectedCostcenterName;
    }

    /*  This function triggers the focus on the next field after selecting an account
        For IT2 this will be the BookingText field in the current accounting row
        For RIMO this field will not be displayed in the first place
    */
    setTriggerFocusNextInput(triggerFocusNextInput: boolean, accountingRowIndex: number) {
        this.triggerFocusNextInput = {
            isActive: triggerFocusNextInput,
            index: accountingRowIndex
        };
    }

    filteredCostcenterData(accountingRowIndex: number): ICostCenter[] {
        let currentCostcenterSearchQuery = this.currentCostcenterSearchQuery[accountingRowIndex] ?? "";
        let selectedCostcenterQueryString = this.selectedCostcenterQueryString[accountingRowIndex] ?? "";

        if (currentCostcenterSearchQuery === selectedCostcenterQueryString) {
            return this.costcenterData;
        }

        if (currentCostcenterSearchQuery === "") {
            return this.costcenterData;
        }

        const filteredAccountData = this.costcenterData.filter((costCenter) => {
            const constcenterName = this.getFormattedCostcenterFieldValue(costCenter);

            return constcenterName.toLowerCase().includes(currentCostcenterSearchQuery.toLowerCase());
        });

        return filteredAccountData;
    }
}
