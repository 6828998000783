import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { ScreenWidth } from "src/stores/UIStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { TicketForm } from "./form/TicketForm";
import styles from "./TicketDetails.module.css";
import { LOADING_TYPE, TICKET_STATE } from "src/stores/TicketStore";
import { Error } from "src/screens/error/Error";
import { Route, getParams, selectRoute } from "src/config/routes";
import { TicketActionCard } from "./cards/TicketActionCard";
import { TicketContactCard } from "./cards/TicketContactCard";
import { TicketWorkflowCard } from "./cards/TicketWorkflowCard";
import { OrderLinkListCard } from "./cards/OrderLinkListCard";
import { TicketHistory } from "./history/TicketHistory";

const TicketDetailsBase = () => {
    const { t } = useTranslation();
    const { navStore, ticketStore, uiStore, authStore } = useContext(RootStoreContext);
    const { currentTicket } = ticketStore;

    const role = authStore.user?.role;
    let params = getParams(Route.ticketDetails, role);
    const ticketNumber = params["ticketnumber"];

    useEffect(() => {
        navStore.setTitle("");
        navStore.setActionButtonMiddle(null);
        navStore.setActionButtonRight(null);

        navStore.setBackbutton(
            selectRoute(Route.tickets, authStore.user?.role),
            t("screens.tickets.sidebar_title").toString()
        );
    }, [navStore, authStore, t, ticketStore]);

    // Initialize Data for Ticket
    useEffect(() => {
        async function initializeTicketData() {
            await ticketStore.init(ticketNumber);
        }
        initializeTicketData();
    }, [ticketStore, ticketNumber]);

    if (ticketStore.error) {
        return <Error message={ticketStore.error.title} />;
    }
    if (!currentTicket) {
        return <></>;
    }

    return (
        <UI.Container
            className={`pt-3 pt-md-5 ${styles.TicketDetailsContainer} ${
                !uiStore.isMobile && uiStore.isSidebarCollapsed ? styles.ExtraPadding : ""
            }`}
            fluid
        >
            <div className="px-2 px-md-0">
                <UI.Title>
                    {ticketStore.currentTicket?.isNewTicket
                        ? t("screens.tickets.new_ticket")
                        : t("screens.tickets.ticket")}
                </UI.Title>
            </div>
            <UI.Row>
                <UI.Col sm={12} lg={9}>
                    {ticketStore.loadingType === LOADING_TYPE.TICKET ? (
                        <UI.RotatingSpinner noLogo size={50} className={styles.LoadingTicketSpinner} />
                    ) : (
                        <>
                            <TicketForm />
                            {uiStore.isMobile ? undefined : <TicketHistory />}
                        </>
                    )}
                </UI.Col>

                <UI.Col sm={12} lg={3}>
                    {ticketStore.currentTicket?.isNewTicket ? undefined : (
                        <>
                            {window.innerWidth <= ScreenWidth.MD && <UI.Spacer />}
                            <TicketWorkflowCard />
                            {ticketStore.currentTicket?.state !== TICKET_STATE.ARCHIVED ? (
                                <TicketActionCard />
                            ) : undefined}
                        </>
                    )}
                    {ticketStore.currentTicket?.orders && !ticketStore.currentTicket?.isNewTicket ? (
                        <OrderLinkListCard
                            orders={ticketStore.currentTicket?.orders!}
                            ticketNumber={
                                ticketStore.currentTicket.number ? ticketStore.currentTicket.number.toString() : ""
                            }
                        />
                    ) : undefined}
                    {currentTicket.incidentPersons && currentTicket.incidentPersons.length !== 0 && (
                        <TicketContactCard incidentPersons={currentTicket.incidentPersons} />
                    )}
                </UI.Col>
            </UI.Row>
        </UI.Container>
    );
};

export const TicketDetails = Sentry.withProfiler(observer(TicketDetailsBase));
