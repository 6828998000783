import { gql } from "@apollo/client";

export const GET_PORTAL_ACCOUNTLISTS_BY_REALESTATEID = gql`
    query PortalAccountlistsByRealestateId($realestateid: uuid!, $lang: String!) {
        common_enumerations(where: { type: { _eq: 310 }, language: { _eq: $lang } }) {
            label
            key
        }
        portal_accountlists(
            where: { realestateid: { _eq: $realestateid } }
            order_by: [{ enddate: desc }, { type: desc }]
        ) {
            id
            title
            subtitle
            type
        }
    }
`;

export const GET_PORTAL_ACCOUNTLIST_BY_ID = gql`
    fragment portal_accounts_part on portal_accounts {
        id
        title
        type
        debit
        credit
        number
        bookings {
            id
            accountid
            text
            credit
            debit
            date
            quantity
            bookingfiles: v_bookingfiles(order_by: { filedate: desc, fileid: desc }, distinct_on: fileid) {
                bookingfileid: id
                fileid
                name
                filedate
            }
        }
    }

    query PortalAccountlistById($id: uuid!) {
        portal_accountlists(where: { id: { _eq: $id } }) {
            id
            accounts(
                where: { parentaccountid: { _is_null: true } }
                order_by: [{ sort: asc }, { secondarysort: asc }, { number: asc }]
            ) {
                ...portal_accounts_part
                accounts(order_by: [{ sort: asc }, { secondarysort: asc }, { number: asc }]) {
                    ...portal_accounts_part
                    accounts(order_by: [{ sort: asc }, { secondarysort: asc }, { number: asc }]) {
                        ...portal_accounts_part
                        accounts(order_by: [{ sort: asc }, { secondarysort: asc }, { number: asc }]) {
                            ...portal_accounts_part
                            accounts(order_by: [{ sort: asc }, { secondarysort: asc }, { number: asc }]) {
                                ...portal_accounts_part
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_PORTAL_ACCOUNTLIST_FILES = gql`
    query GetAccountFilesList($accountlistid: uuid!) {
        accountlistfiles: file_v_accountlistfiles(
            where: { accountlistid: { _eq: $accountlistid } }
            order_by: [{ filedate: desc }, { name: asc }]
        ) {
            id: fileid
            name
            filedate
        }
    }
`;
