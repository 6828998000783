import { observable, action, makeObservable, runInAction } from "mobx";
import { RootStore } from "./RootStore";
import { apolloClientInstance } from "src/network/apolloClientInstance";
import { GET_ALL_ORDERS_OF_TICKET, GET_ORDER_BY_NUMBER, GET_ORDER_URL_FOR_YAROWA } from "src/api/order";
import i18next from "i18next";
import { format } from "date-fns";
import { de, fr, enGB, it } from "date-fns/locale";
import {
    GetOrderByNumber,
    GetOrderByNumberVariables,
    GetOrderByNumber_orders,
    GetOrderByNumber_orders_orderfiles
} from "src/api/generated/GetOrderByNumber";
import {
    GetAllOrdersOfTicket,
    GetAllOrdersOfTicketVariables,
    GetAllOrdersOfTicket_orders,
    GetAllOrdersOfTicket_orders_orderfiles
} from "src/api/generated/GetAllOrdersOfTicket";
import { Ticket } from "./TicketStore";
import { NetworkConfig } from "src/network/NetworkConfig";
import { getRoleKey } from "src/network/User";
import { MessageType } from "src/components/notifications/Notifier";
import { GetOrderUrl } from "src/api/generated/GetOrderUrl";
import { ConnectionExtraData } from "src/api/ticket";

export enum ORDER_STATE {
    NEW = 0,
    SUBMITTED = 10,
    SUBMISSION_FAILED = 60,
    DONE = 90
}

export enum CRAFT_TYPE {
    TBD = 10
}

export enum CONTRACTOR_TYPE {
    SERVICE = 1,
    YAROWA = 2,
    SERVICE_7000 = 3
}

export interface IContractor {
    id?: string;
    type?: CONTRACTOR_TYPE;
    name1?: string;
    name2?: string;
    email?: string;
    mobile?: string;
    phoneBusiness?: string;
    phonePrivate?: string;
    street?: string;
    zip?: string;
    city?: string;
}

export enum ORDER_LOADING_TYPE {
    NONE,
    ORDER,
    INITIALIZING,
    CLOSING_ORDER,
    RETRY_SUBMISSION
}

export enum ORDER_PROPERTY {
    ID = "id",
    WORKFLOWINSTANCE = "workflowinstance",
    TICKET_NUMBER = "ticketNumber",
    NUMBER = "number",
    DATE = "date",
    TITLE = "title",
    STATE = "state",
    INSTRUCTION = "instruction",
    CRAFT = "craft",
    CONTRACTOR = "contractor"
}

export class OrderStore {
    rootStore: RootStore;

    orders: Order[] = [];
    currentOrderTicketNumber?: number;
    currentOrderTicket?: Ticket;
    currentOrder: Order | undefined;
    isEditing: boolean = false;
    loadingType: ORDER_LOADING_TYPE = ORDER_LOADING_TYPE.NONE;

    error?: {
        title: string;
    } = undefined;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            orders: observable,
            currentOrderTicket: observable,
            currentOrderTicketNumber: observable,
            currentOrder: observable,
            isEditing: observable,
            loadingType: observable,

            error: observable,

            init: action,
            loadAllOrders: action,
            setCurrentOrder: action,
            setOrders: action,
            setLoadingType: action,
            loadCurrentOrder: action,
            setIsEditing: action,
            setCurrentOrderTicketNumber: action,
            setCurrentOrderTicket: action
        });
    }

    init = async (ticketnumber: string, orderNumber: string) => {
        this.setLoadingType(ORDER_LOADING_TYPE.INITIALIZING);

        const isOrderInStore = this.orders.find((order) => order.number === Number(orderNumber)) !== undefined;
        await this.loadCurrentOrder(ticketnumber, orderNumber, isOrderInStore);

        const ticket = await this.rootStore.ticketStore.getTicketByNumber(Number(ticketnumber));

        if (ticket) {
            this.setCurrentOrderTicket(ticket);
        }

        this.setLoadingType(ORDER_LOADING_TYPE.NONE);
    };

    getOrderStateByNumber = (id: number): ORDER_STATE => {
        switch (id) {
            case 0:
                return ORDER_STATE.NEW;
            case 10:
                return ORDER_STATE.SUBMITTED;
            case 60:
                return ORDER_STATE.SUBMISSION_FAILED;
            case 90:
                return ORDER_STATE.DONE;
            default:
                return ORDER_STATE.NEW;
        }
    };

    getOrderPropertiesObjectFromIncidentQuery = (
        order: GetOrderByNumber_orders | GetAllOrdersOfTicket_orders
    ): IOrderProperties => {
        const title = order.orders_mls.length > 0 ? order.orders_mls[0].title : "";
        const instruction =
            order.orders_mls.length > 0 && order.orders_mls[0].instruction ? order.orders_mls[0].instruction : "";

        const contractorPerson = order.person ?? undefined;
        const contractorAddress = contractorPerson ? contractorPerson.addresses[0] : undefined;

        const contractor: IContractor = {
            id: contractorPerson?.id,
            type: order.contractortype,
            name1: contractorPerson?.name1,
            name2: contractorPerson?.name2 ?? undefined,
            email: contractorPerson?.email ?? undefined,
            mobile: contractorPerson?.mobile ?? undefined,
            phoneBusiness: contractorPerson?.phonebusiness ?? undefined,
            phonePrivate: contractorPerson?.phoneprivate ?? undefined,
            street: contractorAddress?.street ?? undefined,
            zip: contractorAddress?.zip ?? undefined,
            city: contractorAddress?.city ?? undefined
        };

        const orderData: IOrderProperties = {
            id: order.id,
            workflowinstance: order.workflowinstance,
            title: title ?? "",
            instruction: instruction,
            state: this.getOrderStateByNumber(order.state),
            date: order.date,
            number: order.number,
            ticketNumber: order.incident ? order.incident.number : undefined,
            craft: order.craft,
            contractortype: order.contractortype,
            contractor: contractor,
            orderfiles: order.orderfiles
        };

        return orderData;
    };

    loadCurrentOrder = async (ticketNumber: string, orderNumber: string, isOrderInStore: boolean) => {
        this.setIsEditing(false);

        if (isOrderInStore) {
            /* TICKET IS IN STORE */
            const orderInStore = this.orders.find((order) => order.number === Number(orderNumber));
            this.currentOrder = new Order(this.rootStore, orderInStore);
        } else {
            /* FETCH TICKET FROM SERVER */
            this.setLoadingType(ORDER_LOADING_TYPE.ORDER);

            this.error = undefined;
            const { data }: { data: GetOrderByNumber } = await apolloClientInstance.query<
                GetOrderByNumber,
                GetOrderByNumberVariables
            >({
                query: GET_ORDER_BY_NUMBER,
                variables: {
                    ticketNumber: Number(ticketNumber),
                    orderNumber: Number(orderNumber),
                    language: "de"
                }
            });

            if (data.orders.length === 0) {
                const errorMessage = i18next.t("screens.orders.error.not_found");
                this.error = {
                    title: errorMessage
                };
                this.setLoadingType(ORDER_LOADING_TYPE.NONE);
                return;
            }

            const newOrderData = this.getOrderPropertiesObjectFromIncidentQuery(data.orders[0]);

            runInAction(() => {
                this.currentOrder = new Order(this.rootStore, newOrderData);
            });

            this.setLoadingType(ORDER_LOADING_TYPE.NONE);
        }
    };

    loadAllOrders = async () => {
        const { data }: { data: GetAllOrdersOfTicket } = await apolloClientInstance.query<
            GetAllOrdersOfTicket,
            GetAllOrdersOfTicketVariables
        >({
            query: GET_ALL_ORDERS_OF_TICKET,
            fetchPolicy: "no-cache",
            variables: { ticketNumber: this.currentOrderTicketNumber, language: "de" }
        });

        if (data) {
            const allOrders: Order[] = data.orders.map((order) => {
                const newOrderData = this.getOrderPropertiesObjectFromIncidentQuery(order);
                return new Order(this.rootStore, newOrderData);
            });

            this.setOrders(allOrders);
        }
    };

    closeCurrentOrder = async () => {
        this.setLoadingType(ORDER_LOADING_TYPE.CLOSING_ORDER);

        await this.currentOrder?.close();

        this.setLoadingType(ORDER_LOADING_TYPE.NONE);
    };

    retrySubmission = async () => {
        this.setLoadingType(ORDER_LOADING_TYPE.RETRY_SUBMISSION);

        await this.currentOrder?.retrySubmission();

        this.setLoadingType(ORDER_LOADING_TYPE.NONE);
    };

    getYarowaUrl = async (orderId: string): Promise<string> => {
        let url = "";
        if (orderId !== undefined && orderId !== "") {
            const { data }: { data: GetOrderUrl } = await apolloClientInstance.query<GetOrderUrl>({
                query: GET_ORDER_URL_FOR_YAROWA,
                variables: { id: orderId }
            });

            url = this.getYarowaLink(data);
        }

        return url;
    };

    getYarowaLink = (queryResult: GetOrderUrl): string => {
        let url = "";
        if (queryResult?.ticket_orders?.length > 0 && queryResult.ticket_orders[0].customer?.connection?.length > 0) {
            var extraData = queryResult.ticket_orders[0].customer.connection[0].extradata as ConnectionExtraData;
            if (extraData != null && extraData.frontend && extraData.frontend?.length > 0) {
                url = `${extraData.frontend}/dashboard`;
                if (queryResult.ticket_orders[0].id?.length > 0) {
                    const house = queryResult.ticket_orders[0].location?.house;
                    let city = "";
                    if (house !== undefined) {
                        const cityValue = house?.city ?? "";
                        const zipValue = house?.zip ?? "";

                        if (cityValue.length > 0 && zipValue.length > 0) {
                            city = `&address.zipCode=${zipValue}&address.municipality=${cityValue}`;
                            city = city
                                .trim()
                                .replace("ä", "ae")
                                .replace("ö", "oe")
                                .replace("ü", "ue")
                                .replace("ß", "ss");
                        }
                    }

                    url += `?ExternalSystemId=${queryResult.ticket_orders[0].id}${city}&action=CreateWO`;
                }
            }
        }

        return url;
    };

    // Setters
    setOrders = (orders: Order[]) => {
        this.orders = orders;
    };

    setLoadingType = (loadingType: ORDER_LOADING_TYPE) => {
        this.loadingType = loadingType;
    };

    setLoadingError = (title?: string) => {
        if (!title || title === "") {
            this.error = undefined;
        } else {
            this.error = { title: title };
        }
    };

    setCurrentOrder = (order: Order) => {
        this.currentOrder = order;
    };

    setIsEditing = (isEditing: boolean) => {
        this.isEditing = isEditing;
    };

    setCurrentOrderTicketNumber = (ticketNumber: number) => {
        this.currentOrderTicketNumber = ticketNumber;
    };

    setCurrentOrderTicket = (ticket: Ticket) => {
        this.currentOrderTicket = ticket;
    };
}

/********************************
      Order DOMAIN CLASS

      To add a new property you have to add it to
        - IorderProperties interface
        - ORDER_PROPERTY enum (make sure that the string representation of the property is written the same way as the property name)
        - Order class
            - Add property
            - Adjust constructor (initialize property + extend 'makeObservable')

 ********************************/

export interface IOrderProperties {
    id?: string;
    ticketNumber?: number;
    workflowinstance?: string;
    number?: number;
    state?: ORDER_STATE;
    date?: string;
    title?: string;
    instruction?: string; // NEW
    craft?: CRAFT_TYPE; // NEW
    contractortype?: CONTRACTOR_TYPE; // NEW
    contractor?: IContractor; // NEW
    orderfiles?: GetOrderByNumber_orders_orderfiles[] | GetAllOrdersOfTicket_orders_orderfiles[];
}

export class Order implements IOrderProperties {
    hasChanges: boolean = false;
    isValid: boolean = false;

    rootStore: RootStore;

    ticketNumber?: number;

    id?: string;
    workflowinstance?: string;
    number?: number;
    state?: ORDER_STATE;
    date?: string;
    title?: string;
    instruction?: string;
    craft?: CRAFT_TYPE;
    contractortype?: CONTRACTOR_TYPE;
    contractor?: IContractor;

    orderfiles?: GetOrderByNumber_orders_orderfiles[] | GetAllOrdersOfTicket_orders_orderfiles[];

    errors = {
        title: ""
    };

    getDefaultContractorDetailsObject = (): IContractor => {
        return {
            id: undefined,
            type: undefined,
            name1: undefined,
            name2: undefined,
            email: undefined,
            mobile: undefined,
            phoneBusiness: undefined,
            phonePrivate: undefined,
            street: undefined,
            zip: undefined,
            city: undefined
        };
    };

    constructor(rootStore: RootStore, orderFields?: IOrderProperties) {
        this.rootStore = rootStore;

        if (orderFields) {
            this.id = orderFields.id ?? undefined;
            this.workflowinstance = orderFields.workflowinstance ?? undefined;
            this.number = orderFields.number ?? undefined;
            this.ticketNumber = orderFields.ticketNumber ?? undefined;
            this.date = orderFields.date ?? undefined;
            this.title = orderFields.title ?? undefined;
            this.state = orderFields.state ?? ORDER_STATE.NEW;
            this.instruction = orderFields.instruction ?? undefined;
            this.craft = orderFields.craft ?? CRAFT_TYPE.TBD;
            this.contractortype = orderFields.contractortype ?? undefined;
            this.contractor = orderFields.contractor ?? this.getDefaultContractorDetailsObject();
            this.orderfiles = orderFields.orderfiles ?? [];
        } else {
            this.id = undefined;
            this.workflowinstance = undefined;
            this.number = undefined;
            this.ticketNumber = undefined;
            this.date = undefined;
            this.title = undefined;
            this.state = ORDER_STATE.NEW;
            this.instruction = undefined;
            this.craft = CRAFT_TYPE.TBD;
            this.contractortype = undefined;
            this.contractor = this.getDefaultContractorDetailsObject();
            this.orderfiles = [];
        }

        makeObservable(this, {
            hasChanges: observable,
            id: observable,
            number: observable,
            date: observable,
            title: observable,
            state: observable,
            errors: observable,
            isValid: observable,
            instruction: observable,
            craft: observable,
            contractor: observable,
            orderfiles: observable,

            updateProperty: action,
            close: action
        });
    }

    updateProperty = (field: keyof Order | string, value: any) => {
        if (field in this) {
            (this as any)[field] = value;
        }
    };

    close = async (): Promise<boolean> => {
        let orderSuccessfullyClosed = false;

        const requestBody = {
            workflowid: this.workflowinstance
        };

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            const response = await fetch(NetworkConfig.orderCloseUrl, {
                method: "POST",
                body: JSON.stringify(requestBody),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            if (response.status === 200) {
                orderSuccessfullyClosed = true;

                this.updateProperty(ORDER_PROPERTY.STATE, ORDER_STATE.DONE);
                await this.rootStore.ticketStore.currentTicket?.reloadHistory();

                this.rootStore.uiStore.printStatusMessage(
                    i18next.t("screens.orders.actions.close.success"),
                    MessageType.SUCCESS
                );
            } else {
                console.error("Error closing the incident: ", response);
                this.rootStore.uiStore.printTicketingErrorMessage(i18next.t("screens.orders.actions.close.failure"));
            }
        } catch (error) {
            console.error("Error while trying to close the incident: ", error);
            this.rootStore.uiStore.printTicketingErrorMessage(i18next.t("screens.orders.actions.close.failure"));
        }

        return orderSuccessfullyClosed;
    };

    retrySubmission = async (): Promise<boolean> => {
        let wasOrderSubmitSuccessful = false;

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            if (this.workflowinstance !== undefined && this.workflowinstance?.length > 0) {
                const orderRetrySubmissionVariables = {
                    workflowid: this.workflowinstance
                };

                const orderRetrySubmissionResponse = await fetch(NetworkConfig.orderRetrySubmission, {
                    method: "POST",
                    body: JSON.stringify(orderRetrySubmissionVariables),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${tokenType} ${accessToken}`,
                        "x-hasura-role": getRoleKey(role)
                    }
                });

                if (orderRetrySubmissionResponse.status === 200) {
                    this.updateProperty(ORDER_PROPERTY.STATE, ORDER_STATE.SUBMITTED);
                    await this.rootStore.ticketStore.currentTicket?.reloadHistory();

                    this.rootStore.uiStore.printStatusMessage(
                        i18next.t("screens.orders.actions.retry_submission.success"),
                        MessageType.SUCCESS
                    );

                    wasOrderSubmitSuccessful = true;
                } else {
                    const workflowinstance = this.workflowinstance.length === 0 ? "" : `'${this.workflowinstance}'`;
                    console.error(`Retry submission unsuccessful for order workflow ${workflowinstance}`);
                }
            }
        } catch (error) {
            const workflowinstance =
                this.workflowinstance === undefined || this.workflowinstance.length === 0
                    ? ""
                    : `'${this.workflowinstance}'`;
            console.error(`Error retry submission for order workflow ${workflowinstance}: `, error);
        }

        if (!wasOrderSubmitSuccessful) {
            this.rootStore.uiStore.printTicketingErrorMessage(
                i18next.t("screens.orders.actions.retry_submission.failure")
            );
        }

        return wasOrderSubmitSuccessful;
    };

    getDateStringForHasuraQuery = (date: any): string => {
        let locale: Locale = de;
        if (i18next.language.includes("de")) locale = de;
        if (i18next.language.includes("en")) locale = enGB;
        if (i18next.language.includes("fr")) locale = fr;
        if (i18next.language.includes("it")) locale = it;

        return format(date, "yyyy-MM-dd", { locale });
    };

    static formatDate = (dateString: string, short = false) => {
        if (short) {
            return format(new Date(dateString), "d.M.yy");
        }
        const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" };
        const [year, month, day] = dateString.split("-");
        const formattedDate = new Date(Number(year), Number(month) - 1, Number(day)).toLocaleDateString(
            i18next.language,
            options
        );

        const monthName = formattedDate.split(" ")[1];
        const dayNumber = parseInt(day);

        return `${dayNumber}. ${monthName} ${year}`;
    };

    static formatDateForImage = (date: Date) => {
        return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS");
    };
}
