import React, { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import styles from "./AmountField.module.css";
import { hasTwoDecimalPlaces } from "src/stores/krediflow/Utils";

interface IAmountFieldProps {
    accountingRowIndex: number;
}

const AmountFieldBase = (props: IAmountFieldProps) => {
    const { t } = useTranslation();
    const { invoiceStore } = useContext(RootStoreContext);

    const { accountingRowIndex } = props;

    const onChangeAmountInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const newAmount: any = e.target.value;

            if (invoiceStore.currentInvoice) {
                invoiceStore.currentInvoice.setAccountingAmount(newAmount, accountingRowIndex);
            }
        },
        [invoiceStore.currentInvoice, accountingRowIndex]
    );

    const onBlurAmountInput = useCallback(() => {
        const currentAmount = invoiceStore.currentInvoice?.getAccountingAmount(accountingRowIndex);
        const currentAmountNumber = Number(currentAmount);

        let amountWithTwoDecimalPlaces = undefined;

        if (currentAmountNumber && !hasTwoDecimalPlaces(currentAmountNumber)) {
            amountWithTwoDecimalPlaces = currentAmountNumber.toFixed(2);
        }

        if (invoiceStore.currentInvoice && amountWithTwoDecimalPlaces) {
            invoiceStore.currentInvoice.setAccountingAmount(amountWithTwoDecimalPlaces, accountingRowIndex);
        }
    }, [invoiceStore.currentInvoice, accountingRowIndex]);

    return (
        <UI.Input
            placeholder={t("screens.kredi_flow.accountings.amount").toString()}
            className={styles.AccountingAmountInput}
            type="number"
            autoComplete="off"
            value={invoiceStore.currentInvoice?.getAccountingAmount(accountingRowIndex) ?? ""}
            onChange={onChangeAmountInput}
            onBlur={onBlurAmountInput}
        />
    );
};

export const AmountField = Sentry.withProfiler(observer(AmountFieldBase));
