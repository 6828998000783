import React from "react";
import { Switch } from "react-router";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { Customer } from "src/components/customer/Customer";
import { PrivateRoute, PublicRoute } from "src/components";
import { Route } from "src/config/routes";
import { Auth } from "src/screens/auth";
import * as Screens from "src/screens";

export const MainRoutesBase = () => {
    return (
        <Switch>
            <PublicRoute path={Route.logout} component={Auth.Logout} />
            <PublicRoute path={Route.logoutSilent} component={Auth.LogoutSilent} />
            <PublicRoute path={Route.passwordrecovery} component={Auth.PasswordRecovery} />
            <PublicRoute path={Route.passwordreset} component={Auth.PasswordReset} />
            <PublicRoute path={Route.activate} component={Auth.Activate} />
            <PublicRoute path={Route.resendActivationEMail} component={Auth.ResendActivationEmail} />
            <PublicRoute path={Route.fileerror} component={Screens.FileError} />
            <PublicRoute path={Route.systemHealthError} component={Screens.SystemHealthError} />
            <PublicRoute path={Route.stwegDemoPage} component={Screens.StwegDemoPage} />
            <PublicRoute path={Route.userInfoPage} component={Screens.UserInfoPage} />

            <PrivateRoute path="/" component={Customer} />
        </Switch>
    );
};

export const MainRoutes = Sentry.withProfiler(observer(MainRoutesBase));
