import React, { useContext, useCallback } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { START_WORKFLOW_STATE } from "src/stores/krediflow/StartWorkflowStore";
import styles from "./StartWorkflow.module.css";

const StartWorkflowInvoiceBase = () => {
    const { startWorkflowStore } = useContext(RootStoreContext);
    const { t } = useTranslation();

    const startWorkflowButtonLabel = (
        <div className="d-flex align-items-center">
            <div>{t("screens.kredi_flow.action.start_workflow.title")}</div>
            <UI.RotatingSpinner
                className={`${styles.ButtonSpinner} ${
                    startWorkflowStore.startWorkflowState !== START_WORKFLOW_STATE.STARTING ? "d-none" : ""
                }`}
                noLogo={true}
                size={20}
            />
        </div>
    );

    const onStartWorkflow = useCallback(async () => {
        const canWorkflowBeStarted = startWorkflowStore.validateStartWorkflow();

        if (canWorkflowBeStarted) {
            await startWorkflowStore.startWorkflow();
        }
    }, [startWorkflowStore]);

    return (
        <div className="mb-2">
            <div className="d-flex align-items-center">
                <UI.Button
                    label={startWorkflowButtonLabel}
                    className={"mb-2 default-button-height"}
                    onClick={onStartWorkflow}
                    disabled={startWorkflowStore.startWorkflowState === START_WORKFLOW_STATE.STARTING}
                />
            </div>
        </div>
    );
};

export const StartWorkflow = Sentry.withProfiler(observer(StartWorkflowInvoiceBase));
