import React, { useContext, useCallback, useState } from "react";
import { CommonUnitById_unit_unitfiles as unitfile } from "src/api/generated/CommonUnitById";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI, ScreenSize } from "@wwimmo/ui";
import { useParams } from "react-router";
import { getDate } from "src/utils/Date";
import { NetworkConfig } from "src/network/NetworkConfig";
import { ColorStyle } from "src/utils/Colors";

interface UnitFileListProps {
    unitFiles: unitfile[];
}

interface Params {
    realestateid: string;
    unitid?: string;
}

const UnitFileList = (props: UnitFileListProps) => {
    const { t } = useTranslation();
    const { uiStore, navStore } = useContext(RootStoreContext);
    const { unitid } = useParams<Params>();

    const listIdentifier = `unitfilelist-${unitid}`;

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    const listParameters = navStore.getListMapValue(listIdentifier);

    let currentSearchQuery: string | undefined = undefined;

    if (listParameters && listParameters.searchQuery) {
        currentSearchQuery = listParameters.searchQuery;
    }

    const [displayedUnitFiles, setDisplayedUnitFiles] = useState<unitfile[]>([]);

    const filterUnitFilesAndSetSearchQuery = useCallback(
        (searchQuery: string = "") => {
            if (props.unitFiles) {
                let foundUnitFiles = props.unitFiles.filter((unitFile) => {
                    const name = unitFile.name;
                    const date = getDate(unitFile.filedate);

                    return (
                        name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        date?.toLowerCase().includes(searchQuery.toLowerCase())
                    );
                });

                setDisplayedUnitFiles([...foundUnitFiles]);
            }
        },
        [props.unitFiles]
    );

    const onPageChanged = useCallback(
        (currentPage: number) => {
            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: window.pageYOffset, paginationPage: currentPage }
                    : { scrollPosition: window.pageYOffset, paginationPage: currentPage }
            );
        },
        [navStore, listIdentifier, listParameters]
    );

    const handleSearchInput = useCallback(
        ({ searchQuery }) => {
            filterUnitFilesAndSetSearchQuery(searchQuery);

            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: 0, searchQuery: searchQuery }
                    : { scrollPosition: 0, searchQuery: searchQuery }
            );
        },
        [filterUnitFilesAndSetSearchQuery, navStore, listIdentifier, listParameters]
    );

    const renderItem = useCallback((unitFile: unitfile, screenSize: ScreenSize) => {
        const fileName = unitFile.name;
        const fileDate = getDate(unitFile.filedate);

        let listRows: React.ReactElement[] = [];

        switch (screenSize) {
            case ScreenSize.DESKTOP:
                listRows = [
                    <UI.List.Row key={"r-1"}>
                        <UI.List.Cell key={"c-1"} colspan={1} value={fileName ?? ""} />
                        <UI.List.Cell key={"c-2"} colspan={0.5} value={fileDate ?? ""} />
                    </UI.List.Row>
                ];
                break;

            case ScreenSize.MOBILE:
                listRows = [
                    <UI.List.Row key={"r-1"}>
                        <UI.List.Cell key={"c-1"} colspan={1} value={fileName ?? ""} />
                    </UI.List.Row>,
                    <UI.List.Row key={"r-2"}>
                        <UI.List.Cell key={"c-1"} colspan={1} value={fileDate ?? ""} className="sublabel" />
                    </UI.List.Row>
                ];
                break;
        }

        const fileId = unitFile.fileid;

        let openFileUrl = NetworkConfig.datafileUrl + fileId;

        const documentIcon = <UI.Icon icon={UI.SVGIcon.Documents} color={ColorStyle("primary")} />;

        return (
            <UI.List.Item
                key={unitFile.id}
                screenSize={screenSize}
                rows={listRows}
                to={openFileUrl}
                isClickableItemWithoutChevron={true}
                icon={documentIcon}
            />
        );
    }, []);

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            let headerRows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell
                                key={"c-1"}
                                colspan={1}
                                value={t("screens.realestate.documents.description")}
                            />
                            <UI.List.Cell key={"c-2"} colspan={0.5} value={t("screens.realestate.documents.date")} />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Header rows={headerRows} />;
        },
        [t]
    );

    return (
        <UI.List.BasicList
            screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
            items={displayedUnitFiles}
            renderItem={renderItem}
            renderHeader={renderHeader}
            scrollPosition={listParameters?.scrollPosition ?? undefined}
            itemsPerPage={25}
            initialPage={listParameters?.paginationPage ?? undefined}
            onPageChanged={onPageChanged}
            onSearch={handleSearchInput}
            initialSearchQuery={currentSearchQuery}
        />
    );
};

export default observer(UnitFileList);
