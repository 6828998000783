import React, { useContext, useCallback, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI, ScreenSize } from "@wwimmo/ui";
import { useParams } from "react-router";
import styles from "./TenantAccountBookingList.module.css";
import {
    GetTenantBookingsAndFiles_tenant_bookings_and_files_tenantbookings as tenantbookings,
    GetTenantBookingsAndFiles_tenant_bookings_and_files_tenantbookings_tenantbookingfiles as tenantbookingfiles
} from "src/api/generated/GetTenantBookingsAndFiles";
import { dateFormat, getDate } from "src/utils/Date";
import { ColorStyle } from "src/utils/Colors";
import { NetworkConfig } from "src/network/NetworkConfig";
import { getCurrencyElement } from "src/utils/Currency";

interface TenantAccountBookingListProps {
    tenantBookings: tenantbookings[] | [];
    accountBalance: any;
}

interface Params {
    tenantid: string;
}

interface TenantAccountBookingItem {
    key?: any;
    date?: string;
    text?: string;
    debit?: any;
    credit?: any;
    isTitle?: boolean;
    icon?: any;
}

const TenantAccountBookingList = (props: TenantAccountBookingListProps) => {
    const { t } = useTranslation();
    const { uiStore, navStore } = useContext(RootStoreContext);
    const { tenantid } = useParams<Params>();

    const iconSize = UI.IconSize.default;
    const listIdentifier = `accountbookinglist-${tenantid}`;

    if (!navStore.listMap.has(listIdentifier)) {
        navStore.setListMapValue(listIdentifier, {});
    }

    const listParameters = navStore.getListMapValue(listIdentifier);

    let currentSearchQuery: string | undefined = undefined;

    if (listParameters && listParameters.searchQuery) {
        currentSearchQuery = listParameters.searchQuery;
    }

    const [modalBookingFiles, setModalBookingFiles] = useState<tenantbookingfiles[]>([]);
    const [displayedAccountBookings, setDisplayedAccountBookings] = useState<TenantAccountBookingItem[]>([]);
    const [displaySelectBookingFilesModal, setDisplaySelectBookingFilesModal] = useState<boolean>(false);

    const getDocumentLinkIcon = useCallback((fileId?: any) => {
        let documentLinkIcon = undefined;

        if (fileId) {
            let urlLink = NetworkConfig.datafileUrl + fileId;
            documentLinkIcon = (
                <a href={urlLink} className="link" rel="noopener noreferrer d-flex" target="_blank">
                    <UI.Icon icon={UI.SVGIcon.Documents} color={ColorStyle("primary")} />
                </a>
            );
        }

        return documentLinkIcon;
    }, []);

    const openDocumentSelectModal = useCallback(
        (bookingFiles: tenantbookingfiles[]) => () => {
            setModalBookingFiles(bookingFiles);
            setDisplaySelectBookingFilesModal(true);
        },
        []
    );

    const onCloseSelectBookingFilesModal = useCallback(() => {
        setDisplaySelectBookingFilesModal(false);
    }, []);

    const accountBookingTitle: TenantAccountBookingItem[] = useMemo(() => {
        return [
            {
                key: "saldo-row",
                isTitle: true,
                text:
                    props.accountBalance < 0
                        ? t("labels.saldo_your_favor")
                        : props.accountBalance > 0
                        ? t("labels.saldo_our_favor")
                        : t("labels.total"),
                credit: props.accountBalance > 0 ? Math.abs(props.accountBalance) : undefined,
                debit: props.accountBalance <= 0 ? Math.abs(props.accountBalance) : undefined,
                icon: <span style={{ width: iconSize }} />
            }
        ];
    }, [iconSize, props.accountBalance, t]);

    const [allAccountBookings] = useState<TenantAccountBookingItem[]>(
        props.tenantBookings.map((tenantBooking, index) => {
            const date = dateFormat(new Date(tenantBooking.date), "dd.MM.yyyy") || "";

            const getAccountDocumentsIcon = (bookingFiles: tenantbookingfiles[]) => {
                let accountDocumentIcon = undefined;

                if (bookingFiles.length === 1 && bookingFiles[0].fileid) {
                    accountDocumentIcon = getDocumentLinkIcon(bookingFiles[0].fileid);
                } else if (bookingFiles.length > 1) {
                    accountDocumentIcon = (
                        <UI.Icon
                            icon={UI.SVGIcon.Documents}
                            color={ColorStyle("primary")}
                            onClick={openDocumentSelectModal(bookingFiles)}
                        />
                    );
                } else {
                    accountDocumentIcon = <span style={{ width: iconSize }} />;
                }

                return accountDocumentIcon;
            };

            const accountBooking: TenantAccountBookingItem = {
                key: index,
                date: date,
                isTitle: false,
                text: tenantBooking.text,
                debit: tenantBooking.debit,
                credit: tenantBooking.credit,
                icon: getAccountDocumentsIcon(tenantBooking.tenantbookingfiles)
            };

            return accountBooking;
        })
    );

    const filterAccountBookingsAndSetSearchQuery = useCallback(
        (searchQuery: string = "") => {
            if (allAccountBookings && allAccountBookings.length > 0) {
                let foundTenantBookings = allAccountBookings.filter((tenantBooking) => {
                    const text = tenantBooking.text;
                    const date = tenantBooking.date;
                    const debit = tenantBooking.debit;
                    const credit = tenantBooking.credit;

                    return (
                        text?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        date?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        debit?.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
                        credit?.toString().toLowerCase().includes(searchQuery.toLowerCase())
                    );
                });

                setDisplayedAccountBookings([...accountBookingTitle, ...foundTenantBookings]);
            }
        },
        [allAccountBookings, accountBookingTitle]
    );

    const onPageChanged = useCallback(
        (currentPage: number) => {
            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: window.pageYOffset, paginationPage: currentPage }
                    : { scrollPosition: window.pageYOffset, paginationPage: currentPage }
            );
        },
        [navStore, listIdentifier, listParameters]
    );

    const handleSearchInput = useCallback(
        ({ searchQuery }) => {
            filterAccountBookingsAndSetSearchQuery(searchQuery);

            navStore.setListMapValue(
                listIdentifier,
                listParameters
                    ? { ...listParameters, scrollPosition: 0, searchQuery: searchQuery }
                    : { scrollPosition: 0, searchQuery: searchQuery }
            );
        },
        [filterAccountBookingsAndSetSearchQuery, navStore, listIdentifier, listParameters]
    );

    const renderItem = useCallback((item: TenantAccountBookingItem, screenSize: ScreenSize) => {
        let listRows: React.ReactElement[] = [];

        const debit = getCurrencyElement(item.debit);
        const credit = getCurrencyElement(item.credit);

        switch (screenSize) {
            case ScreenSize.DESKTOP:
                if (item.isTitle) {
                    listRows = [
                        <UI.List.Row key={"r-1"} className="bold">
                            <UI.List.Cell key={"c-1"} colspan={1} value={item.date} />
                            <UI.List.Cell key={"c-2"} colspan={3} value={item.text} />
                            <UI.List.Cell key={"c-3"} colspan={1} value={debit} className="text-right" />
                            <UI.List.Cell key={"c-4"} colspan={1} value={credit} className="text-right" />
                        </UI.List.Row>
                    ];
                } else {
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={item.date} />
                            <UI.List.Cell key={"c-2"} colspan={3} value={item.text} />
                            <UI.List.Cell key={"c-3"} colspan={1} value={debit} className="text-right" />
                            <UI.List.Cell key={"c-4"} colspan={1} value={credit} className="text-right" />
                        </UI.List.Row>
                    ];
                }
                break;
            case ScreenSize.MOBILE:
                if (item.isTitle) {
                    listRows = [
                        <UI.List.Row key={"r-1"} className="bold">
                            <UI.List.Cell key={"c-1"} colspan={1} value={item.text} className="uppercase" />
                            <UI.List.Cell key={"c-3"} colspan={1} value={credit} className="text-right" />
                            <UI.List.Cell key={"c-2"} colspan={1} value={debit} className="text-right" />
                        </UI.List.Row>
                    ];
                } else {
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={item.date} />
                            <UI.List.Cell key={"c-2"} colspan={1} value={debit} className="text-right" />
                            <UI.List.Cell key={"c-3"} colspan={1} value={credit} className="text-right" />
                        </UI.List.Row>,
                        <UI.List.Row key={"r-2"}>
                            <UI.List.Cell key={"c-4"} colspan={1} value={item.text} className="sublabel" />
                        </UI.List.Row>
                    ];
                }

                break;
        }

        return (
            <UI.List.Item
                key={item.key}
                screenSize={screenSize}
                rows={listRows}
                icon={item.icon}
                className={item.isTitle ? "border-bottom" : ""}
            />
        );
    }, []);

    const renderHeader = useCallback(
        (screenSize: ScreenSize) => {
            let headerRows: React.ReactElement[] = [];

            const bookingDate = t("screens.realestate.finances.booking_date");
            const description = t("screens.realestate.finances.booking_description");
            const debit = t("screens.realestate.finances.debit");
            const credit = t("screens.realestate.finances.credit");

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={bookingDate} />
                            <UI.List.Cell key={"c-2"} colspan={3} value={description} />
                            <UI.List.Cell key={"c-3"} colspan={1} value={debit} className="text-right" />
                            <UI.List.Cell key={"c-4"} colspan={1} value={credit} className="text-right" />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    headerRows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={bookingDate} />
                            <UI.List.Cell key={"c-2"} colspan={1} value={debit} className="text-right" />
                            <UI.List.Cell key={"c-3"} colspan={1} value={credit} className="text-right" />
                        </UI.List.Row>
                    ];
                    break;
            }

            return <UI.List.Header key={bookingDate} rows={headerRows} />;
        },
        [t]
    );

    const renderBookingFileItem = useCallback(
        (bookingFile: tenantbookingfiles, screenSize: ScreenSize) => {
            let listRows: React.ReactElement[] = [];

            const file = bookingFile;
            const fileDate = getDate(file?.filedate);

            const documentLinkIcon = getDocumentLinkIcon(bookingFile.fileid);

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={file?.name} />
                            <UI.List.Cell key={"c-2"} colspan={0.4} value={fileDate} />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    listRows = [
                        <UI.List.Row key={"r-1"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={file?.name} />
                        </UI.List.Row>,
                        <UI.List.Row key={"r-2"}>
                            <UI.List.Cell key={"c-1"} colspan={1} value={fileDate} className="sublabel" />
                        </UI.List.Row>
                    ];
                    break;
            }

            return (
                <UI.List.Item key={bookingFile.id} screenSize={screenSize} rows={listRows} icon={documentLinkIcon} />
            );
        },
        [getDocumentLinkIcon]
    );

    const selectBookingFilesModal = (
        <UI.Modal show={displaySelectBookingFilesModal} onClose={onCloseSelectBookingFilesModal} isScrollable={true}>
            <div>
                <h2 className={`${styles.DocumentsModalTitle} mt-2 mb-3`}>{t("screens.realestate.documents.title")}</h2>
                <UI.List.BasicList
                    screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                    items={modalBookingFiles}
                    renderItem={renderBookingFileItem}
                />
            </div>
            <UI.Button
                className="AnnouncementModalButton"
                label={t("labels.cancel")}
                onClick={onCloseSelectBookingFilesModal}
                variant="link"
            />
        </UI.Modal>
    );

    return (
        <>
            {selectBookingFilesModal}
            <UI.List.BasicList
                screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                items={displayedAccountBookings}
                renderItem={renderItem}
                renderHeader={renderHeader}
                scrollPosition={listParameters?.scrollPosition ?? undefined}
                itemsPerPage={10}
                initialPage={listParameters?.paginationPage ?? undefined}
                onPageChanged={onPageChanged}
                onSearch={handleSearchInput}
                initialSearchQuery={currentSearchQuery}
            />
        </>
    );
};

export default observer(TenantAccountBookingList);
