import { observable, action, computed, makeObservable } from "mobx";
import { persist } from "mobx-persist";
import { AuthClient } from "src/network/AuthClient";
import { NetworkConfig } from "src/network/NetworkConfig";
import { User, Role, ErpType } from "src/network/User";
import { RootStore } from "./RootStore";

export class AuthStore {
    rootStore: RootStore;
    @observable loaded = false;
    @observable relogin = false;
    @observable login = false;
    @observable logout = false;
    @observable user? = undefined as User | undefined;
    @observable token? = undefined as string | undefined;
    @observable currentlyUsedRole: string | undefined;
    @persist @observable refreshToken? = undefined as string | undefined;
    @persist @observable isLoginProcessRunning: boolean = false;
    @persist @observable loginCodeVerifier: string = "";
    @persist @observable entryPath: string = "";
    @persist @observable entryUri: string = "";
    @persist @observable loginRedirectUri: string = "";

    authClient: AuthClient;
    timer?: NodeJS.Timeout;
    tokenType = "Bearer";
    refreshTokenResponseType = "code";

    loginCodeChallengeMethod = "S256";
    scopes = "wwimmo-mobile-core-api openid profile offline_access";

    private setRefreshTimer(active: boolean, interval: number) {
        if (this.timer) {
            clearTimeout(this.timer);
        }

        if (active) {
            this.timer = setTimeout(
                () =>
                    this.authClient
                        .refresh(this.refreshToken)
                        .catch((error) => console.log(`error in catch: ${error}`)),
                interval * 1000
            );
        }
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeObservable(this);
        this.authClient = new AuthClient(NetworkConfig, this);
    }

    @action
    setLoaded(loaded: boolean) {
        this.loaded = loaded;
    }

    @action
    setRelogin(relogin: boolean) {
        this.relogin = relogin;
    }

    @action
    setLogin(login: boolean) {
        this.login = login;
    }

    @action
    setLogout(logout: boolean) {
        this.logout = logout;
    }

    @action
    setRefreshToken(refresh_token: string | undefined, expires_in: number = 0) {
        this.refreshToken = refresh_token;
        this.setRefreshTimer(this.refreshToken !== undefined, expires_in * 0.9);
    }

    @action
    setToken(token: string | undefined) {
        this.token = token;
    }

    @action
    addUser = (user: User, accessToken: string, refreshToken: string, expiresIn: number) => {
        this.token = accessToken;
        this.setRefreshToken(refreshToken, expiresIn);
        this.user = user;
    };

    @action
    removeUser = () => {
        this.token = undefined;
        this.setRefreshToken(undefined);
        this.user = undefined;
    };

    @action
    changeRole = (role: Role) => {
        if (this.user) {
            this.user.role = role;
        }
    };

    @action
    setErp = (newErp: ErpType) => {
        if (this.user) {
            this.user.erpType = newErp;
        }
    };

    @action
    setCurrentlyUsedRole = (currentlyUsedRole: string) => {
        this.currentlyUsedRole = currentlyUsedRole;
    };

    @action
    setIsLoginProcessRunning = (isLoginProcessRunning: boolean) => {
        this.isLoginProcessRunning = isLoginProcessRunning;
    };

    @action
    setLoginCodeVerifier = (loginCodeVerifier: string) => {
        this.loginCodeVerifier = loginCodeVerifier;
    };

    @action
    setEntryPath = (entryPath: string) => {
        this.entryPath = entryPath;
    };

    @action
    setEntryUri = (entryUri: string) => {
        this.entryUri = entryUri;
    };

    @action
    setLoginRedirectUri = (loginRedirectUri: string) => {
        this.loginRedirectUri = loginRedirectUri;
    };

    @action
    resetLoginVariables = () => {
        this.isLoginProcessRunning = false;
        this.loginCodeVerifier = "";
        this.entryPath = "";
        this.entryUri = "";
        this.loginRedirectUri = "";
    };

    @computed get isLoggedIn() {
        return this.user !== undefined && this.token !== undefined;
    }

    @computed get portalRoleWithHighestPriority() {
        let portalRoleWithHighestPriority: Role | undefined;

        if (this.user?.availablePortalRoles && this.user?.availablePortalRoles.length > 0) {
            portalRoleWithHighestPriority = this.user?.availablePortalRoles[0];
        }

        return portalRoleWithHighestPriority;
    }
}
