import { UI } from "@wwimmo/ui";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import {
    APPROVE_REJECT_INVOICE_STATE,
    APPROVE_REJECT_MODAL_TYPE,
    IUserToRejectTo
} from "src/stores/krediflow/ApproveRejectInvoiceStore";
import { ApproveRejectInvoiceProgress } from "src/screens/creditors/creditor/details/actions/rejectInvoice/progress/ApproveRejectInvoiceProgress";
import { boldenMatchedSubstring } from "src/utils/Common";
import { useSelectionListKeyHandler } from "src/hooks/ticket/selection-list-key-handler/useSelectionListKeyHandler";
import styles from "./RejectInvoiceModal.module.css";

const RejectInvoiceModalBase = () => {
    const { t } = useTranslation();
    const { assignInvoiceStore, approveRejectInvoiceStore, invoiceStore } = useContext(RootStoreContext);

    const [displayUsersToRejectList, setDisplayUsersToRejectList] = useState<boolean>(false);
    const [lastScrollPositionYRealestateItems, setLastSrollYPositionRealestateItems] = useState<number>(0);

    const userToRejectToInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const handleClickOutsideRealestateInput = (event: any) => {
            if (
                displayUsersToRejectList &&
                userToRejectToInputRef.current &&
                !userToRejectToInputRef.current.contains(event.target) &&
                !(
                    event.target.classList.contains("list-item") && event.target.classList.contains("user-to-reject-to")
                ) &&
                !(
                    event.target.parentElement.classList.contains("list-item") &&
                    event.target.classList.contains("user-to-reject-to-text")
                ) &&
                !(event.target.localName === "path" || event.target.localName === "svg") &&
                !(
                    event.target.classList.contains("highlighted-text") &&
                    event.target.classList.contains("user-to-reject-to")
                )
            ) {
                setDisplayUsersToRejectList(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutsideRealestateInput);

        return () => {
            document.removeEventListener("mousedown", handleClickOutsideRealestateInput);
        };
    }, [displayUsersToRejectList]);

    const updateStoreWithSelectedUserToRejectTo = useCallback(
        async (userToRejectTo: IUserToRejectTo) => {
            approveRejectInvoiceStore.setSelectedUserToRejectTo(userToRejectTo);
            approveRejectInvoiceStore.setCurrentUserToRejectToSearchQuery(userToRejectTo.name);
            approveRejectInvoiceStore.validateRejectInvoice();
        },
        [approveRejectInvoiceStore]
    );

    const onSelectRejectToUserItem = useCallback(
        (activeListItemIndex: number) => {
            const selectedUserToReject = approveRejectInvoiceStore.filteredUsersToRejectToData[activeListItemIndex];

            updateStoreWithSelectedUserToRejectTo(selectedUserToReject);
        },
        [updateStoreWithSelectedUserToRejectTo, approveRejectInvoiceStore.filteredUsersToRejectToData]
    );

    const resetSearchQuery = useCallback(() => {
        approveRejectInvoiceStore.setCurrentUserToRejectToSearchQuery(
            approveRejectInvoiceStore.selectedUserToRejectToQueryString ?? ""
        );
    }, [approveRejectInvoiceStore]);

    const { onKeyDownFunction, setActiveListItemIndex, NO_LIST_ITEM_SELECTED } = useSelectionListKeyHandler({
        listName: "users-to-reject",
        totalNumberOfDisplayedItems: invoiceStore.numberOfDisplayedItems,
        setIsListDisplayed: setDisplayUsersToRejectList,
        isListDisplayed: displayUsersToRejectList,
        onSelectItem: onSelectRejectToUserItem,
        resetSearchQuery: resetSearchQuery
    });

    const toggleDisplayAssignInvoiceModal = useCallback(() => {
        const modalIsBeingClosed = approveRejectInvoiceStore.isModalDisplayed;

        approveRejectInvoiceStore.setIsModalDisplayed(!approveRejectInvoiceStore.isModalDisplayed);

        if (modalIsBeingClosed) {
            approveRejectInvoiceStore.reset();
        }
    }, [approveRejectInvoiceStore]);

    const onChangeAssignUserInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const rejectToUser = e.target.value;
            approveRejectInvoiceStore.setCurrentUserToRejectToSearchQuery(rejectToUser);
        },
        [approveRejectInvoiceStore]
    );

    const onChangeRejectedCommentInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const rejectedComment = e.target.value;
            approveRejectInvoiceStore.setRejectedComment(rejectedComment);
            approveRejectInvoiceStore.validateRejectInvoice();
        },
        [approveRejectInvoiceStore]
    );

    const onClickReject = useCallback(() => {
        const isValid = approveRejectInvoiceStore.validateRejectInvoice();

        if (isValid) {
            approveRejectInvoiceStore.rejectInvoice();
        }
    }, [approveRejectInvoiceStore]);

    const onClickRejectToUserItem = useCallback(
        (userToRejectTo: IUserToRejectTo) => async (e: any) => {
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
            setDisplayUsersToRejectList(false);

            updateStoreWithSelectedUserToRejectTo(userToRejectTo);
        },
        [updateStoreWithSelectedUserToRejectTo, setActiveListItemIndex, NO_LIST_ITEM_SELECTED]
    );

    const onScrollUsersToRejectToList = useCallback(
        (e: React.BaseSyntheticEvent) => {
            const windowHeight = e.target.clientHeight;
            const pixelsFromTop = e.target.scrollTop;
            const totalHeight = e.target.scrollHeight;

            const is150PixelsFromBottom = totalHeight - pixelsFromTop < windowHeight + 150;
            const isScrollingDown = pixelsFromTop > lastScrollPositionYRealestateItems;

            const hasMoreResulsToDisplay =
                invoiceStore.numberOfDisplayedItems < assignInvoiceStore.filteredUsersToAssignData.length;

            if (is150PixelsFromBottom && isScrollingDown && hasMoreResulsToDisplay) {
                invoiceStore.setNumberOfDisplayedItems(
                    invoiceStore.numberOfDisplayedItems + invoiceStore.sliceSizeOfDisplayedItems
                );
            }

            setLastSrollYPositionRealestateItems(pixelsFromTop);
        },
        [lastScrollPositionYRealestateItems, invoiceStore, assignInvoiceStore.filteredUsersToAssignData.length]
    );

    const rejectToUserSelectionList = (
        <ul
            id="reject-to-user-dropdown-list"
            className={`dropdown-list rejectto krediflow ${styles.RejectToUserSelectionList}`}
            onScroll={onScrollUsersToRejectToList}
        >
            {approveRejectInvoiceStore.filteredUsersToRejectToData
                .slice(0, invoiceStore.numberOfDisplayedItems)
                .map((userToRejectTo, index) => {
                    const formattedUserToRejectName = boldenMatchedSubstring(
                        approveRejectInvoiceStore.currentUserToRejectToSearchQuery ?? "",
                        userToRejectTo.name,
                        "highlighted-text user-to-reject-to"
                    );

                    return (
                        <li
                            key={index}
                            className="d-flex align-items-center list-item user-to-reject-to"
                            onClick={onClickRejectToUserItem(userToRejectTo)}
                            id={`user-to-reject-to-list-item-${index}`}
                        >
                            <div
                                className="user-to-reject-to-text"
                                dangerouslySetInnerHTML={{
                                    __html: formattedUserToRejectName
                                }}
                            />
                        </li>
                    );
                })}
        </ul>
    );

    const resetAmountOfItemsDisplayedInUsersToRejectList = useCallback(() => {
        setLastSrollYPositionRealestateItems(0);
        invoiceStore.setNumberOfDisplayedItems(invoiceStore.sliceSizeOfDisplayedItems);
    }, [invoiceStore]);

    const onFocusUserToRejectInput = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            if (userToRejectToInputRef.current) {
                userToRejectToInputRef.current.select();
            }

            resetAmountOfItemsDisplayedInUsersToRejectList();
            setDisplayUsersToRejectList(true);
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
        },
        [resetAmountOfItemsDisplayedInUsersToRejectList, setActiveListItemIndex, NO_LIST_ITEM_SELECTED]
    );

    return (
        <UI.Modal
            title={t("screens.kredi_flow.action.reject").toString()}
            size="lg"
            show={
                approveRejectInvoiceStore.isModalDisplayed &&
                approveRejectInvoiceStore.displayedModalType === APPROVE_REJECT_MODAL_TYPE.REJECT
            }
            onClose={toggleDisplayAssignInvoiceModal}
            centered={true}
        >
            {approveRejectInvoiceStore.approveRejectInvoiceState === APPROVE_REJECT_INVOICE_STATE.INIT ? (
                <div className={styles.RejectInvoiceContainer}>
                    <UI.Row className={"mt-2"}>
                        <UI.Col>
                            <UI.Input
                                label={`${t("screens.kredi_flow.action.reject_invoice.reject_to").toString()}*`}
                                ref={userToRejectToInputRef}
                                type="text"
                                autoComplete="off"
                                className={styles.FormInput}
                                id="assign-invoice-select-user-input"
                                value={approveRejectInvoiceStore.currentUserToRejectToSearchQuery ?? ""}
                                onChange={onChangeAssignUserInput}
                                onFocus={onFocusUserToRejectInput}
                                onKeyDown={onKeyDownFunction}
                                placeholder={t("labels.search").toString()}
                                iconRight={
                                    <UI.Icon
                                        icon={UI.SVGIcon.Search}
                                        color="grey"
                                        size={"small"}
                                        style={{ right: "5px" }}
                                    />
                                }
                            />
                            {displayUsersToRejectList ? rejectToUserSelectionList : undefined}
                            {approveRejectInvoiceStore.error.noUserSelected ? (
                                <span className="input-error-text">
                                    {approveRejectInvoiceStore.error.noUserSelected}
                                </span>
                            ) : undefined}
                        </UI.Col>
                    </UI.Row>
                    <UI.Row className={"mt-2"}>
                        <UI.Col>
                            <UI.Input
                                label={`${t("screens.kredi_flow.action.reject_invoice.rejected_comment").toString()}*`}
                                type="text"
                                as="textarea"
                                className={styles.RejectedCommentTextArea}
                                value={approveRejectInvoiceStore.rejectedComment ?? ""}
                                onChange={onChangeRejectedCommentInput}
                            />
                            {approveRejectInvoiceStore.error.noRejectionCommentEntered ? (
                                <span className="input-error-text">
                                    {approveRejectInvoiceStore.error.noRejectionCommentEntered}
                                </span>
                            ) : undefined}
                        </UI.Col>
                    </UI.Row>
                    <UI.Row className="mt-3">
                        <UI.Col>
                            <UI.Button
                                label={t("labels.cancel").toString()}
                                className={`mb-2 default-button-height ${styles.CancelButton}`}
                                onClick={toggleDisplayAssignInvoiceModal}
                            />
                        </UI.Col>
                        <UI.Col>
                            <UI.Button
                                label={t("screens.kredi_flow.action.reject").toString()}
                                className={`mb-2 default-button-height ml-auto ${styles.SubmitButton}`}
                                onClick={onClickReject}
                            />
                        </UI.Col>
                    </UI.Row>
                </div>
            ) : undefined}

            {approveRejectInvoiceStore.approveRejectInvoiceState === APPROVE_REJECT_INVOICE_STATE.REJECTING ||
            approveRejectInvoiceStore.approveRejectInvoiceState === APPROVE_REJECT_INVOICE_STATE.APPROVING ||
            approveRejectInvoiceStore.approveRejectInvoiceState === APPROVE_REJECT_INVOICE_STATE.FINISHED ? (
                <ApproveRejectInvoiceProgress
                    key={"approve-reject-invoice-progress-assigning"}
                    currentRejectInvoiceState={approveRejectInvoiceStore.approveRejectInvoiceState}
                />
            ) : undefined}
        </UI.Modal>
    );
};

export const RejectInvoiceModal = Sentry.withProfiler(observer(RejectInvoiceModalBase));
