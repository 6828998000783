import React, { useContext, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import styles from "./InvoiceHistoryComment.module.css";
import { LOADING_TYPE, INVOICE_PROPERTY } from "src/stores/krediflow/InvoiceEnums";
import { MessageType } from "src/components/notifications/Notifier";

const InvoiceHistoryCommentBase = () => {
    const { t } = useTranslation();
    const { invoiceStore, uiStore } = useContext(RootStoreContext);

    const saveComment = useCallback(
        async (e: any) => {
            e.preventDefault();
            const wasSuccessfullyValidated = invoiceStore.currentInvoice?.validate();

            if (wasSuccessfullyValidated) {
                const wasSuccessfullySaved = await invoiceStore.saveCurrentInvoiceComment();

                if (wasSuccessfullySaved) {
                    invoiceStore.currentInvoice?.reloadHistory();
                } else {
                    uiStore.printStatusMessage(t("screens.kredi_flow.form.errors.save_form_error"), MessageType.ERROR);
                }
            } else {
                if (
                    !invoiceStore.currentInvoice?.isNewInvoice &&
                    invoiceStore.currentInvoice?.realestate === undefined
                ) {
                    uiStore.printStatusMessage(
                        t("screens.kredi_flow.form.errors.realestate_missing"),
                        MessageType.ERROR
                    );
                }
            }
        },
        [invoiceStore, t, uiStore]
    );

    const saveCommentButtonLabel = (
        <div className="d-flex align-items-center">
            <div>{t("labels.save")}</div>
            <UI.RotatingSpinner
                className={`${styles.FromSaveButtonSpinner} ${
                    invoiceStore.loadingType !== LOADING_TYPE.SAVING_COMMENT ? "d-none" : ""
                }`}
                noLogo={true}
                size={20}
            />
        </div>
    );

    const onChangeCommentField = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const newCommentText = e.target.value;

            if (invoiceStore.currentInvoice) {
                invoiceStore.currentInvoice.updateProperty(INVOICE_PROPERTY.COMMENT, newCommentText);
            }
        },
        [invoiceStore]
    );

    return invoiceStore.currentInvoice?.isNewInvoice ? (
        <></>
    ) : (
        <div className={styles.TicketHistoryCommentContainer}>
            <div className={`${styles.icketHistoryCommentTitle}`}></div>
            <>
                <UI.Row className="d-flex justify-content-end">
                    <UI.Col sm={12}>
                        <UI.Input
                            placeholder={`${t("screens.tickets.history.comment_placeholder").toString()}`}
                            type="text"
                            as="textarea"
                            autoComplete="off"
                            value={invoiceStore.currentInvoice && invoiceStore.currentInvoice.comment}
                            onChange={onChangeCommentField}
                            errorMsg={invoiceStore.currentInvoice?.errors.commentDescriptionRequired ?? undefined}
                        />
                    </UI.Col>
                    <UI.Col sm={12} className="d-flex justify-content-end">
                        <UI.Button
                            onClick={saveComment}
                            className={styles.SaveButton}
                            label={saveCommentButtonLabel}
                            disabled={
                                invoiceStore.loadingType !== LOADING_TYPE.NONE ||
                                invoiceStore?.currentInvoice?.comment === ""
                            }
                        />
                    </UI.Col>
                </UI.Row>
            </>
        </div>
    );
};

export const InvoiceHistoryComment = Sentry.withProfiler(observer(InvoiceHistoryCommentBase));
