import { observable, makeObservable, action, computed } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { apolloClientInstance } from "src/network/apolloClientInstance";
import i18next from "i18next";
import { GET_ACTIVE_MANAGER_USERS } from "src/api/cred";
import { INVOICE_PROPERTY, INVOICE_STATE } from "./InvoiceEnums";

import { GetActiveManagerUsers } from "src/api/generated/GetActiveManagerUsers";
import { NetworkConfig } from "src/network/NetworkConfig";
import { getRoleKey } from "src/network/User";
import { MessageType } from "src/components/notifications/Notifier";

export enum APPROVE_REJECT_INVOICE_STATE {
    INIT,
    REJECTING,
    APPROVING,
    FINISHED,
    SUCCESS,
    ERROR
}

export interface IUserToRejectTo {
    userid: string;
    name: string;
}

export enum APPROVE_REJECT_MODAL_TYPE {
    APPROVE,
    REJECT
}

export class ApproveRejectInvoiceStore {
    rootStore: RootStore;

    isModalDisplayed = false;
    displayedModalType: APPROVE_REJECT_MODAL_TYPE = APPROVE_REJECT_MODAL_TYPE.APPROVE;

    approveRejectInvoiceState: APPROVE_REJECT_INVOICE_STATE = APPROVE_REJECT_INVOICE_STATE.INIT;

    usersToRejectToData: IUserToRejectTo[] = [];
    currentUserToRejectToSearchQuery: string = "";
    selectedUserToRejectToQueryString: string = "";
    selectedUserToRejectTo?: IUserToRejectTo = undefined;
    rejectedComment: string = "";

    error = {
        noUserSelected: "",
        noRejectionCommentEntered: ""
    };

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            approveRejectInvoiceState: observable,
            isModalDisplayed: observable,
            usersToRejectToData: observable,
            currentUserToRejectToSearchQuery: observable,
            selectedUserToRejectToQueryString: observable,
            selectedUserToRejectTo: observable,
            rejectedComment: observable,
            error: observable,
            displayedModalType: observable,

            init: action,
            reset: action,
            setIsModalDisplayed: action,
            setUsersToRejectToData: action,
            setCurrentUserToRejectToSearchQuery: action,
            setSelectedUserToRejectTo: action,
            setApproveRejectInvoiceState: action,
            rejectInvoice: action,
            approveInvoice: action,
            loadUsersToRejectTo: action,
            setRejectedComment: action,
            setSelectedUserToRejectToQueryString: action,
            setDisplayedModalType: action,
            validateRejectInvoice: action,

            filteredUsersToRejectToData: computed
        });
    }

    init = async () => {
        await this.loadUsersToRejectTo();
    };

    reset = () => {
        this.approveRejectInvoiceState = APPROVE_REJECT_INVOICE_STATE.INIT;
        this.currentUserToRejectToSearchQuery = "";
        this.selectedUserToRejectToQueryString = "";
        this.selectedUserToRejectTo = undefined;
        this.error.noUserSelected = "";
        this.error.noRejectionCommentEntered = "";
    };

    loadUsersToRejectTo = async () => {
        const { data }: { data: GetActiveManagerUsers } = await apolloClientInstance.query<GetActiveManagerUsers>({
            query: GET_ACTIVE_MANAGER_USERS
        });

        const usersToAssignInvoice: IUserToRejectTo[] = data.ums_v_customer_users.map((user) => {
            const assignPerson: IUserToRejectTo = {
                userid: user.userid,
                name: user.name ?? ""
            };

            return assignPerson;
        });

        this.setUsersToRejectToData(usersToAssignInvoice);
    };

    get filteredUsersToRejectToData(): IUserToRejectTo[] {
        const searchQuery = this.currentUserToRejectToSearchQuery ?? "";

        let filteredUsersToAssignData: IUserToRejectTo[] = [...this.usersToRejectToData];

        const filteredUsersToApplyDataBySearchQuery = filteredUsersToAssignData.filter((userToAssign) => {
            return userToAssign.name?.toLowerCase().includes(searchQuery.toLowerCase());
        });

        if (searchQuery === "") {
            return filteredUsersToAssignData;
        } else {
            return filteredUsersToApplyDataBySearchQuery;
        }
    }

    rejectInvoice = async (): Promise<boolean> => {
        this.setApproveRejectInvoiceState(APPROVE_REJECT_INVOICE_STATE.REJECTING);

        let wasInvoiceSuccessfullyRejected = false;

        const currentInvoice = this.rootStore.invoiceStore.currentInvoice;

        const rejectInvoiceVariables = {
            approved: false,
            invoiceid: currentInvoice?.id,
            workflowid: currentInvoice?.workflowInstance,
            state: currentInvoice?.state,
            rejectedcomment: this.rejectedComment,
            rejectedto: this.selectedUserToRejectTo ? this.selectedUserToRejectTo.userid : undefined
        };

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            const rejectInvoiceResponse = await fetch(NetworkConfig.approveOrRejectInvoiceUrl, {
                method: "POST",
                body: JSON.stringify(rejectInvoiceVariables),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            if (rejectInvoiceResponse.status === 200) {
                wasInvoiceSuccessfullyRejected = true;

                this.rootStore.invoiceStore.currentInvoice?.updateProperty(
                    INVOICE_PROPERTY.STATE,
                    INVOICE_STATE.REJECTED
                );
            } else {
                console.error("Error occured while trying to reject invoice: ", rejectInvoiceResponse);
            }
        } catch (error) {
            console.error("Error rejecting invoice: ", error);
        }

        if (wasInvoiceSuccessfullyRejected) {
            this.setApproveRejectInvoiceState(APPROVE_REJECT_INVOICE_STATE.FINISHED);
        } else {
            this.setApproveRejectInvoiceState(APPROVE_REJECT_INVOICE_STATE.INIT);
            this.rootStore.uiStore.printStatusMessage(
                i18next.t("screens.kredi_flow.action.reject_invoice.failure"),
                MessageType.ERROR
            );
        }
        return wasInvoiceSuccessfullyRejected;
    };

    approveInvoice = async (): Promise<boolean> => {
        this.setApproveRejectInvoiceState(APPROVE_REJECT_INVOICE_STATE.APPROVING);

        let wasInvoiceSuccessfullyApproved = false;

        const currentInvoice = this.rootStore.invoiceStore.currentInvoice;

        const approveInvoiceVariables = {
            approved: true,
            invoiceid: currentInvoice?.id,
            workflowid: currentInvoice?.workflowInstance,
            state: currentInvoice?.state
        };

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            const approveInvoiceResponse = await fetch(NetworkConfig.approveOrRejectInvoiceUrl, {
                method: "POST",
                body: JSON.stringify(approveInvoiceVariables),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            if (approveInvoiceResponse.status === 200) {
                wasInvoiceSuccessfullyApproved = true;

                const nextState = this.rootStore.invoiceStore.currentInvoice?.getNextState();

                this.rootStore.invoiceStore.currentInvoice?.updateProperty(INVOICE_PROPERTY.STATE, nextState);
            } else {
                console.error("Error occured while trying to approve invoice: ", approveInvoiceResponse);
            }
        } catch (error) {
            console.error("Error approving invoice: ", error);
        }

        if (wasInvoiceSuccessfullyApproved) {
            this.setApproveRejectInvoiceState(APPROVE_REJECT_INVOICE_STATE.FINISHED);
        } else {
            this.setApproveRejectInvoiceState(APPROVE_REJECT_INVOICE_STATE.INIT);
            this.rootStore.uiStore.printStatusMessage(
                i18next.t("screens.kredi_flow.action.approve_invoice.failure"),
                MessageType.ERROR
            );
        }
        return wasInvoiceSuccessfullyApproved;
    };

    validateRejectInvoice = (): boolean => {
        let isValid = true;

        if (!this.selectedUserToRejectTo) {
            this.error.noUserSelected = i18next
                .t("screens.kredi_flow.action.reject_invoice.no_user_selected")
                .toString();

            isValid = false;
        } else {
            this.error.noUserSelected = "";
        }

        if (!this.rejectedComment) {
            this.error.noRejectionCommentEntered = i18next
                .t("screens.kredi_flow.action.reject_invoice.no_rejection_comment_entered")
                .toString();

            isValid = false;
        } else {
            this.error.noRejectionCommentEntered = "";
        }

        return isValid;
    };

    /* SETTERS */
    setIsModalDisplayed = (isModalDisplayed: boolean) => {
        this.isModalDisplayed = isModalDisplayed;
    };

    setUsersToRejectToData = (usersToRejectoToData: IUserToRejectTo[]) => {
        this.usersToRejectToData = usersToRejectoToData;
    };

    setCurrentUserToRejectToSearchQuery = (currentUserToRejectToSearchQuery: string) => {
        this.currentUserToRejectToSearchQuery = currentUserToRejectToSearchQuery;
    };

    setSelectedUserToRejectTo = (selectedUserToRejectTo: IUserToRejectTo) => {
        this.selectedUserToRejectTo = selectedUserToRejectTo;
    };

    setApproveRejectInvoiceState = (assignInvoiceState: APPROVE_REJECT_INVOICE_STATE) => {
        this.approveRejectInvoiceState = assignInvoiceState;
    };

    setRejectedComment = (rejectedComment: string) => {
        this.rejectedComment = rejectedComment;
    };

    setSelectedUserToRejectToQueryString = (selectedUserToRejectToQueryString: string) => {
        this.selectedUserToRejectToQueryString = selectedUserToRejectToQueryString;
    };

    setDisplayedModalType = (displayedModalType: APPROVE_REJECT_MODAL_TYPE) => {
        this.displayedModalType = displayedModalType;
    };
}
