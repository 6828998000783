import { action, computed, makeObservable, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { apolloClientInstance } from "src/network/apolloClientInstance";
import { GetRealestateData } from "src/api/generated/GetRealestateData";
import { GET_REALESTATE_DATA } from "src/api/ticket";
import { IRealestate } from "./krediflow/InvoiceTypes";

export interface IRealestateData {
    id: string;
    number: string;
    consolidation: boolean;
    consolidationId: string;
    nameZipCity: string;
    name: string;
    zip: string;
    city: string;
    thumbnailFileId?: string;
}

export class RealestateSearchStore {
    rootStore: RootStore;

    realestateData: IRealestateData[] = [];
    currentRealestateSearchQuery: string = "";
    selectedRealestateQueryString: string | undefined = undefined;
    selectedRealestateName: string | undefined = undefined;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            realestateData: observable,
            currentRealestateSearchQuery: observable,
            selectedRealestateQueryString: observable,
            selectedRealestateName: observable,

            loadRealestateData: action,
            setRealestateData: action,
            setCurrentRealestateSearchQuery: action,
            setSelectedRealestateQueryString: action,
            setSelectedRealestateName: action,

            filteredRealestateData: computed
        });
    }

    loadRealestateData = async () => {
        const { data }: { data: GetRealestateData } = await apolloClientInstance.query<GetRealestateData>({
            query: GET_REALESTATE_DATA
        });

        const realestateData: IRealestateData[] = data.realestates.map((realestate) => {
            let zip: string | null | undefined;
            let city: string | null | undefined;

            if (realestate.houses.length > 0) {
                zip = realestate.houses[0].zip;
                city = realestate.houses[0].city;
            }

            return {
                id: realestate.id,
                number: realestate.number,
                consolidation: realestate.consolidation,
                consolidationId: realestate.consolidationid,
                nameZipCity: `${realestate.name}${zip || city ? ", " : ""}${zip ? ` ${zip}` : ""}${
                    city ? ` ${city}` : ""
                }`,
                name: realestate.name,
                zip: zip ?? "",
                city: city ?? "",
                thumbnailFileId: realestate.thumbnail.length > 0 ? realestate.thumbnail[0].fileid : undefined
            };
        });

        this.setRealestateData(realestateData);
    };

    getRealestateThumbnailFileId = async (realestateId: string) => {
        const thumbnailFileId = this.realestateData.find(
            (realestate) => realestate.id === realestateId
        )?.thumbnailFileId;

        return thumbnailFileId;
    };

    getFormattedRealestateFieldValue = (realestate: IRealestate): string => {
        return `${realestate.name} [${realestate.number}]\n${realestate.zip} ${realestate.city}`;
    };

    // Setters
    setRealestateData(data: IRealestateData[]) {
        this.realestateData = data;
    }

    setCurrentRealestateSearchQuery(currentRealestateSearchQuery: string) {
        this.currentRealestateSearchQuery = currentRealestateSearchQuery;
    }

    setSelectedRealestateQueryString(selectedRealestateQueryString: string) {
        this.selectedRealestateQueryString = selectedRealestateQueryString;
    }

    setSelectedRealestateName(selectedRealestateName: string) {
        this.selectedRealestateName = selectedRealestateName;
    }

    get filteredRealestateData(): IRealestateData[] {
        const searchQuery = this.currentRealestateSearchQuery ?? "";

        if (this.currentRealestateSearchQuery === this.selectedRealestateQueryString) {
            return this.realestateData;
        }

        if (searchQuery === "") {
            return this.realestateData;
        }

        const filteredRealestateData = this.realestateData.filter((realestate) => {
            const realestateName = `${realestate.nameZipCity} [${realestate.number}]`;

            return realestateName.toLowerCase().includes(searchQuery.toLowerCase());
        });

        return filteredRealestateData;
    }
}
