import React, { useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { RootStoreContext } from "src/stores/RootStore";
import { Loading } from "src/screens";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Route } from "src/config/routes";

enum RELOGINSTATES {
    PENDING = 0,
    ACTIVE = 1,
    DONE = 2
}

const LoginBase = () => {
    const { t } = useTranslation();
    const { navStore, authStore } = useContext(RootStoreContext);
    const [reloginState, setReloginState] = useState<RELOGINSTATES>(RELOGINSTATES.PENDING);
    const [loginState, setLoginState] = useState<RELOGINSTATES>(RELOGINSTATES.PENDING);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const loginVerificationCode = location.pathname === Route.register ? null : queryParams.get("code");

    useEffect(() => {
        runInAction(() => {
            navStore.setBackbutton(null);
            const customerName = getComputedStyle(document.documentElement).getPropertyValue("--wwcustomername");
            navStore.setTitle(reloginState === RELOGINSTATES.DONE ? t("screens.auth.login") : "");
            navStore.setTabTitle(`${customerName} Portal - ${t("screens.auth.login")}`);
        });
    }, [reloginState, navStore, t]);

    // re-login useEffect
    useEffect(() => {
        async function doRelogin(): Promise<boolean> {
            let wasReloginSuccessful = false;
            try {
                wasReloginSuccessful = await authStore.authClient.relogin(authStore.refreshToken);

                if (!wasReloginSuccessful) {
                    setReloginState(RELOGINSTATES.DONE);
                    authStore.setIsLoginProcessRunning(true);
                }
            } catch (error) {
                authStore.setIsLoginProcessRunning(true);
                setReloginState(RELOGINSTATES.DONE);
            }

            return wasReloginSuccessful;
        }

        if (authStore.loaded && reloginState === RELOGINSTATES.PENDING && !authStore.isLoginProcessRunning) {
            setReloginState(RELOGINSTATES.ACTIVE);

            doRelogin();
        }
    }, [
        reloginState,
        authStore.loaded,
        authStore.refreshToken,
        authStore.authClient,
        authStore,
        loginVerificationCode
    ]);

    // login useEffect
    useEffect(() => {
        async function doLogin(): Promise<boolean> {
            let wasLoginSuccessful = false;

            try {
                if (authStore.isLoginProcessRunning && loginVerificationCode) {
                    if (loginVerificationCode) {
                        wasLoginSuccessful = await authStore.authClient.login(loginVerificationCode);
                        if (!wasLoginSuccessful) {
                            authStore.authClient.initiateLogin();
                        }
                    }

                    authStore.setIsLoginProcessRunning(false);
                    authStore.resetLoginVariables();
                } else if (authStore.isLoginProcessRunning) {
                    authStore.authClient.initiateLogin();
                }

                if (!wasLoginSuccessful) {
                    setLoginState(RELOGINSTATES.DONE);
                }
            } catch (error) {
                authStore.resetLoginVariables();
                window.location.href = `${Route.logoutSilent}`;
                setLoginState(RELOGINSTATES.DONE);
            }

            return wasLoginSuccessful;
        }

        if (authStore.loaded && loginState === RELOGINSTATES.PENDING && authStore.isLoginProcessRunning) {
            setLoginState(RELOGINSTATES.ACTIVE);

            doLogin();
        }
    }, [authStore, loginVerificationCode, loginState, authStore.isLoginProcessRunning]);

    if (!authStore.loaded || reloginState !== RELOGINSTATES.DONE || loginState !== RELOGINSTATES.DONE) {
        return <Loading noLogo noBackgroundChange />;
    } else {
        return <></>;
    }
};

export const Login = Sentry.withProfiler(observer(LoginBase));
