import React, { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import "src/screens/realestates/realestate/finances/Finances.css";
import { observer } from "mobx-react-lite";
import { UI, ScreenSize } from "@wwimmo/ui";
import { useQuery } from "@apollo/client";
import {
    GetAccountFilesList,
    GetAccountFilesListVariables,
    GetAccountFilesList_accountlistfiles as portalAccountlistfiles
} from "src/api/generated/GetAccountFilesList";
import { GET_PORTAL_ACCOUNTLIST_FILES } from "src/api/finances";
import { format } from "date-fns";
import { ColorStyle } from "src/utils/Colors";
import { NetworkConfig } from "src/network/NetworkConfig";
import { getRoleKey, Role } from "src/network/User";

interface Props {
    selectedlistid: any;
}

const AccountFilesListBase = (props: Props) => {
    const { t } = useTranslation();
    const { uiStore, authStore } = useContext(RootStoreContext);

    const { loading, error, data } = useQuery<GetAccountFilesList, GetAccountFilesListVariables>(
        GET_PORTAL_ACCOUNTLIST_FILES,
        {
            variables: { accountlistid: props.selectedlistid },
            context: {
                headers: {
                    "x-hasura-role": authStore.user?.hasCoownerAuditorRole
                        ? getRoleKey(Role.AUDITOR)
                        : getRoleKey(authStore.user?.role)
                }
            }
        }
    );

    const renderAccountlistFileHeader = useCallback((screenSize: ScreenSize) => {
        if (screenSize === ScreenSize.DESKTOP) {
            const headerRows: React.ReactElement[] = [
                <UI.List.Row key={"row-1"}>
                    <UI.List.Cell key={"c-1"} colspan={2} value={"Bezeichnung"} />
                    <UI.List.Cell key={"c-2"} colspan={1} value={"Datum"} />
                </UI.List.Row>
            ];

            return <UI.List.Header rows={headerRows} />;
        }
    }, []);

    const renderAccountlistFileItem = useCallback((item: portalAccountlistfiles, screenSize: ScreenSize) => {
        const file = item;
        const listRows: any[] = [];

        if (file) {
            const date = format(new Date(file.filedate), "dd.MM.yyy");

            let rows: React.ReactElement[] = [];

            switch (screenSize) {
                case ScreenSize.DESKTOP:
                    rows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={2} value={file.name} />
                            <UI.List.Cell key={"c-2"} colspan={1} value={date} />
                        </UI.List.Row>
                    ];
                    break;
                case ScreenSize.MOBILE:
                    rows = [
                        <UI.List.Row key={"row-1"}>
                            <UI.List.Cell key={"c-1"} colspan={2} value={file.name} />
                        </UI.List.Row>,
                        <UI.List.Row key={"row-2"}>
                            <UI.List.Cell key={"c-2"} colspan={1} value={date} className="sublabel" />
                        </UI.List.Row>
                    ];
                    break;
            }

            listRows.push(rows);
        }

        let urlLink = NetworkConfig.datafileUrl + file?.id;

        const icon = (
            <a href={urlLink} className="link d-flex" rel="noopener noreferrer" target="_blank">
                <UI.Icon icon={UI.SVGIcon.Documents} color={ColorStyle("primary")} />
            </a>
        );

        return <UI.List.Item key={file?.id} screenSize={screenSize} rows={listRows} icon={icon} />;
    }, []);

    if (loading) {
        return <UI.LoadingCard />;
    }
    if (error) {
        return <UI.ErrorCard message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <UI.ErrorCard message={t("error.nodata").toString()} />;
    }

    let accountlistfileItems: portalAccountlistfiles[] | undefined = undefined;

    if (data.accountlistfiles.length > 0) {
        accountlistfileItems = data.accountlistfiles;
    }

    return (
        <>
            {accountlistfileItems ? (
                <UI.Card flex={true}>
                    <UI.Row>
                        <UI.Col>
                            <UI.List.BasicList
                                title="Abschlussdokumente"
                                screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                                items={accountlistfileItems}
                                renderItem={renderAccountlistFileItem}
                                renderHeader={renderAccountlistFileHeader}
                                emptyListMessage={t("screens.realestate.documents.no_documents_found").toString()}
                                itemsPerPage={10}
                            />
                        </UI.Col>
                    </UI.Row>
                </UI.Card>
            ) : undefined}
        </>
    );
};

export const AccountFilesList = Sentry.withProfiler(observer(AccountFilesListBase));
