import { observable, action, makeObservable, computed, runInAction } from "mobx";
import { RootStore } from "./RootStore";
import { apolloClientInstance } from "src/network/apolloClientInstance";
import {
    GET_PROCESSES,
    UPSERT_INCIDENT,
    GET_INCIDENT_RELATIONSHIP_IDS,
    GET_TENANCY_INFORMATION,
    GET_ALL_TICKETS,
    GET_TICKET_BY_NUMBER,
    DELETE_FILE,
    GET_ORDERS_OF_TICKET,
    GET_TICKET_ENUMERATIONS,
    GET_HISTORY_OF_TICKET,
    UPSERT_INCIDENT_COMMENT,
    GET_INCIDENT_PERSONS_OF_TICKET,
    SUBSCRIBE_TO_ASSIGNED_USER_UPDATES,
    SUBSCRIBE_TO_INCIDENT_PERSON_UPDATES,
    UPDATE_PERSON_DETAILS
} from "src/api/ticket";
import { GetProcesses, GetProcessesVariables, GetProcesses_processes } from "src/api/generated/GetProcesses";
import { UpsertIncident, UpsertIncidentVariables } from "src/api/generated/UpsertIncident";
import { UpsertIncidentComment, UpsertIncidentCommentVariables } from "src/api/generated/UpsertIncidentComment";
import {
    GetIncidentReleationshipIds,
    GetIncidentReleationshipIdsVariables,
    GetIncidentReleationshipIds_incident_files
} from "src/api/generated/GetIncidentReleationshipIds";
import { GetTenancyInformation, GetTenancyInformationVariables } from "src/api/generated/GetTenancyInformation";
import i18next from "i18next";
import {
    ticket_incidentpersons_arr_rel_insert_input,
    ticket_incidentpersons_update_column,
    ticket_incidents_insert_input,
    ticket_incidents_mls_arr_rel_insert_input,
    ticket_incidents_mls_constraint,
    ticket_incidents_mls_insert_input,
    ticket_incidents_mls_update_column,
    ticket_incidentpersons_insert_input
} from "src/api/generated/globalTypes";
import { isEmpty } from "lodash";
import { utcToZonedTime, format } from "date-fns-tz";
import { de, fr, enGB, it } from "date-fns/locale";
import { ticket_incidentpersons_constraint } from "src/api/generated/globalTypes";
import {
    GetTicketByNumber,
    GetTicketByNumberVariables,
    GetTicketByNumber_primarycontacttypeEnum,
    GetTicketByNumber_ticket_incidents,
    GetTicketByNumber_ticket_incidents_history,
    GetTicketByNumber_ticket_incidents_incidentfiles,
    GetTicketByNumber_ticket_incidents_incidentpersons,
    GetTicketByNumber_ticket_incidents_incidentpersons_person
} from "src/api/generated/GetTicketByNumber";
import { getTicketStateWithId } from "./tickets/TicketListStore";
import {
    GetAllTickets,
    GetAllTickets_ticket_incidents,
    GetAllTickets_ticket_incidents_incidentpersons,
    GetAllTickets_ticket_incidents_incidentpersons_person
} from "src/api/generated/GetAllTickets";
import { IIncidentContactPersonData } from "./tickets/ContactPersonSearchStore";
import { Role, getRoleId, getRoleKey } from "src/network/User";
import { NetworkConfig } from "src/network/NetworkConfig";
import { DeleteFile, DeleteFileVariables } from "src/api/generated/DeleteFile";
import { findDifferences, isEmptyObject } from "src/utils/Object";
import { MessageType } from "src/components/notifications/Notifier";
import { CONTRACTOR_TYPE } from "./OrderStore";
import { GetOrdersOfTicket, GetOrdersOfTicketVariables } from "src/api/generated/GetOrdersOfTicket";
import { GetTicketEnumerations, GetTicketEnumerationsVariables } from "src/api/generated/GetTicketEnumerations";
import { GetHistoryOfTicket, GetHistoryOfTicketVariables } from "src/api/generated/GetHistoryOfTicket";
import { Route } from "src/config/routes";
import {
    GetIncidentPersonsOfTicket,
    GetIncidentPersonsOfTicketVariables,
    GetIncidentPersonsOfTicket_ticket_incidentpersons
} from "src/api/generated/GetIncidentPersonsOfTicket";
import {
    SubscribeToAssignedUserUpdates,
    SubscribeToAssignedUserUpdatesVariables,
    SubscribeToAssignedUserUpdates_incident
} from "src/api/generated/SubscribeToAssignedUserUpdates";
import { Subscription } from "zen-observable-ts";
import {
    SubscribeToIncidentPersonUpdates,
    SubscribeToIncidentPersonUpdatesVariables,
    SubscribeToIncidentPersonUpdates_ticket_incidentpersons
} from "src/api/generated/SubscribeToIncidentPersonUpdates";
import { UpdatePersonDetails, UpdatePersonDetailsVariables } from "src/api/generated/UpdatePersonDetails";

export enum TICKET_STATE {
    NEW = "new",
    ASSIGNED = "assigned",
    COMISSIONED = "comissioned",
    COMPLETED = "completed",
    ARCHIVED = "archived"
}

export enum IMAGE_TYPE {
    DAMAGE = 501,
    SERIALNUMBER = 502,
    PRODUCTIONNUMBER = 503,
    KEY = 504,
    DOCUMENT = 505
}

export enum TICKET_IMAGE_STATE {
    NONE,
    NEW,
    LOADED_FROM_DB,
    HAS_CHANGED,
    WAS_DELETED
}

export interface ITicketImage {
    fileId?: string;
    incidentFileId?: string;
    name?: string;
    url?: string;
    type?: IMAGE_TYPE;
    base64String?: string;
    mimetype?: string;
    extension?: string;
    fileDate?: string;
    state?: TICKET_IMAGE_STATE;
}

export enum PROCESS {
    COMMON_REQUEST = 1,
    DAMAGE_NOTIFICATION = 2,
    NAMESIGN = 3,
    DOCUMENT_QUESTION = 4,
    KEY_ORDER = 5,
    MY_REALESTATES = 6
}

export enum TICKET_ORIGIN {
    MANUAL = 1,
    EASY_CONTACT = 2
}

export enum PERSON_ORIGIN {
    CLOUD = 1,
    ERP = 2,
    EXTERNAL = 3
}

interface IProcess {
    notificationtype: number | null;
    name: string;
}

export interface IRealestate {
    id: string;
    number: string;
    name: string;
    zip: string;
    city: string;
}

export interface IRealestateData {
    id: string;
    number: string;
    consolidation: boolean;
    nameZipCity: string;
    name: string;
    zip: string;
    city: string;
    thumbnailFileId?: string;
}

export interface IIncidentPerson {
    id?: string;
    name1: string;
    name2?: string | null;
    email?: string | null;
    phone?: string | null;
    mobile?: string | null;
    role?: number | null;
    origin?: number | null;
}

export interface IOrder {
    number: number;
    workflowinstance: any | null;
    state: number;
    contractorType: CONTRACTOR_TYPE;
    serviceProviderName?: string;
    id: string;
}

export enum INCIDENT_PERSON_ROLE {
    MANAGER = 10,
    OWNER = 11,
    FACILITY = 12,
    CONTRACTOR = 22,
    CONTACT = 30
}

export enum COMPONENT_TYPE {
    GROUP = 1,
    COMPONENT = 2,
    OTHER = 3
}

export interface IAssignedUser {
    name1: string;
    name2?: string | null;
}

export interface IUnitData {
    houseId: string;
    unitId: string;
    unitNumber?: number;
    unitName: string;
    tenancyData?: ITenancyData;
}

export interface ITenancyData {
    tenancyid?: string;
    tenantid?: string;
}

export interface IAssignedUser {
    id: string;
    name1: string;
    name2?: string | null;
}

export interface IApplianceData {
    id: any;
    name: string | null;
    model: string | null;
    manufacturer: string | null;
    serialnumber: string | null;
    realestateid: any;
    unitid: any | null;
}

export interface IComponent {
    id: any;
    parentid: any | null;
    type: number;
    parameters: any | null;
    title: string;
    isSelected: boolean;
    childComponentsIds?: string[];
}

export interface IDamageNotificationDetails {
    [key: string]: any;
    description?: string;
    isOtherComponentSelected: boolean;
    otherComponentName?: string;
    hasManufacturer?: boolean;
    manufacturer?: string;
    hasSerialNumber?: boolean;
    serialnumber?: string;
    images: ITicketImage[];
    serialNumberImage: ITicketImage;
}

export interface ICommonRequestNotificationDetails {
    [key: string]: any;
    description?: string;
}

export interface INamesignNotificationDetails {
    [key: string]: any;
    inscription: string;
    belldifferent: boolean;
    mailboxdifferent: boolean;
    doordifferent: boolean;
    bellinscription: string;
    mailboxinscription: string;
    doorinscription: string;
    description?: string;
}

export interface IKeyOrderNotificationDetails {
    [key: string]: any;
    inscription: string;
    isOtherKeyComponentSelected: boolean;
    otherKeyName?: string;
    amount: number;
    lost?: boolean;
    description?: string;
    images: ITicketImage[];
}

export interface IQuestionToDocumentNotificationDetails {
    [key: string]: any;
    isOtherDocumentComponentSelected: boolean;
    otherDocumentName?: string;
    message: string;
    images: ITicketImage[];
}

export enum PERSON_SEARCH_FILTER_TYPE {
    REALESTATE,
    UNIT
}

export enum LOADING_TYPE {
    NONE,
    TICKET,
    SEARCHING_REALESTATES,
    REALESTATE_THUMBNAIL,
    OBJECT_UNITS,
    SAVING_TICKET,
    SAVING_COMMENT,
    TENANT_INFORMATION,
    APPLIANCE_DATA,
    COMPONENT_DATA,
    CHECK_INCIDENT_CAN_BE_COMPLETED,
    CLOSING_TICKET,
    ASSIGNING_TICKET_TO_USER,
    TAKING_OVER_TICKET,
    RELOADING_ORDERS
}

export enum TICKET_PROPERTY {
    ID = "id",
    WORKFLOWINSTANCE = "workflowinstance",
    NUMBER = "number",
    DATE = "date",
    TITLE = "title",
    STATE = "state",
    WORKFLOW_TYPE = "workflow_type",
    REALESTATE = "realestate",
    REALESTATE_THUMBNAIL_FILEID = "realestateThumbnailFileId",
    UNIT = "unit",
    INCIDENT_CONTACT_PERSON = "incidentContactPerson",
    ASSIGNED_USER = "assignedUser",
    APPLIANCE = "appliance",
    INCIDENT_PERSONS = "incidentPersons",
    COMPONENTID = "componentid",
    ROOMTYPE = "roomtype",
    APPLIANCETYPE = "appliancetype",
    DAMAGE_NOTIFICATION_DETAILS = "damageNotificationDetails",
    COMMON_REQUEST_NOTIFICATION_DETAILS = "commonRequestNotificationDetails",
    NAMESIGN_NOTIFICATION_DETAILS = "namesignNotificationDetails",
    KEY_ORDER_NOTIFICATION_DETAILS = "keyOrderNotificationDetails",
    QUESTION_TO_DOCUMENT_NOTIFICATION_DETAILS = "questionToDocumentNotificationDetails",
    ORDERS = "orders",
    HISTORY = "history",
    COMMENT = "comment",
    NOTIFICATION_ADDRESS = "notificationAddress",
    INCIDENT_ID = "incidentid"
}

export enum HISTORY_TYPE {
    INCIDENT_CREATED,
    INCIDENT_USER_ASSIGNED,
    INCIDENT_STATUS_CHANGED,
    INCIDENT_DATA_CHANGED,
    INCIDENT_TEXT_CHANGED,
    INCIDENT_NEW_CONTACT_PERSON,
    INCIDENT_COMMENT,
    ORDER_CREATED,
    ORDER_STATUS_CHANGED,
    ORDER_COMMENT,
    INCIDENT_EMAIL_OUTBOUND,
    ORDER_EMAIL_OUTBOUND,
    INCIDENT_EMAIL_INBOUND,
    ORDER_EMAIL_INBOUND
}

export enum TICKET_EMAIL_TYPE {
    TICKETING_NOTIFICATION = 550,
    ADMIN_NOTIFICATION = 551,
    TICKET_CONFIRMATION = 552,
    TICKET_COMPLETION = 553,
    MANAGER_NOTIFICATION = 554,
    USER_NOTIFICATION = 555,
    CONTRACTOR_ORDER_NOTIFICATION = 570,
    REPORTER_ORDER_NOTIFICATION = 571
}

const TICKET_LIST_REFRESH_TIME_IN_SECONDS = 60;

export class TicketStore {
    rootStore: RootStore;

    tickets: Ticket[] = [];
    currentTicket: Ticket | undefined;
    isEditing: boolean = false;
    loadingType: LOADING_TYPE = LOADING_TYPE.NONE;

    userDefinedAfterSaveFunction?: () => void;

    loadAllTicketsTimer?: NodeJS.Timer;
    initialLoadAllTicketsCompleted: boolean = false;

    processes: IProcess[] = [];

    numberOfDisplayedItems: number = 100;
    sliceSizeOfDisplayedItems: number = 50;

    roomTypes: { [key: number]: string } = {};
    applianceTypes: { [key: number]: string } = {};

    error?: {
        title: string;
    } = undefined;

    NEW_TICKET_ID = "new-ticket";

    primarycontacttypeEnum: GetTicketByNumber_primarycontacttypeEnum[] = [];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            processes: observable,
            tickets: observable,
            currentTicket: observable,
            isEditing: observable,
            loadingType: observable,
            numberOfDisplayedItems: observable,
            sliceSizeOfDisplayedItems: observable,
            error: observable,
            roomTypes: observable,
            applianceTypes: observable,
            userDefinedAfterSaveFunction: observable,
            initialLoadAllTicketsCompleted: observable,
            primarycontacttypeEnum: observable,

            init: action,
            initForm: action,
            loadAllTickets: action,
            initialLoadAllTickets: action,
            setCurrentTicket: action,
            setTickets: action,
            setProcesses: action,
            setLoadingType: action,
            loadCurrentTicket: action,
            setIsEditing: action,
            loadRealestateThumbnailFile: action,
            loadRealestateData: action,
            updateUnitOnTicketWithAdditionalTenantInformation: action,
            setNumberOfDisplayedItems: action,
            setSliceSizeOfDisplayedItems: action,
            resetRealestateFormField: action,
            resetUnitFormField: action,
            resetPersonFormField: action,
            saveCurrentTicket: action,
            checkCurrentTicketReadyToComplete: action,
            closeCurrentTicket: action,
            setLoadingError: action,
            resetFormFields: action,
            setRealestateAndUnitFormFields: action,
            initializeFormFields: action,
            getTicketByNumber: action,
            reloadCurrentTicketOrders: action,
            loadEnumerations: action,
            setRoomTypes: action,
            setApplianceTypes: action,
            setUserDefinedAfterSaveFunction: action,
            setInitialLoadAllTicketsCompleted: action,
            setPrimarycontacttypeEnum: action
        });
    }

    init = async (ticketNumber: string) => {
        await this.loadProcesses();
        await this.loadEnumerations();
        await this.loadRealestateData();
        await this.rootStore.damageNotificationStore.init();
        await this.rootStore.keyOrderNotificationStore.init();
        await this.rootStore.questionToDocumentNotificationStore.init();
        await this.rootStore.assignTicketModalStore.init();

        this.resetFormFields();

        await this.initForm(ticketNumber);
    };

    initForm = async (ticketNumber: string) => {
        if (ticketNumber === this.NEW_TICKET_ID) {
            this.setIsEditing(true);
            runInAction(() => {
                this.currentTicket = new Ticket(this.rootStore);
            });
        } else {
            runInAction(async () => {
                await this.loadCurrentTicket(ticketNumber);
                await this.rootStore.assignOrderModalStore.init();
                this.currentTicket?.resetAllFieldsWithChanges();
            });
        }
    };

    loadProcesses = async () => {
        this.setLoadingType(LOADING_TYPE.TICKET);

        const { data }: { data: GetProcesses } = await apolloClientInstance.query<GetProcesses, GetProcessesVariables>({
            query: GET_PROCESSES
        });

        const processes = data.processes.map((process: GetProcesses_processes) => {
            let processName = "";
            let processObject: IProcess;

            processName = process.process_mls.length > 0 ? process.process_mls[0].title : "";
            if (process.notificationtype === PROCESS.NAMESIGN && processName.startsWith("Namensschilder")) {
                processName = i18next.t("easyticket.workflow_types.3");
            }

            processObject = {
                notificationtype: process.notificationtype,
                name: processName
            };

            return processObject;
        });

        this.setProcesses(processes);

        this.setLoadingType(LOADING_TYPE.NONE);
    };

    loadEnumerations = async () => {
        this.setLoadingType(LOADING_TYPE.TICKET);

        const { data }: { data: GetTicketEnumerations } = await apolloClientInstance.query<
            GetTicketEnumerations,
            GetTicketEnumerationsVariables
        >({
            query: GET_TICKET_ENUMERATIONS,
            variables: {
                language: "de"
            }
        });

        const roomTypesObject: { [key: number]: string } = {};
        const applianceTypesObject: { [key: number]: string } = {};

        data.roomtypes.forEach((roomtype) => {
            if (roomtype.key && roomtype.label) {
                roomTypesObject[roomtype.key] = roomtype.label;
            }
        });

        data.appliancetypes.forEach((appliancetype) => {
            if (appliancetype.key && appliancetype.label) {
                applianceTypesObject[appliancetype.key] = appliancetype.label;
            }
        });

        this.setRoomTypes(roomTypesObject);
        this.setApplianceTypes(applianceTypesObject);

        this.setLoadingType(LOADING_TYPE.NONE);
    };

    loadRealestateData = async () => {
        this.setLoadingType(LOADING_TYPE.TICKET);

        await this.rootStore.realestateSearchStore.loadRealestateData();

        this.setLoadingType(LOADING_TYPE.NONE);
    };

    loadUnitData = async (realestateId: string) => {
        this.setLoadingType(LOADING_TYPE.OBJECT_UNITS);

        await this.rootStore.unitSearchStore.loadUnitData(realestateId);

        this.setLoadingType(LOADING_TYPE.NONE);
    };

    updateUnitOnTicketWithAdditionalTenantInformation = async (unit: IUnitData): Promise<boolean> => {
        let successfullyUpdatedTicketWithTenantInformation = true;

        this.setLoadingType(LOADING_TYPE.TENANT_INFORMATION);

        const { data }: { data: GetTenancyInformation } = await apolloClientInstance.query<
            GetTenancyInformation,
            GetTenancyInformationVariables
        >({
            query: GET_TENANCY_INFORMATION,
            variables: {
                unitid: unit.unitId
            }
        });

        if (data.tenancys.length > 0) {
            const tenancy = data.tenancys[0];
            const tenancyData: ITenancyData = {
                tenancyid: tenancy.id,
                tenantid: tenancy.tenantid
            };

            const newUnitData: IUnitData = {
                ...unit,
                tenancyData: tenancyData
            };

            this.currentTicket?.updateProperty(TICKET_PROPERTY.UNIT, newUnitData);
        } else {
            this.rootStore.uiStore.printTicketingErrorMessage(
                i18next.t("screens.tickets.form.errors.unit.no_further_information_could_be_loaded")
            );
            successfullyUpdatedTicketWithTenantInformation = false;
        }

        this.setLoadingType(LOADING_TYPE.NONE);

        return successfullyUpdatedTicketWithTenantInformation;
    };

    updateFormWithSelectedRealestate = async (selectedRealestate: IRealestate) => {
        const realestateSearchStore = this.rootStore.realestateSearchStore;

        const newRealestateFieldValue = realestateSearchStore.getFormattedRealestateFieldValue(selectedRealestate);

        realestateSearchStore.setCurrentRealestateSearchQuery(newRealestateFieldValue);
        realestateSearchStore.setSelectedRealestateQueryString(newRealestateFieldValue);
        realestateSearchStore.setSelectedRealestateName(selectedRealestate.name);

        await this.rootStore.unitSearchStore.loadUnitData(selectedRealestate.id);

        this.rootStore.contactPersonSearchStore.setPersonSearchFilterRealestate(true);

        this.rootStore.damageNotificationStore.setIsApplianceSearchedFromMasterdata(
            this.rootStore.damageNotificationStore.hasApplianceInSelectedRealestate(selectedRealestate.id)
        );

        if (this.currentTicket) {
            if (this.currentTicket.isUnitSelected) {
                this.resetUnitFormField();
            }

            this.currentTicket.updateProperty(TICKET_PROPERTY.REALESTATE, selectedRealestate);
            this.currentTicket.validateRealestateNotificationDetails();

            await this.loadRealestateThumbnailFile(selectedRealestate.id);
        }

        this.rootStore.contactPersonSearchStore.setIsSearchingPersonsInBackendByQuery(false);
        this.rootStore.contactPersonSearchStore.searchPersonsByRealestate();

        this.rootStore.unitSearchStore.setTriggerFocusOnUnitInput(true);
    };

    resetRealestateFormField = () => {
        this.currentTicket?.updateProperty(TICKET_PROPERTY.REALESTATE, undefined);

        const realestateSearchStore = this.rootStore.realestateSearchStore;
        realestateSearchStore.setCurrentRealestateSearchQuery("");
        realestateSearchStore.setSelectedRealestateQueryString("");
        realestateSearchStore.setSelectedRealestateName("");
    };

    resetUnitFormField = () => {
        this.currentTicket?.updateProperty(TICKET_PROPERTY.UNIT, undefined);

        const unitSearchStore = this.rootStore.unitSearchStore;
        unitSearchStore.setCurrentUnitSearchQuery("");
        unitSearchStore.setSelectedUnitQueryString("");

        this.rootStore.contactPersonSearchStore.setPersonSearchFilterUnit(false);
    };

    resetPersonFormField = () => {
        this.currentTicket?.updateProperty(TICKET_PROPERTY.INCIDENT_CONTACT_PERSON, undefined);
    };

    loadRealestateThumbnailFile = async (realestateId: string) => {
        this.setLoadingType(LOADING_TYPE.REALESTATE_THUMBNAIL);

        const realestateThumbnailFileId = await this.rootStore.realestateSearchStore.getRealestateThumbnailFileId(
            realestateId
        );

        this.currentTicket?.updateProperty(TICKET_PROPERTY.REALESTATE_THUMBNAIL_FILEID, realestateThumbnailFileId);
        this.setLoadingType(LOADING_TYPE.NONE);

        return realestateThumbnailFileId;
    };

    getProcesses = async () => {
        if (this.processes.length === 0) {
            await this.loadProcesses();
        }

        return this.processes;
    };

    setRealestateAndUnitFormFields = async (ticket: Ticket) => {
        const realestate = ticket.realestate;
        if (realestate) {
            await this.loadUnitData(realestate.id);

            const realestateSearchStore = this.rootStore.realestateSearchStore;

            await this.loadRealestateThumbnailFile(realestate.id);

            const newRealestateFieldValue = realestateSearchStore.getFormattedRealestateFieldValue(realestate);

            realestateSearchStore.setCurrentRealestateSearchQuery(newRealestateFieldValue);
            realestateSearchStore.setSelectedRealestateQueryString(newRealestateFieldValue);
            realestateSearchStore.setSelectedRealestateName(realestate.name);

            this.rootStore.contactPersonSearchStore.setPersonSearchFilterRealestate(true);
            await this.rootStore.contactPersonSearchStore.searchPersonsByRealestate();
        }

        const unit = ticket.unit;
        if (unit) {
            const newUnitFieldValue = `${unit.unitName}${unit.unitNumber ? ` [${unit.unitNumber}]` : ""}`;

            const unitSearchStore = this.rootStore.unitSearchStore;

            unitSearchStore.setCurrentUnitSearchQuery(newUnitFieldValue);
            unitSearchStore.setSelectedUnitQueryString(newUnitFieldValue);

            this.rootStore.contactPersonSearchStore.setPersonSearchFilterUnit(true);
        }
    };

    initializeFormFields = async (ticket: Ticket) => {
        await this.setRealestateAndUnitFormFields(ticket);

        if (this.currentTicket) {
            if (this.currentTicket.workflow_type === PROCESS.DAMAGE_NOTIFICATION) {
                if (this.currentTicket.componentid) {
                    this.rootStore.damageNotificationStore.reconstructFormComponentTreeData();
                } else {
                    this.rootStore.damageNotificationStore.initializeFormComponentTreeData();
                }

                if (this.currentTicket.appliance) {
                    this.rootStore.damageNotificationStore.setIsApplianceSearchedFromMasterdata(true);
                } else {
                    this.rootStore.damageNotificationStore.setIsApplianceSearchedFromMasterdata(false);
                }
            }

            if (this.currentTicket.workflow_type === PROCESS.KEY_ORDER) {
                if (this.currentTicket.componentid) {
                    this.rootStore.keyOrderNotificationStore.initializeFormComponentTreeData();
                }
            }

            if (this.currentTicket.workflow_type === PROCESS.DOCUMENT_QUESTION) {
                if (this.currentTicket.componentid) {
                    this.rootStore.questionToDocumentNotificationStore.initializeFormComponentTreeData();
                }
            }
        }
    };

    getTicketImagesFromIncidentFilesQueryResult = (
        incidentFiles: GetTicketByNumber_ticket_incidents_incidentfiles[],
        imageType: IMAGE_TYPE
    ): ITicketImage[] => {
        const ticketImages: ITicketImage[] = [];

        incidentFiles.forEach((incidentfile) => {
            if (incidentfile.type === imageType) {
                const ticketImage: ITicketImage = {
                    fileId: incidentfile.fileid,
                    incidentFileId: incidentfile.incidentfileid,
                    state: TICKET_IMAGE_STATE.LOADED_FROM_DB,
                    url: NetworkConfig.openThumbnailUrl + incidentfile.fileid
                };

                ticketImages.push(ticketImage);
            }
        });

        return ticketImages;
    };

    getComponentNameById = (componentId: string): string | undefined => {
        const component = this.rootStore.damageNotificationStore.componentData.find(
            (component) => component.id === componentId
        );

        let componentName = undefined;

        if (component && component.component_mls.length > 0) {
            componentName = component.component_mls[0].title;
        }

        return componentName;
    };

    getHistoryChangeEntries = (
        history: GetTicketByNumber_ticket_incidents_history,
        isHistoryTypeIncident: boolean,
        isHistoryTypeOrder: boolean
    ): IHistoryChangeEntry[] => {
        const changeObjectArray: IHistoryChangeEntry[] = [];

        Object.entries(history.new_values).forEach(([key, value]) => {
            const changeObject: IHistoryChangeEntry = {};

            let oldValue = history.old_values && history.old_values[key] ? history.old_values[key] : undefined;
            let newValue = value;

            switch (key) {
                case "componentid":
                    const oldComponentName = this.getComponentNameById(oldValue);
                    oldValue = oldComponentName ?? "-";

                    const newComponentName = this.getComponentNameById(String(newValue));
                    newValue = newComponentName ?? "-";
                    break;

                case "roomtype":
                    oldValue = oldValue ? this.roomTypes[oldValue] : "-";
                    newValue = this.roomTypes[Number(newValue)];
                    break;

                case "appliancetype":
                    oldValue = oldValue ? this.applianceTypes[oldValue] : "-";
                    newValue = newValue ? this.applianceTypes[Number(newValue)] : "-";
                    break;

                case "state":
                    if (isHistoryTypeIncident) {
                        oldValue = oldValue
                            ? i18next.t(`easyticket.states.${getTicketStateWithId(Number(oldValue))}`)
                            : "-";
                        newValue = newValue
                            ? i18next.t(`easyticket.states.${getTicketStateWithId(Number(newValue))}`)
                            : "-";
                    } else if (isHistoryTypeOrder) {
                        oldValue = oldValue ? i18next.t(`screens.orders.state.${oldValue}`) : "-";
                        newValue = newValue ? i18next.t(`screens.orders.state.${newValue}`) : "-";
                    }

                    break;

                case "type":
                    oldValue = oldValue ? i18next.t(`easyticket.workflow_types.${oldValue}`) : "-";
                    newValue = newValue ? i18next.t(`easyticket.workflow_types.${newValue}`) : "-";
                    break;

                case "origin":
                    oldValue = oldValue ? i18next.t(`easyticket.origin.${oldValue}`) : "-";
                    newValue = newValue ? i18next.t(`easyticket.origin.${newValue}`) : "-";
                    break;

                case "contractortype":
                    oldValue = oldValue ? i18next.t(`screens.orders.contractor.type.${oldValue}`) : "-";
                    newValue = newValue ? i18next.t(`screens.orders.contractor.type.${newValue}`) : "-";
                    break;
                case "nameplate":
                    try {
                        const oldObject = JSON.parse(oldValue);
                        const newObject = JSON.parse(String(newValue));

                        Object.entries(newObject).forEach(([key, value]) => {
                            if (!["belldifferent", "doordifferent", "mailboxdifferent"].includes(key)) {
                                const nestedChangeObject: IHistoryChangeEntry = {};

                                let oldObjectValue = oldObject && oldObject[key] ? oldObject[key] : undefined;
                                let newObjectValue: string = String(value);

                                nestedChangeObject.oldValue =
                                    oldObjectValue && oldObjectValue.length > 0 ? oldObjectValue : "-";
                                nestedChangeObject.newValue =
                                    newObjectValue && newObjectValue.length > 0 ? newObjectValue : "-";

                                nestedChangeObject.description = i18next.exists(
                                    `screens.tickets.history.nameplate.${key}`
                                )
                                    ? i18next.t(`screens.tickets.history.nameplate.${key}`).toString()
                                    : key;

                                changeObjectArray.push(nestedChangeObject);
                            }
                        });
                    } catch (error) {
                        console.error("Failed to parse JSON:", error);
                    }

                    break;
                case "key":
                    try {
                        const oldObject = JSON.parse(oldValue);
                        const newObject = JSON.parse(String(newValue));

                        Object.entries(newObject).forEach(([key, value]) => {
                            if (!["keyimage1", "keyimage2"].includes(key)) {
                                const nestedChangeObject: IHistoryChangeEntry = {};

                                let oldObjectValue = oldObject && oldObject[key] ? String(oldObject[key]) : undefined;
                                let newObjectValue: string = String(value);

                                oldObjectValue =
                                    oldObjectValue === "true"
                                        ? i18next.t("labels.yes").toString()
                                        : oldObjectValue === "false"
                                        ? i18next.t("labels.no").toString()
                                        : oldObjectValue;

                                newObjectValue =
                                    newObjectValue === "true"
                                        ? i18next.t("labels.yes").toString()
                                        : newObjectValue === "false"
                                        ? i18next.t("labels.no").toString()
                                        : newObjectValue;

                                nestedChangeObject.oldValue =
                                    oldObjectValue && oldObjectValue.length > 0 ? oldObjectValue : "-";
                                nestedChangeObject.newValue =
                                    newObjectValue && newObjectValue.length > 0 ? newObjectValue : "-";

                                nestedChangeObject.description = i18next.exists(`screens.tickets.history.key.${key}`)
                                    ? i18next.t(`screens.tickets.history.key.${key}`).toString()
                                    : key;

                                changeObjectArray.push(nestedChangeObject);
                            }
                        });
                    } catch (error) {
                        console.error("Failed to parse JSON:", error);
                    }
                    break;
            }

            const keysWithNestedObjects = ["nameplate", "key"];

            if (!keysWithNestedObjects.includes(key)) {
                changeObject.description = i18next.exists(`screens.tickets.history.${key}`)
                    ? i18next.t(`screens.tickets.history.${key}`).toString()
                    : key;
                changeObject.oldValue = oldValue ?? "-";
                changeObject.newValue = newValue;

                changeObjectArray.push(changeObject);
            }
        });

        return changeObjectArray;
    };

    getHistoryChangeText = (historyType: HISTORY_TYPE, history: GetTicketByNumber_ticket_incidents_history): string => {
        let changeText = "";

        switch (historyType) {
            case HISTORY_TYPE.INCIDENT_CREATED:
                changeText = i18next.t("screens.tickets.history.incident_created");
                break;
            case HISTORY_TYPE.INCIDENT_USER_ASSIGNED:
                changeText = i18next.t("screens.tickets.history.incident_user_assigned");
                break;
            case HISTORY_TYPE.INCIDENT_STATUS_CHANGED:
                changeText = i18next.t("screens.tickets.history.incident_status_changed");
                break;
            case HISTORY_TYPE.INCIDENT_DATA_CHANGED:
            case HISTORY_TYPE.INCIDENT_TEXT_CHANGED:
                changeText = i18next.t("screens.tickets.history.data_changed");
                break;
            case HISTORY_TYPE.INCIDENT_NEW_CONTACT_PERSON:
                changeText = i18next.t("screens.tickets.history.incident_new_contact_person");
                break;
            case HISTORY_TYPE.INCIDENT_COMMENT:
                changeText = history.comment_text ?? "";
                break;
            case HISTORY_TYPE.ORDER_CREATED:
                changeText = i18next.t("screens.tickets.history.order_created");
                break;
            case HISTORY_TYPE.ORDER_STATUS_CHANGED:
                changeText = i18next.t("screens.tickets.history.order_status_changed");
                break;
            case HISTORY_TYPE.ORDER_COMMENT:
                changeText = history.comment_text ?? "";
                break;
        }

        return changeText;
    };

    getHistoryObjectFromQueryData = (historyQueryData: GetTicketByNumber_ticket_incidents_history[]): IHistory[] => {
        const historyObjectArray: IHistory[] = historyQueryData.map((history) => {
            const historyType = HISTORY_TYPE[history.type as keyof typeof HISTORY_TYPE];

            const isHistoryTypeComment =
                historyType === HISTORY_TYPE.INCIDENT_COMMENT || historyType === HISTORY_TYPE.ORDER_COMMENT;

            const isHistoryTypeOrder =
                historyType === HISTORY_TYPE.ORDER_COMMENT ||
                historyType === HISTORY_TYPE.ORDER_CREATED ||
                historyType === HISTORY_TYPE.ORDER_STATUS_CHANGED ||
                historyType === HISTORY_TYPE.ORDER_EMAIL_OUTBOUND;

            const isHistoryTypeEmailOutbound =
                historyType === HISTORY_TYPE.ORDER_EMAIL_OUTBOUND ||
                historyType === HISTORY_TYPE.INCIDENT_EMAIL_OUTBOUND;

            const isHistoryTypeEmailInbound =
                historyType === HISTORY_TYPE.INCIDENT_EMAIL_INBOUND || historyType === HISTORY_TYPE.ORDER_EMAIL_INBOUND;

            const isHistoryTypeEmail = isHistoryTypeEmailOutbound || isHistoryTypeEmailInbound;

            const isHistoryTypeIncident = !isHistoryTypeOrder;

            let changeObjectArray: IHistoryChangeEntry[] = [];

            if (history.new_values !== undefined && history.new_values !== null) {
                changeObjectArray = this.getHistoryChangeEntries(history, isHistoryTypeIncident, isHistoryTypeOrder);
            }

            let changeText = this.getHistoryChangeText(historyType, history);

            let emailDetails: IHistoryEmail | undefined = undefined;

            if (isHistoryTypeEmail) {
                let attachments: IHistoryEmailAttachment[] = [];

                if (isHistoryTypeEmailInbound && history.inboundfiles && history.inboundfiles.length > 0) {
                    history.inboundfiles.forEach((inboundfile) => {
                        const fileName = inboundfile.name ?? "";
                        const fileId = inboundfile.id ?? "";

                        attachments.push({
                            fileName: fileName,
                            fileId: fileId
                        });
                    });
                }

                let sendingErrors: IHistoryEmailLog[] = [];

                if (history.failed_outbound_email_recipients && history.failed_outbound_email_recipients.length > 0) {
                    history.failed_outbound_email_recipients.forEach((recipient) => {
                        // If there are multiple email logs, for now we only show the latest one
                        const emailLog =
                            recipient.emaillogs && recipient.emaillogs.length > 0 ? recipient.emaillogs[0] : undefined;

                        sendingErrors.push({
                            recipient: recipient.emailaddress ?? "",
                            emailLogId: emailLog?.id ?? "",
                            event: emailLog?.event ?? "",
                            message: emailLog?.message ?? ""
                        });
                    });
                }

                emailDetails = {
                    body: history.email_body ?? undefined,
                    subject: history.email_subject ?? undefined,
                    sender: history.email_sender ?? undefined,
                    recipients: history.email_recipients ?? undefined,
                    type: history.email_type ?? undefined,
                    attachments: attachments.length > 0 ? attachments : undefined,
                    sendingErrors: sendingErrors.length > 0 ? sendingErrors : undefined
                };
            }

            let userName = isHistoryTypeComment
                ? history.comment_username ?? undefined
                : history.history_username ?? undefined;
            userName = userName === "WWWorkflowClient" || isHistoryTypeEmail ? "Workflow" : userName;

            const adjustTimezoneUtcToZH = !isHistoryTypeEmailOutbound ? true : false;
            const historyObject: IHistory = {
                type: HISTORY_TYPE[history.type as keyof typeof HISTORY_TYPE],
                date: Ticket.formatDateHistoryEntry(history.inserted, adjustTimezoneUtcToZH),
                timestamp: Ticket.getTimeStampForHistoryEntry(history.inserted, adjustTimezoneUtcToZH),
                changeText: changeText,
                user: userName,
                changeEntry: changeObjectArray,
                email: emailDetails ?? undefined
            };

            return historyObject;
        });

        historyObjectArray.sort((a, b) => {
            const bTime = b.timestamp?.getTime() ?? 0;
            const aTime = a.timestamp?.getTime() ?? 0;
            return bTime - aTime;
        });

        return historyObjectArray;
    };

    static getIncidentPersonsFromQueryData = (
        incidentPersons:
            | GetTicketByNumber_ticket_incidents_incidentpersons[]
            | GetAllTickets_ticket_incidents_incidentpersons[]
            | GetIncidentPersonsOfTicket_ticket_incidentpersons[]
    ): IIncidentPerson[] => {
        const iIncidentPersons: IIncidentPerson[] = incidentPersons
            .filter((ip) => ip.person)
            .map((incidentPerson) => {
                const person = incidentPerson.person;
                return {
                    id: person?.id ?? "",
                    name1: person?.name1 ?? "",
                    name2: person?.name2 ?? null,
                    email: person?.email ?? null,
                    mobile: person?.mobile ?? null,
                    phone: person?.phoneprivate ?? null,
                    role: incidentPerson.role,
                    origin: person?.origin ?? PERSON_ORIGIN.ERP
                };
            });

        return iIncidentPersons;
    };

    getTicketPropertiesObjectFromIncidentQuery = (
        ticket: GetTicketByNumber_ticket_incidents | GetAllTickets_ticket_incidents
    ): ITicketProperties => {
        const title = ticket.incidents_mls.length > 0 ? ticket.incidents_mls[0].title : "";
        const description =
            ticket.incidents_mls.length > 0 && ticket.incidents_mls[0].description
                ? ticket.incidents_mls[0].description
                : "";
        const altComponent =
            ticket.incidents_mls.length > 0 && ticket.incidents_mls[0].altcomponent
                ? ticket.incidents_mls[0].altcomponent
                : undefined;

        const { zip, city } =
            ticket.realestate && ticket.realestate.houses.length > 0
                ? { zip: ticket.realestate.houses[0].zip ?? "", city: ticket.realestate.houses[0].city ?? "" }
                : { zip: "", city: "" };

        const realestate: IRealestate | undefined = ticket.realestate
            ? {
                  id: ticket.realestate.id,
                  number: ticket.realestate.number,
                  name: ticket.realestate.name,
                  zip,
                  city
              }
            : undefined;

        let unit: IUnitData | undefined = undefined;

        if ("unit" in ticket) {
            unit = ticket.unit
                ? {
                      houseId: ticket.unit.houseid,
                      unitId: ticket.unit.id,
                      unitNumber: ticket.unit.number ?? undefined,
                      unitName: ticket.unit.name ?? "",
                      tenancyData: {
                          tenancyid: ticket.tenancyid,
                          tenantid: ticket.tenantid
                      }
                  }
                : undefined;
        }

        const incidentContactPersonFromQuery:
            | GetAllTickets_ticket_incidents_incidentpersons_person
            | GetTicketByNumber_ticket_incidents_incidentpersons_person
            | undefined
            | null = ticket.incidentpersons.find((ip) => ip.role === INCIDENT_PERSON_ROLE.CONTACT)?.person;

        let incidentContactPersonAddress = undefined;

        if (
            incidentContactPersonFromQuery &&
            "addresses" in incidentContactPersonFromQuery &&
            Array.isArray(incidentContactPersonFromQuery.addresses)
        ) {
            incidentContactPersonAddress =
                incidentContactPersonFromQuery.addresses && incidentContactPersonFromQuery.addresses.length > 0
                    ? incidentContactPersonFromQuery.addresses[0]
                    : undefined;
        }

        const incidentContactPerson: IIncidentContactPersonData | undefined = incidentContactPersonFromQuery
            ? {
                  personid: incidentContactPersonFromQuery.id,
                  customerid: incidentContactPersonFromQuery.customerid,
                  name1: incidentContactPersonFromQuery.name1,
                  name2: incidentContactPersonFromQuery.name2,
                  mobile: incidentContactPersonFromQuery.mobile,
                  phoneprivate: incidentContactPersonFromQuery.phoneprivate,
                  email: incidentContactPersonFromQuery.email,
                  gender: incidentContactPersonFromQuery.gender,
                  street: incidentContactPersonAddress ? incidentContactPersonAddress.street : null,
                  housenumber: incidentContactPersonAddress ? incidentContactPersonAddress.housenumber : null,
                  zip: incidentContactPersonAddress ? incidentContactPersonAddress.zip : null,
                  city: incidentContactPersonAddress ? incidentContactPersonAddress.city : null,
                  origin: incidentContactPersonFromQuery.origin
              }
            : undefined;

        const appliance: IApplianceData | undefined = ticket.appliance
            ? {
                  id: ticket.appliance.id,
                  name: ticket.appliance.name,
                  model: ticket.appliance.model,
                  manufacturer: ticket.appliance.manufacturer,
                  serialnumber: ticket.appliance.serialnumber,
                  realestateid: ticket.appliance.realestateid,
                  unitid: ticket.appliance.unitid
              }
            : undefined;

        const incidentPersons: IIncidentPerson[] = TicketStore.getIncidentPersonsFromQueryData(ticket.incidentpersons);

        let incidentDamageFiles: ITicketImage[] = [];
        let incidentLabelFiles: ITicketImage[] = [];

        if ("incidentfiles" in ticket) {
            incidentDamageFiles = this.getTicketImagesFromIncidentFilesQueryResult(
                ticket.incidentfiles,
                IMAGE_TYPE.DAMAGE
            );

            incidentLabelFiles = this.getTicketImagesFromIncidentFilesQueryResult(
                ticket.incidentfiles,
                IMAGE_TYPE.SERIALNUMBER
            );
        }

        const damageNotificationDetails: IDamageNotificationDetails = {
            description: description,
            isOtherComponentSelected: altComponent ? true : false,
            otherComponentName: altComponent,
            hasManufacturer: ticket.manufacturer ? true : false,
            manufacturer: ticket.manufacturer ?? undefined,
            hasSerialNumber: ticket.serialnumber ? true : false,
            serialnumber: ticket.serialnumber ?? undefined,
            images: incidentDamageFiles,
            serialNumberImage:
                incidentLabelFiles.length === 1 && incidentLabelFiles[0]
                    ? incidentLabelFiles[0]
                    : { state: TICKET_IMAGE_STATE.NEW }
        };

        const commonRequestNotificationDetails: ICommonRequestNotificationDetails = {
            description: description
        };

        const ticketNamesignDetails: any | undefined = ticket.nameplate ?? undefined;

        const namesignNotificationDetails: INamesignNotificationDetails = {
            inscription: ticketNamesignDetails?.inscription ?? "",
            belldifferent: ticketNamesignDetails?.belldifferent ?? false,
            bellinscription: ticketNamesignDetails?.bellinscription ?? "",
            doordifferent: ticketNamesignDetails?.doordifferent ?? false,
            doorinscription: ticketNamesignDetails?.doorinscription ?? "",
            mailboxdifferent: ticketNamesignDetails?.mailboxdifferent ?? false,
            mailboxinscription: ticketNamesignDetails?.mailboxinscription ?? "",
            description: description
        };

        let incidentKeyFiles: ITicketImage[] = [];

        if ("incidentfiles" in ticket) {
            incidentKeyFiles = this.getTicketImagesFromIncidentFilesQueryResult(ticket.incidentfiles, IMAGE_TYPE.KEY);
        }

        const ticketKeyDetails: any | undefined = ticket.key ?? undefined;

        const keyOrderNotificationDetails: IKeyOrderNotificationDetails = {
            inscription: ticketKeyDetails?.inscription ?? "",
            isOtherKeyComponentSelected: altComponent ? true : false,
            otherKeyName: altComponent,
            amount: ticketKeyDetails?.amount ?? 1,
            lost: ticketKeyDetails?.lost ?? false,
            description: description,
            images: incidentKeyFiles
        };

        let incidentDocumentFiles: ITicketImage[] = [];

        if ("incidentfiles" in ticket) {
            incidentDocumentFiles = this.getTicketImagesFromIncidentFilesQueryResult(
                ticket.incidentfiles,
                IMAGE_TYPE.DOCUMENT
            );
        }

        const questionToDocumentDetails: IQuestionToDocumentNotificationDetails = {
            isOtherDocumentComponentSelected: altComponent ? true : false,
            otherDocumentName: altComponent,
            message: description,
            images: incidentDocumentFiles.length > 0 ? incidentDocumentFiles : []
        };

        let orders: IOrder[] = [];

        if ("orders" in ticket) {
            orders = ticket.orders.map((order) => {
                const serviceProviderName = order.service_provider
                    ? `${order.service_provider.name1 ?? ""} ${order.service_provider.name2 ?? ""}`.trim()
                    : undefined;

                const orderObject: IOrder = {
                    number: order.number,
                    workflowinstance: order.workflowinstance,
                    state: order.state,
                    contractorType: order.contractortype,
                    serviceProviderName: serviceProviderName,
                    id: order.id
                };

                return orderObject;
            });
        }

        let history: IHistory[] = [];

        if ("history" in ticket) {
            history = this.getHistoryObjectFromQueryData(ticket.history);
        }

        let componentId = undefined;

        if ("componentid" in ticket) {
            componentId = ticket.componentid;
        }

        let notificationAddress: INotificationAddress = {};

        const isTicketFromEasyContactWithoutDeterminedRealestate =
            ticket.origin === TICKET_ORIGIN.EASY_CONTACT && !ticket.realestate;

        if (isTicketFromEasyContactWithoutDeterminedRealestate && "notification" in ticket) {
            const location = ticket.notification?.originalmessage["location"];

            if (location) {
                notificationAddress.zip = "zip" in location ? location["zip"] : undefined;
                notificationAddress.city = "city" in location ? location["city"] : undefined;
                notificationAddress.street = "street" in location ? location["street"] : undefined;
                notificationAddress.housenumber = "number" in location ? location["number"] : undefined;
            }
        }

        let notificationId = undefined;

        if ("notificationid" in ticket) {
            notificationId = ticket.notificationid;
        }

        const newTicketData: ITicketProperties = {
            workflowinstance: ticket.workflowinstance,
            title: title,
            state: getTicketStateWithId(ticket.state),
            workflow_type: ticket.type as PROCESS,
            date: ticket.date,
            appliance: appliance,
            id: ticket.id,
            origin: ticket.origin,
            number: ticket.number,
            unit: unit,
            realestate: realestate,
            incidentContactPerson: incidentContactPerson,
            incidentPersons,
            assignedUser: ticket.user ?? undefined,
            componentid: componentId,
            roomtype: ticket.roomtype !== null ? ticket.roomtype : undefined,
            appliancetype: ticket.appliancetype !== null ? ticket.appliancetype : undefined,
            damageNotificationDetails: damageNotificationDetails,
            commonRequestNotificationDetails: commonRequestNotificationDetails,
            namesignNotificationDetails: namesignNotificationDetails,
            keyOrderNotificationDetails: keyOrderNotificationDetails,
            questionToDocumentNotificationDetails: questionToDocumentDetails,
            orders: orders,
            history: history,
            notificationAddress: !isEmptyObject(notificationAddress) ? notificationAddress : undefined,
            notificationid: notificationId
        };

        return newTicketData;
    };

    loadCurrentTicket = async (ticketNumber: string) => {
        this.setIsEditing(false);

        /* FETCH TICKET FROM SERVER */
        this.setLoadingType(LOADING_TYPE.TICKET);
        this.resetFormFields();
        this.error = undefined;
        const { data }: { data: GetTicketByNumber } = await apolloClientInstance.query<
            GetTicketByNumber,
            GetTicketByNumberVariables
        >({
            query: GET_TICKET_BY_NUMBER,
            fetchPolicy: "no-cache",
            variables: {
                ticketNumber: Number(ticketNumber),
                language: i18next.language
            }
        });

        if (data.ticket_incidents.length === 0) {
            const errorMessage = i18next.t("screens.tickets.error.not_found");
            this.error = {
                title: errorMessage
            };
            this.setLoadingType(LOADING_TYPE.NONE);
            return;
        }

        if (data.primarycontacttypeEnum) {
            this.setPrimarycontacttypeEnum(data.primarycontacttypeEnum);
        }

        const newTicketData = this.getTicketPropertiesObjectFromIncidentQuery(data.ticket_incidents[0]);

        runInAction(() => {
            this.currentTicket = new Ticket(this.rootStore, newTicketData);
            this.initializeFormFields(this.currentTicket);
        });

        this.setLoadingType(LOADING_TYPE.NONE);
    };

    loadAllTickets = async () => {
        const { data }: { data: GetAllTickets } = await apolloClientInstance.query<GetAllTickets>({
            query: GET_ALL_TICKETS,
            fetchPolicy: "no-cache",
            variables: { language: "de", userId: this.rootStore.authStore.user?.userid }
        });

        if (data) {
            const allTickets: Ticket[] = data.ticket_incidents.map((ticket) => {
                const newTicketData = this.getTicketPropertiesObjectFromIncidentQuery(ticket);
                return new Ticket(this.rootStore, newTicketData);
            });

            this.setTickets(allTickets);
        }
    };

    initialLoadAllTickets = async () => {
        this.setInitialLoadAllTicketsCompleted(false);

        const { data: dataFirstTicketTranch }: { data: GetAllTickets } =
            await apolloClientInstance.query<GetAllTickets>({
                query: GET_ALL_TICKETS,
                fetchPolicy: "no-cache",
                variables: { language: "de", limit: 100 }
            });

        if (dataFirstTicketTranch) {
            const first100Tickets: Ticket[] = dataFirstTicketTranch.ticket_incidents.map((ticket) => {
                const newTicketData = this.getTicketPropertiesObjectFromIncidentQuery(ticket);
                return new Ticket(this.rootStore, newTicketData);
            });

            this.setTickets(first100Tickets);
        }

        const { data: dataRemainingTickets }: { data: GetAllTickets } = await apolloClientInstance.query<GetAllTickets>(
            {
                query: GET_ALL_TICKETS,
                fetchPolicy: "no-cache",
                variables: { language: "de", limit: null, offset: 100 }
            }
        );

        if (dataRemainingTickets) {
            const remainingTickets: Ticket[] = dataRemainingTickets.ticket_incidents.map((ticket) => {
                const newTicketData = this.getTicketPropertiesObjectFromIncidentQuery(ticket);
                return new Ticket(this.rootStore, newTicketData);
            });

            const allTickets = this.tickets.concat(remainingTickets);
            this.setTickets(allTickets);
        }

        this.setInitialLoadAllTicketsCompleted(true);
    };

    getTicketByNumber = async (ticketNumber: number): Promise<Ticket | undefined> => {
        let ticketInStore = this.tickets.find((ticket) => ticket.number === ticketNumber);

        if (!ticketInStore) {
            await this.loadAllTickets();
            ticketInStore = this.tickets.find((ticket) => ticket.number === ticketNumber);
        }

        return ticketInStore;
    };

    startLoadAllTicketsTimer = () => {
        this.loadAllTicketsTimer = setInterval(() => {
            this.loadAllTickets();
        }, TICKET_LIST_REFRESH_TIME_IN_SECONDS * 1000);
    };

    clearLoadAllTicketsTimer = () => {
        clearInterval(this.loadAllTicketsTimer);
    };

    discardCurrentTicketChanges = async () => {
        const ticketNumber =
            !this.currentTicket?.isNewTicket || this.currentTicket?.number !== undefined
                ? `${this.currentTicket?.number}`
                : this.NEW_TICKET_ID;

        this.resetFormFields();

        await this.initForm(ticketNumber);
    };

    saveCurrentTicket = async (closeEditModeAfterSave: boolean = true): Promise<boolean> => {
        this.setLoadingType(LOADING_TYPE.SAVING_TICKET);
        let hasSuccessfullySavedCurrentTicket = false;
        let savingNewTicket = this.currentTicket?.isNewTicket ? true : false;
        if (this.currentTicket?.hasChanges) {
            hasSuccessfullySavedCurrentTicket = await this.currentTicket?.save();

            if (hasSuccessfullySavedCurrentTicket) {
                if (closeEditModeAfterSave) {
                    this.setIsEditing(false);
                }

                if (savingNewTicket) {
                    window.history.replaceState(
                        null,
                        "",
                        `${Route.manager}${Route.ticket}/${this.currentTicket?.number}`
                    );

                    await this.rootStore.assignOrderModalStore.init();
                }

                if (this.userDefinedAfterSaveFunction) {
                    this.userDefinedAfterSaveFunction();
                }

                this.rootStore.uiStore.printStatusMessage(
                    i18next.t("screens.tickets.action.save.success"),
                    MessageType.SUCCESS
                );
            } else {
                this.rootStore.uiStore.printStatusMessage(
                    i18next.t("screens.tickets.form.errors.save_form_error"),
                    MessageType.ERROR
                );
            }
        } else {
            if (closeEditModeAfterSave) {
                this.setIsEditing(false);
            }

            hasSuccessfullySavedCurrentTicket = true;
        }

        this.setLoadingType(LOADING_TYPE.NONE);

        return hasSuccessfullySavedCurrentTicket ?? false;
    };

    saveCurrentTicketComment = async (): Promise<boolean> => {
        if (this.currentTicket?.comment && this.currentTicket?.comment.length > 0) {
            this.setLoadingType(LOADING_TYPE.SAVING_COMMENT);

            const hasSuccessfullySavedComment = await this.currentTicket?.saveComment(
                this.currentTicket?.id ?? "",
                this.currentTicket?.comment ?? ""
            );

            this.setLoadingType(LOADING_TYPE.NONE);

            return hasSuccessfullySavedComment ?? false;
        }

        return false;
    };

    checkCurrentTicketReadyToComplete = async (): Promise<boolean> => {
        this.setLoadingType(LOADING_TYPE.CHECK_INCIDENT_CAN_BE_COMPLETED);

        const canCurrentTicketBeCompleted = await this.currentTicket?.checkReadyToComplete();

        this.setLoadingType(LOADING_TYPE.NONE);

        return canCurrentTicketBeCompleted ?? false;
    };

    closeCurrentTicket = async (informNotifierAboutClose?: boolean): Promise<boolean> => {
        this.setLoadingType(LOADING_TYPE.CLOSING_TICKET);

        const ticketIdOfTicketToBeClosed = this.currentTicket?.id;

        const hasSuccessfullyClosedCurrentTicket = await this.currentTicket?.close(informNotifierAboutClose ?? false);

        if (hasSuccessfullyClosedCurrentTicket && ticketIdOfTicketToBeClosed) {
            const ticketIndexInAllTicketsArray = this.tickets.findIndex(
                (ticket) => ticket.id === ticketIdOfTicketToBeClosed
            );

            if (ticketIndexInAllTicketsArray > -1) {
                this.tickets[ticketIndexInAllTicketsArray].state = TICKET_STATE.COMPLETED;
            }
        }

        this.setLoadingType(LOADING_TYPE.NONE);

        return hasSuccessfullyClosedCurrentTicket ?? false;
    };

    resetFormFields = () => {
        this.resetUnitFormField();
        this.resetRealestateFormField();
        this.currentTicket?.reset();
        this.rootStore.damageNotificationStore.resetComponentSelection();
        this.rootStore.keyOrderNotificationStore.resetComponentSelection();
        this.rootStore.questionToDocumentNotificationStore.resetComponentSelection();
    };

    reloadCurrentTicketOrders = async () => {
        this.setLoadingType(LOADING_TYPE.RELOADING_ORDERS);

        await this.currentTicket?.reloadOrders();

        this.setLoadingType(LOADING_TYPE.NONE);
    };

    // Setters
    setTickets = (tickets: Ticket[]) => {
        this.tickets = tickets;
        this.rootStore.ticketListStore.resetKanbanBoard();
    };

    setLoadingType = (loadingType: LOADING_TYPE) => {
        this.loadingType = loadingType;
    };

    setLoadingError = (title?: string) => {
        if (!title || title === "") {
            this.error = undefined;
        } else {
            this.error = { title: title };
        }
    };

    setCurrentTicket = (ticket: Ticket) => {
        this.currentTicket = ticket;
    };

    setNumberOfDisplayedItems = (numberOfDisplayedItems: number) => {
        this.numberOfDisplayedItems = numberOfDisplayedItems;
    };

    setSliceSizeOfDisplayedItems = (slizeSiceOfDisplayedItems: number) => {
        this.sliceSizeOfDisplayedItems = slizeSiceOfDisplayedItems;
    };

    setProcesses = (processes: IProcess[]) => {
        this.processes = processes;
    };

    setIsEditing = (isEditing: boolean) => {
        this.isEditing = isEditing;
    };

    setRoomTypes = (roomTypes: { [key: number]: string }) => {
        this.roomTypes = roomTypes;
    };

    setApplianceTypes = (applianceTypes: { [key: number]: string }) => {
        this.applianceTypes = applianceTypes;
    };

    setUserDefinedAfterSaveFunction = (userDefinedAfterSaveFunction: () => void) => {
        this.userDefinedAfterSaveFunction = userDefinedAfterSaveFunction;
    };

    setInitialLoadAllTicketsCompleted = (initialLoadAllTicketsCompleted: boolean) => {
        this.initialLoadAllTicketsCompleted = initialLoadAllTicketsCompleted;
    };

    setPrimarycontacttypeEnum = (primarycontacttypeEnum: GetTicketByNumber_primarycontacttypeEnum[]) => {
        this.primarycontacttypeEnum = primarycontacttypeEnum;
    };
}

/********************************
      TICKET DOMAIN CLASS

      To add a new property you have to add it to
        - ITicketProperties interface
        - TICKET_PROPERTY enum (make sure that the string representation of the property is written the same way as the property name)
        - Ticket class
            - Add property
            - Adjust constructor (initialize property + extend 'makeObservable')

 ********************************/

export interface ITicketProperties {
    id?: string;
    origin?: number;
    workflowinstance?: string;
    number?: number;
    date?: string;
    title?: string;
    state?: TICKET_STATE;
    workflow_type?: PROCESS;
    realestate?: IRealestate;
    realestateId?: string;
    realestateThumbnailFileId?: string;
    unit?: IUnitData;
    incidentContactPerson?: IIncidentContactPersonData;
    appliance?: IApplianceData;
    assignedUser?: IAssignedUser | null;
    incidentPersons?: IIncidentPerson[];
    componentid?: string;
    roomtype?: number;
    appliancetype?: number;
    damageNotificationDetails?: IDamageNotificationDetails;
    commonRequestNotificationDetails?: ICommonRequestNotificationDetails;
    namesignNotificationDetails?: INamesignNotificationDetails;
    keyOrderNotificationDetails?: IKeyOrderNotificationDetails;
    questionToDocumentNotificationDetails?: IQuestionToDocumentNotificationDetails;
    orders?: IOrder[];
    history?: IHistory[];
    comment?: string;
    notificationAddress?: INotificationAddress;
    notificationid?: string;
}

export interface ITicketRelationshipIds {
    incidentMlsId?: string;
    incidentContactPersonId?: string;
    incidentFiles?: GetIncidentReleationshipIds_incident_files[];
}

export interface IHistoryChangeEntry {
    description?: string;
    oldValue?: string;
    newValue?: any;
}

export interface IHistoryEmailAttachment {
    fileId: string;
    fileName: string;
}

export interface IHistoryEmailLog {
    emailLogId?: string;
    recipient?: string;
    event?: string;
    message?: string;
}

export interface IHistoryEmail {
    subject?: string;
    body?: string;
    sender?: string;
    recipients?: string;
    type?: number;
    attachments?: IHistoryEmailAttachment[];
    sendingErrors?: IHistoryEmailLog[];
}
export interface IHistory {
    type?: HISTORY_TYPE;
    date?: string;
    timestamp?: Date;
    changeText?: string;
    user?: string;
    changeEntry?: IHistoryChangeEntry[];
    email?: IHistoryEmail;
}

export interface INotificationAddress {
    street?: string;
    housenumber?: string;
    zip?: string;
    city?: string;
}

export enum INCIDENT_CONTACT_PERSON_PROPERTY {
    CUSTOMERID = "customerid",
    REALESTATEID = "realestateid",
    UNITID = "unitid",
    PERSONID = "personid",
    NAME1 = "name1",
    NAME2 = "name2",
    MOBILE = "mobile",
    PHONEPRIVATE = "phoneprivate",
    EMAIL = "email",
    GENDER = "gender",
    STREET = "street",
    HOUSENUMBER = "housenumber",
    ZIP = "zip",
    CITY = "city",
    ORIGIN = "origin"
}

export enum DAMAGE_NOTIFICATION_PROPERTY {
    DESCRIPTION = "description",
    HAS_MANUFACTURER = "hasManufacturer",
    MANUFACTURER = "manufacturer",
    HAS_SERIALNUMBER = "hasSerialNumber",
    SERIALNUMBER = "serialnumber",
    IMAGES = "images",
    SERIALNUMBER_IMAGE = "serialNumberImage",
    IS_OTHER_COMPONENT_SELECTED = "isOtherComponentSelected",
    OTHER_COMPONENT_NAME = "otherComponentName"
}

export enum COMMON_REQUEST_NOTIFICATION_PROPERTY {
    DESCRIPTION = "description"
}

export enum NAMESIGN_NOTIFICATION_PROPERTY {
    INSCRIPTION = "inscription",
    BELL_DIFFERENT = "belldifferent",
    MAILBOX_DIFFERENT = "mailboxdifferent",
    DOOR_DIFFERENT = "doordifferent",
    BELL_INSCRIPTION = "bellinscription",
    MAILBOX_INSCRIPTION = "mailboxinscription",
    DOOR_INSCRIPTION = "doorinscription",
    DESCRIPTION = "description"
}

export enum KEY_ORDER_NOTIFICATION_PROPERTY {
    INSCRIPTION = "inscription",
    IS_OTHER_KEY_COMPONENT_SELECTED = "isOtherKeyComponentSelected",
    OTHER_KEY_NAME = "otherKeyName",
    AMOUNT = "amount",
    LOST = "lost",
    DESCRIPTION = "description",
    IMAGES = "images"
}

export enum QUESTION_TO_DOCUMENT_NOTIFICATION_PROPERTY {
    IS_OTHER_DOCUMENT_COMPONENT_SELECTED = "isOtherDocumentComponentSelected",
    OTHER_DOCUMENT_NAME = "otherDocumentName",
    MESSAGE = "message",
    IMAGES = "images"
}

export enum TICKET_FIELD_TYPE {
    GENERAL,
    DAMAGE_NOTIFICATION,
    COMMON_REQUEST,
    NAMESIGN,
    KEY_ORDER,
    QUESTION_TO_DOCUMENT,
    INCIDENT_CONTACT_PERSON
}

export interface ITicketIncidentVariables extends ticket_incidents_insert_input {
    incidentmlslist?: Array<ticket_incidents_mls_insert_input>;
    incidentpersonlist?: Array<ticket_incidentpersons_insert_input>;
}

export class Ticket implements ITicketProperties {
    isValid: boolean = false;

    ticketRelationshipIds: ITicketRelationshipIds = {};

    rootStore: RootStore;

    fieldsWithChanges?: string[] = [];
    fieldsWithChangesDamageNotification?: string[] = [];
    fieldsWithChangesCommonRequestNotification?: string[] = [];
    fieldsWithChangesNamesignNotification?: string[] = [];
    fieldsWithChangesKeyOrderNotification?: string[] = [];
    fieldsWithChangesQuestionToDocumentNotification?: string[] = [];
    fieldsWithChangesIncidentContactPerson?: string[] = [];

    id?: string;
    origin?: number;
    workflowinstance?: string;
    number?: number;
    date?: string; //Format is YYYY-MM-DD
    title?: string;
    state!: TICKET_STATE;
    workflow_type!: PROCESS;
    realestate?: IRealestate;
    realestateThumbnailFileId?: string;
    unit?: IUnitData;
    incidentContactPerson?: IIncidentContactPersonData;
    appliance?: IApplianceData;
    assignedUser?: IAssignedUser | null;
    componentid?: string;
    roomtype?: number;
    appliancetype?: number;
    comment?: string;
    notificationid?: string;
    notificationAddress?: INotificationAddress;

    incidentPersons?: IIncidentPerson[];

    damageNotificationDetails!: IDamageNotificationDetails;
    commonRequestNotificationDetails!: ICommonRequestNotificationDetails;
    namesignNotificationDetails!: INamesignNotificationDetails;
    keyOrderNotificationDetails!: IKeyOrderNotificationDetails;
    questionToDocumentNotificationDetails?: IQuestionToDocumentNotificationDetails;

    orders?: IOrder[];
    history?: IHistory[];

    incidentUserSubscription?: Subscription;
    incidentPersonSubscription?: Subscription;

    errors = {
        title: "",
        realestate: "",
        damageDescription: "",
        damageSelectComponent: "",
        damageOtherComponentName: "",
        commonRequestDescription: "",
        namesignInscription: "",
        namesignBellInscription: "",
        namesignDoorInscription: "",
        namesignMailboxInscription: "",
        keyOrderInscription: "",
        keyOrderAmount: "",
        keyOrderOtherKeyInscription: "",
        keyOrderSelectKeyType: "",
        questionToDocumentMessage: "",
        questionToDocumentSelectDocumentType: "",
        questionToDocumentOtherDocumentName: ""
    };

    ticketFieldsOriginal: ITicketProperties = {};

    missingInformationForAssignOrderAction: string[] = [];

    getIncidentPersonByRole = (role: INCIDENT_PERSON_ROLE): IIncidentPerson | undefined => {
        return this.incidentPersons?.find((ip) => ip.role === role);
    };

    getDefaultDamageNotificationDetailsObject = (): IDamageNotificationDetails => {
        return {
            isOtherComponentSelected: false,
            hasManufacturer: false,
            hasSerialNumber: false,
            images: [],
            serialNumberImage: {},
            state: TICKET_IMAGE_STATE.NEW
        };
    };

    getDefaultNamesignDetailsObject = (): INamesignNotificationDetails => {
        return {
            inscription: "",
            belldifferent: false,
            mailboxdifferent: false,
            doordifferent: false,
            bellinscription: "",
            mailboxinscription: "",
            doorinscription: "",
            description: ""
        };
    };

    getDefaultKeyOrderDetailsObject = (): IKeyOrderNotificationDetails => {
        return {
            inscription: "",
            isOtherKeyComponentSelected: false,
            amount: 1,
            lost: false,
            description: "",
            images: []
        };
    };

    getDefaultQuestionToDocumentDetailsObject = (): IQuestionToDocumentNotificationDetails => {
        return {
            isOtherDocumentComponentSelected: false,
            message: "",
            images: []
        };
    };

    getDefaultIncidentContactPersonObject = (): IIncidentContactPersonData => {
        return {
            personid: "",
            name1: "",
            name2: null,
            mobile: null,
            phoneprivate: null,
            email: null
        };
    };

    setTicketFieldsOnObject = (ticketFieldObject: ITicketProperties, ticketFields?: ITicketProperties) => {
        if (ticketFields) {
            ticketFieldObject.id = ticketFields.id ?? "";
            ticketFieldObject.origin = ticketFields.origin ?? TICKET_ORIGIN.MANUAL;
            ticketFieldObject.workflowinstance = ticketFields.workflowinstance ?? "";
            ticketFieldObject.number = ticketFields.number ?? 0;
            ticketFieldObject.date = ticketFields.date ?? "";
            ticketFieldObject.title = ticketFields.title ?? "";
            ticketFieldObject.state = ticketFields.state ?? TICKET_STATE.NEW;
            ticketFieldObject.workflow_type = ticketFields.workflow_type ?? PROCESS.DAMAGE_NOTIFICATION;
            ticketFieldObject.appliance = ticketFields.appliance;
            ticketFieldObject.incidentContactPerson = ticketFields.incidentContactPerson
                ? { ...ticketFields.incidentContactPerson }
                : this.getDefaultIncidentContactPersonObject();
            ticketFieldObject.realestate = ticketFields.realestate ?? undefined;
            ticketFieldObject.realestateThumbnailFileId = ticketFields.realestateThumbnailFileId ?? "";
            ticketFieldObject.unit = ticketFields.unit ?? undefined;
            ticketFieldObject.assignedUser = ticketFields.assignedUser ?? undefined;
            ticketFieldObject.incidentPersons = ticketFields.incidentPersons ?? [];
            ticketFieldObject.componentid = ticketFields.componentid ?? undefined;
            ticketFieldObject.roomtype = ticketFields.roomtype ?? undefined;
            ticketFieldObject.appliancetype = ticketFields.appliancetype ?? undefined;
            ticketFieldObject.damageNotificationDetails = ticketFields.damageNotificationDetails
                ? { ...ticketFields.damageNotificationDetails }
                : this.getDefaultDamageNotificationDetailsObject();
            ticketFieldObject.commonRequestNotificationDetails = ticketFields.commonRequestNotificationDetails
                ? { ...ticketFields.commonRequestNotificationDetails }
                : {};
            ticketFieldObject.namesignNotificationDetails = ticketFields.namesignNotificationDetails
                ? { ...ticketFields.namesignNotificationDetails }
                : this.getDefaultNamesignDetailsObject();
            ticketFieldObject.keyOrderNotificationDetails = ticketFields.keyOrderNotificationDetails
                ? { ...ticketFields.keyOrderNotificationDetails }
                : this.getDefaultKeyOrderDetailsObject();
            ticketFieldObject.questionToDocumentNotificationDetails = ticketFields.questionToDocumentNotificationDetails
                ? { ...ticketFields.questionToDocumentNotificationDetails }
                : this.getDefaultQuestionToDocumentDetailsObject();
            ticketFieldObject.orders = ticketFields.orders ?? [];
            ticketFieldObject.history = ticketFields.history ?? [];
            ticketFieldObject.comment = ticketFields.comment ?? "";
            ticketFieldObject.notificationAddress = ticketFields.notificationAddress ?? undefined;
            ticketFieldObject.notificationid = ticketFields.notificationid ?? undefined;
        } else {
            ticketFieldObject.id = undefined;
            ticketFieldObject.origin = TICKET_ORIGIN.MANUAL;
            ticketFieldObject.workflowinstance = undefined;
            ticketFieldObject.number = undefined;
            ticketFieldObject.date = undefined;
            ticketFieldObject.state = TICKET_STATE.NEW;
            ticketFieldObject.workflow_type = PROCESS.DAMAGE_NOTIFICATION;
            ticketFieldObject.title = "";
            ticketFieldObject.realestate = undefined;
            ticketFieldObject.realestateThumbnailFileId = undefined;
            ticketFieldObject.unit = undefined;
            ticketFieldObject.incidentContactPerson = this.getDefaultIncidentContactPersonObject();
            ticketFieldObject.appliance = undefined;
            ticketFieldObject.assignedUser = undefined;
            ticketFieldObject.incidentPersons = [];
            ticketFieldObject.componentid = undefined;
            ticketFieldObject.roomtype = undefined;
            ticketFieldObject.appliancetype = undefined;
            ticketFieldObject.damageNotificationDetails = this.getDefaultDamageNotificationDetailsObject();
            ticketFieldObject.commonRequestNotificationDetails = {};
            ticketFieldObject.namesignNotificationDetails = this.getDefaultNamesignDetailsObject();
            ticketFieldObject.keyOrderNotificationDetails = this.getDefaultKeyOrderDetailsObject();
            ticketFieldObject.questionToDocumentNotificationDetails = this.getDefaultQuestionToDocumentDetailsObject();
            ticketFieldObject.orders = [];
            ticketFieldObject.history = [];
            ticketFieldObject.comment = undefined;
            ticketFieldObject.notificationAddress = undefined;
            ticketFieldObject.notificationid = undefined;
        }
    };

    constructor(rootStore: RootStore, ticketFields?: ITicketProperties) {
        this.rootStore = rootStore;

        this.setTicketFieldsOnObject(this, ticketFields);
        this.setTicketFieldsOnObject(this.ticketFieldsOriginal, ticketFields);

        makeObservable(this, {
            id: observable,
            number: observable,
            date: observable,
            title: observable,
            state: observable,
            workflow_type: observable,
            realestate: observable,
            realestateThumbnailFileId: observable,
            unit: observable,
            incidentContactPerson: observable,
            assignedUser: observable,
            errors: observable,
            isValid: observable,
            appliance: observable,
            roomtype: observable,
            appliancetype: observable,
            damageNotificationDetails: observable,
            commonRequestNotificationDetails: observable,
            namesignNotificationDetails: observable,
            keyOrderNotificationDetails: observable,
            questionToDocumentNotificationDetails: observable,
            missingInformationForAssignOrderAction: observable,
            orders: observable,
            history: observable,
            comment: observable,
            fieldsWithChanges: observable,
            fieldsWithChangesDamageNotification: observable,
            fieldsWithChangesCommonRequestNotification: observable,
            fieldsWithChangesNamesignNotification: observable,
            fieldsWithChangesKeyOrderNotification: observable,
            fieldsWithChangesQuestionToDocumentNotification: observable,
            fieldsWithChangesIncidentContactPerson: observable,
            ticketFieldsOriginal: observable,
            incidentPersons: observable,
            incidentUserSubscription: observable,
            incidentPersonSubscription: observable,
            notificationAddress: observable,

            updateProperty: action,
            updateDamageNotificationProperty: action,
            updateCommonRequestNotificationProperty: action,
            updateNamesignNotificationProperty: action,
            updateKeyOrderNotificationProperty: action,
            updateQuestionToDocumentNotificationProperty: action,
            updateIncidentContactPersonProperty: action,
            resetErrors: action,
            validate: action,
            validateBaseNotificationDetails: action,
            validateRealestateNotificationDetails: action,
            validateCommonRequestNotificationDetails: action,
            validateDamageNotificationDetails: action,
            validateDamageNotificationComponentSelected: action,
            validateNamesignNotificationDetails: action,
            validateKeyOrderNotificationDetails: action,
            validateKeyOrderComponentSelected: action,
            validateQuestionToDocumentNotificationDetails: action,
            validateQuestionToDocumentComponentSelected: action,
            validateTicketReadyForAssignOrderAction: action,
            save: action,
            close: action,
            assignToUser: action,
            saveImages: action,
            createIncidentBackend: action,
            updateIncidentHasura: action,
            updatePersonDetails: action,
            reset: action,
            setTicketFieldsOnObject: action,
            reloadOrders: action,
            reloadHistory: action,
            updateFieldsWithChanges: action,
            resetAllFieldsWithChanges: action,
            reloadIncidentPersons: action,
            setIncidentUserSubscription: action,
            onIncidentUserSubscriptionDataReceived: action.bound,
            setIncidentPersonSubscription: action,
            onIncidentPersonSubscriptionDataReceived: action.bound,
            archive: action,

            isRealestateSelected: computed,
            isUnitSelected: computed,
            isNewTicket: computed,
            isIncidentContactPersonSelected: computed,
            hasChanges: computed,
            canUpdateIncidentContactPerson: computed
        });
    }

    setAssignedUser = (assignedUser: IAssignedUser | null) => {
        this.assignedUser = assignedUser;
    };

    setIncidentUserSubscription = (incidentUserSubscription: Subscription | undefined) => {
        this.incidentUserSubscription = incidentUserSubscription;
    };

    onIncidentUserSubscriptionDataReceived = (incident: SubscribeToAssignedUserUpdates_incident) => {
        const user = incident.user;

        if (user) {
            this.updateProperty(TICKET_PROPERTY.ASSIGNED_USER, user);
            this.updateProperty(TICKET_PROPERTY.STATE, TICKET_STATE.ASSIGNED);
            this.resetAllFieldsWithChanges();

            this.stopIncidentUserSubscription();
        }
    };

    startIncidentUserSubscription(incidentId: string) {
        if (this.incidentUserSubscription) {
            this.stopIncidentUserSubscription();
        }

        if (incidentId && incidentId !== "") {
            const incidentUserSubscription = apolloClientInstance
                .subscribe<SubscribeToAssignedUserUpdates, SubscribeToAssignedUserUpdatesVariables>({
                    query: SUBSCRIBE_TO_ASSIGNED_USER_UPDATES,
                    variables: { incidentid: incidentId }
                })
                .subscribe({
                    next: ({ data }) => {
                        if (data?.incident && data.incident.length > 0) {
                            this.onIncidentUserSubscriptionDataReceived(data.incident[0]);
                        }
                    },
                    error: (err) => {
                        console.error("SUBSCRIBE_TO_ASSIGNED_USER_UPDATES subscription error:", err);
                        this.stopIncidentUserSubscription();
                    }
                });

            this.setIncidentUserSubscription(incidentUserSubscription);
        }
    }

    stopIncidentUserSubscription() {
        if (this.incidentUserSubscription) {
            this.incidentUserSubscription.unsubscribe();
            this.setIncidentUserSubscription(undefined);
        }
    }

    setIncidentPersonSubscription = (incidentPersonSubscription: Subscription | undefined) => {
        this.incidentPersonSubscription = incidentPersonSubscription;
    };

    onIncidentPersonSubscriptionDataReceived = (
        incidentPersonsSubscriptionUpdate: SubscribeToIncidentPersonUpdates_ticket_incidentpersons[]
    ) => {
        const incidentPersons: IIncidentPerson[] = TicketStore.getIncidentPersonsFromQueryData(
            incidentPersonsSubscriptionUpdate
        );

        this.updateProperty(TICKET_PROPERTY.INCIDENT_PERSONS, incidentPersons);
        this.resetAllFieldsWithChanges();

        this.stopIncidentPersonSubscription();
    };

    startIncidentPersonSubscription(incidentId: string) {
        if (this.incidentPersonSubscription) {
            this.stopIncidentPersonSubscription();
        }

        if (incidentId && incidentId !== "") {
            const incidentUserSubscription = apolloClientInstance
                .subscribe<SubscribeToIncidentPersonUpdates, SubscribeToIncidentPersonUpdatesVariables>({
                    query: SUBSCRIBE_TO_INCIDENT_PERSON_UPDATES,
                    variables: { incidentid: incidentId }
                })
                .subscribe({
                    next: ({ data }) => {
                        if (data?.ticket_incidentpersons && data.ticket_incidentpersons.length > 0) {
                            this.onIncidentPersonSubscriptionDataReceived(data.ticket_incidentpersons);
                        }
                    },
                    error: (err) => {
                        console.error("SUBSCRIBE_TO_INCIDENT_PERSON_UPDATES subscription error:", err);
                        this.stopIncidentPersonSubscription();
                    }
                });

            this.setIncidentPersonSubscription(incidentUserSubscription);
        }
    }

    stopIncidentPersonSubscription() {
        if (this.incidentPersonSubscription) {
            this.incidentPersonSubscription.unsubscribe();
            this.setIncidentPersonSubscription(undefined);
        }
    }

    updateIncidentContactPersonProperty = (field: keyof IIncidentContactPersonData | string, value: any) => {
        if (this.incidentContactPerson) {
            (this.incidentContactPerson as any)[field] = value;
        }

        this.updateFieldsWithChanges(TICKET_FIELD_TYPE.INCIDENT_CONTACT_PERSON);
    };

    updateDamageNotificationProperty = (field: keyof IDamageNotificationDetails, value: any, fileId?: string) => {
        if (this.damageNotificationDetails) {
            if (fileId) {
                this.damageNotificationDetails.images = this.damageNotificationDetails.images.filter(
                    (image) => image.fileId !== fileId
                );
            } else {
                if (field === DAMAGE_NOTIFICATION_PROPERTY.IMAGES) {
                    this.damageNotificationDetails.images.push(value);
                } else {
                    (this.damageNotificationDetails as any)[field] = value;
                }
            }
        }

        this.updateFieldsWithChanges(TICKET_FIELD_TYPE.DAMAGE_NOTIFICATION);
    };

    updateCommonRequestNotificationProperty = (field: keyof ICommonRequestNotificationDetails | string, value: any) => {
        if (this.commonRequestNotificationDetails) {
            (this.commonRequestNotificationDetails as any)[field] = value;
        }

        this.updateFieldsWithChanges(TICKET_FIELD_TYPE.COMMON_REQUEST);
    };

    updateNamesignNotificationProperty = (field: keyof INamesignNotificationDetails | string, value: any) => {
        if (this.namesignNotificationDetails) {
            (this.namesignNotificationDetails as any)[field] = value;
        }

        this.updateFieldsWithChanges(TICKET_FIELD_TYPE.NAMESIGN);
    };

    updateKeyOrderNotificationProperty = (
        field: keyof IKeyOrderNotificationDetails | string,
        value: any,
        fileId?: string
    ) => {
        if (this.keyOrderNotificationDetails) {
            if (fileId) {
                this.keyOrderNotificationDetails.images = this.keyOrderNotificationDetails.images.filter(
                    (image) => image.fileId !== fileId
                );
            } else {
                if (field === KEY_ORDER_NOTIFICATION_PROPERTY.IMAGES) {
                    this.keyOrderNotificationDetails.images.push(value);
                } else {
                    (this.keyOrderNotificationDetails as any)[field] = value;
                }
            }
        }

        this.updateFieldsWithChanges(TICKET_FIELD_TYPE.KEY_ORDER);
    };

    updateQuestionToDocumentNotificationProperty = (
        field: keyof IQuestionToDocumentNotificationDetails | string,
        value: any,
        fileId?: string
    ) => {
        if (this.questionToDocumentNotificationDetails) {
            if (fileId) {
                this.questionToDocumentNotificationDetails.images =
                    this.questionToDocumentNotificationDetails.images.filter((image) => image.fileId !== fileId);
            } else {
                if (field === QUESTION_TO_DOCUMENT_NOTIFICATION_PROPERTY.IMAGES) {
                    this.questionToDocumentNotificationDetails.images.push(value);
                } else {
                    (this.questionToDocumentNotificationDetails as any)[field] = value;
                }
            }
        }

        this.updateFieldsWithChanges(TICKET_FIELD_TYPE.QUESTION_TO_DOCUMENT);
    };

    updateProperty = (field: keyof Ticket | string, value: any) => {
        if (field in this) {
            (this as any)[field] = value;
        }

        this.updateFieldsWithChanges(TICKET_FIELD_TYPE.GENERAL);
    };

    get hasChanges(): boolean {
        return (this.fieldsWithChanges && this.fieldsWithChanges?.length > 0) ||
            (this.fieldsWithChangesCommonRequestNotification &&
                this.fieldsWithChangesCommonRequestNotification?.length > 0) ||
            (this.fieldsWithChangesDamageNotification && this.fieldsWithChangesDamageNotification?.length > 0) ||
            (this.fieldsWithChangesKeyOrderNotification && this.fieldsWithChangesKeyOrderNotification?.length > 0) ||
            (this.fieldsWithChangesNamesignNotification && this.fieldsWithChangesNamesignNotification?.length > 0) ||
            (this.fieldsWithChangesQuestionToDocumentNotification &&
                this.fieldsWithChangesQuestionToDocumentNotification?.length > 0) ||
            (this.fieldsWithChangesIncidentContactPerson && this.fieldsWithChangesIncidentContactPerson?.length > 0)
            ? true
            : false;
    }

    get isRealestateSelected(): boolean {
        return this.realestate !== undefined;
    }

    get isUnitSelected(): boolean {
        return this.unit !== undefined;
    }

    get isIncidentContactPersonSelected(): boolean {
        return this.incidentContactPerson !== undefined;
    }

    get isApplianceSelected(): boolean {
        return this.appliance !== undefined;
    }

    get isNewTicket(): boolean {
        return this.id === undefined;
    }

    get canUpdateIncidentContactPerson(): boolean {
        return (
            this.incidentContactPerson?.origin === PERSON_ORIGIN.ERP &&
            this.rootStore.navStore.hasOptionUpdatePersonsViaASB
        );
    }

    validateBaseNotificationDetails = (isValidPreviousCheck?: boolean): boolean => {
        let isValid = isValidPreviousCheck ?? true;

        if (!this.title) {
            this.errors.title = i18next.t("screens.tickets.form.errors.title.required");
            this.missingInformationForAssignOrderAction.push(i18next.t("screens.tickets.form.title"));

            isValid = false;
        } else {
            this.errors.title = "";
        }

        return isValid;
    };

    validateRealestateNotificationDetails = (isValidPreviousCheck?: boolean): boolean => {
        let isValid = isValidPreviousCheck ?? true;

        if (!this.realestate) {
            this.errors.realestate = i18next.t("screens.tickets.form.errors.realestate.required");
            this.missingInformationForAssignOrderAction.push(
                i18next.t("screens.tickets.form.missing_information.realestate")
            );
            isValid = false;
        } else {
            this.errors.realestate = "";
        }

        return isValid;
    };

    validateDamageNotificationComponentSelected = (isValidPreviousCheck?: boolean): boolean => {
        let isValid = isValidPreviousCheck ?? true;

        if (this.workflow_type === PROCESS.DAMAGE_NOTIFICATION) {
            if (
                !this.rootStore.damageNotificationStore.isApplianceSelectionFromMasterdataFinished &&
                !this.rootStore.damageNotificationStore.isComponentSelectionFinished
            ) {
                this.errors.damageSelectComponent = i18next.t("screens.tickets.form.errors.damage.select_component");
                isValid = false;
            } else {
                this.errors.damageSelectComponent = "";
            }
        }

        return isValid;
    };

    validateDamageNotificationDetails = (isValidPreviousCheck?: boolean): boolean => {
        let isValid = isValidPreviousCheck ?? true;

        if (this.workflow_type === PROCESS.DAMAGE_NOTIFICATION) {
            if (!this.damageNotificationDetails.description) {
                this.errors.damageDescription = i18next.t("screens.tickets.form.errors.description.required");
                this.missingInformationForAssignOrderAction.push(
                    i18next.t("screens.tickets.form.missing_information.damage.description")
                );

                isValid = false;
            } else {
                this.errors.damageDescription = "";
            }

            if (
                this.damageNotificationDetails.isOtherComponentSelected &&
                !this.damageNotificationDetails.otherComponentName
            ) {
                this.errors.damageOtherComponentName = i18next.t(
                    "screens.tickets.form.errors.damage.other_component_name"
                );
                this.missingInformationForAssignOrderAction.push(
                    i18next.t("screens.tickets.form.missing_information.damage.other_component_name")
                );

                isValid = false;
            } else {
                this.errors.damageOtherComponentName = "";
            }

            isValid = this.validateDamageNotificationComponentSelected(isValid);
        }

        return isValid;
    };

    validateCommonRequestNotificationDetails = (isValidPreviousCheck?: boolean): boolean => {
        let isValid = isValidPreviousCheck ?? true;

        if (this.workflow_type === PROCESS.COMMON_REQUEST) {
            if (!this.commonRequestNotificationDetails.description) {
                this.errors.commonRequestDescription = i18next.t("screens.tickets.form.errors.description.required");
                this.missingInformationForAssignOrderAction.push(
                    i18next.t("screens.tickets.form.missing_information.common_request.description")
                );

                isValid = false;
            } else {
                this.errors.commonRequestDescription = "";
            }
        }

        return isValid;
    };

    validateNamesignNotificationDetails = (isValidPreviousCheck?: boolean): boolean => {
        let isValid = isValidPreviousCheck ?? true;

        if (this.workflow_type === PROCESS.NAMESIGN) {
            if (!this.namesignNotificationDetails.inscription) {
                this.errors.namesignInscription = i18next.t("screens.tickets.form.errors.inscription.required");
                this.missingInformationForAssignOrderAction.push(
                    i18next.t("screens.tickets.form.missing_information.namesign.inscription")
                );

                isValid = false;
            } else {
                this.errors.namesignInscription = "";
            }

            if (this.namesignNotificationDetails.belldifferent && !this.namesignNotificationDetails.bellinscription) {
                this.errors.namesignBellInscription = i18next.t("screens.tickets.form.errors.inscription.required");
                this.missingInformationForAssignOrderAction.push(
                    i18next.t("screens.tickets.form.missing_information.namesign.inscription_bell")
                );

                isValid = false;
            } else {
                this.errors.namesignBellInscription = "";
            }

            if (this.namesignNotificationDetails.doordifferent && !this.namesignNotificationDetails.doorinscription) {
                this.errors.namesignDoorInscription = i18next.t("screens.tickets.form.errors.inscription.required");
                this.missingInformationForAssignOrderAction.push(
                    i18next.t("screens.tickets.form.missing_information.namesign.inscription_door")
                );

                isValid = false;
            } else {
                this.errors.namesignDoorInscription = "";
            }

            if (
                this.namesignNotificationDetails.mailboxdifferent &&
                !this.namesignNotificationDetails.mailboxinscription
            ) {
                this.errors.namesignMailboxInscription = i18next.t("screens.tickets.form.errors.inscription.required");
                this.missingInformationForAssignOrderAction.push(
                    i18next.t("screens.tickets.form.missing_information.namesign.inscription_mailbox")
                );

                isValid = false;
            } else {
                this.errors.namesignMailboxInscription = "";
            }
        }

        return isValid;
    };

    validateKeyOrderComponentSelected = (isValidPreviousCheck?: boolean): boolean => {
        let isValid = isValidPreviousCheck ?? true;

        if (this.workflow_type === PROCESS.KEY_ORDER) {
            if (!this.rootStore.keyOrderNotificationStore.isComponentSelectionFinished) {
                this.errors.keyOrderSelectKeyType = i18next.t("screens.tickets.form.errors.key_order.select_key_type");

                isValid = false;
            } else {
                this.errors.keyOrderSelectKeyType = "";
            }
        }

        return isValid;
    };

    validateKeyOrderNotificationDetails = (isValidPreviousCheck?: boolean): boolean => {
        let isValid = isValidPreviousCheck ?? true;

        if (this.workflow_type === PROCESS.KEY_ORDER) {
            if (!this.keyOrderNotificationDetails.inscription) {
                this.errors.keyOrderInscription = i18next.t("screens.tickets.form.errors.inscription.required");
                this.missingInformationForAssignOrderAction.push(
                    i18next.t("screens.tickets.form.missing_information.key_order.inscription")
                );

                isValid = false;
            } else {
                this.errors.keyOrderInscription = "";
            }

            if (
                typeof this.keyOrderNotificationDetails.amount !== "number" ||
                this.keyOrderNotificationDetails.amount <= 0 ||
                this.keyOrderNotificationDetails.amount % 1 !== 0
            ) {
                this.errors.keyOrderAmount = i18next.t("screens.tickets.form.errors.key_order.invalid_format");
                isValid = false;
            } else {
                this.errors.keyOrderAmount = "";
            }

            if (
                this.keyOrderNotificationDetails.isOtherKeyComponentSelected &&
                !this.keyOrderNotificationDetails.otherKeyName
            ) {
                this.errors.keyOrderOtherKeyInscription = i18next.t(
                    "screens.tickets.form.errors.key_order.other_key_name"
                );
                this.missingInformationForAssignOrderAction.push(
                    i18next.t("screens.tickets.form.missing_information.key_order.description_other_key")
                );

                isValid = false;
            } else {
                this.errors.keyOrderOtherKeyInscription = "";
            }

            isValid = this.validateKeyOrderComponentSelected(isValid);
        }

        return isValid;
    };

    validateQuestionToDocumentComponentSelected = (isValidPreviousCheck?: boolean): boolean => {
        let isValid = isValidPreviousCheck ?? true;

        if (this.workflow_type === PROCESS.DOCUMENT_QUESTION) {
            if (!this.rootStore.questionToDocumentNotificationStore.isComponentSelectionFinished) {
                this.errors.questionToDocumentSelectDocumentType = i18next.t(
                    "screens.tickets.form.errors.question_to_document.select_document_type"
                );
                isValid = false;
            } else {
                this.errors.questionToDocumentSelectDocumentType = "";
            }
        }

        return isValid;
    };

    validateQuestionToDocumentNotificationDetails = (isValidPreviousCheck?: boolean): boolean => {
        let isValid = isValidPreviousCheck ?? true;

        if (this.workflow_type === PROCESS.DOCUMENT_QUESTION) {
            if (!this.questionToDocumentNotificationDetails?.message) {
                this.errors.questionToDocumentMessage = i18next.t(
                    "screens.tickets.form.errors.question_to_document.message_requirend"
                );
                this.missingInformationForAssignOrderAction.push(
                    i18next.t("screens.tickets.form.missing_information.question_to_document.message")
                );

                isValid = false;
            } else {
                this.errors.questionToDocumentMessage = "";
            }

            if (
                this.questionToDocumentNotificationDetails?.isOtherDocumentComponentSelected &&
                !this.questionToDocumentNotificationDetails.otherDocumentName
            ) {
                this.errors.questionToDocumentOtherDocumentName = i18next.t(
                    "screens.tickets.form.errors.question_to_document.other_document_name"
                );
                this.missingInformationForAssignOrderAction.push(
                    i18next.t("screens.tickets.form.missing_information.question_to_document.other_document_name")
                );

                isValid = false;
            } else {
                this.errors.questionToDocumentOtherDocumentName = "";
            }

            isValid = this.validateQuestionToDocumentComponentSelected(isValid);
        }

        return isValid;
    };

    validateTicketReadyForAssignOrderAction = (isValidPreviousCheck?: boolean): boolean => {
        let isValidForAssignOrderAction = isValidPreviousCheck ?? true;
        this.missingInformationForAssignOrderAction = [];

        if (!this.assignedUser) {
            this.missingInformationForAssignOrderAction.push(
                i18next.t("screens.tickets.form.missing_information.assigned_user")
            );
            isValidForAssignOrderAction = false;
        }

        if (!this.realestate) {
            this.missingInformationForAssignOrderAction.push(
                i18next.t("screens.tickets.form.missing_information.realestate")
            );
            isValidForAssignOrderAction = false;
        }

        isValidForAssignOrderAction = this.validate(isValidForAssignOrderAction);

        if (this.missingInformationForAssignOrderAction.length > 0) {
            let missingInformationMessage = this.missingInformationForAssignOrderAction
                .map((missingInformation) => `- ${missingInformation}`)
                .join("\n");

            this.rootStore.uiStore.printTicketingErrorMessage(
                `${i18next.t(
                    "screens.tickets.form.missing_information.missing_mandatory_information"
                )}:\n${missingInformationMessage}`
            );
        }

        return isValidForAssignOrderAction;
    };

    validate = (isValidPreviousCheck?: boolean): boolean => {
        let isValid = isValidPreviousCheck ?? true;

        isValid = this.validateBaseNotificationDetails(isValid);
        isValid = this.validateRealestateNotificationDetails(isValid);
        isValid = this.validateDamageNotificationDetails(isValid);
        isValid = this.validateCommonRequestNotificationDetails(isValid);
        isValid = this.validateNamesignNotificationDetails(isValid);
        isValid = this.validateKeyOrderNotificationDetails(isValid);
        isValid = this.validateQuestionToDocumentNotificationDetails(isValid);

        this.isValid = isValid;

        return this.isValid;
    };

    resetErrors = () => {
        this.errors.title = "";
    };

    reset = () => {
        this.id = undefined;
        this.workflowinstance = undefined;
        this.number = undefined;
        this.date = undefined;
        this.title = "";
        this.state = TICKET_STATE.NEW;
        this.workflow_type = PROCESS.DAMAGE_NOTIFICATION;
        this.realestate = undefined;
        this.realestateThumbnailFileId = undefined;
        this.unit = undefined;
        this.incidentContactPerson = undefined;
        this.appliance = undefined;
        this.componentid = undefined;
        this.roomtype = undefined;
        this.appliancetype = undefined;
        this.damageNotificationDetails = this.getDefaultDamageNotificationDetailsObject();
        this.commonRequestNotificationDetails = {};
        this.namesignNotificationDetails = this.getDefaultNamesignDetailsObject();
        this.keyOrderNotificationDetails = this.getDefaultKeyOrderDetailsObject();
        this.questionToDocumentNotificationDetails = this.getDefaultQuestionToDocumentDetailsObject();
        this.comment = undefined;
        this.orders = [];
        this.history = [];
    };

    loadTicketReleationshipIds = async (): Promise<ITicketRelationshipIds> => {
        const { data }: { data: GetIncidentReleationshipIds } = await apolloClientInstance.query<
            GetIncidentReleationshipIds,
            GetIncidentReleationshipIdsVariables
        >({
            query: GET_INCIDENT_RELATIONSHIP_IDS,
            variables: {
                incidentid: this.id
            }
        });

        if (data && data.incident_mls.length > 0) {
            this.ticketRelationshipIds.incidentMlsId = data.incident_mls[0].id;
        }

        if (data && data.incident_contactperson.length > 0) {
            this.ticketRelationshipIds.incidentContactPersonId = data.incident_contactperson[0].id;
        }

        if (data && data.incident_files.length > 0) {
            this.ticketRelationshipIds.incidentFiles = data.incident_files;
        }

        return this.ticketRelationshipIds;
    };

    getDateStringForHasuraQuery = (date: any): string => {
        let locale: Locale = de;
        if (i18next.language.includes("de")) locale = de;
        if (i18next.language.includes("en")) locale = enGB;
        if (i18next.language.includes("fr")) locale = fr;
        if (i18next.language.includes("it")) locale = it;

        return format(date, "yyyy-MM-dd", { locale });
    };

    static formatDate = (dateString: string, short = false) => {
        if (short) {
            return format(new Date(dateString), "d.M.yy");
        }
        const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" };
        const [year, month, day] = dateString.split("-");
        const formattedDate = new Date(Number(year), Number(month) - 1, Number(day)).toLocaleDateString(
            i18next.language,
            options
        );

        const monthName = formattedDate.split(" ")[1];
        const dayNumber = parseInt(day);

        return `${dayNumber}. ${monthName} ${year}`;
    };

    static formatDateForImage = (date: Date) => {
        return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS");
    };

    static formatDateHistoryEntry = (dateString: string, adjustTimezoneUtcToZH: boolean) => {
        const dateFormat = "d.M.yy HH:mm";

        if (adjustTimezoneUtcToZH) {
            const timeZone = "Europe/Zurich";
            const zonedDate = utcToZonedTime(dateString + "Z", timeZone);
            return format(zonedDate, dateFormat, { timeZone });
        } else {
            return format(new Date(dateString), dateFormat);
        }
    };

    static getTimeStampForHistoryEntry = (dateString: string, adjustTimezoneUtcToZH: boolean) => {
        if (adjustTimezoneUtcToZH) {
            const timeZone = "Europe/Zurich";
            const zonedDate = utcToZonedTime(dateString + "Z", timeZone);
            return zonedDate;
        } else {
            return new Date(dateString);
        }
    };

    getTicketIncidentsInsertInputQueryVariables = async (
        savingNewTicket: boolean
    ): Promise<ITicketIncidentVariables> => {
        let baseIncidentVariables: ITicketIncidentVariables = {
            origin: savingNewTicket ? TICKET_ORIGIN.MANUAL : this.origin,
            type: this.workflow_type,
            date: this.date,
            realestateid: this.realestate?.id,
            houseid: this.unit?.houseId,
            unitid: this.unit?.unitId,
            tenancyid: this.unit?.tenancyData?.tenancyid,
            tenantid: this.unit?.tenancyData?.tenantid,
            componentid: this.componentid,
            roomtype: this.componentid ? this.roomtype : undefined,
            appliancetype: this.componentid ? this.appliancetype : undefined,
            applianceid: this.appliance ? this.appliance.id : undefined,
            manufacturer: this.damageNotificationDetails.manufacturer ?? undefined,
            serialnumber: this.damageNotificationDetails.serialnumber ?? undefined,
            notificationid: this.notificationid ?? undefined,
            number: this.number,
            nameplate:
                this.workflow_type === PROCESS.NAMESIGN
                    ? {
                          inscription: this.namesignNotificationDetails.inscription ?? undefined,
                          belldifferent: this.namesignNotificationDetails.belldifferent ?? undefined,
                          bellinscription: this.namesignNotificationDetails.bellinscription ?? undefined,
                          doordifferent: this.namesignNotificationDetails.doordifferent ?? undefined,
                          doorinscription: this.namesignNotificationDetails.doorinscription ?? undefined,
                          mailboxdifferent: this.namesignNotificationDetails.mailboxdifferent ?? undefined,
                          mailboxinscription: this.namesignNotificationDetails.mailboxinscription ?? undefined
                      }
                    : undefined,
            key:
                this.workflow_type === PROCESS.KEY_ORDER
                    ? {
                          inscription: this.keyOrderNotificationDetails.inscription ?? undefined,
                          amount: this.keyOrderNotificationDetails.amount ?? undefined,
                          lost: this.keyOrderNotificationDetails.lost ?? false
                      }
                    : undefined
        };

        let incidentVariables: ITicketIncidentVariables = {};

        let incidentMlsInput: ticket_incidents_mls_arr_rel_insert_input | null | undefined = {
            on_conflict: {
                constraint: ticket_incidents_mls_constraint.PK_incidents_mls,
                update_columns: [
                    ticket_incidents_mls_update_column.title,
                    ticket_incidents_mls_update_column.description,
                    ticket_incidents_mls_update_column.altcomponent
                ]
            },
            data: []
        };

        let incidentContactPersonInput: ticket_incidentpersons_arr_rel_insert_input | null | undefined = {
            on_conflict: {
                constraint: ticket_incidentpersons_constraint.PK_incidentpersons,
                update_columns: [ticket_incidentpersons_update_column.personid]
            },
            data: []
        };

        let description = undefined;

        if (this.workflow_type === PROCESS.DAMAGE_NOTIFICATION && this.damageNotificationDetails.description) {
            description = this.damageNotificationDetails.description;
        }

        if (this.workflow_type === PROCESS.COMMON_REQUEST) {
            description = this.commonRequestNotificationDetails.description;
        }

        if (this.workflow_type === PROCESS.NAMESIGN) {
            if (this.namesignNotificationDetails?.description) {
                description = this.namesignNotificationDetails.description;
            }
        }

        if (this.workflow_type === PROCESS.KEY_ORDER) {
            if (this.keyOrderNotificationDetails?.description) {
                description = this.keyOrderNotificationDetails.description;
            }
        }

        if (this.workflow_type === PROCESS.DOCUMENT_QUESTION && this.questionToDocumentNotificationDetails?.message) {
            description = this.questionToDocumentNotificationDetails?.message;
        }

        let altComponentDescription = undefined;

        if (this.workflow_type === PROCESS.DAMAGE_NOTIFICATION && this.damageNotificationDetails.otherComponentName) {
            altComponentDescription = this.damageNotificationDetails.otherComponentName;
        }

        if (this.workflow_type === PROCESS.KEY_ORDER && this.keyOrderNotificationDetails.otherKeyName) {
            altComponentDescription = this.keyOrderNotificationDetails.otherKeyName;
        }

        if (
            this.workflow_type === PROCESS.DOCUMENT_QUESTION &&
            this.questionToDocumentNotificationDetails?.otherDocumentName
        ) {
            altComponentDescription = this.questionToDocumentNotificationDetails?.otherDocumentName;
        }

        if (this.isNewTicket) {
            // Inserting a new ticket
            const currentDate = this.getDateStringForHasuraQuery(new Date());

            incidentVariables = {
                ...baseIncidentVariables,
                date: currentDate
            };

            incidentVariables.incidentmlslist = [
                {
                    title: this.title,
                    language: i18next.language,
                    description: description,
                    altcomponent: altComponentDescription
                }
            ];

            if (this.incidentContactPerson && this.incidentContactPerson.personid) {
                incidentVariables.incidentpersonlist = [
                    {
                        personid: this.incidentContactPerson?.personid,
                        role: INCIDENT_PERSON_ROLE.CONTACT
                    }
                ];
            }
        } else {
            // Updating an existing ticket
            if (isEmpty(this.ticketRelationshipIds)) {
                await this.loadTicketReleationshipIds();
            }

            incidentVariables = {
                ...baseIncidentVariables,
                id: this.id,
                origin: this.origin
            };

            incidentMlsInput.data.push({
                id: this.ticketRelationshipIds.incidentMlsId,
                title: this.title,
                language: i18next.language,
                description: description,
                altcomponent: altComponentDescription
            });
            incidentVariables.incidents_mls = incidentMlsInput;

            if (this.incidentContactPerson && this.incidentContactPerson.personid) {
                incidentContactPersonInput.data.push({
                    id: this.ticketRelationshipIds.incidentContactPersonId,
                    personid: this.incidentContactPerson?.personid,
                    role: INCIDENT_PERSON_ROLE.CONTACT
                });
                incidentVariables.incidentpersons = incidentContactPersonInput;
            }
        }

        return incidentVariables;
    };

    saveIncidentImage = async (image: ITicketImage, incidentId: string): Promise<string | undefined> => {
        let newFileId = undefined;

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            const requestBody = {
                version: "2.0",
                id: image.fileId,
                origin: TICKET_ORIGIN.MANUAL,
                mimetype: image.mimetype,
                extension: image.extension,
                name: image.name,
                filedate: image.fileDate,
                file: image.base64String,
                links: [
                    {
                        id: image.incidentFileId,
                        entityid: incidentId,
                        entity: "ticket.incidents",
                        roleid: getRoleId(Role.MANAGER),
                        visible: true,
                        type: image.type
                    }
                ]
            };

            const response = await fetch(NetworkConfig.uploadFileUrl, {
                method: "PUT",
                body: JSON.stringify(requestBody),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            const responseNewFileId = await response.text();

            if (response.ok) {
                newFileId = responseNewFileId;
            } else {
                console.error("Error uploading image");
            }
        } catch (err) {
            console.error("Fetch error occured while trying to upload image: ", err, image);
        }

        return newFileId;
    };

    saveDamageImages = async (incidentId: string) => {
        if (this.damageNotificationDetails && this.damageNotificationDetails.images.length > 0) {
            // Loop through each image in the images array
            for (const image of this.damageNotificationDetails.images) {
                if (
                    image.base64String &&
                    (image.state === TICKET_IMAGE_STATE.NEW || image.state === TICKET_IMAGE_STATE.HAS_CHANGED)
                ) {
                    // Save each image
                    await this.saveIncidentImage(image, incidentId);
                }
            }
        }

        if (this.damageNotificationDetails.serialNumberImage) {
            const serialNumberImage = this.damageNotificationDetails.serialNumberImage;

            if (
                (serialNumberImage.base64String && serialNumberImage.state === TICKET_IMAGE_STATE.NEW) ||
                serialNumberImage.state === TICKET_IMAGE_STATE.HAS_CHANGED
            ) {
                await this.saveIncidentImage(serialNumberImage, incidentId);
            }
        }
    };

    saveKeyOrderImages = async (incidentId: string) => {
        if (this.keyOrderNotificationDetails && this.keyOrderNotificationDetails.images.length > 0) {
            for (const image of this.keyOrderNotificationDetails.images) {
                if (
                    image.base64String &&
                    (image.state === TICKET_IMAGE_STATE.NEW || image.state === TICKET_IMAGE_STATE.HAS_CHANGED)
                ) {
                    await this.saveIncidentImage(image, incidentId);
                }
            }
        }
    };

    saveQuestionToDocumentImages = async (incidentId: string) => {
        if (
            this.questionToDocumentNotificationDetails &&
            this.questionToDocumentNotificationDetails.images.length > 0
        ) {
            for (const image of this.questionToDocumentNotificationDetails.images) {
                if (
                    image.base64String &&
                    (image.state === TICKET_IMAGE_STATE.NEW || image.state === TICKET_IMAGE_STATE.HAS_CHANGED)
                ) {
                    await this.saveIncidentImage(image, incidentId);
                }
            }
        }
    };

    saveComment = async (upsertIncidentId: string, comment: string) => {
        let success = false;

        if (comment && comment.length > 0) {
            try {
                await apolloClientInstance.mutate<UpsertIncidentComment, UpsertIncidentCommentVariables>({
                    mutation: UPSERT_INCIDENT_COMMENT,
                    variables: {
                        comment: {
                            userid: this.rootStore.authStore.user?.userid,
                            incidentid: upsertIncidentId,
                            language: "de",
                            text: comment
                        }
                    }
                });
            } catch (error) {
                console.error(`Error commenting ticket: ${upsertIncidentId}`, error);
            }

            this.updateProperty(TICKET_PROPERTY.COMMENT, "");

            success = true;
        }

        return success;
    };

    deleteDamageImage = async (fileId?: string): Promise<boolean> => {
        let success = false;
        if (fileId) {
            try {
                const { data: deleteFileResult } = await apolloClientInstance.mutate<DeleteFile, DeleteFileVariables>({
                    mutation: DELETE_FILE,
                    variables: {
                        fileid: fileId
                    }
                });

                if (
                    deleteFileResult &&
                    deleteFileResult?.deleted_files &&
                    deleteFileResult?.deleted_files?.affected_rows > 0
                ) {
                    success = true;
                }
            } catch (error) {
                console.error("error deleting file: ", error);
            } finally {
                return success;
            }
        }

        return success;
    };

    createIncidentBackend = async (incidentVariables: ITicketIncidentVariables): Promise<string> => {
        let upsertIncidentId = undefined;

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            const createIncidentResponse = await fetch(NetworkConfig.createIncidentUrl, {
                method: "POST",
                body: JSON.stringify(incidentVariables),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            if (createIncidentResponse.status === 200) {
                const createIncidentResult = await createIncidentResponse.json();

                upsertIncidentId = createIncidentResult.data.id;

                this.updateProperty(TICKET_PROPERTY.ID, createIncidentResult.data.id);
                this.updateProperty(TICKET_PROPERTY.NUMBER, createIncidentResult.data.number);
                this.updateProperty(TICKET_PROPERTY.WORKFLOWINSTANCE, createIncidentResult.data.workflowInstance);
                this.updateProperty(TICKET_PROPERTY.DATE, this.getDateStringForHasuraQuery(new Date()));

                if (createIncidentResult.data.incidentMlsList.length > 0) {
                    this.ticketRelationshipIds.incidentMlsId = createIncidentResult.data.incidentMlsList[0].id;
                }

                if (createIncidentResult.data.incidentPersonList.length > 0) {
                    this.ticketRelationshipIds.incidentContactPersonId =
                        createIncidentResult.data.incidentPersonList[0].id;
                }
            } else {
                console.error("Error occured while trying to create incident: ", createIncidentResponse);
            }
        } catch (error) {
            console.error("Error saving ticket: ", error);
        }

        return upsertIncidentId;
    };

    updateIncidentHasura = async (incidentVariables: ITicketIncidentVariables): Promise<string> => {
        let upsertIncidentId = undefined;

        try {
            const { data: upsertIncidentResult } = await apolloClientInstance.mutate<
                UpsertIncident,
                UpsertIncidentVariables
            >({
                mutation: UPSERT_INCIDENT,
                variables: {
                    incident: incidentVariables
                }
            });

            if (upsertIncidentResult && upsertIncidentResult.upserted_incidents_data) {
                upsertIncidentId = upsertIncidentResult.upserted_incidents_data.id;
            }
        } catch (error) {
            console.error("Error updating ticket: ", error);
        }

        return upsertIncidentId;
    };

    updatePersonDetails = async (updatePersonDetailsVariables: UpdatePersonDetailsVariables): Promise<string> => {
        let updatedPersonId = undefined;

        try {
            const { data: updatePesonDetails } = await apolloClientInstance.mutate<
                UpdatePersonDetails,
                UpdatePersonDetailsVariables
            >({
                mutation: UPDATE_PERSON_DETAILS,
                variables: updatePersonDetailsVariables
            });

            if (
                updatePesonDetails &&
                updatePesonDetails.update_common_persons &&
                updatePesonDetails.update_common_persons.returning?.length > 0
            ) {
                updatedPersonId = updatePesonDetails.update_common_persons.returning[0].id;
            }
        } catch (error) {
            console.error("Error updating person details: ", error);
        }

        return updatedPersonId;
    };

    saveImages = async (upsertIncidentId: string) => {
        if (this.workflow_type === PROCESS.DAMAGE_NOTIFICATION) {
            await this.saveDamageImages(upsertIncidentId);
        }

        if (this.workflow_type === PROCESS.KEY_ORDER) {
            await this.saveKeyOrderImages(upsertIncidentId);
        }

        if (this.workflow_type === PROCESS.DOCUMENT_QUESTION) {
            await this.saveQuestionToDocumentImages(upsertIncidentId);
        }
    };

    save = async (): Promise<boolean> => {
        let upsertIncidentId: string | undefined = undefined;
        let wasSuccessfullySaved = false;
        let savingNewTicket = this.isNewTicket ? true : false;

        const ticketAssignRealestateNeedsToBeConcluded =
            this.origin === TICKET_ORIGIN.EASY_CONTACT &&
            this.workflowinstance !== undefined &&
            this.ticketFieldsOriginal !== undefined &&
            this.ticketFieldsOriginal?.realestate?.id === undefined;

        const incidentVariables = await this.getTicketIncidentsInsertInputQueryVariables(savingNewTicket);

        if (savingNewTicket) {
            upsertIncidentId = await this.createIncidentBackend(incidentVariables);
        } else {
            upsertIncidentId = await this.updateIncidentHasura(incidentVariables);
        }

        if (upsertIncidentId) {
            await this.saveImages(upsertIncidentId);
        }

        // Updating IncidentContactPersonDetails
        if (this.fieldsWithChangesIncidentContactPerson && this.fieldsWithChangesIncidentContactPerson.length > 0) {
            const updatePersonDetailsVariables: UpdatePersonDetailsVariables = {
                customerid: this.incidentContactPerson?.customerid,
                id: this.incidentContactPerson?.personid,
                personDetails: {
                    email: this.incidentContactPerson?.email,
                    mobile: this.incidentContactPerson?.mobile,
                    phoneprivate: this.incidentContactPerson?.phoneprivate
                }
            };

            await this.updatePersonDetails(updatePersonDetailsVariables);
        }

        wasSuccessfullySaved = upsertIncidentId !== undefined;

        await this.reloadHistory();

        if (wasSuccessfullySaved) {
            if (savingNewTicket) {
                this.startIncidentUserSubscription(upsertIncidentId);
                this.startIncidentPersonSubscription(upsertIncidentId);
            }

            if (!savingNewTicket) {
                if (
                    (this.incidentPersons && // owner may be missing for immotop2-customers
                        this.incidentPersons.find((ip) => ip.role === INCIDENT_PERSON_ROLE.OWNER) === undefined) ||
                    (this.fieldsWithChanges &&
                        this.fieldsWithChanges?.length > 0 &&
                        this.fieldsWithChanges.includes(TICKET_PROPERTY.REALESTATE) &&
                        this.realestate?.id)
                ) {
                    const wereIncidentPersonsSuccessfullyUpdated = await this.updateIncidentPersons(
                        this.realestate?.id ?? ""
                    );

                    if (wereIncidentPersonsSuccessfullyUpdated) {
                        await this.reloadIncidentPersons();
                    }
                }

                if (ticketAssignRealestateNeedsToBeConcluded) {
                    const wasRealestateAssigendSuccessfullyConcluded =
                        (await this.concludeRealestateAssigend()) ?? false;
                    wasSuccessfullySaved = wasSuccessfullySaved && wasRealestateAssigendSuccessfullyConcluded;
                }
            }

            this.setTicketFieldsOnObject(this.ticketFieldsOriginal, this);
            this.resetAllFieldsWithChanges();
        }

        return wasSuccessfullySaved;
    };

    checkReadyToComplete = async (): Promise<boolean> => {
        let incidentReadyToBeClosed = false;

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            const response = await fetch(`${NetworkConfig.checkIncidentCanBeCompletedUrl}?id=${this.id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            if (response.status === 200) {
                incidentReadyToBeClosed = true;
            } else {
                console.error("response: ", response);

                const checkIncidentCanBeCompletedResult = await response.json();

                if (checkIncidentCanBeCompletedResult && checkIncidentCanBeCompletedResult.code) {
                    this.rootStore.uiStore.printTicketingErrorMessage(
                        i18next.t(`screens.tickets.code.${checkIncidentCanBeCompletedResult.code}`)
                    );
                } else {
                    this.rootStore.uiStore.printTicketingErrorMessage(
                        i18next.t("screens.tickets.code.closing_ticket_general_error")
                    );
                }

                console.error("The incident cannot be closed yet: ", checkIncidentCanBeCompletedResult);
            }
        } catch (error) {
            console.error("Error checking if incident can be closed: ", error);
        }

        return incidentReadyToBeClosed;
    };

    close = async (informNotifierAboutClose?: boolean): Promise<boolean> => {
        let incidentSuccessfullyClosed = false;

        const requestBody = {
            workflowid: this.workflowinstance,
            mail: informNotifierAboutClose ?? false
        };

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            const response = await fetch(NetworkConfig.closeIncidentUrl, {
                method: "POST",
                body: JSON.stringify(requestBody),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            if (response.status === 200) {
                incidentSuccessfullyClosed = true;

                this.updateProperty(TICKET_PROPERTY.STATE, TICKET_STATE.COMPLETED);
                await this.reloadHistory();
                this.resetAllFieldsWithChanges();
            } else {
                console.error("Error closing the incident: ", response);
            }
        } catch (error) {
            console.error("Error while trying to close the incident: ", error);
        }

        return incidentSuccessfullyClosed;
    };

    assignToUser = async (userId: string): Promise<boolean> => {
        let wasTicketSuccessfullyAssigned = false;

        const requestBody = {
            id: this.id,
            userid: userId
        };

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            const response = await fetch(NetworkConfig.assignIncidentToUserUrl, {
                method: "POST",
                body: JSON.stringify(requestBody),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            if (response.status === 200) {
                wasTicketSuccessfullyAssigned = true;
                await this.reloadHistory();
            } else {
                console.error("Error assigning the ticket: ", response);
            }
        } catch (error) {
            console.error("Error while trying to assign the ticket: ", error);
        }

        return wasTicketSuccessfullyAssigned;
    };

    concludeUserAssigend = async (): Promise<boolean> => {
        let wasTicketAssignUserSuccessfullyConcluded = false;

        const requestBody = {
            workflowid: this.workflowinstance
        };

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            const response = await fetch(NetworkConfig.concludeUserAssignedUrl, {
                method: "POST",
                body: JSON.stringify(requestBody),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            if (response.status === 200) {
                wasTicketAssignUserSuccessfullyConcluded = true;
            } else {
                console.error("Error concluding assign user: ", response);
            }
        } catch (error) {
            console.error("Error while trying to call 'conclude user assigned': ", error);
        }

        return wasTicketAssignUserSuccessfullyConcluded;
    };

    concludeRealestateAssigend = async (): Promise<boolean> => {
        let wasRealestateAssigendSuccessfullyConcluded = false;

        const requestBody = {
            workflowid: this.workflowinstance
        };

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            const response = await fetch(NetworkConfig.concludeRealestateAssignedUrl, {
                method: "POST",
                body: JSON.stringify(requestBody),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            if (response.status === 200) {
                wasRealestateAssigendSuccessfullyConcluded = true;
            } else {
                console.error("Error concluding assign realestate: ", response);
            }
        } catch (error) {
            console.error("Error while trying to call 'conclude realestate assigned': ", error);
        }

        return wasRealestateAssigendSuccessfullyConcluded;
    };

    reloadOrders = async (): Promise<boolean> => {
        let successfullyReloadedOrders = false;

        try {
            const { data } = await apolloClientInstance.query<GetOrdersOfTicket, GetOrdersOfTicketVariables>({
                query: GET_ORDERS_OF_TICKET,
                fetchPolicy: "no-cache",
                variables: {
                    ticketId: this.id
                }
            });

            const reloadedOrders: IOrder[] = data.ticket_orders.map((order) => {
                const serviceProviderName = order.service_provider
                    ? `${order.service_provider.name1 ?? ""} ${order.service_provider.name2 ?? ""}`.trim()
                    : undefined;

                const orderObject: IOrder = {
                    id: order.id,
                    number: order.number,
                    workflowinstance: order.workflowinstance,
                    state: order.state,
                    contractorType: order.contractortype,
                    serviceProviderName: serviceProviderName
                };

                return orderObject;
            });

            this.updateProperty(TICKET_PROPERTY.ORDERS, reloadedOrders);

            // Update ticket information if the ticket is already in the store
            const ticketInStore = this.rootStore.ticketStore.tickets.find(
                (ticket) => ticket.number === Number(this.rootStore.ticketStore.currentTicket?.number)
            );

            if (ticketInStore) {
                ticketInStore.orders = reloadedOrders;
            }

            successfullyReloadedOrders = true;
        } catch (error) {
            console.error("Error reloading orders: ", error);
        }

        return successfullyReloadedOrders;
    };

    reloadHistory = async (): Promise<boolean> => {
        let successfullyReloadedHistory = false;

        try {
            const { data } = await apolloClientInstance.query<GetHistoryOfTicket, GetHistoryOfTicketVariables>({
                query: GET_HISTORY_OF_TICKET,
                fetchPolicy: "no-cache",
                variables: {
                    ticketId: this.id
                }
            });

            const history = this.rootStore.ticketStore.getHistoryObjectFromQueryData(data.ticket_v_incident_history);

            this.updateProperty(TICKET_PROPERTY.HISTORY, history);

            successfullyReloadedHistory = true;
        } catch (error) {
            console.error("Error reloading history: ", error);
        }

        return successfullyReloadedHistory;
    };

    reloadIncidentPersons = async (): Promise<boolean> => {
        let successfullyReloadedIncidentPersons = false;

        try {
            const { data } = await apolloClientInstance.query<
                GetIncidentPersonsOfTicket,
                GetIncidentPersonsOfTicketVariables
            >({
                query: GET_INCIDENT_PERSONS_OF_TICKET,
                fetchPolicy: "no-cache",
                variables: {
                    ticketId: this.id
                }
            });

            const reloadedIncidentPersons: IIncidentPerson[] = TicketStore.getIncidentPersonsFromQueryData(
                data.ticket_incidentpersons
            );

            this.updateProperty(TICKET_PROPERTY.INCIDENT_PERSONS, reloadedIncidentPersons);
            successfullyReloadedIncidentPersons = true;
        } catch (error) {
            console.error("Error reloading incident persons: ", error);
        }

        return successfullyReloadedIncidentPersons;
    };

    // TODO SG:
    archive = async (): Promise<boolean> => {
        let wasTicketSuccessfullyArchived = false;

        const requestBody = {
            workflowid: this.workflowinstance
        };

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            const response = await fetch(NetworkConfig.incidentArchive, {
                method: "POST",
                body: JSON.stringify(requestBody),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            if (response.status === 200) {
                wasTicketSuccessfullyArchived = true;
                await this.reloadHistory();
            } else {
                console.error("Error archiving the ticket: ", response);
            }
        } catch (error) {
            console.error("Error while trying to archive the ticket: ", error);
        }

        return wasTicketSuccessfullyArchived;
    };

    updateFieldsWithChanges = (fieldType: TICKET_FIELD_TYPE) => {
        if (this.rootStore.ticketStore.isEditing) {
            switch (fieldType) {
                case TICKET_FIELD_TYPE.GENERAL:
                    this.fieldsWithChanges = findDifferences(this.ticketFieldsOriginal, this, true, [
                        TICKET_PROPERTY.REALESTATE_THUMBNAIL_FILEID,
                        TICKET_PROPERTY.ASSIGNED_USER,
                        TICKET_PROPERTY.STATE,
                        TICKET_PROPERTY.COMMENT,
                        TICKET_PROPERTY.HISTORY,
                        TICKET_PROPERTY.WORKFLOWINSTANCE,
                        TICKET_PROPERTY.INCIDENT_CONTACT_PERSON
                    ]);
                    break;
                case TICKET_FIELD_TYPE.DAMAGE_NOTIFICATION:
                    this.fieldsWithChangesDamageNotification = findDifferences(
                        this.ticketFieldsOriginal.damageNotificationDetails,
                        this.damageNotificationDetails,
                        true
                    );
                    break;
                case TICKET_FIELD_TYPE.COMMON_REQUEST:
                    this.fieldsWithChangesCommonRequestNotification = findDifferences(
                        this.ticketFieldsOriginal.commonRequestNotificationDetails,
                        this.commonRequestNotificationDetails,
                        true
                    );
                    break;
                case TICKET_FIELD_TYPE.NAMESIGN:
                    this.fieldsWithChangesNamesignNotification = findDifferences(
                        this.ticketFieldsOriginal.namesignNotificationDetails,
                        this.namesignNotificationDetails,
                        true
                    );
                    break;
                case TICKET_FIELD_TYPE.KEY_ORDER:
                    this.fieldsWithChangesKeyOrderNotification = findDifferences(
                        this.ticketFieldsOriginal.keyOrderNotificationDetails,
                        this.keyOrderNotificationDetails,
                        true
                    );
                    break;
                case TICKET_FIELD_TYPE.QUESTION_TO_DOCUMENT:
                    this.fieldsWithChangesQuestionToDocumentNotification = findDifferences(
                        this.ticketFieldsOriginal.questionToDocumentNotificationDetails,
                        this.questionToDocumentNotificationDetails,
                        true
                    );
                    break;
                case TICKET_FIELD_TYPE.INCIDENT_CONTACT_PERSON:
                    this.fieldsWithChangesIncidentContactPerson = findDifferences(
                        this.ticketFieldsOriginal.incidentContactPerson,
                        this.incidentContactPerson,
                        true
                    );
                    break;
            }
        }
    };

    resetAllFieldsWithChanges = () => {
        this.fieldsWithChanges = [];
        this.fieldsWithChangesDamageNotification = [];
        this.fieldsWithChangesCommonRequestNotification = [];
        this.fieldsWithChangesNamesignNotification = [];
        this.fieldsWithChangesKeyOrderNotification = [];
        this.fieldsWithChangesQuestionToDocumentNotification = [];
        this.fieldsWithChangesIncidentContactPerson = [];
    };

    updateIncidentPersons = async (realestateid: string): Promise<boolean> => {
        let wereIncidentPersonsSuccessfullyUpdated = false;

        const requestBody = {
            id: this.id,
            customerid: this.rootStore.authStore.user?.customerid,
            realestateid: realestateid
        };

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            const response = await fetch(NetworkConfig.updateIncidentPersonsUrl, {
                method: "POST",
                body: JSON.stringify(requestBody),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            if (response.status === 200) {
                wereIncidentPersonsSuccessfullyUpdated = true;
                await this.reloadHistory();
            } else {
                console.error("Error assigning the ticket: ", response);
            }
        } catch (error) {
            console.error("Error while trying to assign the ticket: ", error);
        }

        return wereIncidentPersonsSuccessfullyUpdated;
    };
}
