import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "src/stores/RootStore";
import { ITicketImage } from "src/stores/TicketStore";
import styles from "./ImageCheckboxGrid.module.css";

const ImageCheckboxGridBase = () => {
    const { ticketStore, assignOrderModalStore } = useContext(RootStoreContext);
    const [selectedImages, setSelectedImages] = useState<ITicketImage[]>([]);

    const images = useMemo(() => {
        return ticketStore.currentTicket?.damageNotificationDetails?.images &&
            ticketStore.currentTicket.damageNotificationDetails.images.length > 0
            ? ticketStore.currentTicket.damageNotificationDetails.images
            : ticketStore.currentTicket?.keyOrderNotificationDetails?.images &&
              ticketStore.currentTicket.keyOrderNotificationDetails.images.length > 0
            ? ticketStore.currentTicket.keyOrderNotificationDetails.images
            : ticketStore.currentTicket?.questionToDocumentNotificationDetails?.images &&
              ticketStore.currentTicket.questionToDocumentNotificationDetails.images.length > 0
            ? ticketStore.currentTicket.questionToDocumentNotificationDetails.images
            : [];
    }, [ticketStore.currentTicket]);

    useEffect(() => {
        if (images.length > 0) {
            const initialSelection = images.slice(0, 2); // Get the first two images
            setSelectedImages(initialSelection);

            // Extract and send fileIds of the initially selected images
            const initialFileIds = initialSelection
                .map((img) => img.fileId)
                .filter((fileId): fileId is string => fileId !== undefined);

            assignOrderModalStore.setSelectedImageIds(initialFileIds);
        }
    }, [images, assignOrderModalStore]);

    const handleCheckboxChange = useCallback(
        (image: ITicketImage) => async (e: any) => {
            const updatedSelection = selectedImages.includes(image)
                ? selectedImages.filter((img) => img.fileId !== image.fileId)
                : [...selectedImages, image];

            setSelectedImages(updatedSelection);

            const selectedFileIds = updatedSelection
                .map((img) => img.fileId)
                .filter((fileId): fileId is string => fileId !== undefined);

            assignOrderModalStore.setSelectedImageIds(selectedFileIds);
        },
        [selectedImages, assignOrderModalStore]
    );

    return (
        <div className={styles.gridContainer}>
            {images.map((image, index) => {
                const imageSrc = image.url || `data:${image.mimetype};base64,${image.base64String}`;

                const isChecked = selectedImages.includes(image);
                const checkboxClass = isChecked ? `${styles.checkbox} ${styles.checkboxChecked}` : styles.checkbox;
                const imageWrapperClass = isChecked ? `${styles.imageWrapper} selected` : styles.imageWrapper;

                return (
                    <div key={index} className={imageWrapperClass}>
                        <img
                            src={imageSrc}
                            alt={image.name}
                            className={isChecked ? styles.styledCheckedImage : styles.styledImage}
                            onClick={handleCheckboxChange(image)}
                        />
                        <div className={styles.checkboxWrapper}>
                            <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange(image)}
                                className={checkboxClass}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export const ImageCheckboxGrid = Sentry.withProfiler(observer(ImageCheckboxGridBase));
