import { UI } from "@wwimmo/ui";
import { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import styles from "./ApproveInvoiceModal.module.css";
import { ApproveRejectInvoiceProgress } from "src/screens/creditors/creditor/details/actions/rejectInvoice/progress/ApproveRejectInvoiceProgress";
import {
    APPROVE_REJECT_INVOICE_STATE,
    APPROVE_REJECT_MODAL_TYPE
} from "src/stores/krediflow/ApproveRejectInvoiceStore";

const ApproveInvoiceModalBase = () => {
    const { t } = useTranslation();
    const { approveRejectInvoiceStore } = useContext(RootStoreContext);

    const toggleDisplayAssignInvoiceModal = useCallback(() => {
        const modalIsBeingClosed = approveRejectInvoiceStore.isModalDisplayed;

        approveRejectInvoiceStore.setIsModalDisplayed(!approveRejectInvoiceStore.isModalDisplayed);

        if (modalIsBeingClosed) {
            approveRejectInvoiceStore.reset();
        }
    }, [approveRejectInvoiceStore]);

    const onClickApprove = useCallback(() => {
        approveRejectInvoiceStore.approveInvoice();
    }, [approveRejectInvoiceStore]);

    return (
        <UI.Modal
            title={t("screens.kredi_flow.action.approve").toString()}
            size="lg"
            show={
                approveRejectInvoiceStore.isModalDisplayed &&
                approveRejectInvoiceStore.displayedModalType === APPROVE_REJECT_MODAL_TYPE.APPROVE
            }
            onClose={toggleDisplayAssignInvoiceModal}
            centered={true}
        >
            {approveRejectInvoiceStore.approveRejectInvoiceState === APPROVE_REJECT_INVOICE_STATE.INIT ? (
                <>
                    <UI.Row className={"mt-2"}>
                        <UI.Col sm={12}>
                            <div className="mt-2 mb-4">
                                {t("screens.kredi_flow.action.approve_invoice.confirm_question")}
                            </div>
                        </UI.Col>
                    </UI.Row>
                    <UI.Row>
                        <UI.Col>
                            <UI.Button
                                label={t("labels.cancel").toString()}
                                className={`mb-2 default-button-height ${styles.CancelButton}`}
                                onClick={toggleDisplayAssignInvoiceModal}
                            />
                        </UI.Col>
                        <UI.Col>
                            <UI.Button
                                label={t("screens.kredi_flow.action.approve").toString()}
                                className={`mb-2 default-button-height ml-auto ${styles.SubmitButton}`}
                                onClick={onClickApprove}
                            />
                        </UI.Col>
                    </UI.Row>
                </>
            ) : undefined}

            {approveRejectInvoiceStore.approveRejectInvoiceState === APPROVE_REJECT_INVOICE_STATE.REJECTING ||
            approveRejectInvoiceStore.approveRejectInvoiceState === APPROVE_REJECT_INVOICE_STATE.APPROVING ||
            approveRejectInvoiceStore.approveRejectInvoiceState === APPROVE_REJECT_INVOICE_STATE.FINISHED ? (
                <ApproveRejectInvoiceProgress
                    key={"approve-reject-invoice-progress-assigning"}
                    currentRejectInvoiceState={approveRejectInvoiceStore.approveRejectInvoiceState}
                />
            ) : undefined}
        </UI.Modal>
    );
};

export const ApproveInvoiceModal = Sentry.withProfiler(observer(ApproveInvoiceModalBase));
