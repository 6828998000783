import { action, makeObservable, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { IAccount } from "./InvoiceTypes";
import { ErpType } from "src/network/User";
import { GET_ACCOUNT_DATA } from "src/api/cred";
import { GetAccountData, GetAccountDataVariables } from "src/api/generated/GetAccountData";
import { apolloClientInstance } from "src/network/apolloClientInstance";
import { INVOICE_ACCOUNT_UNIT_OF_MEASURE } from "./InvoiceEnums";

export enum LOADING_ACCOUNT_DATA_STATE {
    INIT,
    LOADING
}

export class AccountingAccountSearchStore {
    rootStore: RootStore;

    erpType: ErpType = ErpType.IT2;

    accountData: IAccount[] = [];
    loadingAccountDataState: LOADING_ACCOUNT_DATA_STATE = LOADING_ACCOUNT_DATA_STATE.INIT;
    loadedAccountDataWithVariables: { realestateId: string; bookingDate: string } | undefined = undefined;

    // This is an array because every Accounting Row has its own account value
    currentAccountSearchQuery: string[] = [];
    selectedAccountQueryString: string[] = [];
    selectedAccountName: string[] = [];

    triggerFocusNextInput: { isActive: boolean; index: number } = { isActive: false, index: 0 };

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            accountData: observable,
            currentAccountSearchQuery: observable,
            selectedAccountQueryString: observable,
            selectedAccountName: observable,
            triggerFocusNextInput: observable,
            loadingAccountDataState: observable,
            loadedAccountDataWithVariables: observable,

            init: action,
            reset: action,
            loadAccountData: action,
            setLoadingAccountDataState: action,
            setCurrentAccountSearchQuery: action,
            setSelectedAccountQueryString: action,
            setSelectedAccountName: action,
            setTriggerFocusNextInput: action,
            filteredAccountData: action,
            setAccountData: action
        });
    }

    init = () => {};

    reset = () => {
        this.currentAccountSearchQuery = [];
        this.selectedAccountQueryString = [];
        this.selectedAccountName = [];
    };

    loadAccountData = async (accountingRowIndex: number) => {
        this.setLoadingAccountDataState(LOADING_ACCOUNT_DATA_STATE.LOADING);

        const currentInvoice = this.rootStore.invoiceStore.currentInvoice;
        const realestateId = currentInvoice?.getAccountingRealestate(accountingRowIndex)?.id;
        const date = currentInvoice?.getAccountingDate(accountingRowIndex);

        const hasAlreadyLoadedAccountData =
            this.loadedAccountDataWithVariables?.realestateId === realestateId &&
            this.loadedAccountDataWithVariables?.bookingDate === date;

        if (realestateId && date && !hasAlreadyLoadedAccountData) {
            this.loadedAccountDataWithVariables = {
                realestateId: realestateId,
                bookingDate: date
            };

            const { data }: { data: GetAccountData } = await apolloClientInstance.query<
                GetAccountData,
                GetAccountDataVariables
            >({
                query: GET_ACCOUNT_DATA,
                variables: {
                    realestateid: realestateId,
                    bookingdate: date
                }
            });

            const accountData: IAccount[] = data.accounts
                .map((account) => {
                    return {
                        id: account.id,
                        number: account.number,
                        name: account.name,
                        requiresCostCenter: account.requirescostcenter,
                        requiresQuantity: account.requiresquantitiy,
                        unitOfMeasure: account.unitofmeasure ?? INVOICE_ACCOUNT_UNIT_OF_MEASURE.N_A,
                        extraCostDate: account.extracostdate
                    };
                })
                .sort((a, b) => {
                    // The result set comes already sorted by 'type'
                    // Then also sort by "number" (lexicographic or numeric comparison)
                    const numberComparison = a.number.localeCompare(b.number, undefined, {
                        numeric: true,
                        sensitivity: "base"
                    });
                    if (numberComparison !== 0) {
                        return numberComparison;
                    }
                    // Finally by "name" (string comparison)
                    return a.name.localeCompare(b.name);
                });

            this.setAccountData(accountData);
        }

        this.setLoadingAccountDataState(LOADING_ACCOUNT_DATA_STATE.INIT);
    };

    // Getters
    getFormattedAccountFieldValue = (account: IAccount): string => {
        return `${account.number} ${account.name}`;
    };

    getCurrentAccountSearchQuery(accountingRowIndex: number) {
        return this.currentAccountSearchQuery[accountingRowIndex];
    }

    getSelectedAccountQueryString(accountingRowIndex: number) {
        return this.selectedAccountQueryString[accountingRowIndex];
    }

    getSelectedAccountName(accountingRowIndex: number) {
        return this.selectedAccountName[accountingRowIndex];
    }

    // Setters
    setLoadingAccountDataState(loadingAccountDataState: LOADING_ACCOUNT_DATA_STATE) {
        this.loadingAccountDataState = loadingAccountDataState;
    }

    setAccountData(data: IAccount[]) {
        this.accountData = data;
    }

    setCurrentAccountSearchQuery(currentAccountSearchQuery: string, accountingRowIndex: number) {
        this.currentAccountSearchQuery[accountingRowIndex] = currentAccountSearchQuery;
    }

    setSelectedAccountQueryString(selectedAccountQueryString: string, accountingRowIndex: number) {
        this.selectedAccountQueryString[accountingRowIndex] = selectedAccountQueryString;
    }

    setSelectedAccountName(selectedAccountName: string, accountingRowIndex: number) {
        this.selectedAccountName[accountingRowIndex] = selectedAccountName;
    }

    /*  This function triggers the focus on the next field after selecting an account
        For IT2 this will be the CostCenter field in the current accounting row
        For RIMO this will be the Booking Text field in the current accounting row
    */
    setTriggerFocusNextInput(triggerFocusNextInput: boolean, accountingRowIndex: number) {
        this.triggerFocusNextInput = {
            isActive: triggerFocusNextInput,
            index: accountingRowIndex
        };
    }

    filteredAccountData(accountingRowIndex: number): IAccount[] {
        let currentAccountSearchQuery = this.currentAccountSearchQuery[accountingRowIndex] ?? "";
        let selectedRealestateQueryString = this.selectedAccountQueryString[accountingRowIndex] ?? "";

        if (currentAccountSearchQuery === selectedRealestateQueryString) {
            return this.accountData;
        }

        if (currentAccountSearchQuery === "") {
            return this.accountData;
        }

        const filteredAccountData = this.accountData.filter((account) => {
            const accountName = `${account.number} ${account.name}`;

            return accountName.toLowerCase().includes(currentAccountSearchQuery.toLowerCase());
        });

        return filteredAccountData;
    }
}
