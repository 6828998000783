import { action, makeObservable, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { IRealestate } from "./InvoiceTypes";

export enum DISPLAYED_MODAL_TYPE {
    NONE,
    REALESTATE_CHANGED_WARNING
}

export class AccountingStore {
    rootStore: RootStore;

    displayedModalType: DISPLAYED_MODAL_TYPE = DISPLAYED_MODAL_TYPE.NONE;
    realestateThatTriggeredWarning: IRealestate | undefined = undefined;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            displayedModalType: observable,
            realestateThatTriggeredWarning: observable,

            setDisplayedModalType: action,
            setRealestateThatTriggeredWarning: action
        });
    }

    setDisplayedModalType = (modalType: DISPLAYED_MODAL_TYPE) => {
        this.displayedModalType = modalType;
    };

    setRealestateThatTriggeredWarning = (realestate: IRealestate | undefined) => {
        this.realestateThatTriggeredWarning = realestate;
    };
}
