export enum INVOICE_STATE {
    UNDEFINED = 0,
    NEW = 10,
    READY_FOR_ACCOUNTING = 20,
    READY_FOR_VISA = 30,
    VISA_1_OK = 41,
    VISA_2_OK = 42,
    VISA_3_OK = 43,
    FULLY_SIGHTED = 50,
    SENT_TO_ERP = 60,
    PAID = 90,
    REJECTED = 95,
    CANCELLED = 99
}

export enum INVOICE_LIST_STATE {
    NEW = 10,
    TO_APPROVE, // States 20 - 49
    PAY, // States 50 - 60
    PAID = 90,
    REJECTED = 95,
    CANCELLED = 99,
    ALL
}

export enum INVOICE_TYPE {
    INVOICE = 1,
    BILL = 2
}

export enum INVOICE_ORIGIN {
    MANUAL = 1,
    SCAN = 2,
    EMAIL = 3,
    TICKET = 4,
    DMS = 5
}

export enum INVOICE_PROPERTY {
    ID = "id",
    ORIGIN = "origin",
    WORKFLOW_INSTANCE = "workflowInstance",
    NUMBER = "number",
    INVOICE_NUMBER = "invoiceNumber",
    AMOUNT = "amount",
    DATE = "date",
    DUE_DATE = "dueDate",
    PAID_DATE = "paidDate",
    TITLE = "title",
    STATE = "state",
    INVOICE_TYPE = "invoiceType",
    TYPE = "type",
    REALESTATE = "realestate",
    REALESTATE_ID = "realestateId",
    REALESTATE_THUMBNAIL_FILEID = "realestateThumbnailFileId",
    UNIT = "unit",
    ASSIGNED_USER = "assignedUser",
    CREDITOR = "creditor",
    PAYMENT_ACCOUNT_ID = "paymentaccountid",
    PAYOUT_ACCOUNT_ID = "payoutaccountid",
    PAYMENT_INFO = "paymentInfo",
    PAYMENT_ACCOUNT = "paymentAccount",
    TICKET = "ticket",
    HISTORY = "history",
    COMMENT = "comment",
    ORDER = "order",
    INVOICE_FILE = "invoiceFile",
    CREDITOR_INFO = "creditorInfo",
    INSERT_USER_ID = "insertUserId",
    INVOICE_INFO = "invoiceInfo",
    WORKFLOW_USERS = "workflowUsers",
    ACCOUNTINGS = "accountings"
}

export enum HISTORY_TYPE {
    INVOICE_CREATED,
    INVOICE_DATA_CHANGED,
    INVOICE_STATUS_CHANGED,
    INVOICE_AMOUNT_CHANGED,
    INVOICE_PAIDDATE_CHANGED,
    INVOICE_DUEDATE_CHANGED,
    INVOICE_CREDITOR_CHANGED,
    INVOICE_PAYMENTACCOUNT_CHANGED,
    INVOICE_PAYOUTACCOUNT_CHANGED,
    INVOICE_ORDER_CHANGED,
    INVOICE_UNIT_CHANGED,
    INVOICE_REALESTATE_CHANGED,
    INVOICE_NEW_WORKFLOWUSER_1,
    INVOICE_NEW_WORKFLOWUSER_2,
    INVOICE_NEW_WORKFLOWUSER_3,
    INVOICE_NEW_WORKFLOWUSER_4,
    INVOICE_NEW_DOCUMENT,
    INVOICE_NEW_ACCOUNTING,
    INVOICE_REJECTED,
    INVOICE_CANCELLED,
    INVOICE_CHANGED_WORKFLOWUSERS,
    INVOICE_CHANGED_WORKFLOWUSERS_ROLE,
    INVOICE_CHANGED_WORKFLOWUSERS_SIGNATURE_1,
    INVOICE_CHANGED_WORKFLOWUSERS_SIGNATURE_2,
    INVOICE_CHANGED_WORKFLOWUSERS_SIGNATURE_3,
    INVOICE_CHANGED_WORKFLOWUSERS_SIGNATURE_4,
    INVOICE_CHANGED_DOCUMENT,
    INVOICE_CHANGED_ACCOUNTING,
    INVOICE_COMMENT,
    INVOICE_EMAIL_OUTBOUND,
    INVOICE_EMAIL_INBOUND
}

export enum INVOICE_PAYMENT_INFO_PROPERTY {
    IBAN = "iban",
    TYPE = "type",
    REFERENCE = "reference"
}

export enum INVOICE_CREDITOR_INFO_PROPERTY {
    NAME = "name",
    STREET = "street",
    HOUSNUMBER = "housnumber",
    ZIP = "zip",
    CITY = "city",
    COUNTRY = "country",
    UID = "uid"
}

export enum INVOICE_ACCOUNTING_PROPERTY {
    ID = "id",
    AMOUNT = "amount",
    DATE = "date",
    REALESTATE = "realestate",
    ACCOUNT = "account",
    COST_CENTER = "costCenter",
    TEXT = "text"
}

export enum INVOICE_ACCOUNTING_ACCOUNT_PROPERTY {
    ID = "id",
    NAME = "name",
    NUMBER = "number",
    TYPE = "type",
    VALID_FROM = "validFrom",
    VALID_TO = "validTo",
    REALESTATE = "realestate",
    TENANT = "tenant",
    REQUIRES_COST_CENTER = "requiresCostCenter",
    REQUIRED_QUANTITY = "requiredQuantity",
    UNIT_OF_MEASURE = "unitOfMeasure",
    VAT_CONFIG = "vatConfig",
    EXTRA_COST_DATE = "extraCostDate"
}

export enum INVOICE_ACCOUNTING_COST_CENTER_PROPERTY {
    ID = "id",
    NAME = "name",
    SHORT = "short"
}

export enum INVOICE_FIELD_TYPE {
    GENERAL,
    PAYMENT_INFO,
    CREDITOR_INFO,
    INVOICE_INFO,
    ACCOUTING
}

export enum INVOICE_ACCOUNTING_FIELD_TYPE {
    GENERAL,
    ACCOUNT,
    COST_CENTER
}

export enum SORT {
    DATE_NEWEST = "dateNewest",
    DATE_OLDEST = "dateOldest"
}

export enum WORKFLOWUSER_ROLE {
    NONE = 0,
    VISA_1 = 1,
    VISA_2 = 2,
    VISA_3 = 3,
    ACCOUNTANT = 4,
    REJECTED_TO = 9,
    CANCELLED_BY = 10
}

export enum LOADING_TYPE {
    NONE,
    INITIALIZING,
    LOADING_INVOICE_DETAILS,
    SAVING_INVOICE,
    SAVING_COMMENT,
    REALESTATE_THUMBNAIL,
    TENANT_INFORMATION,
    OBJECT_UNITS
}

export enum LOADING_TYPE_ACCOUNTING {
    NONE,
    INITIALIZING,
    LOADING_ACCOUNTINGS,
    SAVING_ACCOUNTINGS
}

export enum RUNNING_SUBSCRIPTION_TPYE {
    NONE,
    WORKFLOW_USERS
}

export enum INVOICE_FILE_TYPE {
    INVOICE = 600
}

export enum INVOICE_FILE_STATE {
    NONE,
    NEW,
    LOADED_FROM_DB,
    HAS_CHANGED,
    WAS_DELETED
}

export enum INVOICE_ACCOUNT_TYPE {
    COST = 1,
    DEBTOR = 2,
    GENERAL_LEDGER = 3,
    COOPERATIVE = 4
}

export enum INVOICE_ACCOUNT_UNIT_OF_MEASURE {
    LITER = 1,
    KWH = 2,
    KG = 3,
    M3 = 4,
    TON = 5,
    N_A = 9
}

export enum INVOICE_ACCOUNT_VAT_CONFIG {
    WITHOUT_VAT = 0,
    ALLOWS_VAT = 1,
    REQUIRES_VAT = 2,
    ONLY_DEFAULT_VATCODE = 3
}
