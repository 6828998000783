import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import styles from "./ServiceOrderDescription.module.css";
import generalStyles from "src/screens/tickets/ticket/details/actions/assignOrder/AssignOrderModal.module.css";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { ServiceProviderListItem } from "src/screens/tickets/ticket/details/actions/assignOrder/selectServiceProvider/serviceProviderListItem/ServiceProviderListItem";
import { BILLING_DATA_PROPERTY, ORDER_CREATION_STATUS } from "src/stores/tickets/AssignOrderModalStore";
import { CreateOrderProgress } from "src/screens/tickets/ticket/details/actions/assignOrder/createOrderProgress/CreateOrderProgress";
import { ImageCheckboxGrid } from "src/screens/tickets/ticket/details/actions/assignOrder/ImageCheckbox/ImageCheckboxGrid";
import { GetTicketByNumber_primarycontacttypeEnum } from "src/api/generated/GetTicketByNumber";
import { ColorStyle } from "src/utils/Colors";

interface SortItem {
    value: string;
    label: string;
}

export const ServiceOrderDescriptionBase = () => {
    const { t } = useTranslation();
    const { uiStore, assignOrderModalStore, ticketStore } = useContext(RootStoreContext);

    const [primarycontactDescription, setPrimarycontactDescription] = useState<string>("");
    const [contactDropdownLabel, setContactDropdownLabel] = useState<string>(
        t("screens.tickets.modals.assign_order.sender").toString()
    );

    const onClickBack = useCallback(() => {
        assignOrderModalStore.moveToPrevProcessStep();
    }, [assignOrderModalStore]);

    const onClickSendOrder = useCallback(() => {
        const isServiceOrderValid = assignOrderModalStore.validateServiceOrderDescription();

        if (isServiceOrderValid) {
            assignOrderModalStore.createOrder();
        }
    }, [assignOrderModalStore]);

    const onChangeServiceProviderOrderDescription = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const newServiceProviderOrderDescriptionText = e.target.value;

            assignOrderModalStore.setServiceProviderOrderDescription(newServiceProviderOrderDescriptionText);
        },
        [assignOrderModalStore]
    );

    const serviceProviderHasEmailAddress = assignOrderModalStore.selectedServiceProvider?.email ? true : false;

    const toggleIsEditingBillingData = useCallback(() => {
        assignOrderModalStore.setIsEditingBillingData(!assignOrderModalStore.isEditingBillingData);
    }, [assignOrderModalStore]);

    const toggleContactPersonData = useCallback(() => {
        assignOrderModalStore.setIsContactPersonsCollapsed(!assignOrderModalStore.isContactPersonsCollapsed);
    }, [assignOrderModalStore]);

    const onChangeBillingData = useCallback(
        (billingDataProperty: BILLING_DATA_PROPERTY) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value;

            if (assignOrderModalStore.billingData) {
                assignOrderModalStore.updateBillingDataProperty(billingDataProperty, newValue);
            }
        },
        [assignOrderModalStore]
    );

    const [listOfAllPrimarycontactTypes, setListOfAllPrimarycontactTypes] =
        useState<Map<number | null, { label: string }>>();

    useEffect(() => {
        if (ticketStore.primarycontacttypeEnum) {
            setListOfAllPrimarycontactTypes(getEnumerationMap(ticketStore.primarycontacttypeEnum));
        }
    }, [ticketStore.primarycontacttypeEnum]);

    const getEnumerationMap = (enumerations: Array<GetTicketByNumber_primarycontacttypeEnum>) => {
        const enumerationMap = new Map();

        enumerations.forEach((enumeration) => {
            if (enumeration.key !== null) {
                enumerationMap.set(enumeration.key, {
                    label: enumeration.label
                });
            }
        });

        return enumerationMap;
    };

    const sortedOrderContactDropdownPersons: SortItem[] = useMemo(() => {
        if (!listOfAllPrimarycontactTypes) return [];
        return Array.from(listOfAllPrimarycontactTypes.entries())
            .slice(0, 2)
            .map(([key, value]) => ({
                value: String(key),
                label: value.label || ""
            }));
    }, [listOfAllPrimarycontactTypes]);

    useEffect(() => {
        if (assignOrderModalStore.filteredIncidentPersons.length > 0) {
            const person = assignOrderModalStore.filteredIncidentPersons[0];

            // Construct the description string dynamically
            const roleLabel = ticketStore.primarycontacttypeEnum[0]?.label || "";
            const primaryContactDescription = assignOrderModalStore.getPrimaryContactDescription(person, roleLabel);
            setPrimarycontactDescription(primaryContactDescription);

            assignOrderModalStore.setPrimarycontact(person);
            assignOrderModalStore.setPrimarycontacttype(ticketStore.primarycontacttypeEnum[0].key!);
        }
    }, [setPrimarycontactDescription, ticketStore.primarycontacttypeEnum, assignOrderModalStore]);

    const updatePrimaryContact = useCallback(
        (index: number, contactType: { label: string | null; key: string | number | null }) => {
            const person = assignOrderModalStore.filteredIncidentPersons[index];
            if (person) {
                setPrimarycontactDescription(
                    assignOrderModalStore.getPrimaryContactDescription(person, contactType.label || "")
                );
                assignOrderModalStore.setPrimarycontact(person);

                // Convert key to number if necessary
                const contactTypeKey =
                    typeof contactType.key === "string" ? parseInt(contactType.key, 10) : contactType.key;

                if (contactTypeKey !== null && contactTypeKey !== undefined) {
                    assignOrderModalStore.setPrimarycontacttype(contactTypeKey);
                }
            }
        },
        [assignOrderModalStore, setPrimarycontactDescription]
    );

    const onSelectDropdown = useCallback(
        (selectedPersonIndex: string) => {
            // Find the contact person from the dropdown list whose value matches the selected person's index.
            const selectedContactPersons = sortedOrderContactDropdownPersons.find(
                (contactPerson: SortItem) => contactPerson.value === selectedPersonIndex
            );

            if (selectedContactPersons) {
                setContactDropdownLabel(selectedContactPersons.label!);

                const index = parseInt(selectedPersonIndex, 10) - 1; // Convert "1" or "2" to 0 or 1
                const contactType = ticketStore.primarycontacttypeEnum[index];

                if (contactType) {
                    updatePrimaryContact(index, {
                        label: contactType.label || "", // Ensure label is a string
                        key: contactType.key // Ensure key is correctly handled
                    });
                }
            }
        },
        [
            sortedOrderContactDropdownPersons,
            setContactDropdownLabel,
            ticketStore.primarycontacttypeEnum,
            updatePrimaryContact
        ]
    );

    const renderContactsForm = () => {
        return (
            <div className={styles.ServiceProviderListItem} key={assignOrderModalStore.primarycontact?.name1 || ""}>
                <div className={styles.ServiceProviderListRow}>
                    <div className="font-weight-bold">{`${assignOrderModalStore.primarycontact?.name1} ${assignOrderModalStore.primarycontact?.name2}`}</div>
                </div>
                <div className={styles.ServiceProviderListRowTwo}>
                    {assignOrderModalStore.primarycontact?.phone ? (
                        <div className={styles.ServiceProviderListRowIconItem}>
                            <UI.Icon icon={UI.SVGIcon.PhoneOutlined} size={25} color={ColorStyle("grey")} />
                            <span>{assignOrderModalStore.primarycontact.phone}</span>
                        </div>
                    ) : undefined}
                    {assignOrderModalStore.primarycontact?.mobile ? (
                        <div className={styles.ServiceProviderListRowIconItem}>
                            <UI.Icon icon={UI.SVGIcon.Device} size={25} color={ColorStyle("grey")} />
                            <span>{assignOrderModalStore.primarycontact.mobile}</span>
                        </div>
                    ) : undefined}
                    {assignOrderModalStore.primarycontact?.email ? (
                        <div className={styles.ServiceProviderListRowIconItem}>
                            <UI.Icon icon={UI.SVGIcon.Mail} size={25} color={ColorStyle("grey")} />
                            <span>{assignOrderModalStore.primarycontact.email}</span>
                        </div>
                    ) : undefined}
                </div>
            </div>
        );
    };

    return (
        <>
            {assignOrderModalStore.currentOrderCreationStatus === ORDER_CREATION_STATUS.INIT ? (
                <>
                    <UI.Row className={"mt-2"}>
                        <UI.Col xs={12}>
                            <ServiceProviderListItem
                                serviceProvider={assignOrderModalStore.selectedServiceProvider!}
                                onClickServiceProviderButton={onClickBack}
                                serviceProviderItemButtonLabel={t("labels.change")}
                            />
                        </UI.Col>
                    </UI.Row>
                    <UI.Row>
                        <UI.Col className="mt-4">
                            {serviceProviderHasEmailAddress ? (
                                <UI.Input
                                    label={`${t("screens.tickets.modals.assign_order.order_text").toString()} *`}
                                    type="text"
                                    as="textarea"
                                    autoComplete="off"
                                    value={assignOrderModalStore.serviceProviderOrderDescription}
                                    onChange={onChangeServiceProviderOrderDescription}
                                    errorMsg={assignOrderModalStore.errors.serviceOrderDescription ?? undefined}
                                    className={styles.DescriptionTextArea}
                                />
                            ) : (
                                <p className={styles.ErrorText}>
                                    {t("screens.tickets.modals.assign_order.service_provider_missing_email").toString()}
                                </p>
                            )}
                        </UI.Col>
                    </UI.Row>
                    <ImageCheckboxGrid />
                    <UI.Row>
                        <UI.Col className="mt-4 mb-4">
                            <div className={styles.BillingDetailsContainer}>
                                <div
                                    className={`${styles.BillingDetailsTitleContainer}  ${
                                        assignOrderModalStore.isEditingBillingData ? "" : styles.Collapsed
                                    }`}
                                    onClick={toggleIsEditingBillingData}
                                >
                                    <div className={styles.BillingDetailsTitle}>
                                        {t("screens.tickets.form.billing.information")}
                                    </div>
                                    <button />
                                </div>
                                <div
                                    className={`${styles.BillingDetailsForm} ${
                                        assignOrderModalStore.isEditingBillingData ? "" : styles.Collapsed
                                    }`}
                                >
                                    <UI.Row>
                                        <UI.Col lg={5}>
                                            <UI.Input
                                                label={t("screens.tickets.form.person.name").toString()}
                                                type="text"
                                                autoComplete="off"
                                                value={assignOrderModalStore.billingData.name ?? ""}
                                                onChange={onChangeBillingData(BILLING_DATA_PROPERTY.NAME)}
                                            />
                                        </UI.Col>
                                        <UI.Col lg={5}>
                                            <UI.Input
                                                label={t("screens.tickets.form.person.street").toString()}
                                                type="text"
                                                autoComplete="off"
                                                value={assignOrderModalStore.billingData.street ?? ""}
                                                onChange={onChangeBillingData(BILLING_DATA_PROPERTY.STREET)}
                                            />
                                        </UI.Col>
                                        <UI.Col lg={2}>
                                            <UI.Input
                                                label={t("screens.tickets.form.person.street_number").toString()}
                                                type="text"
                                                autoComplete="off"
                                                value={assignOrderModalStore.billingData.housenumber ?? ""}
                                                onChange={onChangeBillingData(BILLING_DATA_PROPERTY.HOUSENUMBER)}
                                            />
                                        </UI.Col>
                                    </UI.Row>
                                    <UI.Row>
                                        <UI.Col lg={5}>
                                            <UI.Input
                                                label={t("screens.tickets.form.person.co").toString()}
                                                type="text"
                                                autoComplete="off"
                                                value={assignOrderModalStore.billingData.co ?? ""}
                                                onChange={onChangeBillingData(BILLING_DATA_PROPERTY.CO)}
                                            />
                                        </UI.Col>
                                        <UI.Col lg={3}>
                                            <UI.Input
                                                label={t("screens.tickets.form.person.zip").toString()}
                                                type="text"
                                                autoComplete="off"
                                                value={assignOrderModalStore.billingData.zip ?? ""}
                                                onChange={onChangeBillingData(BILLING_DATA_PROPERTY.ZIP)}
                                            />
                                        </UI.Col>
                                        <UI.Col lg={4}>
                                            <UI.Input
                                                label={t("screens.tickets.form.person.city").toString()}
                                                type="text"
                                                autoComplete="off"
                                                value={assignOrderModalStore.billingData.city ?? ""}
                                                onChange={onChangeBillingData(BILLING_DATA_PROPERTY.CITY)}
                                            />
                                        </UI.Col>
                                    </UI.Row>
                                    <UI.Row>
                                        <UI.Col lg={5}>
                                            <UI.Input
                                                label={t("screens.tickets.form.billing.bill_email").toString()}
                                                type="text"
                                                autoComplete="off"
                                                value={assignOrderModalStore.billingData.email ?? ""}
                                                onChange={onChangeBillingData(BILLING_DATA_PROPERTY.EMAIL)}
                                            />
                                        </UI.Col>
                                    </UI.Row>
                                </div>
                                <div
                                    className={`${styles.BillingDetails} ${
                                        assignOrderModalStore.isEditingBillingData ? "d-none" : ""
                                    }`}
                                >
                                    {"\u00A0\u00A0"}
                                    {`${assignOrderModalStore.billingData.name}, ${t(
                                        "screens.tickets.form.person.co"
                                    )}, ${assignOrderModalStore.billingData.co}...${
                                        assignOrderModalStore.billingData.email
                                            ? `, ${assignOrderModalStore.billingData.email}`
                                            : ""
                                    }`}
                                </div>
                            </div>
                        </UI.Col>
                    </UI.Row>
                    <UI.Row>
                        <UI.Col className="mt-4 mb-4">
                            <div className={styles.ContactsContainer}>
                                <div
                                    className={`${styles.BillingDetailsTitleContainer}  ${
                                        assignOrderModalStore.isContactPersonsCollapsed ? "" : styles.Collapsed
                                    }`}
                                    onClick={toggleContactPersonData}
                                >
                                    <div className={styles.BillingDetailsTitle}>
                                        {t("screens.tickets.form.primarycontact.description")}
                                    </div>
                                    <button />
                                </div>
                                <div
                                    className={`${styles.ContactsForm} ${
                                        assignOrderModalStore.isContactPersonsCollapsed ? "" : styles.Collapsed
                                    }`}
                                >
                                    <div>
                                        <UI.Dropdown
                                            className={styles.ContactDropdown}
                                            id="contactDropdown"
                                            label={contactDropdownLabel}
                                            items={sortedOrderContactDropdownPersons}
                                            onSelect={onSelectDropdown}
                                        />
                                    </div>
                                    {renderContactsForm()}
                                </div>
                                {assignOrderModalStore.isContactPersonsCollapsed ? undefined : (
                                    <div>
                                        {"\u00A0\u00A0"}
                                        {primarycontactDescription}
                                    </div>
                                )}
                            </div>
                        </UI.Col>
                    </UI.Row>
                    <UI.Row className={`mt-2 d-flex ${uiStore.isMobile ? generalStyles.MobileRowButton : ""}`}>
                        <UI.Col>
                            <UI.Button
                                className={`ticket-button mt-2 ${generalStyles.BackButton}`}
                                label={t("labels.goback")}
                                variant={"outline-primary"}
                                onClick={onClickBack}
                            ></UI.Button>
                        </UI.Col>
                        <UI.Col>
                            <UI.Button
                                className={`ticket-button mt-2 ${generalStyles.NextButton}`}
                                label={t("labels.submit")}
                                onClick={onClickSendOrder}
                                disabled={!serviceProviderHasEmailAddress}
                            ></UI.Button>
                        </UI.Col>
                    </UI.Row>
                </>
            ) : undefined}
            {assignOrderModalStore.currentOrderCreationStatus === ORDER_CREATION_STATUS.IN_PROGRESS ? (
                <CreateOrderProgress currentCreateOrderState={ORDER_CREATION_STATUS.IN_PROGRESS} />
            ) : undefined}
            {assignOrderModalStore.currentOrderCreationStatus === ORDER_CREATION_STATUS.SUCCESS ? (
                <CreateOrderProgress currentCreateOrderState={ORDER_CREATION_STATUS.SUCCESS} />
            ) : undefined}
        </>
    );
};

export const ServiceOrderDescription = Sentry.withProfiler(observer(ServiceOrderDescriptionBase));
