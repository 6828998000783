import React, { useContext, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { UI } from "@wwimmo/ui";
import { RootStoreContext } from "src/stores/RootStore";
import { getDate } from "src/utils/Date";
import { GetAssemblyByID_assemblyfiles } from "src/api/generated/GetAssemblyByID";
import { NetworkConfig } from "src/network/NetworkConfig";
import { ColorStyle } from "src/utils/Colors";
import { AddDocumentsModal } from "./add-documents-modal/AddDocumentsModal";
import { useMutation } from "@apollo/client";
import { DELETE_ASSEMBLY_FILE_MANAGER } from "src/api/assembly";
import {
    DeleteAssemblyFileManager,
    DeleteAssemblyFileManagerVariables
} from "src/api/generated/DeleteAssemblyFileManager";
import { MessageType } from "src/components/notifications/Notifier";
import { AssemblyDocumentsListItem } from "./list-item/AssemblyDocumentsListItem";

interface AssemblyDocumentsProps {
    assemblyFiles: GetAssemblyByID_assemblyfiles[];
}

const AssemblyDocumentsBase = (props: AssemblyDocumentsProps) => {
    const { t } = useTranslation();
    const { uiStore, assemblyStore } = useContext(RootStoreContext);

    const { assemblyFiles } = props;

    const printStatusMessage = useCallback(
        (message: string, msgType: MessageType) => {
            uiStore.enqueueSnackbar({
                content: message,
                options: {
                    type: msgType,
                    autoClose: msgType === MessageType.ERROR ? false : 3000
                }
            });
        },
        [uiStore]
    );

    const [deleteAssemblyFileMutation] = useMutation<DeleteAssemblyFileManager, DeleteAssemblyFileManagerVariables>(
        DELETE_ASSEMBLY_FILE_MANAGER,
        {
            onCompleted: () => {
                printStatusMessage(
                    t("screens.realestate.assembly.assembly_edit.remove_file_success"),
                    MessageType.INFO
                );
                assemblyStore.setShowRefetchAssemblyData(true);
            }
        }
    );

    const onDeleteAssemblyFile = useCallback(
        (fileid: string) => async (e: any) => {
            e.preventDefault();
            await deleteAssemblyFileMutation({
                variables: {
                    fileid: fileid
                }
            });
        },
        [deleteAssemblyFileMutation]
    );

    const alreadyAddedAssemblyFileIds: string[] = assemblyFiles.map((assemblyFile) => assemblyFile.fileid);

    const assemblyFileList =
        assemblyFiles.length > 0
            ? assemblyFiles.map((assemblyFile) => {
                  let assemblyFileLink = NetworkConfig.datafileUrl + assemblyFile.fileid;
                  const deleteAssembylFileIcon = (
                      <UI.Icon
                          icon={UI.SVGIcon.Close}
                          color={ColorStyle("black")}
                          size={15}
                          onClick={onDeleteAssemblyFile(assemblyFile.fileid)}
                      />
                  );
                  return (
                      <AssemblyDocumentsListItem
                          key={assemblyFile.fileid}
                          fileDate={getDate(assemblyFile.filedate)}
                          fileId={assemblyFile.fileid}
                          fileName={assemblyFile.name ?? "-"}
                          additionalIcon={deleteAssembylFileIcon}
                          fileLink={assemblyFileLink}
                          isDisplayedInModal={false}
                      />
                  );
              })
            : undefined;

    return (
        <>
            <AddDocumentsModal alreadyAddedAssemblyFileIds={alreadyAddedAssemblyFileIds} />
            {assemblyFileList}
        </>
    );
};

export const AssemblyDocuments = Sentry.withProfiler(observer(AssemblyDocumentsBase));
