import { UI } from "@wwimmo/ui";
import { useContext, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { LOADING_TYPE, TICKET_STATE } from "src/stores/TicketStore";
import styles from "./TakeOverTicketButton.module.css";
import { MessageType } from "src/components/notifications/Notifier";

const TakeOverTicketButtonBase = () => {
    const { ticketStore, authStore, assignTicketModalStore, uiStore } = useContext(RootStoreContext);
    const { t } = useTranslation();

    const onClickTakeOverButton = useCallback(async () => {
        const wasSuccessfullyTakenOver = await assignTicketModalStore.assignTicketToUser(
            authStore.user?.userid ?? "",
            LOADING_TYPE.TAKING_OVER_TICKET
        );

        if (wasSuccessfullyTakenOver) {
            uiStore.printStatusMessage(
                t("screens.tickets.action.assign_ticket.success_take_over"),
                MessageType.SUCCESS
            );
        }
    }, [assignTicketModalStore, authStore.user?.userid, t, uiStore]);

    const takeOverTicketButtonLabel = (
        <div className="d-flex align-items-center position-relative">
            <div>{t("screens.tickets.action.assign_ticket.take_over")}</div>
            <UI.RotatingSpinner
                className={`${styles.ButtonSpinner} ${
                    ticketStore.loadingType !== LOADING_TYPE.TAKING_OVER_TICKET ? "d-none" : ""
                }`}
                noLogo={true}
                size={20}
            />
        </div>
    );

    const displayTakeOverButton = authStore.user?.userid !== ticketStore.currentTicket?.assignedUser?.id;

    return displayTakeOverButton ? (
        <UI.Button
            label={takeOverTicketButtonLabel}
            onClick={onClickTakeOverButton}
            disabled={
                ticketStore.currentTicket?.state === TICKET_STATE.COMPLETED ||
                ticketStore.isEditing ||
                ticketStore.loadingType === LOADING_TYPE.TAKING_OVER_TICKET ||
                ticketStore.currentTicket?.realestate?.id === undefined
            }
            className={"ticket-button mt-2"}
            hidden={ticketStore.currentTicket?.state === TICKET_STATE.ARCHIVED}
        />
    ) : (
        <> </>
    );
};

export const TakeOverTicketButton = Sentry.withProfiler(observer(TakeOverTicketButtonBase));
