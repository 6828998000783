import { UI } from "@wwimmo/ui";
import { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { ColorStyle } from "src/utils/Colors";
import { AssignInvoiceProgress } from "src/screens/creditors/creditor/details/actions/assignInvoice/progress/AssignInvoiceProgress";
import { WORKFLOWUSER_ROLE } from "src/stores/krediflow/InvoiceEnums";
import { ASSIGN_INVOICE_STATE, IUserToAssign } from "src/stores/krediflow/AssignInvoiceStore";
import styles from "./AssignInvoiceModal.module.css";
import { getRoleNameByWorkflowUserRole } from "src/stores/krediflow/Utils";

interface AssignInvoiceModalProps {
    workflowUserRole: WORKFLOWUSER_ROLE;
}

const AssignInvoiceModalBase = (props: AssignInvoiceModalProps) => {
    const { t } = useTranslation();
    const { assignInvoiceStore, invoiceStore } = useContext(RootStoreContext);

    const { workflowUserRole: modalWorkflowUserRole } = props;

    const toggleDisplayAssignInvoiceModal = useCallback(() => {
        const modalIsBeingClosed = assignInvoiceStore.isModalDisplayed;

        assignInvoiceStore.setIsModalDisplayed(!assignInvoiceStore.isModalDisplayed, modalWorkflowUserRole);

        if (modalIsBeingClosed) {
            assignInvoiceStore.resetAssignInvoiceModal();
        }
    }, [assignInvoiceStore, modalWorkflowUserRole]);

    const onChangeAssignUserInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const assignUser = e.target.value;
            assignInvoiceStore.setCurrentAssignUserSearchQuery(assignUser);
        },
        [assignInvoiceStore]
    );

    const onClickAssignUserItem = useCallback(
        (assignUserId: string) => () => {
            assignInvoiceStore.assignInvoiceToWorkflowUser(assignUserId, modalWorkflowUserRole);
        },
        [assignInvoiceStore, modalWorkflowUserRole]
    );

    const getUserToAssignElement = useCallback(
        (userToAssign: IUserToAssign) => {
            const userToAssignWorkflowUsers = invoiceStore.currentInvoice?.workflowUsers?.filter(
                (workflowUser) => workflowUser.user.id === userToAssign.userid
            );

            const userToAssignWorkflowUserRoles = userToAssignWorkflowUsers?.map((workflowUser) => workflowUser.role);

            const userToAssignWorkflowUserRoleNames = userToAssignWorkflowUsers?.map((workflowUser) =>
                getRoleNameByWorkflowUserRole(workflowUser.role)
            );
            const workflowUserRoleNamesString = userToAssignWorkflowUserRoleNames?.join(" / ");

            const currentAssignIsForVisa =
                modalWorkflowUserRole === WORKFLOWUSER_ROLE.VISA_1 ||
                modalWorkflowUserRole === WORKFLOWUSER_ROLE.VISA_2 ||
                modalWorkflowUserRole === WORKFLOWUSER_ROLE.VISA_3;

            const userIsAlreadyAssignedToVisa =
                userToAssignWorkflowUserRoles?.includes(WORKFLOWUSER_ROLE.VISA_1) ||
                userToAssignWorkflowUserRoles?.includes(WORKFLOWUSER_ROLE.VISA_2) ||
                userToAssignWorkflowUserRoles?.includes(WORKFLOWUSER_ROLE.VISA_3);

            let userCanBeAssigned = true;
            if (currentAssignIsForVisa && userIsAlreadyAssignedToVisa) {
                userCanBeAssigned = false;
            }

            return (
                <div
                    key={userToAssign.userid}
                    className={`${styles.AssignUserItem} ${!userCanBeAssigned ? styles.Disabled : ""}`}
                    onClick={userCanBeAssigned ? onClickAssignUserItem(userToAssign.userid) : undefined}
                >
                    <UI.Icon
                        icon={UI.SVGIcon.Person}
                        color={userCanBeAssigned ? ColorStyle("black") : ColorStyle("grey")}
                    />
                    <div className={styles.AssignUserItemName}>{userToAssign.name}</div>
                    <span className="ml-auto">{workflowUserRoleNamesString}</span>
                    <UI.Icon
                        className={!userCanBeAssigned ? "invisible" : ""}
                        icon={UI.SVGIcon.ChevronRight}
                        color={ColorStyle("primary")}
                    />
                </div>
            );
        },
        [onClickAssignUserItem, invoiceStore.currentInvoice?.workflowUsers, modalWorkflowUserRole]
    );

    return (
        <UI.Modal
            title={t("screens.tickets.action.assign_ticket.select_manager").toString()}
            size="lg"
            show={
                assignInvoiceStore.isModalDisplayed &&
                assignInvoiceStore.modalCurrentlyUsedForRole === modalWorkflowUserRole
            }
            onClose={toggleDisplayAssignInvoiceModal}
            centered={true}
        >
            {assignInvoiceStore.assignInvoiceState === ASSIGN_INVOICE_STATE.INIT ? (
                <>
                    <UI.Row className={"mt-2"}>
                        <UI.Col sm={12}>
                            <UI.Input
                                type="text"
                                autoComplete="off"
                                className={styles.FormInput}
                                id="assign-invoice-select-user-input"
                                value={assignInvoiceStore.currentUserToAssignSearchQuery ?? ""}
                                onChange={onChangeAssignUserInput}
                                placeholder={t("labels.search").toString()}
                                iconRight={
                                    <UI.Icon
                                        icon={UI.SVGIcon.Search}
                                        color="grey"
                                        size={"small"}
                                        style={{ right: "5px" }}
                                    />
                                }
                            />
                        </UI.Col>
                    </UI.Row>
                    <UI.Row className={"mt-2"}>
                        <UI.Col sm={12}>
                            <div className={styles.AssignUserContainer}>
                                {assignInvoiceStore.filteredUsersToAssignData.map((user) => {
                                    return getUserToAssignElement(user);
                                })}
                            </div>
                        </UI.Col>
                    </UI.Row>
                </>
            ) : undefined}

            {assignInvoiceStore.assignInvoiceState === ASSIGN_INVOICE_STATE.ASSIGNING ? (
                <AssignInvoiceProgress
                    key={"assign-invoice-progress-assigning"}
                    currentAssignInvoiceState={ASSIGN_INVOICE_STATE.ASSIGNING}
                />
            ) : undefined}
            {assignInvoiceStore.assignInvoiceState === ASSIGN_INVOICE_STATE.FINISHED ? (
                <AssignInvoiceProgress
                    key={"assign-invoice-progress-finished"}
                    currentAssignInvoiceState={ASSIGN_INVOICE_STATE.FINISHED}
                />
            ) : undefined}
        </UI.Modal>
    );
};

export const AssignInvoiceModal = Sentry.withProfiler(observer(AssignInvoiceModalBase));
