import React, { useCallback, useContext, useEffect, useRef } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { Appheader } from "src/components/header/appheader/Appheader";
import { RootStoreContext } from "src/stores/RootStore";
import { Footer } from "src/components/footer/Footer";
import { useHistory } from "react-router-dom";
import { CookieConsentMessage } from "src/screens/cookieConsentMessage/CookieConsentMessage";
import { useSystemHealthCheck } from "src/hooks/system-health-check/useSystemHealthCheck";
import { MainRoutes } from "src/screens/routes/MainRoutes";
import { Sidebar } from "src/components/sidebar/Sidebar";
import { useSidebarFunctions } from "src/hooks/sidebar-functions/useSidebarFunctions";
import styles from "./Main.module.css";
import { FeatureAnnouncementBanner } from "src/components/featureAnnouncementBanner/FeatureAnnouncementBanner";
import { UserRatingModal } from "src/screens/userrating/UserRatingModal";
import { DemoModeBanner } from "src/components/demoModeBanner/DemoModeBanner";

export const MainBase = () => {
    const { uiStore, navStore, authStore } = useContext(RootStoreContext);
    const history = useHistory();
    const mainDivRef = useRef<Element>(null);
    useSystemHealthCheck();
    const { collapseSidebar } = useSidebarFunctions();

    useEffect(() => {
        navStore.addToHistoryStack(history.location.pathname);

        return history.listen((location) => {
            navStore.addToHistoryStack(location.pathname);
        });
    }, [history, navStore]);

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            const mainContentWindowWidthAfterResize = entries[0].contentRect.width;
            uiStore.setContentWidth(mainContentWindowWidthAfterResize);
        });

        const currentRef = mainDivRef.current;

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [uiStore]);

    const closeSidebarIfOnMobile = useCallback(
        (e: any) => {
            if (uiStore.isMobile && uiStore.sidebarWidth > 0 && e.target.id === "SidebarOverlay") {
                collapseSidebar();
            }
        },
        [collapseSidebar, uiStore.isMobile, uiStore.sidebarWidth]
    );

    return (
        <main>
            <div
                className="d-flex"
                id="MainContainer"
                onClick={uiStore.isMobile && uiStore.sidebarWidth > 0 ? closeSidebarIfOnMobile : undefined}
            >
                <Sidebar />

                {uiStore.isMobile && uiStore.sidebarWidth > 0 ? (
                    <div id="SidebarOverlay" className={styles.MobileSidebarOverlay}></div>
                ) : undefined}

                <div
                    id="ContentWindow"
                    className="d-flex flex-column flex-grow-1"
                    style={{
                        minHeight: "100vh",
                        background: uiStore.backgroundColor,
                        marginLeft: uiStore.isMobile ? "0px" : `${uiStore.sidebarWidth}px`,
                        transition: "margin-left 0.3s",
                        width: "100%"
                    }}
                >
                    <Appheader />
                    {authStore.token &&
                    authStore.user?.customerid &&
                    authStore.user?.userid &&
                    !authStore.user?.hasOnlyEcAdminRole ? (
                        <FeatureAnnouncementBanner />
                    ) : null}
                    {authStore.token || !authStore.user?.hasOnlyEcAdminRole ? (
                        <div className={styles.DemoModeBanner}>
                            <DemoModeBanner key={history.location.pathname} />
                        </div>
                    ) : null}

                    <div ref={mainDivRef} id="MainWindow" className="flex-grow-1 d-flex flex-column">
                        <MainRoutes />
                    </div>
                </div>
            </div>
            <CookieConsentMessage />
            <Footer />
            <UserRatingModal />
        </main>
    );
};

export const Main = Sentry.withProfiler(observer(MainBase));
