import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { Route, selectRoute } from "src/config/routes";
import { observer } from "mobx-react-lite";
import { Tiles } from "src/components";
import { useParams } from "react-router";
import { ScreenWidth } from "src/stores/UIStore";
import styles from "./TilesDashboard.module.css";
import { Link } from "react-router-dom";
import { Role } from "src/network/User";
import { DisplayNewsBars } from "src/components/news/displayNewsBars/DisplayNewsBars";
import { GetCoownerNews_realestates_newsitems_newsitem as coownerNewsitems } from "src/api/generated/GetCoownerNews";
import { GetTenantNews_realestates_newsitems_newsitem as tenantNewsitems } from "src/api/generated/GetTenantNews";

interface Params {
    realestateid: string;
}

interface TilesDashboardProps {
    coownerNewsSortedItems: coownerNewsitems[];
    tenantNewsSortedItems: tenantNewsitems[];
}

const TilesDashboardBase = (props: TilesDashboardProps) => {
    const { t } = useTranslation();
    const { navStore, authStore, uiStore } = useContext(RootStoreContext);
    const { realestateid } = useParams<Params>();

    const hasUserTenantRole = authStore.user?.role === Role.TENANT;

    const newsSortedItems =
        hasUserTenantRole && props.tenantNewsSortedItems.length > 0
            ? props.tenantNewsSortedItems
            : authStore.user?.hasCoownerRole && props.coownerNewsSortedItems.length > 0
            ? props.coownerNewsSortedItems
            : [];

    useEffect(() => {
        if (!navStore.singleRealestate) {
            navStore.setBackbutton(
                selectRoute(Route.realestates, authStore.portalRoleWithHighestPriority ?? authStore.user?.role),
                t("screens.realestates.title").toString()
            );
        }
        uiStore.setShowNavigationBar(false);

        return () => {
            uiStore.setShowNavigationBar(true);
        };
    }, [authStore.user, navStore, uiStore, t, authStore.portalRoleWithHighestPriority]);

    return (
        <UI.Container className="pt-4">
            <div className={styles.TitleDashboard}>
                <h1>{`${t("labels.welcome", { username: navStore.customer.userName })}!`}</h1>
            </div>
            <UI.Row>
                <UI.Col xl={{ span: 8, offset: 2 }} md={{ span: 10, offset: 1 }} sm={{ span: 12 }}>
                    <UI.Row className={hasUserTenantRole ? styles.TilesOuterTenant : styles.TilesOuter}>
                        <UI.Col className={styles.TilesInner}>
                            <Link
                                className={styles.Link}
                                style={{ textDecoration: "none" }}
                                to={selectRoute(Route.documents, authStore.user?.role, { realestateid: realestateid })}
                            >
                                <UI.Icon
                                    className={styles.IconsStyles}
                                    icon={UI.SVGIcon.DocumentsDashboard}
                                    size={window.innerWidth >= 600 ? "xl" : 60}
                                />
                                <span className={styles.TilesLabel}>{t("screens.realestate.documents.title")}</span>
                            </Link>
                        </UI.Col>
                        {authStore.user?.role === Role.COOWNER ? (
                            <UI.Col className={styles.TilesInner}>
                                <Link
                                    className={styles.Link}
                                    style={{ textDecoration: "none" }}
                                    to={selectRoute(Route.coownerAssemblies, authStore.user?.role, {
                                        realestateid: realestateid
                                    })}
                                >
                                    <UI.Icon
                                        className={styles.IconsStyles}
                                        icon={UI.SVGIcon.AssemblyDashboard}
                                        size={window.innerWidth >= 600 ? "xl" : 60}
                                    />
                                    <span className={styles.TilesLabel}>
                                        {t("screens.realestate.assemblies.title")}
                                    </span>
                                </Link>
                            </UI.Col>
                        ) : undefined}
                        <UI.Col className={styles.TilesInner}>
                            <Link
                                className={styles.Link}
                                style={{ textDecoration: "none" }}
                                to={selectRoute(
                                    hasUserTenantRole ? Route.tenantAccount : Route.coownerAccount,
                                    authStore.user?.role,
                                    {
                                        realestateid: realestateid
                                    }
                                )}
                            >
                                <UI.Icon
                                    className={styles.IconsStyles}
                                    icon={UI.SVGIcon.MyaccountDashboard}
                                    size={window.innerWidth >= 600 ? "xl" : 60}
                                />
                                <span className={styles.TilesLabel}>{t("screens.realestate.my_account.title")}</span>
                            </Link>
                        </UI.Col>
                        {authStore.user?.hasCoownerAuditorRole ? (
                            <UI.Col className={styles.TilesInner}>
                                <Link
                                    className={styles.Link}
                                    style={{ textDecoration: "none" }}
                                    to={selectRoute(Route.finances, Role.COOWNER, {
                                        realestateid: realestateid
                                    })}
                                >
                                    <UI.Icon
                                        className={styles.IconsStyles}
                                        icon={UI.SVGIcon.FinancesDashboard}
                                        size={window.innerWidth >= 600 ? "xl" : 60}
                                    />
                                    <span className={styles.TilesLabel}>{t("screens.realestate.finances.title")}</span>
                                </Link>
                            </UI.Col>
                        ) : undefined}
                    </UI.Row>
                </UI.Col>
            </UI.Row>

            <UI.Row className="mb-md-4 mb-0">
                <UI.Col xl={9} className="position-relative mb-lg-0 mb-3 px-0">
                    <DisplayNewsBars newsItems={newsSortedItems} />
                </UI.Col>
                {window.innerWidth >= ScreenWidth.XL ? (
                    <UI.Col xl={3}>
                        <Tiles.ContactTile realestateid={realestateid} />
                    </UI.Col>
                ) : (
                    <UI.Col sm={12}>
                        <Tiles.ContactTile realestateid={realestateid} />
                    </UI.Col>
                )}
            </UI.Row>
        </UI.Container>
    );
};

export const TilesDashboard = Sentry.withProfiler(observer(TilesDashboardBase));
