import React, { useRef, useEffect, useContext, useCallback } from "react";
import { UI } from "@wwimmo/ui";
import { ColorStyle } from "src/utils/Colors";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import styles from "./AssemblyAgendaItem.module.css";
import { RootStoreContext } from "src/stores/RootStore";
import { AgendaItem } from "src/types";
import { AssemblyAgendaDocuments } from "src/components/assemblies/agenda/list/AssemblyAgendaDocuments";
import { getLang } from "src/utils/i18n";
import { useMutation } from "@apollo/client";
import {
    InsertAssemblyAgendaitems,
    InsertAssemblyAgendaitemsVariables
} from "src/api/generated/InsertAssemblyAgendaitems";
import { INSERT_ASSEMBLY_AGENDAITEMS } from "src/api/assembly";
import { GetAssemblyByID_agendaitem_parents_agendaitem_children } from "src/api/generated/GetAssemblyByID";
import { useTranslation } from "react-i18next";
import { DraggableProvided } from "react-beautiful-dnd";
export interface AssemblyAgendaListItemProps {
    itemId: string;
    itemIndex: number;
    childItemIndex?: number;
    itemText: string;
    itemDescription: string;
    isNew?: boolean;
    onDeleteAgendaItem: any;
    onChangeAgendaItemText: any;
    onChangeAgendaItemDescription: any;
    onFocusAgendaItemText: any;
    onFocusAgendaItemDescription: any;
    agendaItem: AgendaItem | GetAssemblyByID_agendaitem_parents_agendaitem_children;
    isChildItem: boolean;
    onAddNewChildItem?: any;
    onAddNewParentItem?: any;
    onAddAgendaItemDescription?: any;
    provided?: DraggableProvided;
}

export const AssemblyAgendaItemBase = (props: AssemblyAgendaListItemProps) => {
    const {
        itemIndex,
        childItemIndex,
        itemId,
        itemText,
        itemDescription,
        onDeleteAgendaItem,
        onChangeAgendaItemText,
        onChangeAgendaItemDescription,
        isNew,
        onFocusAgendaItemText,
        onFocusAgendaItemDescription,
        agendaItem,
        isChildItem,
        onAddNewChildItem,
        onAddNewParentItem,
        onAddAgendaItemDescription,
        provided
    } = props;
    const { assemblyStore } = useContext(RootStoreContext);
    const { t } = useTranslation();

    const inputAgendaTextRef = useRef<HTMLInputElement>(null);
    const inputAgendaDescriptionRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (
            (isNew &&
                assemblyStore.focusedAgendaItemIdText === itemId &&
                assemblyStore.focusedAgendaItemIdDescription === "") ||
            (!isNew &&
                assemblyStore.focusedAgendaItemIdText === itemId &&
                assemblyStore.focusedAgendaItemIdDescription === "")
        ) {
            inputAgendaTextRef.current?.focus();
            inputAgendaDescriptionRef.current?.blur();
        } else if (
            (isNew &&
                assemblyStore.focusedAgendaItemIdText === "" &&
                assemblyStore.focusedAgendaItemIdDescription === itemId) ||
            (!isNew &&
                assemblyStore.focusedAgendaItemIdText === "" &&
                assemblyStore.focusedAgendaItemIdDescription === itemId)
        ) {
            inputAgendaTextRef.current?.blur();
            inputAgendaDescriptionRef.current?.focus();
            inputAgendaDescriptionRef.current?.setSelectionRange(
                inputAgendaDescriptionRef.current.value.length,
                inputAgendaDescriptionRef.current.value.length
            );
        }
        // eslint-disable-next-line
    }, []);

    const [insertAssemblyAgendaItemsMutation] = useMutation<
        InsertAssemblyAgendaitems,
        InsertAssemblyAgendaitemsVariables
    >(INSERT_ASSEMBLY_AGENDAITEMS);

    let parentItemIndex: number;
    if (typeof itemIndex === "string") {
        parentItemIndex = Number(itemIndex);
    } else {
        parentItemIndex = itemIndex;
    }
    const counter =
        childItemIndex !== undefined ? `${parentItemIndex + 1}.${childItemIndex + 1}` : `${parentItemIndex + 1}.`;

    const onClickDocumentIcon = useCallback(
        async (e: any) => {
            e.preventDefault();
            const language = getLang();
            assemblyStore.createInsertAssemblyAgendaitems(language);

            await insertAssemblyAgendaItemsMutation({
                variables: {
                    agendaItems: assemblyStore.insertAssemblyAgendaitems,
                    agendaids: assemblyStore.deletedAgendaItemIds
                }
            });

            assemblyStore.setIsAddingDocumentsDisplayed(true);
        },
        [assemblyStore, insertAssemblyAgendaItemsMutation]
    );

    return (
        <>
            <div key={itemId} {...provided?.dragHandleProps}>
                <UI.Row>
                    <UI.Col className={styles.AgendaItemCounter}>{counter.toString()}</UI.Col>
                    <UI.Col className={isChildItem ? styles.ChildAgendaColumnInput : styles.AgendaColumnInput}>
                        <UI.Input
                            className={styles.AgendaInput}
                            value={itemText}
                            onChange={onChangeAgendaItemText(itemIndex, itemId)}
                            ref={inputAgendaTextRef}
                            onFocus={onFocusAgendaItemText(itemId)}
                        />
                        {(itemDescription && itemDescription.length > 0) ||
                        assemblyStore.isNewDescription === itemId ||
                        assemblyStore.focusedAgendaItemIdDescription === agendaItem.id ? (
                            <UI.Input
                                as="textarea"
                                type="text"
                                className={styles.AgendaItemDescription}
                                value={itemDescription}
                                onChange={onChangeAgendaItemDescription(itemIndex, itemId, isChildItem)}
                                placeholder={t("screens.realestate.assembly.new_agendaitem_description").toString()}
                                ref={inputAgendaDescriptionRef}
                                onFocus={onFocusAgendaItemDescription(itemId)}
                            />
                        ) : undefined}

                        {assemblyStore.focusedAgendaItemIdText === agendaItem.id ||
                        assemblyStore.focusedAgendaItemIdDescription === agendaItem.id ||
                        agendaItem.agendaitemfiles?.length > 0 ? (
                            <>
                                {assemblyStore.isAgendaItemTemplate === false ? (
                                    <div className={styles.Dokuments}>
                                        {assemblyStore.focusedAgendaItemIdText === agendaItem.id ||
                                        assemblyStore.focusedAgendaItemIdDescription === agendaItem.id ? (
                                            <div onClick={onClickDocumentIcon} className={styles.PaperclipIcon}>
                                                <UI.Icon icon={UI.SVGIcon.Paperclip} />
                                            </div>
                                        ) : undefined}

                                        <AssemblyAgendaDocuments
                                            agendaItemFiles={agendaItem.agendaitemfiles}
                                            agendaitemId={agendaItem.id}
                                        />
                                    </div>
                                ) : undefined}

                                {assemblyStore.focusedAgendaItemIdText === agendaItem.id ||
                                assemblyStore.focusedAgendaItemIdDescription === agendaItem.id ? (
                                    <UI.Row>
                                        {!isChildItem ? (
                                            <>
                                                <div
                                                    className={styles.AddAgendaItem}
                                                    onClick={onAddNewParentItem(itemIndex)}
                                                >
                                                    <UI.Icon icon={UI.SVGIcon.Add} size={16} style={{ marginTop: 3 }} />
                                                    {t("screens.realestate.assembly.add_new_agendaitem")}
                                                </div>
                                            </>
                                        ) : undefined}
                                        <div
                                            className={styles.AddAgendaItem}
                                            onClick={onAddNewChildItem(childItemIndex, itemIndex)}
                                        >
                                            <UI.Icon icon={UI.SVGIcon.Add} size={16} style={{ marginTop: 3 }} />{" "}
                                            {t("screens.realestate.assembly.add_new_agendaitemchild")}
                                        </div>
                                        {itemDescription.length <= 0 && assemblyStore.isNewDescription !== itemId ? (
                                            <div
                                                className={styles.AddAgendaItem}
                                                onClick={onAddAgendaItemDescription(itemId)}
                                            >
                                                <UI.Icon icon={UI.SVGIcon.Add} size={16} style={{ marginTop: 3 }} />{" "}
                                                {t("screens.realestate.assembly.add_text")}
                                            </div>
                                        ) : undefined}
                                    </UI.Row>
                                ) : undefined}
                            </>
                        ) : undefined}
                    </UI.Col>
                    <UI.Col className={styles.AgendaIcons}>
                        <UI.Icon
                            icon={UI.SVGIcon.Trash}
                            color={ColorStyle("gray")}
                            onClick={onDeleteAgendaItem(agendaItem.id)}
                        />
                    </UI.Col>
                    <UI.Col className={styles.AgendaIcons}>
                        <UI.Icon icon={UI.SVGIcon.MoveDrag} color={ColorStyle("gray")} />
                    </UI.Col>
                </UI.Row>
            </div>
        </>
    );
};

export const AssemblyAgendaItem = Sentry.withProfiler(observer(AssemblyAgendaItemBase));
