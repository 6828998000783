import { gql } from "@apollo/client";

/* FRAGMENTS */

export const REALESTATE_DETAILS_ALL_INVOICES = gql`
    fragment realestate_details_all_invoices on common_realestates {
        id
        name
        number
        consolidation
        houses(limit: 1) {
            zip
            city
        }
    }
`;

export const UNIT_DETAILS = gql`
    fragment unit_details on common_units {
        id
        number
        name
        houseid
    }
`;

export const REALESTATE_DETAILS = gql`
    fragment invoice_realestate_details on common_realestates {
        id
        name
        number
        consolidation
        consolidationid
        houses(limit: 1) {
            zip
            city
        }
        thumbnail: v_realestatefiles(
            where: { type: { _eq: 311 } }
            order_by: { filedate: desc, fileid: desc }
            distinct_on: fileid
            limit: 1
        ) {
            fileid
        }
    }
`;

export const USER_DETAIL = gql`
    fragment invoiceuser_details on ums_users {
        id
        name1
        name2
    }
`;

export const PAYMENTACCOUNT_DETAILS = gql`
    fragment paymentaccount_details on cred_paymentaccounts {
        id
        iban
        name
        type
    }
`;

export const V_TICKET_DETAILS = gql`
    fragment v_ticket_details on cred_v_tickets {
        incidentid
        realestateid
        unitid
        date
        date_long
        number
        title
    }
`;

export const INVOICE_FILES_DETAILS = gql`
    fragment invoicefiles_details on file_v_invoicefiles {
        invoicefileid: id
        fileid
        type
    }
`;

export const V_ORDER_DETAILS = gql`
    ${V_TICKET_DETAILS}
    fragment v_order_details on cred_v_orders {
        orderid
        realestateid
        date
        date_long
        number
        title
        instruction
        contractorid
        name1
        name2
        contractortype
        ticket: incident {
            ...v_ticket_details
        }
    }
`;

export const ACCOUNT_DETAILS = gql`
    fragment account_details on cred_accounts {
        id
        name
        number
        validfrom
        validto
        requirescostcenter
        requiresquantitiy
        unitofmeasure
        extracostdate
    }
`;

export const COSTCENTER_DETAILS = gql`
    fragment costcenter_details on cred_costcenters {
        id
        name
        short
    }
`;

export const V_CREDITOR_DETAILS = gql`
    ${PAYMENTACCOUNT_DETAILS}
    fragment v_creditor_details on cred_v_creditors {
        creditorid
        creditorname
        personid
        email
        gender
        name1
        name2
        phonebusiness
        mobile
        person_origin
        zip
        city
        street
        housenumber
        paymentaccounts {
            ...paymentaccount_details
        }
    }
`;

export const ACCOUNTING_DETAILS = gql`
    ${ACCOUNT_DETAILS}
    ${COSTCENTER_DETAILS}
    ${REALESTATE_DETAILS}
    fragment accounting_details on cred_accountings {
        id
        amount
        date
        text
        quantity
        extracostdate
        realestate {
            ...invoice_realestate_details
        }
        account {
            ...account_details
        }
        costcenter {
            ...costcenter_details
        }
    }
`;

/* HISTORY VIEW */
export const INVOICE_HISTORY_DETAIL = gql`
    fragment invoice_history_detail on cred_v_invoice_history {
        invoiceid
        type
        inserted
        history_username
        old_values
        new_values
        comment_username
        comment_text
        email_subject
        email_body
        email_type
        email_recipients
        email_sender
        entityid
        inboundfiles: v_inboundfiles {
            id: fileid
            name
        }
        failed_outbound_email_recipients: outboundemailrecipients(
            where: { emaillogs: { event: { _nin: ["delivered", "processed", "open"] } } }
        ) {
            id
            emailaddress
            emaillogs(order_by: { timestamp: desc }) {
                id
                event
                message
            }
        }
    }
`;

export const GET_HISTORY_OF_INVOICE = gql`
    ${INVOICE_HISTORY_DETAIL}
    query GetHistoryOfInvoice($invoiceId: uuid) {
        cred_v_invoice_history(where: { invoiceid: { _eq: $invoiceId } }, order_by: { inserted: desc }) {
            ...invoice_history_detail
        }
    }
`;

export const INVOICE_DETAILS_ALL_INVOICES = gql`
    ${REALESTATE_DETAILS_ALL_INVOICES}
    ${USER_DETAIL}
    fragment invoice_details_all_invoices on cred_invoices {
        id
        type
        number
        date
        duedate
        state
        invoicenumber
        amount
        creditor {
            id
            name
            personid
        }
        realestate {
            ...realestate_details_all_invoices
        }
        insertuserinfo {
            ...invoiceuser_details
        }
        workflowusers {
            id
            role
            user {
                ...invoiceuser_details
            }
        }
    }
`;

export const INVOICE_DETAILS = gql`
    ${REALESTATE_DETAILS}
    ${UNIT_DETAILS}
    ${USER_DETAIL}
    ${V_CREDITOR_DETAILS}
    ${PAYMENTACCOUNT_DETAILS}
    ${V_TICKET_DETAILS}
    ${V_ORDER_DETAILS}
    ${INVOICE_FILES_DETAILS}
    ${ACCOUNTING_DETAILS}
    ${INVOICE_HISTORY_DETAIL}
    fragment invoice_details on cred_invoices {
        id
        type
        number
        date
        duedate
        paiddate
        state
        invoicenumber
        amount
        paymentaccountid
        payoutaccountid
        paymentinfo
        workflowinstance
        paymentaccount {
            ...paymentaccount_details
        }
        creditor: v_creditor {
            ...v_creditor_details
        }
        ticket: v_incident {
            ...v_ticket_details
        }
        order: v_order {
            ...v_order_details
        }
        realestate {
            ...invoice_realestate_details
        }
        unit {
            ...unit_details
        }
        insertuserinfo {
            ...invoiceuser_details
        }
        workflowusers(order_by: { role: asc }) {
            id
            role
            signaturestamp
            user {
                ...invoiceuser_details
            }
        }
        invoicefiles: v_invoicefiles {
            ...invoicefiles_details
        }
        accountings(order_by: { sort: asc }) {
            ...accounting_details
        }
        history(order_by: { inserted: desc }) {
            ...invoice_history_detail
        }
    }
`;

/* QUERIES */

export const GET_ALL_INVOICES = gql`
    ${INVOICE_DETAILS_ALL_INVOICES}
    query GetAllInvoices($limit: Int = 99999, $offset: Int = 0) {
        cred_invoices(order_by: { number: desc }, limit: $limit, offset: $offset) {
            ...invoice_details_all_invoices
        }
    }
`;

export const GET_INVOICE_BY_NUMBER = gql`
    ${INVOICE_DETAILS}
    query GetInvoiceByNumber($invoiceNumber: Int) {
        cred_invoices(where: { number: { _eq: $invoiceNumber } }, limit: 1) {
            ...invoice_details
        }
    }
`;

export const GET_INVOICE_NUMBER_BY_ID = gql`
    ${INVOICE_DETAILS}
    query GetInvoiceNumberById($id: uuid!) {
        cred_invoices(where: { id: { _eq: $id } }, limit: 1) {
            id
            number
        }
    }
`;

export const GET_CREDITOR_DATA = gql`
    ${V_CREDITOR_DETAILS}
    query GetCreditorData {
        creditors: cred_v_creditors {
            ...v_creditor_details
        }
    }
`;

export const GET_CREDITORS_WITH_QUERY = gql`
    ${V_CREDITOR_DETAILS}
    query GetCreditorsWithQuery($searchQuery: String) {
        creditors: cred_v_creditors(
            where: {
                _or: [
                    { creditorname: { _ilike: $searchQuery } }
                    { name1: { _ilike: $searchQuery } }
                    { name2: { _ilike: $searchQuery } }
                    { mobile: { _ilike: $searchQuery } }
                    { phonebusiness: { _ilike: $searchQuery } }
                    { email: { _ilike: $searchQuery } }
                    { city: { _ilike: $searchQuery } }
                    { zip: { _ilike: $searchQuery } }
                    { street: { _ilike: $searchQuery } }
                ]
            }
        ) {
            ...v_creditor_details
        }
    }
`;

export const GET_TICKET_DATA = gql`
    ${V_TICKET_DETAILS}
    query GetTicketData {
        tickets: cred_v_tickets(order_by: { number: desc }) {
            ...v_ticket_details
        }
    }
`;

export const GET_TICKET_DATA_BY_REALESTATEID = gql`
    ${V_TICKET_DETAILS}
    query GetTicketDataByRealestateId($realestateid: uuid) {
        tickets: cred_v_tickets(order_by: { number: desc }, where: { realestateid: { _eq: $realestateid } }) {
            ...v_ticket_details
        }
    }
`;

export const GET_TICKETS_WITH_QUERY = gql`
    ${V_TICKET_DETAILS}
    query GetTicketsWithQuery($searchQuery: String) {
        tickets: cred_v_tickets(
            where: {
                _or: [
                    { date: { _ilike: $searchQuery } }
                    { date_long: { _ilike: $searchQuery } }
                    { number: { _ilike: $searchQuery } }
                    { title: { _ilike: $searchQuery } }
                ]
            }
        ) {
            ...v_ticket_details
        }
    }
`;

export const GET_ORDER_DATA = gql`
    ${V_ORDER_DETAILS}
    query GetOrderData {
        orders: cred_v_orders(order_by: { number: desc }) {
            ...v_order_details
        }
    }
`;

export const GET_ORDER_DATA_BY_REALESTATEID = gql`
    ${V_ORDER_DETAILS}
    query GetOrderDataByRealestateId($realestateid: uuid) {
        orders: cred_v_orders(order_by: { number: desc }, where: { realestateid: { _eq: $realestateid } }) {
            ...v_order_details
        }
    }
`;

export const GET_ORDER_DATA_BY_INCIDENT_ID = gql`
    ${V_ORDER_DETAILS}
    query GetOrderDataByIncidentId($incidentid: uuid) {
        orders: cred_v_orders(order_by: { number: desc }, where: { incidentid: { _eq: $incidentid } }) {
            ...v_order_details
        }
    }
`;

export const GET_ORDER_DATA_BY_CREDITOR_ID = gql`
    ${V_ORDER_DETAILS}
    query GetOrderDataByCreditorId($creditorid: uuid) {
        orders: cred_v_orders(order_by: { number: desc }, where: { contractorid: { _eq: $creditorid } }) {
            ...v_order_details
        }
    }
`;

export const GET_ORDERS_WITH_QUERY = gql`
    ${V_ORDER_DETAILS}
    query GetOrdersWithQuery($searchQuery: String) {
        orders: cred_v_orders(
            where: {
                _or: [
                    { date: { _ilike: $searchQuery } }
                    { date_long: { _ilike: $searchQuery } }
                    { number: { _ilike: $searchQuery } }
                    { instruction: { _ilike: $searchQuery } }
                    { name1: { _ilike: $searchQuery } }
                ]
            }
        ) {
            ...v_order_details
        }
    }
`;

export const GET_ACTIVE_MANAGER_USERS = gql`
    query GetActiveManagerUsers {
        ums_v_customer_users(
            where: {
                activated: { _eq: true }
                system: { _eq: false }
                blocked: { _eq: false }
                roles: { role: { _eq: 30 } }
            }
        ) {
            userid
            name
        }
    }
`;

export const GET_ACCOUNT_DATA = gql`
    ${ACCOUNT_DETAILS}
    query GetAccountData($realestateid: uuid, $bookingdate: date) {
        accounts: cred_accounts(
            where: {
                realestateid: { _eq: $realestateid }
                validfrom: { _lte: $bookingdate }
                _or: [{ validto: { _gte: $bookingdate } }, { validto: { _is_null: true } }]
            }
            order_by: { type: asc, number: asc, name: asc }
        ) {
            ...account_details
        }
    }
`;

export const GET_COSTCENTER_DATA = gql`
    ${COSTCENTER_DETAILS}
    query GetCostCenterData($accountid: uuid) {
        accountcostcenters: cred_accountcostcenters(
            where: { accountid: { _eq: $accountid } }
            order_by: { costcenter: { short: asc, name: asc } }
        ) {
            costcenter {
                ...costcenter_details
            }
        }
    }
`;

/* MUTATIONS */

export const UPSERT_INVOICE = gql`
    mutation UpsertInvoice($invoice: cred_invoices_insert_input!) {
        upserted_invoice_data: insert_cred_invoices_one(
            object: $invoice
            on_conflict: {
                constraint: PK_invoices
                update_columns: [
                    amount
                    creditorid
                    creditorinfo
                    date
                    duedate
                    incidentid
                    insertuserid
                    invoiceinfo
                    invoicenumber
                    orderid
                    origin
                    paiddate
                    paymentaccountid
                    paymentinfo
                    payoutaccountid
                    realestateid
                    type
                    unitid
                    workflowinstance
                ]
            }
        ) {
            id
        }
    }
`;

export const UPSERT_ACCOUNTINGS = gql`
    mutation UpsertAccountings($accountingObjects: [cred_accountings_insert_input!]!) {
        upserted_accounting_data: insert_cred_accountings(
            objects: $accountingObjects
            on_conflict: {
                constraint: PK_accountings
                update_columns: [
                    amount
                    date
                    text
                    accountid
                    costcenterid
                    realestateid
                    extracostdate
                    quantity
                    unitofmeasure
                ]
            }
        ) {
            returning {
                id
            }
        }
    }
`;
export const DELETE_ACCOUNTING = gql`
    mutation DeleteAccounting($accountingid: uuid!) {
        deleted_accouting: delete_cred_accountings(where: { id: { _eq: $accountingid } }) {
            returning {
                id
            }
        }
    }
`;

export const INSERT_WORKFLOW_USER = gql`
    mutation InsertWorkflowUser($workflowuser: cred_workflowusers_insert_input!) {
        insert_cred_workflowusers_one(object: $workflowuser) {
            id
            role
            signaturestamp
            user {
                id
                name1
                name2
            }
        }
    }
`;

export const UPDATE_USER_ID_FOR_WORKFLOW_USER = gql`
    mutation UpdateUserIdForWorkflowUser($invoiceid: uuid!, $role: Int!, $userid: uuid!) {
        update_cred_workflowusers(
            where: { invoiceid: { _eq: $invoiceid }, role: { _eq: $role } }
            _set: { userid: $userid }
        ) {
            affected_rows
            returning {
                user {
                    id
                    name1
                    name2
                }
            }
        }
    }
`;

export const UPSERT_INVOICE_COMMENT = gql`
    mutation UpsertInvoiceComment($comment: cred_invoicecomments_insert_input!) {
        insert_cred_invoicecomments_one(
            object: $comment
            on_conflict: { constraint: PK_invoicecomments, update_columns: [language, text] }
        ) {
            id
        }
    }
`;

/* SUBSCRIPTIONS */

export const SUBSCRIBE_TO_INVOICE_WORKFLOWUSER_UPDATES = gql`
    ${USER_DETAIL}
    subscription SubscribeToInvoiceWorkflowUserUpdates($invoiceid: uuid!) {
        workflowusers: cred_workflowusers(where: { invoiceid: { _eq: $invoiceid } }, order_by: { role: asc }) {
            id
            role
            signaturestamp
            user {
                ...invoiceuser_details
            }
        }
    }
`;

export const SUBSCRIBE_TO_INVOICE_STATE_UPDATE = gql`
    subscription SubscribeToInvoiceStateUpdate($invoiceid: uuid!) {
        invoice: cred_invoices(where: { id: { _eq: $invoiceid } }) {
            id
            state
        }
    }
`;
