import React, { useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { RootStoreContext } from "src/stores/RootStore";
import { Loading } from "src/screens";
import { Redirect } from "react-router-dom";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useApolloClient } from "@apollo/client";

enum LOGOUTSTATES {
    PENDING = 0,
    ACTIVE = 1,
    DONE = 2
}

const LogoutSilentBase = () => {
    const { navStore, uiStore, authStore } = useContext(RootStoreContext);
    const [logout, setLogout] = useState<LOGOUTSTATES>(LOGOUTSTATES.PENDING);
    const [redirect, setRedirect] = useState(false);
    const apollo = useApolloClient();

    useEffect(() => {
        runInAction(() => {
            navStore.setBackbutton(null);
        });
    }, [navStore]);

    useEffect(() => {
        if (logout === LOGOUTSTATES.PENDING) {
            setLogout(LOGOUTSTATES.ACTIVE);
            authStore.authClient
                .logout()
                .then(() => apollo.clearStore())
                .then(() => navStore.reset())
                .then(() => uiStore.reset())
                .then(() => setLogout(LOGOUTSTATES.DONE));
        }
        if (logout === LOGOUTSTATES.DONE) {
            uiStore.hideSidebar();
            setRedirect(true);
        }
    }, [logout, authStore.authClient, apollo, navStore, uiStore]);

    if (redirect) {
        return <Redirect to="/" />;
    }

    return <Loading fullscreen />;
};

export const LogoutSilent = Sentry.withProfiler(observer(LogoutSilentBase));
