import React, { useState, useCallback } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import {
    GetAssemblySelectionFilesManager,
    GetAssemblySelectionFilesManagerVariables
} from "src/api/generated/GetAssemblySelectionFilesManager";
import * as Screens from "src/screens";
import { useTranslation } from "react-i18next";
import { UI } from "@wwimmo/ui";
import { GET_ASSEMBLY_SELECTION_FILES_MANAGER } from "src/api/assembly";
import { AddDocumentsModalList } from "./list/AddDocumentsModalList";
import { getDate } from "src/utils/Date";
import styles from "./AddDocumentsModal.module.css";

interface Params {
    realestateid: string;
}

export interface AssemblyAddDocumentsListFile {
    fileId?: string;
    fileName?: string;
    fileDate?: string;
    fileType?: number;
}

interface AddDocumentsModalProps {
    alreadyAddedAssemblyFileIds: string[];
}

const AddDocumentsModalBase = (props: AddDocumentsModalProps) => {
    const { t } = useTranslation();
    const { realestateid } = useParams<Params>();

    const { alreadyAddedAssemblyFileIds } = props;

    const [isAddingDocumentsDisplayed, setIsAddingDocumentsDisplayed] = useState<boolean>(false);

    const { data, loading, error } = useQuery<
        GetAssemblySelectionFilesManager,
        GetAssemblySelectionFilesManagerVariables
    >(GET_ASSEMBLY_SELECTION_FILES_MANAGER, {
        variables: {
            realestateid: realestateid
        }
    });

    const onClickAddDocuments = useCallback(async (e: any) => {
        e.preventDefault();
        setIsAddingDocumentsDisplayed(true);
    }, []);

    const onCloseAddDocumentsModal = useCallback(() => {
        setIsAddingDocumentsDisplayed(false);
    }, []);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata").toString()} />;
    }

    const fileItems: AssemblyAddDocumentsListFile[] = [];

    data.accountlistfiles.forEach((file) => {
        const fileToAdd: AssemblyAddDocumentsListFile = {
            fileId: file.fileid,
            fileName: file.name ?? "",
            fileDate: getDate(file.filedate),
            fileType: file.type ?? 0
        };

        if (!alreadyAddedAssemblyFileIds.includes(fileToAdd.fileId ?? "")) {
            fileItems.push(fileToAdd);
        }
    });

    data.realestatefiles.forEach((file) => {
        const fileToAdd: AssemblyAddDocumentsListFile = {
            fileId: file.fileid,
            fileName: file.name ?? "",
            fileDate: getDate(file.filedate),
            fileType: file.type ?? 0
        };

        if (!alreadyAddedAssemblyFileIds.includes(fileToAdd.fileId ?? "")) {
            fileItems.push(fileToAdd);
        }
    });

    return (
        <div className={`${styles.AssemblyAddDocumentsModalContainer}`}>
            <UI.Button onClick={onClickAddDocuments} label={"+"} />
            <UI.Modal
                show={isAddingDocumentsDisplayed}
                onClose={onCloseAddDocumentsModal}
                isScrollable={true}
                className={styles.AddDocumentsModal}
            >
                <AddDocumentsModalList files={fileItems} />
                <div className="text-center">
                    <UI.Button
                        className={`${styles.AddDocumentsModalButton} mt-3`}
                        label={t("labels.cancel")}
                        onClick={onCloseAddDocumentsModal}
                        variant="link"
                    />
                </div>
            </UI.Modal>
        </div>
    );
};

export const AddDocumentsModal = Sentry.withProfiler(observer(AddDocumentsModalBase));
