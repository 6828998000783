import { observable, action, makeObservable, computed } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { apolloClientInstance } from "src/network/apolloClientInstance";
import { ICreditor } from "./InvoiceTypes";
import { GET_CREDITORS_WITH_QUERY, GET_CREDITOR_DATA } from "src/api/cred";
import {
    GetCreditorsWithQuery,
    GetCreditorsWithQueryVariables,
    GetCreditorsWithQuery_creditors
} from "src/api/generated/GetCreditorsWithQuery";
import { GetCreditorData, GetCreditorData_creditors } from "src/api/generated/GetCreditorData";

export class CreditorSearchStore {
    rootStore: RootStore;

    creditorData: ICreditor[] = [];
    currentCreditorSearchQuery: string = "";
    selectedCreditorQueryString: string | undefined = undefined;
    isSearchingCreditorData: boolean = false;

    triggerFocusOnCreditorInput: boolean = false;
    isSearchingCreditorsInBackendByQuery: boolean = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            creditorData: observable,
            triggerFocusOnCreditorInput: observable,
            currentCreditorSearchQuery: observable,
            selectedCreditorQueryString: observable,
            isSearchingCreditorData: observable,
            isSearchingCreditorsInBackendByQuery: observable,

            searchCreditorsInBackendByQuery: action,
            setCurrentCreditorSearchQuery: action,
            setSelectedCreditorQueryString: action,
            setTriggerFocusOnCreditorInput: action,
            setCreditorData: action,
            setIsSearchingCreditorData: action,
            setIsSearchingCreditorsInBackendByQuery: action,
            init: action,
            loadCreditorData: action,

            filteredCreditorData: computed
        });
    }

    init = async () => {
        await this.loadCreditorData();
    };

    loadCreditorData = async () => {
        const { data }: { data: GetCreditorData } = await apolloClientInstance.query<GetCreditorData>({
            query: GET_CREDITOR_DATA
        });

        const creditorData: ICreditor[] = this.getMappedAndSortedCreditorData(data.creditors);

        if (creditorData.length > 1000) {
            this.setIsSearchingCreditorsInBackendByQuery(true);
        }

        this.setCreditorData(creditorData);
    };

    getMappedAndSortedCreditorData = (
        creditors: GetCreditorsWithQuery_creditors[] | GetCreditorData_creditors[]
    ): ICreditor[] => {
        const creditorData: ICreditor[] = creditors.map((creditor) => {
            return {
                id: creditor.creditorid,
                name: creditor.creditorname ?? "",
                personId: creditor.personid ?? undefined,
                name1: creditor.name1 ?? undefined,
                name2: creditor.name2 ?? undefined,
                mobile: creditor.mobile ?? undefined,
                phonebusiness: creditor.phonebusiness ?? undefined,
                email: creditor.email ?? undefined,
                gender: creditor.gender ?? undefined,
                street: creditor.street ?? undefined,
                housenumber: creditor.housenumber ?? undefined,
                zip: creditor.zip ?? undefined,
                city: creditor.city ?? undefined,
                origin: creditor.person_origin ?? undefined,
                paymentAccounts: creditor.paymentaccounts ?? []
            };
        });

        // Sorting creditors by name
        creditorData.sort((creditorA, creditorB) => {
            if (creditorA.name === "" && creditorB.name !== "") {
                return 1;
            }

            if (creditorA.name !== "" && creditorB.name === "") {
                return -1;
            }

            const creditorAName = creditorA.name ?? "";
            const creditorBName2 = creditorB.name2 ?? "";

            if (creditorAName < creditorBName2) {
                return -1;
            }

            if (creditorAName > creditorBName2) {
                return 1;
            }

            return 0;
        });

        return creditorData;
    };

    searchCreditorsInBackendByQuery = async (searchQuery: string) => {
        this.setIsSearchingCreditorData(true);

        const { data }: { data: GetCreditorsWithQuery } = await apolloClientInstance.query<
            GetCreditorsWithQuery,
            GetCreditorsWithQueryVariables
        >({
            query: GET_CREDITORS_WITH_QUERY,
            variables: {
                searchQuery: `%${searchQuery}%`
            }
        });

        const creditorData: ICreditor[] = this.getMappedAndSortedCreditorData(data.creditors);

        this.setCreditorData(creditorData);

        this.setIsSearchingCreditorData(false);
    };

    get filteredCreditorData(): ICreditor[] {
        let filteredCreditorData: ICreditor[] = [...this.creditorData];

        const filteredCreditorDataWithoutDuplicates =
            filteredCreditorData.length < 1000
                ? filteredCreditorData.filter((creditor, index, self) => {
                      return index === self.findIndex((c) => c.personId === creditor.personId);
                  })
                : filteredCreditorData;

        if (!this.isSearchingCreditorsInBackendByQuery) {
            const searchQuery = this.currentCreditorSearchQuery ?? "";

            const filteredCreditorDataBySearchQuery = filteredCreditorDataWithoutDuplicates.filter((creditor) => {
                const creditorName = `${creditor.name1} ${creditor.name2}`.trim();
                const zipCity = `${creditor.zip} ${creditor.city}`.trim();
                return (
                    creditorName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    creditor.street?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    zipCity.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    creditor.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    creditor.mobile?.toLowerCase().includes(searchQuery.toLowerCase())
                );
            });

            return filteredCreditorDataBySearchQuery;
        } else {
            return filteredCreditorDataWithoutDuplicates;
        }
    }

    // Setters
    setIsSearchingCreditorData = (isSearchingCreditorData: boolean) => {
        this.isSearchingCreditorData = isSearchingCreditorData;
    };

    setCurrentCreditorSearchQuery = (currentCreditorSearchQuery: string) => {
        this.currentCreditorSearchQuery = currentCreditorSearchQuery;
    };

    setSelectedCreditorQueryString = (selectedCreditorQueryString: string | undefined) => {
        this.selectedCreditorQueryString = selectedCreditorQueryString;
    };

    setTriggerFocusOnCreditorInput = (triggerFocusOnCreditorInput: boolean) => {
        this.triggerFocusOnCreditorInput = triggerFocusOnCreditorInput;
    };

    setCreditorData = (creditorData: ICreditor[]) => {
        this.creditorData = creditorData;
    };

    setIsSearchingCreditorsInBackendByQuery = (isSearchingCreditorsInBackendByQuery: boolean) => {
        this.isSearchingCreditorsInBackendByQuery = isSearchingCreditorsInBackendByQuery;
    };
}
