import React, { useContext } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { AssignInvoiceToWorkflowUser } from "src/screens/creditors/creditor/details/actions/assignInvoice/AssignInvoiceToWorkflowUser";
import { LOADING_TYPE, RUNNING_SUBSCRIPTION_TPYE, WORKFLOWUSER_ROLE } from "src/stores/krediflow/InvoiceEnums";
import styles from "./InvoiceCard.module.css";

const InvoiceWorkflowCardBase = () => {
    const { t } = useTranslation();

    const { navStore, invoiceStore } = useContext(RootStoreContext);

    const numberOfVisas =
        navStore.customer.settings && navStore.customer.settings.cred && navStore.customer.settings.cred.numberOfVisas
            ? navStore.customer.settings.cred.numberOfVisas
            : 0;

    return (
        <>
            <UI.Card title={t("screens.tickets.workflow.title").toString()} flex>
                {invoiceStore.loadingType === LOADING_TYPE.INITIALIZING ||
                invoiceStore.runningSubscriptionType === RUNNING_SUBSCRIPTION_TPYE.WORKFLOW_USERS ? (
                    <div className={styles.LoadingContainer}>
                        <UI.RotatingSpinner noLogo size={40} className={styles.LoadingTicketSpinner} />
                        <div className="mt-3">{t("screens.kredi_flow.loading_workflow_users")}</div>
                    </div>
                ) : (
                    <>
                        {Array.from({ length: numberOfVisas }).map((_, index) => {
                            let workflowUserRole = WORKFLOWUSER_ROLE.VISA_1;

                            const visaNumber = index + 1;

                            switch (visaNumber) {
                                case 1:
                                    workflowUserRole = WORKFLOWUSER_ROLE.VISA_1;
                                    break;
                                case 2:
                                    workflowUserRole = WORKFLOWUSER_ROLE.VISA_2;
                                    break;
                                case 3:
                                    workflowUserRole = WORKFLOWUSER_ROLE.VISA_3;
                                    break;
                            }

                            return (
                                <AssignInvoiceToWorkflowUser
                                    key={workflowUserRole.toString()}
                                    workflowUserRole={workflowUserRole}
                                />
                            );
                        })}
                        <AssignInvoiceToWorkflowUser
                            key={WORKFLOWUSER_ROLE.ACCOUNTANT.toString()}
                            workflowUserRole={WORKFLOWUSER_ROLE.ACCOUNTANT}
                        />
                    </>
                )}
            </UI.Card>
        </>
    );
};

export const InvoiceWorkflowCard = Sentry.withProfiler(observer(InvoiceWorkflowCardBase));
