import { UI } from "@wwimmo/ui";
import { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { DISPLAYED_MODAL_TYPE } from "src/stores/krediflow/AccountingStore";
import styles from "./RealestateChangedWarningModal.module.css";

const RealestateChangedWarningModalBase = () => {
    const { t } = useTranslation();
    const { accountingStore, invoiceStore } = useContext(RootStoreContext);

    const toggleDisplayModal = useCallback(() => {
        const modalIsBeingClosed = accountingStore.displayedModalType !== DISPLAYED_MODAL_TYPE.NONE;

        if (modalIsBeingClosed) {
            accountingStore.setDisplayedModalType(DISPLAYED_MODAL_TYPE.NONE);
        } else {
            accountingStore.setDisplayedModalType(DISPLAYED_MODAL_TYPE.REALESTATE_CHANGED_WARNING);
        }
    }, [accountingStore]);

    const onClickApprove = useCallback(() => {
        invoiceStore.resetAccountingFormFields();
        invoiceStore.currentInvoice?.resetAccountings(true);

        if (accountingStore.realestateThatTriggeredWarning) {
            invoiceStore.updateAccountingWithSelectedRealestate(accountingStore.realestateThatTriggeredWarning, false);
        }

        accountingStore.setDisplayedModalType(DISPLAYED_MODAL_TYPE.NONE);
    }, [invoiceStore, accountingStore]);

    const onClickDecline = useCallback(() => {
        accountingStore.setDisplayedModalType(DISPLAYED_MODAL_TYPE.NONE);
    }, [accountingStore]);

    return (
        <UI.Modal
            title={t(
                "screens.kredi_flow.accountings.accounting.change_mandant_with_selected_account_warning_title"
            ).toString()}
            size="lg"
            show={accountingStore.displayedModalType === DISPLAYED_MODAL_TYPE.REALESTATE_CHANGED_WARNING}
            onClose={toggleDisplayModal}
            centered={true}
        >
            <UI.Row className={"mt-2"}>
                <UI.Col sm={12}>
                    <div className="mt-2 mb-4">
                        {t("screens.kredi_flow.accountings.accounting.change_mandant_with_selected_account_warning")}
                    </div>
                </UI.Col>
            </UI.Row>
            <UI.Row>
                <UI.Col>
                    <UI.Button
                        label={t("labels.no").toString()}
                        className={`mb-2 default-button-height ${styles.ModalButton}`}
                        onClick={onClickDecline}
                    />
                </UI.Col>
                <UI.Col>
                    <UI.Button
                        label={t("labels.yes").toString()}
                        className={`mb-2 default-button-height ml-auto ${styles.ModalButton}`}
                        onClick={onClickApprove}
                    />
                </UI.Col>
            </UI.Row>
        </UI.Modal>
    );
};

export const RealestateChangedWarningModal = Sentry.withProfiler(observer(RealestateChangedWarningModalBase));
