import { UI } from "@wwimmo/ui";
import { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import styles from "./RestartInvoiceModal.module.css";
import {
    RESTART_CANCEL_INVOICE_STATE,
    RESTART_CANCEL_MODAL_TYPE
} from "src/stores/krediflow/RestartCancelInvoiceStore";
import { RestartCancelInvoiceProgress } from "src/screens/creditors/creditor/details/actions/restartInvoice/progress/RestartCancelInvoiceProgress";

const RestartInvoiceModalBase = () => {
    const { t } = useTranslation();
    const { restartCancelInvoiceStore } = useContext(RootStoreContext);

    const toggleDisplayAssignInvoiceModal = useCallback(() => {
        const modalIsBeingClosed = restartCancelInvoiceStore.isModalDisplayed;

        restartCancelInvoiceStore.setIsModalDisplayed(!restartCancelInvoiceStore.isModalDisplayed);

        if (modalIsBeingClosed) {
            restartCancelInvoiceStore.reset();
        }
    }, [restartCancelInvoiceStore]);

    const onClickRestart = useCallback(() => {
        restartCancelInvoiceStore.restartInvoice();
    }, [restartCancelInvoiceStore]);

    return (
        <UI.Modal
            title={t("screens.kredi_flow.action.restart").toString()}
            size="lg"
            show={
                restartCancelInvoiceStore.isModalDisplayed &&
                restartCancelInvoiceStore.displayedModalType === RESTART_CANCEL_MODAL_TYPE.RESTART
            }
            onClose={toggleDisplayAssignInvoiceModal}
            centered={true}
        >
            {restartCancelInvoiceStore.restartCancelInvoiceState === RESTART_CANCEL_INVOICE_STATE.INIT ? (
                <>
                    <UI.Row className={"mt-2"}>
                        <UI.Col sm={12}>
                            <div className="mt-2 mb-4">
                                {t("screens.kredi_flow.action.restart_invoice.confirm_question")}
                            </div>
                        </UI.Col>
                    </UI.Row>
                    <UI.Row>
                        <UI.Col>
                            <UI.Button
                                label={t("labels.cancel").toString()}
                                className={`mb-2 default-button-height ${styles.CancelButton}`}
                                onClick={toggleDisplayAssignInvoiceModal}
                            />
                        </UI.Col>
                        <UI.Col>
                            <UI.Button
                                label={t("screens.kredi_flow.action.restart").toString()}
                                className={`mb-2 default-button-height ml-auto ${styles.SubmitButton}`}
                                onClick={onClickRestart}
                            />
                        </UI.Col>
                    </UI.Row>
                </>
            ) : undefined}

            {restartCancelInvoiceStore.restartCancelInvoiceState === RESTART_CANCEL_INVOICE_STATE.RESTARTING ||
            restartCancelInvoiceStore.restartCancelInvoiceState === RESTART_CANCEL_INVOICE_STATE.CANCELLING ||
            restartCancelInvoiceStore.restartCancelInvoiceState === RESTART_CANCEL_INVOICE_STATE.FINISHED ? (
                <RestartCancelInvoiceProgress
                    key={"restart-cancel-invoice-progress-assigning"}
                    currentRestartInvoiceState={restartCancelInvoiceStore.restartCancelInvoiceState}
                />
            ) : undefined}
        </UI.Modal>
    );
};

export const RestartInvoiceModal = Sentry.withProfiler(observer(RestartInvoiceModalBase));
