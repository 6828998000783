import React, { useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { InvoiceHistoryComment } from "./historyComment/InvoiceHistoryComment";
import { InvoiceHistoryTimeline } from "./historyTimeline/InvoiceHistoryTimeline";
import { useParams } from "react-router";
import styles from "./InvoiceHistory.module.css";
import { Route, getRoute } from "src/config/routes";

const InvoiceHistoryBase = () => {
    const { t } = useTranslation();
    const { invoiceStore, navStore, authStore } = useContext(RootStoreContext);
    const { invoicenumber } = useParams<{ invoicenumber: string }>();

    useEffect(() => {
        async function initializeInvoiceData() {
            await invoiceStore.init(invoicenumber);
        }
        if (invoiceStore.currentInvoice === undefined) {
            initializeInvoiceData();
        }

        const role = authStore.user?.role;
        const currentPage = role ? getRoute(window.location.pathname, role) : undefined;

        if (currentPage === Route.invoiceHistory) {
            navStore.setTitle("");
        }

        // only run one time. will only run if invoiceHistory component is openend by itself
        // eslint-disable-next-line
    }, []);

    return invoiceStore.currentInvoice?.isNewInvoice ? (
        <></>
    ) : (
        <div className={styles.InvoiceHistoryContainer}>
            <div className={`${styles.InvoiceHistoryTitle} mb-3`}>{t("screens.kredi_flow.history.section_title")}</div>
            {/* History Comment Section */}
            <InvoiceHistoryComment />
            {/* History Entries Section */}
            <InvoiceHistoryTimeline />
        </div>
    );
};

export const InvoiceHistory = Sentry.withProfiler(observer(InvoiceHistoryBase));
