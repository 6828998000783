import React, { useCallback, useContext } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import styles from "./RestartCancelInvoiceProgress.module.css";
import {
    RESTART_CANCEL_INVOICE_STATE,
    RESTART_CANCEL_MODAL_TYPE
} from "src/stores/krediflow/RestartCancelInvoiceStore";

interface RestartCancelInvoiceProgressProps {
    currentRestartInvoiceState: RESTART_CANCEL_INVOICE_STATE;
}

export const RestartCancelInvoiceProgressBase = (props: RestartCancelInvoiceProgressProps) => {
    const { t } = useTranslation();
    const { restartCancelInvoiceStore } = useContext(RootStoreContext);

    const { currentRestartInvoiceState: currentAssignInvoiceState } = props;

    const onClickFinishRestartOrCancelInvoice = useCallback(() => {
        restartCancelInvoiceStore.setIsModalDisplayed(false);
        restartCancelInvoiceStore.reset();
    }, [restartCancelInvoiceStore]);

    let icon;
    let text;

    switch (currentAssignInvoiceState) {
        case RESTART_CANCEL_INVOICE_STATE.RESTARTING:
            icon = <UI.RotatingSpinner noLogo size={50} className="loading-items-spinner" />;
            text = t("screens.kredi_flow.action.restart_invoice.in_progress").toString();
            break;
        case RESTART_CANCEL_INVOICE_STATE.CANCELLING:
            icon = <UI.RotatingSpinner noLogo size={50} className="loading-items-spinner" />;
            text = t("screens.kredi_flow.action.cancel_invoice.in_progress").toString();
            break;
        case RESTART_CANCEL_INVOICE_STATE.FINISHED:
            icon = <UI.Icon icon={UI.SVGIcon.Check} size={50} color={"black"} />;
            text =
                restartCancelInvoiceStore.displayedModalType === RESTART_CANCEL_MODAL_TYPE.RESTART
                    ? t("screens.kredi_flow.action.restart_invoice.success").toString()
                    : t("screens.kredi_flow.action.cancel_invoice.success").toString();
            break;
    }

    return (
        <UI.Row className={"mt-2 d-flex"}>
            <UI.Col
                xs={12}
                className={`d-flex align-items-center justify-content-center ${styles.AssignInvoiceProgressContainer}`}
            >
                <div className="d-flex align-items-center flex-column">
                    <div>{icon}</div>
                    <div className="d-flex mt-2">
                        <span className={`${styles.AssignInvoiceProgressText} text-center`}>{text}</span>
                    </div>
                </div>
            </UI.Col>
            <UI.Col xs={12} className="mt-4">
                <UI.Button
                    className={`mt-2 ${styles.FinishButton}`}
                    label={t("labels.finished").toString()}
                    disabled={
                        currentAssignInvoiceState === RESTART_CANCEL_INVOICE_STATE.RESTARTING ||
                        currentAssignInvoiceState === RESTART_CANCEL_INVOICE_STATE.CANCELLING
                            ? true
                            : false
                    }
                    onClick={onClickFinishRestartOrCancelInvoice}
                ></UI.Button>
            </UI.Col>
        </UI.Row>
    );
};

export const RestartCancelInvoiceProgress = Sentry.withProfiler(observer(RestartCancelInvoiceProgressBase));
