import { gql } from "@apollo/client";

export enum AssemblyState {
    Editing = 10,
    Published = 20
}

export enum ASSEMBLY_AUDIT_EVENT {
    VIEWED = 10
}

const ASSEMBLY_FRAGMENTS = gql`
    fragment assembly_basic_details on cond_assemblys {
        id
        date
        state
        street
        housenumber
        zip
        city
        title
        locationdetail
        publishdate
        motiondeadline
        summaryassemblyfiles: v_assemblyfiles_aggregate(where: { type: { _neq: 314 } }) {
            aggregate {
                count
            }
        }
    }
`;

export const GET_ASSEMBLY_BY_REALESTATEID = gql`
    ${ASSEMBLY_FRAGMENTS}
    query GetAssemblyByRealestateid($realestateid: uuid!) {
        assemblys: cond_assemblys(
            order_by: { date: desc }
            where: { realestate: { id: { _eq: $realestateid } }, state: { _eq: 1 } }
            limit: 1
        ) {
            ...assembly_basic_details
        }
    }
`;

export const GET_ASSEMBLY_BY_REALESTATEID_IT2 = gql`
    ${ASSEMBLY_FRAGMENTS}
    query GetAssemblyByRealestateidIT2($realestateid: uuid!) {
        assemblys: cond_assemblys(
            order_by: { date: desc }
            where: {
                realestate: { _or: [{ id: { _eq: $realestateid } }, { consolidationid: { _eq: $realestateid } }] }
                state: { _eq: 1 }
            }
            limit: 1
        ) {
            ...assembly_basic_details
        }
    }
`;

export const GET_ASSEMBLIES_BY_REALESTATEID = gql`
    ${ASSEMBLY_FRAGMENTS}
    query GetAssembliesByRealestateid($realestateid: uuid!, $language: String = "de") {
        assemblyitems: cond_assemblys(order_by: { date: desc }, where: { realestateid: { _eq: $realestateid } }) {
            ...assembly_basic_details
        }

        assemblystates: common_enumerations(
            where: { type: { _eq: 401 }, language: { _eq: $language } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }
    }
`;

export const GET_ASSEMBLIES_BY_ID = gql`
    query GetAssemblyByID($realestateid: uuid!, $id: uuid!, $language: String = "de") {
        assemblyitems: cond_assemblys(
            order_by: { date: desc }
            where: {
                _or: [
                    { realestate: { id: { _eq: $realestateid } } }
                    { realestate: { consolidationid: { _eq: $realestateid } } }
                ]
                id: { _eq: $id }
            }
        ) {
            id
            date
            state
            street
            housenumber
            zip
            city
            title
            locationdetail
            publishdate
            motiondeadline
            assemblyevents(order_by: { inserted: desc }, limit: 1) {
                inserted
            }
            count_coowners: coowners_aggregate {
                aggregate {
                    count
                }
            }
        }

        assemblystates: common_enumerations(
            where: { type: { _eq: 401 }, language: { _eq: $language } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }

        assemblyfiles: file_v_assemblyfiles(
            where: { assemblyid: { _eq: $id }, _and: { type: { _neq: 314 } } }
            distinct_on: fileid
        ) {
            id
            fileid
            mimetype
            name
            filedate
        }

        agendaitem_parents: cond_agendaitems(
            where: { assemblyid: { _eq: $id }, parentagendaitemid: { _is_null: true } }
            order_by: { sort: asc }
        ) {
            id
            sort
            agendaitems_mls(where: { language: { _eq: $language } }) {
                language
                id
                text
                description
            }
            agendaitemfiles: v_agendaitemfiles {
                id
                fileid
                mimetype
                name
                filedate
            }
            agendaitem_children: agendaitems {
                id
                sort
                parentagendaitemid
                agendaitems_mls(where: { language: { _eq: $language } }) {
                    language
                    id
                    text
                    description
                }
                agendaitemfiles: v_agendaitemfiles {
                    id
                    fileid
                    mimetype
                    name
                    filedate
                }
            }
        }
    }
`;

export const SUBCRIPTION_GET_LAST_INSERTED_ASSEMBLY_EVENT = gql`
    subscription GetLastAssemblyEventById($assemblyid: uuid!) {
        noti_assemblyevents(order_by: { inserted: desc }, limit: 1, where: { assemblyid: { _eq: $assemblyid } }) {
            inserted
        }
    }
`;

export const GET_ASSEMBLY_SELECTION_FILES_MANAGER = gql`
    query GetAssemblySelectionFilesManager($realestateid: uuid!) {
        accountlistfiles: file_v_accountlistfiles(
            where: { _or: [{ realestateid: { _eq: $realestateid } }, { consolidationid: { _eq: $realestateid } }] }
            order_by: [{ filedate: desc }, { fileid: desc }]
            distinct_on: [filedate, fileid]
        ) {
            id
            type
            fileid
            name
            mimetype
            filedate
        }
        realestatefiles: file_v_realestatefiles(
            where: { _or: [{ realestateid: { _eq: $realestateid } }, { consolidationid: { _eq: $realestateid } }] }
            order_by: [{ filedate: desc }, { fileid: desc }]
            distinct_on: [filedate, fileid]
        ) {
            id
            type
            fileid
            name
            mimetype
            filedate
        }
    }
`;

export const GET_ASSEMBLY_SELECTION_FILES_MANAGER_IT2 = gql`
    query GetAssemblySelectionFilesManagerIT2($realestateid: uuid!, $consolidationid: uuid!) {
        accountlistfiles: file_v_accountlistfiles(
            where: {
                _or: [
                    { realestateid: { _eq: $realestateid } }
                    { consolidationid: { _eq: $realestateid } }
                    { realestateid: { _eq: $consolidationid } }
                ]
            }
            order_by: [{ filedate: desc }, { fileid: desc }]
            distinct_on: [filedate, fileid]
        ) {
            id
            type
            fileid
            name
            mimetype
            filedate
        }
        realestatefiles: file_v_realestatefiles(
            where: {
                _or: [
                    { realestateid: { _eq: $realestateid } }
                    { consolidationid: { _eq: $realestateid } }
                    { realestateid: { _eq: $consolidationid } }
                ]
            }
            order_by: [{ filedate: desc }, { fileid: desc }]
            distinct_on: [filedate, fileid]
        ) {
            id
            type
            fileid
            name
            mimetype
            filedate
        }
    }
`;

export const UPSERT_ASSEMBLIES = gql`
    mutation UpsertAssembly($objects: [cond_assemblys_insert_input!]!) {
        insert_cond_assemblys(
            objects: $objects
            on_conflict: {
                constraint: PK_assemblys
                update_columns: [
                    realestateid
                    date
                    street
                    housenumber
                    zip
                    city
                    remarks
                    state
                    title
                    locationdetail
                    motiondeadline
                ]
            }
        ) {
            returning {
                id
                realestateid
            }
        }
    }
`;

export const UPDATE_ASSEMBLY_FOR_PDF = gql`
    mutation UpdateAssemblyForPDF($assemblyid: uuid!, $state: Int!) {
        update_cond_assemblys(where: { id: { _eq: $assemblyid } }, _set: { state: $state }) {
            affected_rows
        }
    }
`;

export const INSERT_ASSEMBLY_FILE_MANAGER = gql`
    mutation InsertAssemblyFileManager($objects: [file_assemblyfiles_insert_input!]!) {
        insertedfiles: insert_file_assemblyfiles(objects: $objects) {
            returning {
                id
                fileid
            }
        }
    }
`;

export const DELETE_ASSEMBLY_FILE_MANAGER = gql`
    mutation DeleteAssemblyFileManager($fileid: uuid!) {
        deletedfiles: delete_file_assemblyfiles(where: { fileid: { _eq: $fileid } }) {
            returning {
                fileid
            }
        }
    }
`;

export const DELETE_ASSEMBLY_BY_ID = gql`
    mutation DeleteAssemblyById($assemblyid: uuid!) {
        delete_cond_assemblys(where: { id: { _eq: $assemblyid } }) {
            affected_rows
        }
    }
`;

export const INSERT_AGENDAITEMS_FILE_MANAGER = gql`
    mutation InsertAgendaItemsFileManager($objects: [file_agendaitemfiles_insert_input!]!) {
        insertedfiles: insert_file_agendaitemfiles(objects: $objects) {
            returning {
                id
                fileid
            }
        }
    }
`;

export const DELETE_AGENDAITEM_FILE_MANAGER = gql`
    mutation DeleteAgendaItemFileManager($fileid: uuid!, $agendaitemid: uuid!) {
        deletedfiles: delete_file_agendaitemfiles(
            where: { fileid: { _eq: $fileid }, _and: { agendaitemid: { _eq: $agendaitemid } } }
        ) {
            returning {
                fileid
            }
        }
    }
`;

export const INSERT_ASSEMBLY_AGENDAITEMS = gql`
    mutation InsertAssemblyAgendaitems($agendaItems: [cond_agendaitems_insert_input!]!, $agendaids: [uuid!]) {
        delete_cond_agendaitems(where: { id: { _in: $agendaids } }) {
            affected_rows
        }

        insert_cond_agendaitems(
            objects: $agendaItems
            on_conflict: { constraint: PK_agendaitems, update_columns: [sort, parentagendaitemid] }
        ) {
            returning {
                id
                assemblyid
                agendaitems_mls {
                    text
                    id
                    description
                }
            }
        }
    }
`;

export const UPSERT_ASSEMBLY_USER_AUDITS = gql`
    mutation UpsertAssemblyUserAudits($objects: [cond_assemblyuseraudits_insert_input!]!) {
        insert_cond_assemblyuseraudits(
            objects: $objects
            on_conflict: {
                constraint: assemblyuseraudits_customerid_userid_assemblyid
                update_columns: [event, timestamp]
            }
        ) {
            returning {
                assemblyid
                userid
                event
                timestamp
            }
        }
    }
`;

export const GET_ASSEMBLY_NOTIFICATION_STATE_BY_ASSEMBLYID = gql`
    query AssemblyEventState($assemblyid: uuid!) {
        AssemblyNotificationState: noti_v_notification_assembly_state(where: { assemblyid: { _eq: $assemblyid } }) {
            name
            email
            datetime
            state
        }
    }
`;

export const GET_AGENDAITEMS_INFORMATION_TO_COPY = gql`
    query GetAgendaitemInformationToCopy($assemblyid: uuid!, $realestateid: uuid!, $assemblydate: timestamp) {
        agendaitemtexts_by_assembly: cond_assemblys(
            where: {
                id: { _neq: $assemblyid }
                _and: { realestateid: { _eq: $realestateid }, _and: { date: { _lt: $assemblydate } } }
            }
            order_by: { date: desc }
            limit: 1
        ) {
            agendaitemtexts(where: { parentagendaitemid: { _is_null: true } }, order_by: { sort: asc }) {
                id
                sort
                text
                description
                subagendaitemtext(order_by: { sort: asc }) {
                    id
                    sort
                    text
                    description
                }
            }
            agendaitemsCounter: agendaitemtexts_aggregate {
                aggregate {
                    count
                }
            }
        }

        agendaitemtemplatetexts: cond_v_getagendaitemtemplatetexts(
            where: { parentagendaitemtemplateid: { _is_null: true } }
            order_by: { sort: asc }
        ) {
            id
            sort
            text
            description
            subagendaitemtemplatetext(order_by: { sort: asc }) {
                id
                sort
                text
                description
            }
        }
        agendaitemtemplatesCounter: cond_v_getagendaitemtemplatetexts_aggregate(
            where: { parentagendaitemtemplateid: { _is_null: true } }
        ) {
            aggregate {
                count
            }
        }
    }
`;
