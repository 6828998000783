import React, { useCallback, useContext, useRef } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";

interface IQuantityFieldProps {
    accountingRowIndex: number;
}

const ExtraCostDateFieldBase = (props: IQuantityFieldProps) => {
    const { t } = useTranslation();
    const { invoiceStore } = useContext(RootStoreContext);

    const { accountingRowIndex } = props;

    const extraCostDateInputRef = useRef<HTMLInputElement>(null);

    const onChangeExtraCostDate = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const inputExtraCostDate = e.target.value;

            invoiceStore.updateAccountingWithExtraCostDate(inputExtraCostDate, accountingRowIndex);

            invoiceStore.currentInvoice?.validateAccountingsOnFieldLevel();
        },
        [invoiceStore, accountingRowIndex]
    );

    return (
        <div className={invoiceStore.currentInvoice?.canEditExtraCostDateField(accountingRowIndex) ? "" : "d-none"}>
            <UI.Input
                ref={extraCostDateInputRef}
                label={`${t("screens.kredi_flow.accountings.extracostdate").toString()} *`}
                type="date"
                autoComplete="off"
                value={invoiceStore.currentInvoice?.getAccountingExtraCostDate(accountingRowIndex) ?? ""}
                onChange={onChangeExtraCostDate}
            />
        </div>
    );
};

export const ExtraCostDateField = Sentry.withProfiler(observer(ExtraCostDateFieldBase));
