import { gql } from "@apollo/client";

export const GET_COMMON_REALESTATE_UNITS = gql`
    query CommonRealestateUnits($realestateid: uuid!, $lang: String) {
        realestates: common_realestates(where: { id: { _eq: $realestateid } }) {
            id
            houses(order_by: { number: asc }) {
                id
                street
                housenumber
                units(order_by: { number: asc }) {
                    id
                    name
                    number
                    space
                    utilization
                    valuequota
                    tenancys_today {
                        leasestart
                        leaseend
                        tenantname
                        netrent
                        vacancy
                        rentsqmyear
                        grossrent
                    }
                    futuretenancies: tenancys(
                        where: { leasestart: { _gt: "now()" } }
                        order_by: { leasestart: asc_nulls_last }
                        limit: 2
                    ) {
                        leasestart
                        leaseend
                        vacancy
                    }
                }
            }
        }

        enumerations: common_enumerations(
            where: { type: { _eq: 13 }, language: { _eq: $lang } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }
    }
`;

export const GET_COMMON_UNIT_BY_ID = gql`
    query CommonUnitById($unitId: uuid!, $lang: String = "de") {
        tenancypersonroles: common_enumerations(
            where: { type: { _eq: 11 }, language: { _eq: $lang } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }

        utilizations: common_enumerations(
            where: { type: { _eq: 13 }, language: { _eq: $lang } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }

        category: common_enumerations(where: { type: { _eq: 17 }, language: { _eq: $lang } }, order_by: { key: asc }) {
            id
            key
            label
        }

        rentalstates: common_enumerations(
            where: { type: { _eq: 701 }, key: { _in: [30, 40, 50, 60] }, language: { _eq: $lang } }
            order_by: { key: asc }
        ) {
            id
            key
            label
        }

        unit: common_units(where: { id: { _eq: $unitId } }) {
            ewid
            floor
            id
            name
            number
            otoid
            category
            realestate {
                id
                condominium
            }
            rooms
            space
            utilization
            valuequota
            tenancys_today {
                accountbalance
                consumerpriceindex
                costincreaseindex
                flatratefee
                grossrent
                interestrateindex
                leaseend
                leasestart
                maturities
                minimumrentdate
                netrent
                noticemonths
                noticeperiodowner
                noticeperiodtenant
                paymentonaccount
                rentincreasereserveamount
                rentincreasereservetext
                tenancyid
                tenantid
                tenantpersonid
                tenantname
                vacancy
                validfrom
                vat
                vattaxed
                rentsqmyear
                tenancypersons {
                    role
                    person {
                        id
                        name1
                        name2
                    }
                }
                rentalsInTenancys {
                    id
                    state
                    rentaldate
                }
                rentalsOutTenancys {
                    id
                    state
                    rentaldate
                }
            }
            futuretenancies: tenancys(
                where: { leasestart: { _gt: "now()" } }
                order_by: { leasestart: asc_nulls_last }
            ) {
                id
                leasestart
                leaseend
                vacancy
                tenant {
                    accountbalance
                    person {
                        name1
                        name2
                    }
                }
                rentalsInTenancys {
                    id
                    state
                    rentaldate
                }
                rentalsOutTenancys {
                    id
                    state
                    rentaldate
                }
            }

            unitfiles: v_unitfiles(distinct_on: fileid) {
                id
                fileid
                filedate
                name
            }
        }
    }
`;

export const GET_UNITNAME_BY_ID = gql`
    query GetUnitNameById($unitid: uuid!) {
        common_units(where: { id: { _eq: $unitid } }) {
            name
        }
    }
`;
