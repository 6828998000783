import React, { useContext } from "react";
import * as Sentry from "@sentry/react";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import styles from "./InvoiceHistoryTimeline.module.css";
import { InvoiceHistoryTimelineEntry } from "./entry/InvoiceHistoryTimelineEntry";

const InvoiceHistoryTimelineBase = () => {
    const { invoiceStore } = useContext(RootStoreContext);

    return invoiceStore.currentInvoice?.history && invoiceStore.currentInvoice.history?.length > 0 ? (
        <div className={styles.TimelineContainer}>
            {invoiceStore.currentInvoice.history.map((historyEntry, index) => {
                return <InvoiceHistoryTimelineEntry key={index} historyEntry={historyEntry} />;
            })}
        </div>
    ) : (
        <></>
    );
};

export const InvoiceHistoryTimeline = Sentry.withProfiler(observer(InvoiceHistoryTimelineBase));
