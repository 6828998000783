import { action, makeObservable, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { IRealestate } from "./InvoiceTypes";
import { IRealestateData } from "src/stores/RealestateSearchStore";
import { ErpType } from "src/network/User";

export class AccountingRealestateSearchStore {
    rootStore: RootStore;

    erpType: ErpType = ErpType.IT2;

    // This is an array because every Accounting Row has its own realestate value
    currentRealestateSearchQuery: string[] = [];
    selectedRealestateQueryString: string[] = [];
    selectedRealestateName: string[] = [];

    triggerFocusNextAccountingAccountInput: { isActive: boolean; index: number } = { isActive: false, index: 0 };

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            currentRealestateSearchQuery: observable,
            selectedRealestateQueryString: observable,
            selectedRealestateName: observable,
            triggerFocusNextAccountingAccountInput: observable,

            reset: action,
            setCurrentRealestateSearchQuery: action,
            setSelectedRealestateQueryString: action,
            setSelectedRealestateName: action,
            setTriggerFocusNextAccountingAccountInput: action,
            filteredRealestateData: action
        });
    }

    reset = () => {
        this.currentRealestateSearchQuery = [];
        this.selectedRealestateQueryString = [];
        this.selectedRealestateName = [];
    };

    // Getters
    getFormattedRealestateFieldValue = (realestate: IRealestate): string => {
        return `${realestate.name} [${realestate.number}]`;
    };

    getCurrentRealestateSearchQuery(accountingRowIndex?: number) {
        const erpType = this.rootStore.authStore.user?.erpType;

        if (erpType === ErpType.IT2) {
            return this.currentRealestateSearchQuery[0];
        } else if (erpType === ErpType.RIMO && accountingRowIndex !== undefined) {
            return this.currentRealestateSearchQuery[accountingRowIndex];
        }
    }

    getSelectedRealestateQueryString(accountingRowIndex?: number) {
        const erpType = this.rootStore.authStore.user?.erpType;

        if (erpType === ErpType.IT2) {
            return this.selectedRealestateQueryString[0];
        } else if (erpType === ErpType.RIMO && accountingRowIndex !== undefined) {
            return this.selectedRealestateQueryString[accountingRowIndex];
        }
    }

    getSelectedRealestateName(accountingRowIndex?: number) {
        const erpType = this.rootStore.authStore.user?.erpType;

        if (erpType === ErpType.IT2) {
            return this.selectedRealestateName[0];
        } else if (erpType === ErpType.RIMO && accountingRowIndex !== undefined) {
            return this.selectedRealestateName[accountingRowIndex];
        }
    }

    // Setters
    setCurrentRealestateSearchQuery(currentRealestateSearchQuery: string, accountingRowIndex?: number) {
        const erpType = this.rootStore.authStore.user?.erpType;

        if (erpType === ErpType.IT2) {
            this.currentRealestateSearchQuery[0] = currentRealestateSearchQuery;
        } else if (erpType === ErpType.RIMO && accountingRowIndex !== undefined) {
            this.currentRealestateSearchQuery[accountingRowIndex] = currentRealestateSearchQuery;
        }
    }

    setSelectedRealestateQueryString(selectedRealestateQueryString: string, accountingRowIndex?: number) {
        const erpType = this.rootStore.authStore.user?.erpType;

        if (erpType === ErpType.IT2) {
            this.selectedRealestateQueryString[0] = selectedRealestateQueryString;
        } else if (erpType === ErpType.RIMO && accountingRowIndex !== undefined) {
            this.selectedRealestateQueryString[accountingRowIndex] = selectedRealestateQueryString;
        }
    }

    setSelectedRealestateName(selectedRealestateName: string, accountingRowIndex?: number) {
        const erpType = this.rootStore.authStore.user?.erpType;

        if (erpType === ErpType.IT2) {
            this.selectedRealestateName[0] = selectedRealestateName;
        } else if (erpType === ErpType.RIMO && accountingRowIndex !== undefined) {
            this.selectedRealestateName[accountingRowIndex] = selectedRealestateName;
        }
    }

    /*  This function triggers the focus on the next Account field after selecting a Realestate
        For IT2 this will always be the Account field in the first accounting row
        For RIMO this will be the Account field in the current accounting row
    */
    setTriggerFocusNextAccountingAccountInput(
        triggerFocusNextAccountingAccountInput: boolean,
        accountingRowIndex?: number
    ) {
        const erpType = this.rootStore.authStore.user?.erpType;

        if (erpType === ErpType.IT2) {
            this.triggerFocusNextAccountingAccountInput = {
                isActive: triggerFocusNextAccountingAccountInput,
                index: 0
            };
        }

        if (erpType === ErpType.RIMO && accountingRowIndex !== undefined) {
            this.triggerFocusNextAccountingAccountInput = {
                isActive: triggerFocusNextAccountingAccountInput,
                index: accountingRowIndex
            };
        }
    }

    filteredRealestateData(accountingRowIndex?: number): IRealestateData[] {
        const erpType = this.rootStore.authStore.user?.erpType;

        const realestateDataByErp =
            erpType === ErpType.RIMO
                ? this.rootStore.realestateSearchStore.realestateData
                : erpType === ErpType.IT2
                ? this.rootStore.realestateSearchStore.realestateData.filter(
                      (realestate) => realestate.consolidation === true
                  )
                : [];

        let currentRealestateSearchQuery = "";

        if (erpType === ErpType.IT2) {
            currentRealestateSearchQuery = this.currentRealestateSearchQuery[0] ?? "";
        } else if (erpType === ErpType.RIMO && accountingRowIndex !== undefined) {
            currentRealestateSearchQuery = this.currentRealestateSearchQuery[accountingRowIndex] ?? "";
        }

        let selectedRealestateQueryString = "";

        if (erpType === ErpType.IT2) {
            selectedRealestateQueryString = this.selectedRealestateQueryString[0] ?? "";
        } else if (erpType === ErpType.RIMO && accountingRowIndex !== undefined) {
            selectedRealestateQueryString = this.selectedRealestateQueryString[accountingRowIndex] ?? "";
        }

        if (currentRealestateSearchQuery === selectedRealestateQueryString) {
            return realestateDataByErp;
        }

        if (currentRealestateSearchQuery === "") {
            return realestateDataByErp;
        }

        const filteredRealestateData = realestateDataByErp.filter((realestate) => {
            const realestateName = `${realestate.nameZipCity} [${realestate.number}]`;

            return realestateName.toLowerCase().includes(currentRealestateSearchQuery.toLowerCase());
        });

        return filteredRealestateData;
    }
}
