import React, { useCallback, useContext } from "react";
import "src/components/contact/Contact.css";
import { ContactItem, ContactItemRow } from "src/components/contact/contactitem/ContactItem";
import { useQuery } from "@apollo/client";
import {
    GetContactInformationByRealestateId,
    GetContactInformationByRealestateIdVariables
} from "src/api/generated/GetContactInformationByRealestateId";
import { GET_CONTACT_INFORMATION_BY_REALESTATEID } from "src/api/contact";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { submitUserFeedback } from "src/utils/Feedback";
import { RootStoreContext } from "src/stores/RootStore";
import { Role } from "src/network/User";
import { NetworkConfig } from "src/network/NetworkConfig";
import { Route, selectRoute } from "src/config/routes";
import { useLocation } from "react-router-dom";

export enum ContactItemType {
    PHONE,
    MAIL,
    DEVICE
}

export enum Group {
    REALESTATE,
    FACILITYMANAGEMENT
}
interface ContactProps {
    realestateid: string;
}

export const Contact = (props: ContactProps) => {
    const { t } = useTranslation();
    const { navStore, authStore } = useContext(RootStoreContext);
    const location = useLocation();

    const handleSubmitFeedback = useCallback(() => {
        submitUserFeedback(navStore.customer);
    }, [navStore.customer]);

    const { loading, error, data } = useQuery<
        GetContactInformationByRealestateId,
        GetContactInformationByRealestateIdVariables
    >(GET_CONTACT_INFORMATION_BY_REALESTATEID, {
        variables: { realestateid: props.realestateid }
    });

    if (loading) {
        return <UI.LoadingCard />;
    }
    if (!(data && data.common_realestatecontacts) || error) {
        return <UI.ErrorCard onSubmitFeedback={handleSubmitFeedback} message={t("error.nodata").toString()} />;
    }
    if (!(data.common_realestatecontacts.length > 0)) {
        return <UI.Card>{t("error.emptydata")}</UI.Card>;
    }

    const portalContact = data.common_realestatecontacts[0];

    const contentRealestate: Array<ContactItemRow> = [
        { type: ContactItemType.PHONE, content: portalContact.managerphone },
        { type: ContactItemType.DEVICE, content: portalContact.managermobile },
        { type: ContactItemType.MAIL, content: portalContact.manageremail }
    ];
    const contentFacilityManagemant: Array<{ type: ContactItemType; content: string | null }> = [
        { type: ContactItemType.PHONE, content: portalContact.facilityphone },
        { type: ContactItemType.DEVICE, content: portalContact.facilitymobile },
        { type: ContactItemType.MAIL, content: portalContact.facilityemail }
    ];
    const contentAccountant: Array<{ type: ContactItemType; content: string | null }> = [
        { type: ContactItemType.PHONE, content: portalContact.accountantphone },
        { type: ContactItemType.DEVICE, content: portalContact.managermobile },
        { type: ContactItemType.MAIL, content: portalContact.accountantemail }
    ];

    let easyContactUrl = undefined;

    if (navStore.hasFeatureEasyContact) {
        if (authStore.user?.role === Role.MANAGER || authStore.user?.role === Role.OWNER) {
            if (data.house.length > 0 && data.house[0].id) {
                const houseId = data.house[0].id;
                const houseToken = btoa(`H=${houseId}`);
                easyContactUrl = `${NetworkConfig.proxy}/ec/start?t=${houseToken}`;
            }
        } else if (authStore.user?.role === Role.COOWNER) {
            if (data.coownertentant.length > 0 && data.coownertentant[0].tenantid) {
                const tenantId = data.coownertentant[0].tenantid;
                const tenantToken = btoa(`T=${tenantId}`);
                easyContactUrl = `${NetworkConfig.proxy}/ec/start?t=${tenantToken}`;
            }
        } else if (authStore.user?.role === Role.TENANT) {
            if (data.tentant.length > 0 && data.tentant[0].tenantid) {
                const tenantId = data.tentant[0].tenantid;
                const tenantToken = btoa(`T=${tenantId}`);
                easyContactUrl = `${NetworkConfig.proxy}/ec/start?t=${tenantToken}`;
            }
        }
        if (easyContactUrl === undefined) {
            easyContactUrl = `${NetworkConfig.proxy}/ec`;
        }
    }

    return (
        <UI.Row className="ContactTile d-flex flex-column flex-md-row flex-xl-column">
            {easyContactUrl ? (
                <UI.Col md={12}>
                    <a href={easyContactUrl} className="link d-flex" rel="noopener noreferrer" target="_blank">
                        <UI.Button className="ContactButton mb-3" label="Online kontaktieren" />
                    </a>
                </UI.Col>
            ) : undefined}
            {!authStore.user?.hasAuditorRole ? (
                <>
                    {portalContact.managername ? (
                        <UI.Col>
                            <div className="ContactRealestate">
                                <div className="ContactTitle">
                                    {location.pathname.includes(
                                        selectRoute(Route.news, authStore.user?.role, {
                                            realestateid: props.realestateid
                                        })
                                    ) || authStore.user?.role === Role.TENANT ? (
                                        <>
                                            {t("screens.contact.partner")} {t("screens.contact.management")}
                                        </>
                                    ) : (
                                        <>
                                            {t("screens.contact.partner")} {t("screens.contact.management_accountant")}
                                        </>
                                    )}
                                </div>
                                <ContactItem name={portalContact.managername} contacts={contentRealestate} />
                            </div>
                        </UI.Col>
                    ) : undefined}
                    {portalContact.facilityname ? (
                        <UI.Col>
                            <div>
                                <div className="ContactTitle">
                                    {t("screens.contact.partner")} {t("screens.contact.facility")}
                                </div>
                                <ContactItem name={portalContact.facilityname} contacts={contentFacilityManagemant} />
                            </div>
                        </UI.Col>
                    ) : undefined}
                </>
            ) : (
                <>
                    {portalContact.accountantname ? (
                        <UI.Col>
                            <div>
                                <div className="ContactRealestate">
                                    <div className="ContactTitle">
                                        {t("screens.contact.partner")} {t("screens.contact.accountant")}
                                    </div>
                                    <ContactItem name={portalContact.accountantname} contacts={contentAccountant} />
                                </div>
                            </div>
                        </UI.Col>
                    ) : undefined}

                    {portalContact.managername ? (
                        <UI.Col>
                            <div className="ContactTitle">
                                {t("screens.contact.partner")} {t("screens.contact.management_accountant")}
                            </div>
                            <ContactItem name={portalContact.managername} contacts={contentRealestate} />
                        </UI.Col>
                    ) : undefined}
                </>
            )}
        </UI.Row>
    );
};
