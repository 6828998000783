import { gql } from "@apollo/client";

export const GET_COMMON_REALESTATE_BY_ID = gql`
    query CommonRealestateById($realestateid: uuid!) {
        common_realestates(where: { id: { _eq: $realestateid } }) {
            id
            condominium
            consolidation
            consolidationid
            name
        }

        common_realestates_aggregate {
            aggregate {
                count
            }
        }
    }
`;

export const GET_DASHBOARD_REALESTATE_INFO = gql`
    query GetDashboardRealestateInfo($realestateid: uuid!, $today: timestamp!) {
        realestate_info: common_realestates(where: { id: { _eq: $realestateid } }) {
            consolidation
            condominium
            name
            number
            houses(order_by: { number: asc }, limit: 1) {
                street
                housenumber
                zip
                city
                egid
            }
            constructionyear: houses_aggregate {
                aggregate {
                    min {
                        constructionyear
                    }
                }
            }
            lastrenovation: houses_aggregate {
                aggregate {
                    max {
                        lastrenovation
                    }
                }
            }
            owner {
                person {
                    id
                    legal
                    name1
                    name2
                    current_address: addresses(
                        order_by: { validfrom: desc }
                        limit: 1
                        where: { validfrom: { _lte: $today } }
                    ) {
                        city
                        zip
                    }
                }
            }
        }
    }
`;

export const GET_REALESTATENAME_BY_ID = gql`
    query GetRealestateNameById($realestateid: uuid!) {
        common_realestates(where: { id: { _eq: $realestateid } }) {
            name
        }
    }
`;
