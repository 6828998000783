import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useSelectionListKeyHandler } from "src/hooks/ticket/selection-list-key-handler/useSelectionListKeyHandler";
import { boldenMatchedSubstring } from "src/utils/Common";
import { IAccount } from "src/stores/krediflow/InvoiceTypes";
import { ErpType } from "src/network/User";
import { LOADING_ACCOUNT_DATA_STATE } from "src/stores/krediflow/AccountingAccountSearchStore";
import styles from "./AccountField.module.css";
import { INVOICE_ACCOUNT_UNIT_OF_MEASURE } from "src/stores/krediflow/InvoiceEnums";

interface IAccountFieldProps {
    accountingRowIndex: number;
}

const AccountFieldBase = (props: IAccountFieldProps) => {
    const { t } = useTranslation();
    const { accountingRealestateSearchStore, accountingAccountSearchStore, invoiceStore, authStore } =
        useContext(RootStoreContext);

    const { accountingRowIndex } = props;

    const [displayAccountList, setDisplayAccountList] = useState<boolean>(false);
    const [lastScrollPositionYAccounItems, setLastSrollYPositionAccountItems] = useState<number>(0);

    const accountInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (
            accountingRealestateSearchStore.triggerFocusNextAccountingAccountInput.isActive &&
            accountingRealestateSearchStore.triggerFocusNextAccountingAccountInput.index === accountingRowIndex
        ) {
            accountInputRef.current?.focus();
            accountingRealestateSearchStore.setTriggerFocusNextAccountingAccountInput(false);
        }
        // This useEffect only listens to changes in accountingRealestateSearchStore.triggerFocusNextAccountingAccountInput
        // eslint-disable-next-line
    }, [accountingRealestateSearchStore.triggerFocusNextAccountingAccountInput]);

    const resetSearchQuery = useCallback(() => {
        const currentSelectedAccountQueryString =
            accountingAccountSearchStore.getSelectedAccountQueryString(accountingRowIndex);

        accountingAccountSearchStore.setCurrentAccountSearchQuery(
            currentSelectedAccountQueryString ?? "",
            accountingRowIndex
        );
    }, [accountingAccountSearchStore, accountingRowIndex]);

    useEffect(() => {
        const handleClickOutsideAccountInput = (event: any) => {
            const target = event.target as HTMLElement;

            const isClickOutsideAccountInputOrList = () => {
                if (!displayAccountList || !accountInputRef.current) {
                    return true;
                }

                const clickedInsideInput = accountInputRef.current.contains(target);

                const clickedListItem =
                    target.classList.contains("list-item") && target.classList.contains("accounting-account");

                const clickedIcon = target.localName === "path" || target.localName === "svg";

                const clickedHighlightedText =
                    target.classList.contains("highlighted-text") && target.classList.contains("accounting-account");

                const clickedListItemChildElement =
                    target.parentElement?.classList.contains("list-item") &&
                    target.parentElement.classList.contains("accounting-account");

                return !(
                    clickedInsideInput ||
                    clickedListItem ||
                    clickedListItemChildElement ||
                    clickedIcon ||
                    clickedHighlightedText
                );
            };

            if (isClickOutsideAccountInputOrList()) {
                setDisplayAccountList(false);

                if (
                    invoiceStore.currentInvoice?.isAccountingAccountSelected(accountingRowIndex) &&
                    accountingAccountSearchStore.getCurrentAccountSearchQuery(accountingRowIndex) === ""
                ) {
                    invoiceStore.resetAccountingAccountFormFields(accountingRowIndex);
                } else {
                    resetSearchQuery();
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutsideAccountInput);

        return () => {
            document.removeEventListener("mousedown", handleClickOutsideAccountInput);
        };
    }, [
        displayAccountList,
        resetSearchQuery,
        accountingAccountSearchStore,
        invoiceStore.currentInvoice?.isAccountingRealestateSelected,
        accountingRowIndex,
        invoiceStore
    ]);

    const updateAccountingWithSelectedAccount = useCallback(
        async (account: IAccount) => {
            invoiceStore.updateAccountingWithSelectedAccount(account, true, accountingRowIndex);
        },
        [invoiceStore, accountingRowIndex]
    );

    const onSelectAccountItem = useCallback(
        (activeListItemIndex: number) => {
            const selectedAccountItem =
                accountingAccountSearchStore.filteredAccountData(accountingRowIndex)[activeListItemIndex];

            const account: IAccount = {
                id: selectedAccountItem.id,
                number: selectedAccountItem.number,
                name: selectedAccountItem.name,
                requiresCostCenter: selectedAccountItem.requiresCostCenter,
                requiresQuantity: selectedAccountItem.requiresQuantity,
                unitOfMeasure: selectedAccountItem.unitOfMeasure ?? INVOICE_ACCOUNT_UNIT_OF_MEASURE.N_A,
                extraCostDate: selectedAccountItem.extraCostDate
            };

            updateAccountingWithSelectedAccount(account);
        },
        [updateAccountingWithSelectedAccount, accountingAccountSearchStore, accountingRowIndex]
    );

    const onTabFromAccountField = useCallback(() => {
        if (accountingAccountSearchStore.filteredAccountData(accountingRowIndex).length) {
            const onlyAccountInList = accountingAccountSearchStore.filteredAccountData(accountingRowIndex)[0];
            updateAccountingWithSelectedAccount(onlyAccountInList);
        }

        accountingAccountSearchStore.setTriggerFocusNextInput(true, accountingRowIndex);
    }, [accountingAccountSearchStore, accountingRowIndex, updateAccountingWithSelectedAccount]);

    const { onKeyDownFunction, setActiveListItemIndex, NO_LIST_ITEM_SELECTED } = useSelectionListKeyHandler({
        listName: "accounting-account",
        totalNumberOfDisplayedItems: invoiceStore.numberOfDisplayedItems,
        setIsListDisplayed: setDisplayAccountList,
        isListDisplayed: displayAccountList,
        onSelectItem: onSelectAccountItem,
        resetSearchQuery: resetSearchQuery,
        additionalTabActionWithNoItemSelected: onTabFromAccountField
    });

    const resetAmountOfItemsDisplayedInAccountList = useCallback(() => {
        setLastSrollYPositionAccountItems(0);
        invoiceStore.setNumberOfDisplayedItems(invoiceStore.sliceSizeOfDisplayedItems);
    }, [invoiceStore]);

    const onChangeAccountInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            resetAmountOfItemsDisplayedInAccountList();

            const inputAccount = e.target.value;
            accountingAccountSearchStore.setCurrentAccountSearchQuery(inputAccount, accountingRowIndex);
        },
        [accountingAccountSearchStore, resetAmountOfItemsDisplayedInAccountList, accountingRowIndex]
    );

    const onFocusAccountInput = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            if (
                invoiceStore.currentInvoice?.isAccountingAccountSelected(accountingRowIndex) &&
                accountInputRef.current
            ) {
                accountInputRef.current.select();
            }

            accountingAccountSearchStore.loadAccountData(accountingRowIndex);

            resetAmountOfItemsDisplayedInAccountList();
            setDisplayAccountList(true);
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
        },
        [
            resetAmountOfItemsDisplayedInAccountList,
            setActiveListItemIndex,
            NO_LIST_ITEM_SELECTED,
            invoiceStore.currentInvoice,
            accountingRowIndex,
            accountingAccountSearchStore
        ]
    );

    const onClickAccountItem = useCallback(
        (accountData: IAccount) => async (e: any) => {
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
            setDisplayAccountList(false);

            const account: IAccount = {
                id: accountData.id,
                number: accountData.number,
                name: accountData.name,
                requiresCostCenter: accountData.requiresCostCenter,
                requiresQuantity: accountData.requiresQuantity,
                unitOfMeasure: accountData.unitOfMeasure ?? INVOICE_ACCOUNT_UNIT_OF_MEASURE.N_A,
                extraCostDate: accountData.extraCostDate
            };

            updateAccountingWithSelectedAccount(account);
        },
        [setActiveListItemIndex, NO_LIST_ITEM_SELECTED, updateAccountingWithSelectedAccount]
    );

    const onScrollAccountList = useCallback(
        (e: React.BaseSyntheticEvent) => {
            const windowHeight = e.target.clientHeight;
            const pixelsFromTop = e.target.scrollTop;
            const totalHeight = e.target.scrollHeight;

            const is150PixelsFromBottom = totalHeight - pixelsFromTop < windowHeight + 150;
            const isScrollingDown = pixelsFromTop > lastScrollPositionYAccounItems;

            const hasMoreResulsToDisplay =
                invoiceStore.numberOfDisplayedItems <
                accountingAccountSearchStore.filteredAccountData(accountingRowIndex).length;

            if (is150PixelsFromBottom && isScrollingDown && hasMoreResulsToDisplay) {
                invoiceStore.setNumberOfDisplayedItems(
                    invoiceStore.numberOfDisplayedItems + invoiceStore.sliceSizeOfDisplayedItems
                );
            }

            setLastSrollYPositionAccountItems(pixelsFromTop);
        },
        [lastScrollPositionYAccounItems, invoiceStore, accountingAccountSearchStore, accountingRowIndex]
    );

    const accountSelectionList = (
        <ul
            id="accounting-account-dropdown-list"
            className={`dropdown-list accounting-account krediflow ${
                authStore.user?.erpType === ErpType.IT2 ? "IT2" : "RIMO"
            }`}
            onScroll={onScrollAccountList}
        >
            {accountingAccountSearchStore.loadingAccountDataState === LOADING_ACCOUNT_DATA_STATE.LOADING ? (
                <li className="d-flex align-items-center justify-content-center list-item accounting-account loading">
                    <div>
                        <UI.RotatingSpinner noLogo size={20} />
                    </div>
                </li>
            ) : (
                accountingAccountSearchStore
                    .filteredAccountData(accountingRowIndex)
                    .slice(0, invoiceStore.numberOfDisplayedItems)
                    .map((account, index) => {
                        const accountName = `${account.number} ${account.name}`;

                        const currentAccountSearchQuery =
                            accountingAccountSearchStore.getCurrentAccountSearchQuery(accountingRowIndex);

                        const formattedAccountName = boldenMatchedSubstring(
                            currentAccountSearchQuery ?? "",
                            accountName,
                            "highlighted-text accounting-account"
                        );

                        return (
                            <li
                                key={index}
                                className="d-flex align-items-center list-item accounting-account"
                                onClick={onClickAccountItem(account)}
                                id={`accounting-account-list-item-${index}`}
                            >
                                <div
                                    className={`accounting-account-text ${styles.AccountingAccountText}`}
                                    dangerouslySetInnerHTML={{
                                        __html: formattedAccountName
                                    }}
                                />
                            </li>
                        );
                    })
            )}
        </ul>
    );

    return (
        <>
            <UI.Input
                ref={accountInputRef}
                autoComplete="off"
                placeholder={t("screens.kredi_flow.accountings.account").toString()}
                type="text"
                value={accountingAccountSearchStore.getCurrentAccountSearchQuery(accountingRowIndex) ?? ""}
                onChange={onChangeAccountInput}
                onFocus={onFocusAccountInput}
                onKeyDown={onKeyDownFunction}
                disabled={!invoiceStore.currentInvoice?.canEditAccountingAccountField(accountingRowIndex)}
            />
            {displayAccountList ? accountSelectionList : undefined}
        </>
    );
};

export const AccountField = Sentry.withProfiler(observer(AccountFieldBase));
