import { gql } from "@apollo/client";

export const GET_TENANT_BOOKINGS_AND_FILES = gql`
    query GetTenantBookingsAndFiles($tenantid: uuid) {
        tenant_bookings_and_files: common_tenants(where: { id: { _eq: $tenantid } }) {
            accountbalance
            tenantbookings(order_by: { date: desc }) {
                id
                debit
                credit
                text
                date
                tenantbookingfiles: v_tenantbookingfiles(distinct_on: fileid) {
                    id
                    fileid
                    filedate
                    name
                }
            }
            tenantfiles: v_tenantfiles(
                order_by: [{ filedate: desc }, { fileid: desc }]
                distinct_on: [filedate, fileid]
            ) {
                id
                fileid
                filedate
                name
            }
        }
    }
`;
