import React from "react";
import * as Sentry from "@sentry/react";
import { UI } from "@wwimmo/ui";
import { observer } from "mobx-react-lite";
import { getDate } from "src/utils/Date";
import styles from "./DisplayNewsBarsAccordionItem.module.css";
import { useNewsImage, IMAGE_STATE, IMAGE_ORIENTATION } from "src/hooks/news-image/useNewsImage";
import { GetCoownerNews_realestates_newsitems_newsitem_attachments as attachments } from "src/api/generated/GetCoownerNews";
import { NetworkConfig } from "src/network/NetworkConfig";
import { ColorStyle, getCssRootValue } from "src/utils/Colors";
import { uuid4 } from "@sentry/utils";

interface DisplayNewsBarsAccordionItemProperties {
    eventKey: string;
    title?: string;
    timestamp: string;
    lead?: string;
    text?: React.ReactNode[];
    imageUrl?: string;
    attachments?: attachments[];
    first?: boolean;
}

const DisplayNewsBarsAccordionItemBase = (props: DisplayNewsBarsAccordionItemProperties) => {
    const { eventKey, title, timestamp, lead, text, imageUrl } = props;

    const { image, imageState, imageOrientation, imageContainerMinHeight } = useNewsImage(imageUrl ?? "");

    return (
        <UI.Accordion.Item eventKey={eventKey} className={styles.AccordionItem}>
            <UI.Accordion.Header as="div" className={styles.AccordionHeader}>
                <div>
                    <div className={styles.AccordionHeaderTitle}>{title}</div>
                    <div className={styles.AccordionHeaderSubtitle}>{getDate(timestamp)}</div>
                </div>
                {props.attachments && props.attachments.length && props.first !== true ? (
                    <div className={styles.AccordionHeaderPaperClipIcon}>
                        <UI.Icon
                            icon={UI.SVGIcon.Paperclip}
                            color={getCssRootValue("primary")}
                            size={30}
                            key={uuid4()}
                        />
                    </div>
                ) : undefined}
            </UI.Accordion.Header>
            <UI.Accordion.Body className={styles.AccordionBody}>
                <div className={styles.AccordionBodyLead}>{lead}</div>
                <div
                    className={`${styles.AccordionBodyText} ${
                        imageOrientation === IMAGE_ORIENTATION.PORTRAIT ? styles.Portrait : styles.Landscape
                    }`}
                    style={{ minHeight: imageContainerMinHeight ?? "default" }}
                >
                    {imageState === IMAGE_STATE.ERROR ? undefined : image}
                    {imageState === IMAGE_STATE.INIT ? (
                        <UI.RotatingSpinner className={styles.ImageLoadSpinner} size={30} noLogo />
                    ) : undefined}
                    {text}
                </div>
                {props.attachments && props.attachments.length > 0 ? (
                    <div className={styles.Attachment}>
                        {props.attachments.map((attachment) => (
                            <div className={styles.NewsFilesItem} key={attachment.fileid}>
                                <a
                                    key={attachment.fileid}
                                    href={NetworkConfig.datafileUrl + attachment.fileid}
                                    className={`${styles.DocumentInfoItemsContainer} link-underline`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <UI.Icon icon={UI.SVGIcon.Documents} color={ColorStyle("white")} size="small" />
                                    <div className={styles.FileName}>{attachment.name}</div>
                                </a>
                            </div>
                        ))}
                    </div>
                ) : (
                    <></>
                )}
            </UI.Accordion.Body>
        </UI.Accordion.Item>
    );
};

export const DisplayNewsBarsAccordionItem = Sentry.withProfiler(observer(DisplayNewsBarsAccordionItemBase));
