import React, { useCallback, useContext } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import {
    GetAssemblySelectionFilesManager,
    GetAssemblySelectionFilesManagerVariables
} from "src/api/generated/GetAssemblySelectionFilesManager";
import * as Screens from "src/screens";
import { useTranslation } from "react-i18next";
import { UI } from "@wwimmo/ui";
import { GET_ASSEMBLY_SELECTION_FILES_MANAGER, GET_ASSEMBLY_SELECTION_FILES_MANAGER_IT2 } from "src/api/assembly";
import { AddAgendaDocumentsModalList } from "./list/AddAgendaDocumentsModalList";
import { getDate } from "src/utils/Date";
import styles from "./AddAgendaDocumentsModal.module.css";
import { RootStoreContext } from "src/stores/RootStore";
import { ErpType } from "src/network/User";

interface Params {
    realestateid: string;
}

export interface AssemblyAddDocumentsListFile {
    fileId?: string;
    fileName?: string;
    fileDate?: string;
    fileType?: number;
}

interface AddAgendaDocumentsModalProps {
    alreadyAddedAssemblyFileIds: string[];
}

const AddAgendaDocumentsModalBase = (props: AddAgendaDocumentsModalProps) => {
    const { t } = useTranslation();
    const { realestateid } = useParams<Params>();
    const { assemblyStore, authStore, navStore } = useContext(RootStoreContext);

    const { alreadyAddedAssemblyFileIds } = props;

    const variablesRequest =
        authStore.user?.erpType === ErpType.IT2 && navStore.consolidationId
            ? {
                  realestateid: realestateid,
                  consolidationid: navStore.consolidationId
              }
            : {
                  realestateid: realestateid
              };

    const { data, loading, error } = useQuery<
        GetAssemblySelectionFilesManager,
        GetAssemblySelectionFilesManagerVariables
    >(
        authStore.user?.erpType === ErpType.IT2 && navStore.consolidationId
            ? GET_ASSEMBLY_SELECTION_FILES_MANAGER_IT2
            : GET_ASSEMBLY_SELECTION_FILES_MANAGER,
        { variables: variablesRequest }
    );

    const onCloseAddDocumentsModal = useCallback(() => {
        assemblyStore.setIsAddingDocumentsDisplayed(false);
        assemblyStore.setShowRefetchAssemblyData(true);
    }, [assemblyStore]);

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata").toString()} />;
    }

    const fileItems: AssemblyAddDocumentsListFile[] = [];

    data.accountlistfiles.forEach((file) => {
        const fileToAdd: AssemblyAddDocumentsListFile = {
            fileId: file.fileid,
            fileName: file.name ?? "",
            fileDate: getDate(file.filedate),
            fileType: file.type ?? 0
        };

        if (!alreadyAddedAssemblyFileIds.includes(fileToAdd.fileId ?? "")) {
            fileItems.push(fileToAdd);
        }
    });

    data.realestatefiles.forEach((file) => {
        const fileToAdd: AssemblyAddDocumentsListFile = {
            fileId: file.fileid,
            fileName: file.name ?? "",
            fileDate: getDate(file.filedate),
            fileType: file.type ?? 0
        };

        if (!alreadyAddedAssemblyFileIds.includes(fileToAdd.fileId ?? "")) {
            fileItems.push(fileToAdd);
        }
    });

    return (
        <UI.Modal
            show={assemblyStore.isAddingDocumentsDisplayed}
            onClose={onCloseAddDocumentsModal}
            isScrollable={true}
            className={styles.AddDocumentsModal}
        >
            <AddAgendaDocumentsModalList files={fileItems} />
            <div className="text-center">
                <UI.Button
                    className={`${styles.AddDocumentsModalButton} mt-3`}
                    label={t("labels.cancel")}
                    onClick={onCloseAddDocumentsModal}
                    variant="link"
                />
            </div>
        </UI.Modal>
    );
};

export const AddAgendaDocumentsModal = Sentry.withProfiler(observer(AddAgendaDocumentsModalBase));
