import { observable, makeObservable, action } from "mobx";
import { RootStore } from "src/stores/RootStore";
import i18next from "i18next";
import { INVOICE_PROPERTY, INVOICE_STATE } from "./InvoiceEnums";

import { NetworkConfig } from "src/network/NetworkConfig";
import { getRoleKey } from "src/network/User";
import { MessageType } from "src/components/notifications/Notifier";

export enum RESTART_CANCEL_INVOICE_STATE {
    INIT,
    RESTARTING,
    CANCELLING,
    FINISHED,
    SUCCESS,
    ERROR
}

export enum RESTART_CANCEL_MODAL_TYPE {
    RESTART,
    CANCEL
}

export class RestartCancelInvoiceStore {
    rootStore: RootStore;

    isModalDisplayed = false;
    displayedModalType: RESTART_CANCEL_MODAL_TYPE = RESTART_CANCEL_MODAL_TYPE.RESTART;

    restartCancelInvoiceState: RESTART_CANCEL_INVOICE_STATE = RESTART_CANCEL_INVOICE_STATE.INIT;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;

        makeObservable(this, {
            restartCancelInvoiceState: observable,
            isModalDisplayed: observable,
            displayedModalType: observable,

            reset: action,
            setIsModalDisplayed: action,
            setRestartCancelInvoiceState: action,
            restartInvoice: action,
            cancelInvoice: action,
            setDisplayedModalType: action
        });
    }

    reset = () => {
        this.restartCancelInvoiceState = RESTART_CANCEL_INVOICE_STATE.INIT;
    };

    cancelInvoice = async (): Promise<boolean> => {
        this.setRestartCancelInvoiceState(RESTART_CANCEL_INVOICE_STATE.CANCELLING);

        let wasInvoiceSuccessfullyCanceled = false;

        const currentInvoice = this.rootStore.invoiceStore.currentInvoice;

        const cancelInvoiceVariables = {
            invoiceid: currentInvoice?.id,
            workflowid: currentInvoice?.workflowInstance,
            cancelled: true
        };

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            const setCancelStateInvoiceResponse = await fetch(NetworkConfig.cancelInvoiceUrl, {
                method: "POST",
                body: JSON.stringify(cancelInvoiceVariables),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            if (setCancelStateInvoiceResponse.status === 200) {
                wasInvoiceSuccessfullyCanceled = true;

                this.rootStore.invoiceStore.currentInvoice?.updateProperty(
                    INVOICE_PROPERTY.STATE,
                    INVOICE_STATE.CANCELLED
                );
            } else {
                console.error("Error occured while trying to cancel invoice: ", setCancelStateInvoiceResponse);
            }
        } catch (error) {
            console.error("Error cancelling invoice: ", error);
        }

        if (wasInvoiceSuccessfullyCanceled) {
            this.setRestartCancelInvoiceState(RESTART_CANCEL_INVOICE_STATE.FINISHED);
        } else {
            this.setRestartCancelInvoiceState(RESTART_CANCEL_INVOICE_STATE.INIT);
            this.rootStore.uiStore.printStatusMessage(
                i18next.t("screens.kredi_flow.action.cancel_invoice.failure"),
                MessageType.ERROR
            );
        }
        return wasInvoiceSuccessfullyCanceled;
    };

    restartInvoice = async (): Promise<boolean> => {
        this.setRestartCancelInvoiceState(RESTART_CANCEL_INVOICE_STATE.RESTARTING);

        let wasInvoiceSuccessfullyRestarted = false;

        const currentInvoice = this.rootStore.invoiceStore.currentInvoice;

        const concludeProcessCancelTaskVariables = {
            taskname: "ProcessCancel",
            workflowid: currentInvoice?.workflowInstance
        };

        try {
            const accessToken = this.rootStore.authStore.token;
            const tokenType = this.rootStore.authStore.tokenType;
            const role = this.rootStore.authStore.user?.role;

            const setRestartInvoiceResponse = await fetch(NetworkConfig.concludeHumanTaskUrl, {
                method: "POST",
                body: JSON.stringify(concludeProcessCancelTaskVariables),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${tokenType} ${accessToken}`,
                    "x-hasura-role": getRoleKey(role)
                }
            });

            if (setRestartInvoiceResponse.status === 200) {
                wasInvoiceSuccessfullyRestarted = true;

                this.rootStore.invoiceStore.currentInvoice?.updateProperty(
                    INVOICE_PROPERTY.STATE,
                    INVOICE_STATE.READY_FOR_VISA
                );
            } else {
                console.error("Error occured while trying to restart invoice: ", setRestartInvoiceResponse);
            }
        } catch (error) {
            console.error("Error restarting invoice: ", error);
        }

        if (wasInvoiceSuccessfullyRestarted) {
            this.setRestartCancelInvoiceState(RESTART_CANCEL_INVOICE_STATE.FINISHED);
        } else {
            this.setRestartCancelInvoiceState(RESTART_CANCEL_INVOICE_STATE.INIT);
            this.rootStore.uiStore.printStatusMessage(
                i18next.t("screens.kredi_flow.action.restart_invoice.failure"),
                MessageType.ERROR
            );
        }
        return wasInvoiceSuccessfullyRestarted;
    };

    /* SETTERS */
    setIsModalDisplayed = (isModalDisplayed: boolean) => {
        this.isModalDisplayed = isModalDisplayed;
    };

    setRestartCancelInvoiceState = (assignInvoiceState: RESTART_CANCEL_INVOICE_STATE) => {
        this.restartCancelInvoiceState = assignInvoiceState;
    };

    setDisplayedModalType = (displayedModalType: RESTART_CANCEL_MODAL_TYPE) => {
        this.displayedModalType = displayedModalType;
    };
}
