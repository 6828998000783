import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { observer } from "mobx-react-lite";
import { UI } from "@wwimmo/ui";
import { useSelectionListKeyHandler } from "src/hooks/ticket/selection-list-key-handler/useSelectionListKeyHandler";
import { boldenMatchedSubstring } from "src/utils/Common";
import debounce from "lodash/debounce";
import "./GeneralInvoiceFormStyles.css";
import { ICreditor } from "src/stores/krediflow/InvoiceTypes";
import styles from "./CreditorFormFields.module.css";
import { SCREEN_SIZE } from "src/stores/UIStore";

const CreditorFormFieldsBase = () => {
    const { t } = useTranslation();
    const { ticketStore, uiStore, contactPersonSearchStore, creditorSearchStore, invoiceStore } =
        useContext(RootStoreContext);

    const [displayCreditorList, setDisplayCreditorList] = useState<boolean>(false);
    const [lastScrollPositionYPCreditorItems, setLastSrollYPositionCreditorItems] = useState<number>(0);

    const creditorInputRef = useRef<HTMLInputElement>(null);

    const resetSearchQuery = useCallback(() => {
        creditorSearchStore.setCurrentCreditorSearchQuery(creditorSearchStore.selectedCreditorQueryString ?? "");
    }, [creditorSearchStore]);

    useEffect(() => {
        const handleClickOutsidePersonInput = (event: any) => {
            if (
                displayCreditorList &&
                creditorInputRef.current &&
                !creditorInputRef.current.contains(event.target) &&
                !(event.target.classList.contains("list-item") && event.target.classList.contains("creditor")) &&
                !(event.target.classList.contains("highlighted-text") && event.target.classList.contains("creditor")) &&
                !event.target.classList.contains("creditor-list-item-span") &&
                !event.target.classList.contains("creditor-list-item-line-1") &&
                !event.target.classList.contains("creditor-list-item-line-2") &&
                !event.target?.parentElement?.parentElement?.parentElement.classList?.contains("IconSVG")
            ) {
                setDisplayCreditorList(false);

                if (
                    invoiceStore.currentInvoice?.isCreditorSelected &&
                    creditorSearchStore.currentCreditorSearchQuery !== creditorSearchStore.selectedCreditorQueryString
                ) {
                    resetSearchQuery();
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutsidePersonInput);

        return () => {
            document.removeEventListener("mousedown", handleClickOutsidePersonInput);
        };
    }, [
        displayCreditorList,
        ticketStore,
        resetSearchQuery,
        creditorSearchStore.currentCreditorSearchQuery,
        creditorSearchStore.selectedCreditorQueryString,
        invoiceStore.currentInvoice?.isCreditorSelected
    ]);

    useEffect(() => {
        if (creditorSearchStore.triggerFocusOnCreditorInput) {
            creditorInputRef.current?.focus();
            creditorSearchStore.setTriggerFocusOnCreditorInput(false);
        }
        // This useEffect only listens to changes in contactPersonSearchStore.triggerFocusOnPersonInput
        // eslint-disable-next-line
    }, [contactPersonSearchStore.triggerFocusOnPersonInput]);

    const updateInvoiceStoreWithSelectedCreditor = useCallback(
        async (creditor: ICreditor | undefined) => {
            if (creditor) {
                invoiceStore.updateInvoiceStoreWithSelectedCreditor(creditor);
            }
        },
        [invoiceStore]
    );

    const onSelectCreditorItem = useCallback(
        (activeListItemIndex: number) => {
            const selectedCreditorItem = creditorSearchStore.filteredCreditorData[activeListItemIndex];

            updateInvoiceStoreWithSelectedCreditor(selectedCreditorItem);
        },
        [creditorSearchStore.filteredCreditorData, updateInvoiceStoreWithSelectedCreditor]
    );

    const { onKeyDownFunction, setActiveListItemIndex, NO_LIST_ITEM_SELECTED } = useSelectionListKeyHandler({
        listName: "creditor",
        totalNumberOfDisplayedItems: invoiceStore.numberOfDisplayedItems,
        setIsListDisplayed: setDisplayCreditorList,
        isListDisplayed: displayCreditorList,
        onSelectItem: onSelectCreditorItem,
        resetSearchQuery: resetSearchQuery
    });

    const resetAmountOfItemsDisplayedInCreditorList = useCallback(() => {
        setLastSrollYPositionCreditorItems(0);
        invoiceStore.setNumberOfDisplayedItems(invoiceStore.sliceSizeOfDisplayedItems);
    }, [invoiceStore]);

    const searchCreditor = debounce((query: string) => {
        creditorSearchStore.searchCreditorsInBackendByQuery(query);
    }, 800);

    const onChangeCreditorInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            resetAmountOfItemsDisplayedInCreditorList();

            const inputCreditor = e.target.value;
            creditorSearchStore.setCurrentCreditorSearchQuery(inputCreditor);

            if (creditorSearchStore.isSearchingCreditorsInBackendByQuery) {
                if (inputCreditor.length > 2) {
                    searchCreditor(inputCreditor);
                } else {
                    creditorSearchStore.setCreditorData([]);
                }
            }
        },

        // searchCreditor function cannot be in dependency array, because it is a debounced function
        // eslint-disable-next-line
        [contactPersonSearchStore, resetAmountOfItemsDisplayedInCreditorList]
    );

    const onFocusCreditorInput = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            if (invoiceStore.currentInvoice?.isCreditorSelected && creditorInputRef.current) {
                creditorInputRef.current.select();
            }

            resetAmountOfItemsDisplayedInCreditorList();
            setDisplayCreditorList(true);
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
        },
        [
            resetAmountOfItemsDisplayedInCreditorList,
            setActiveListItemIndex,
            NO_LIST_ITEM_SELECTED,
            invoiceStore.currentInvoice?.isCreditorSelected
        ]
    );

    const onClickCreditorItem = useCallback(
        (personId: string) => async (e: any) => {
            setActiveListItemIndex(NO_LIST_ITEM_SELECTED);
            setDisplayCreditorList(false);

            const selectedCreditorItem = creditorSearchStore.filteredCreditorData.find((creditor) => {
                return creditor.personId === personId;
            });

            updateInvoiceStoreWithSelectedCreditor(selectedCreditorItem);
        },
        [
            updateInvoiceStoreWithSelectedCreditor,
            setActiveListItemIndex,
            NO_LIST_ITEM_SELECTED,
            creditorSearchStore.filteredCreditorData
        ]
    );

    const onScrollCreditorList = useCallback(
        (e: React.BaseSyntheticEvent) => {
            const windowHeight = e.target.clientHeight;
            const pixelsFromTop = e.target.scrollTop;
            const totalHeight = e.target.scrollHeight;

            const is150PixelsFromBottom = totalHeight - pixelsFromTop < windowHeight + 150;
            const isScrollingDown = pixelsFromTop > lastScrollPositionYPCreditorItems;

            const hasMoreResulsToDisplay =
                invoiceStore.numberOfDisplayedItems < creditorSearchStore.filteredCreditorData.length;

            if (is150PixelsFromBottom && isScrollingDown && hasMoreResulsToDisplay) {
                invoiceStore.setNumberOfDisplayedItems(
                    invoiceStore.numberOfDisplayedItems + invoiceStore.sliceSizeOfDisplayedItems
                );
            }

            setLastSrollYPositionCreditorItems(pixelsFromTop);
        },
        [lastScrollPositionYPCreditorItems, creditorSearchStore.filteredCreditorData.length, invoiceStore]
    );

    const getSpanElementWithFormattedText = useCallback(
        (stringToFormat: string) => {
            const formattedString =
                creditorSearchStore.currentCreditorSearchQuery !== ""
                    ? boldenMatchedSubstring(
                          creditorSearchStore.currentCreditorSearchQuery ?? "",
                          stringToFormat,
                          "highlighted-text creditor"
                      )
                    : stringToFormat;

            return (
                <span
                    className="creditor-list-item-span"
                    dangerouslySetInnerHTML={{
                        __html: formattedString
                    }}
                />
            );
        },
        [creditorSearchStore.currentCreditorSearchQuery]
    );

    const creditorSelectionList = (
        <div id="creditor-dropdown-list-container">
            <div
                className={`${styles.CreditorSelectionListSearchIndicator} ${
                    !creditorSearchStore.isSearchingCreditorData &&
                    !(creditorSearchStore.creditorData.length === 0 && !creditorSearchStore.isSearchingCreditorData)
                        ? styles.NoBorderBottom
                        : ""
                }`}
            >
                {creditorSearchStore.isSearchingCreditorData ? (
                    <UI.RotatingSpinner noLogo size={30} className={styles.SearchingCreditorsSpinner} />
                ) : undefined}
            </div>
            {creditorSearchStore.isSearchingCreditorData ? undefined : (
                <ul id="creditor-dropdown-list" className={"dropdown-list creditor"} onScroll={onScrollCreditorList}>
                    {creditorSearchStore.filteredCreditorData
                        .slice(0, invoiceStore.numberOfDisplayedItems)
                        .map((creditor, index) => {
                            const creditorName = `${creditor.name1}${`${
                                creditor.name2 ? ` ${creditor.name2}` : ""
                            }`}`.trim();
                            const creditorZipCity = `${creditor.zip} ${creditor.city}`.trim();

                            const formattedCreditorNameSpan = getSpanElementWithFormattedText(creditorName ?? "");
                            const formattedCreditorStreetSpan = getSpanElementWithFormattedText(creditor.street ?? "");
                            const formattedCreditorZipCitySpan = getSpanElementWithFormattedText(creditorZipCity ?? "");
                            const formattedCreditorEmailSpan = creditor.email
                                ? getSpanElementWithFormattedText(creditor.email)
                                : undefined;
                            const formattedCreditorMobileSpan = creditor.mobile
                                ? getSpanElementWithFormattedText(creditor.mobile)
                                : undefined;

                            return (
                                <li key={index}>
                                    <div
                                        className={"list-item creditor"}
                                        id={`creditor-list-item-${index}`}
                                        onClick={onClickCreditorItem(creditor.personId ?? "")}
                                    >
                                        {uiStore.isMobile ? (
                                            <>
                                                <div>{formattedCreditorNameSpan}</div>
                                                <div>{formattedCreditorStreetSpan}</div>
                                                <div>{formattedCreditorZipCitySpan}</div>
                                                <div>{formattedCreditorEmailSpan}</div>
                                                <div>{formattedCreditorMobileSpan}</div>
                                            </>
                                        ) : (
                                            <>
                                                <div className={"creditor-list-item-line-1"}>
                                                    {formattedCreditorNameSpan}, {formattedCreditorStreetSpan},{" "}
                                                    {formattedCreditorZipCitySpan}
                                                </div>
                                                <div className={"creditor-list-item-line-2"}>
                                                    {formattedCreditorEmailSpan}
                                                    {formattedCreditorEmailSpan === undefined ||
                                                    formattedCreditorMobileSpan === undefined
                                                        ? ""
                                                        : formattedCreditorEmailSpan !== undefined &&
                                                          formattedCreditorMobileSpan !== undefined
                                                        ? ", "
                                                        : ""}
                                                    {formattedCreditorMobileSpan}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </li>
                            );
                        })}
                </ul>
            )}
        </div>
    );

    const editModeCreditorElements = (
        <>
            <UI.Row className="mt-md-2">
                <UI.Col lg={7}>
                    <UI.Input
                        ref={creditorInputRef}
                        type="text"
                        autoComplete="off"
                        className={styles.CreditorInputField}
                        id="ticket-form-peson-input"
                        value={creditorSearchStore.currentCreditorSearchQuery ?? ""}
                        onChange={onChangeCreditorInput}
                        onFocus={onFocusCreditorInput}
                        onKeyDown={onKeyDownFunction}
                        disabled={!invoiceStore.isEditing}
                        iconRight={
                            <UI.Icon icon={UI.SVGIcon.Search} color="grey" size={"small"} style={{ right: "5px" }} />
                        }
                    />
                    {displayCreditorList ? creditorSelectionList : undefined}
                </UI.Col>
            </UI.Row>
            <UI.Row>
                <div
                    className={
                        uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP
                            ? "col-3"
                            : uiStore.invoicePaneScreenSize === SCREEN_SIZE.TABLET
                            ? "col-6"
                            : "col-12"
                    }
                >
                    <UI.Input
                        label={t("screens.kredi_flow.form.creditor.salutation").toString()}
                        type="text"
                        autoComplete="off"
                        disabled={true}
                        value={
                            invoiceStore.currentInvoice &&
                            invoiceStore.currentInvoice.creditor &&
                            invoiceStore.currentInvoice.creditor.gender
                                ? t(
                                      `screens.kredi_flow.form.creditor.gender_${invoiceStore.currentInvoice.creditor.gender}`
                                  ).toString()
                                : ""
                        }
                    />
                </div>
            </UI.Row>
            <UI.Row>
                <div
                    className={
                        uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP
                            ? "col-3"
                            : uiStore.invoicePaneScreenSize === SCREEN_SIZE.TABLET
                            ? "col-6"
                            : "col-12"
                    }
                >
                    <UI.Input
                        label={t("screens.kredi_flow.form.creditor.name").toString()}
                        type="text"
                        autoComplete="off"
                        disabled={true}
                        value={
                            invoiceStore.currentInvoice && invoiceStore.currentInvoice.creditor
                                ? invoiceStore.currentInvoice.creditor?.name1 ?? ""
                                : ""
                        }
                    />
                </div>
                <div
                    className={
                        uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP
                            ? "col-3"
                            : uiStore.invoicePaneScreenSize === SCREEN_SIZE.TABLET
                            ? "col-6"
                            : "col-12"
                    }
                >
                    <UI.Input
                        label={t("screens.kredi_flow.form.creditor.name_suffix").toString()}
                        type="text"
                        autoComplete="off"
                        disabled={true}
                        value={
                            invoiceStore.currentInvoice && invoiceStore.currentInvoice.creditor
                                ? invoiceStore.currentInvoice.creditor?.name2 ?? ""
                                : ""
                        }
                    />
                </div>
                <div className={uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP ? "col-6" : "col-12"}>
                    <UI.Input
                        label={t("screens.kredi_flow.form.creditor.email").toString()}
                        type="text"
                        autoComplete="off"
                        disabled={true}
                        value={
                            invoiceStore.currentInvoice && invoiceStore.currentInvoice.creditor
                                ? invoiceStore.currentInvoice.creditor?.email ?? ""
                                : ""
                        }
                        iconRight={<UI.Icon icon={UI.SVGIcon.Mail} color="grey" size={"medium"} />}
                    />
                </div>
            </UI.Row>
            <UI.Row>
                <div
                    className={
                        uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP
                            ? "col-5"
                            : uiStore.invoicePaneScreenSize === SCREEN_SIZE.TABLET
                            ? "col-8"
                            : "col-12"
                    }
                >
                    <UI.Input
                        label={t("screens.kredi_flow.form.creditor.street").toString()}
                        type="text"
                        autoComplete="off"
                        disabled={true}
                        value={
                            invoiceStore.currentInvoice && invoiceStore.currentInvoice.creditor
                                ? invoiceStore.currentInvoice.creditor?.street ?? ""
                                : ""
                        }
                    />
                </div>
                <div
                    className={
                        uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP
                            ? "col-1"
                            : uiStore.invoicePaneScreenSize === SCREEN_SIZE.TABLET
                            ? "col-4"
                            : "col-12"
                    }
                >
                    <UI.Input
                        label={t("screens.kredi_flow.form.creditor.street_number").toString()}
                        type="text"
                        autoComplete="off"
                        disabled={true}
                        value={
                            invoiceStore.currentInvoice && invoiceStore.currentInvoice.creditor
                                ? invoiceStore.currentInvoice.creditor?.housenumber ?? ""
                                : ""
                        }
                    />
                </div>
                <div className={uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP ? "col-6" : "col-12"}>
                    <UI.Input
                        label={t("screens.kredi_flow.form.creditor.mobile").toString()}
                        type="text"
                        autoComplete="off"
                        disabled={true}
                        value={
                            invoiceStore.currentInvoice && invoiceStore.currentInvoice.creditor
                                ? invoiceStore.currentInvoice.creditor?.mobile ?? ""
                                : ""
                        }
                        iconRight={<UI.Icon icon={UI.SVGIcon.Device} color="grey" size={"medium"} />}
                    />
                </div>
            </UI.Row>
            <UI.Row>
                <div
                    className={
                        uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP
                            ? "col-3"
                            : uiStore.invoicePaneScreenSize === SCREEN_SIZE.TABLET
                            ? "col-6"
                            : "col-12"
                    }
                >
                    <UI.Input
                        label={t("screens.kredi_flow.form.creditor.zip").toString()}
                        type="text"
                        autoComplete="off"
                        disabled={true}
                        value={
                            invoiceStore.currentInvoice && invoiceStore.currentInvoice.creditor
                                ? invoiceStore.currentInvoice.creditor?.zip ?? ""
                                : ""
                        }
                    />
                </div>
                <div
                    className={
                        uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP
                            ? "col-3"
                            : uiStore.invoicePaneScreenSize === SCREEN_SIZE.TABLET
                            ? "col-6"
                            : "col-12"
                    }
                >
                    <UI.Input
                        label={t("screens.kredi_flow.form.creditor.city").toString()}
                        type="text"
                        autoComplete="off"
                        disabled={true}
                        value={
                            invoiceStore.currentInvoice && invoiceStore.currentInvoice.creditor
                                ? invoiceStore.currentInvoice.creditor?.city ?? ""
                                : ""
                        }
                    />
                </div>
                <div className={uiStore.invoicePaneScreenSize >= SCREEN_SIZE.LAPTOP ? "col-6" : "col-12"}>
                    <UI.Input
                        label={t("screens.kredi_flow.form.creditor.phone_business").toString()}
                        type="text"
                        autoComplete="off"
                        disabled={true}
                        value={
                            invoiceStore.currentInvoice && invoiceStore.currentInvoice.creditor
                                ? invoiceStore.currentInvoice.creditor?.phonebusiness ?? ""
                                : ""
                        }
                        iconRight={<UI.Icon icon={UI.SVGIcon.Device} color="grey" size={"medium"} />}
                    />
                </div>
            </UI.Row>
        </>
    );

    const currentCreditor = invoiceStore.currentInvoice?.creditor;

    const currentCreditorName = `${currentCreditor?.name1}${`${
        currentCreditor?.name2 ? ` ${currentCreditor?.name2}` : ""
    }`}`.trim();

    const viewCreditorElementsDesktop = (
        <UI.Container fluid className="mt-3">
            <UI.Row>
                {/* First Column */}
                <div className="col-6">
                    <UI.Row>
                        {currentCreditorName.length > 0 ? (
                            <div className={`${styles.CreditorName} mb-1`}>{currentCreditorName}</div>
                        ) : undefined}
                    </UI.Row>
                    <UI.Row>
                        {currentCreditor?.street ? <div className="mb-1">{currentCreditor?.street}</div> : undefined}
                    </UI.Row>
                    <UI.Row>
                        {currentCreditor?.zip || currentCreditor?.city ? (
                            <div>{`${currentCreditor?.zip} ${currentCreditor?.city}`.trim()}</div>
                        ) : undefined}
                    </UI.Row>
                </div>

                {/* Second Column, which grows as needed (E-Mail-Address) */}
                <UI.Col md="auto">
                    <UI.Row>
                        {currentCreditor?.email ? <div className="mb-1">{currentCreditor?.email}</div> : undefined}
                    </UI.Row>
                    <UI.Row>
                        {currentCreditor?.mobile ? <div className="mb-1">{currentCreditor?.mobile}</div> : undefined}
                    </UI.Row>
                    <UI.Row>
                        {currentCreditor?.phonebusiness ? (
                            <div className="mb-1">{currentCreditor?.phonebusiness}</div>
                        ) : undefined}
                    </UI.Row>
                </UI.Col>

                {/* Third Column */}
                <UI.Col>
                    <UI.Row>
                        {currentCreditor?.email ? (
                            <div className={`${styles.Icon} mb-1`}>
                                <UI.Icon icon={UI.SVGIcon.Mail} color="grey" size={"medium"} />
                            </div>
                        ) : undefined}
                    </UI.Row>
                    <UI.Row>
                        {currentCreditor?.mobile ? (
                            <div className={`${styles.Icon} mb-1`}>
                                <UI.Icon icon={UI.SVGIcon.Device} color="grey" size={"medium"} />
                            </div>
                        ) : undefined}
                    </UI.Row>
                    <UI.Row>
                        {currentCreditor?.phonebusiness ? (
                            <div className={`${styles.Icon} ${styles.Phone}`}>
                                <UI.Icon icon={UI.SVGIcon.Phone} color="grey" size={25} />
                            </div>
                        ) : undefined}
                    </UI.Row>
                </UI.Col>
            </UI.Row>
        </UI.Container>
    );

    const viewOnlyCreditorElementsMobile = (
        <>
            <div className={`${styles.ViewOnlyCreditorCol} ${styles.CreditorName}`}>
                {currentCreditor
                    ? `${currentCreditor.name1}${`${currentCreditor.name2 ? ` ${currentCreditor.name2}` : ""}`}`.trim()
                    : ""}
            </div>
            <div>{currentCreditor?.street}</div>
            <div className="mb-2">{`${currentCreditor?.zip} ${currentCreditor?.city}`.trim()}</div>

            <div>{currentCreditor?.email}</div>
            <div>{currentCreditor?.mobile}</div>
            <div>{currentCreditor?.phonebusiness}</div>
        </>
    );

    const viewOnlyCreditorElements = currentCreditor ? (
        !uiStore.isMobile ? (
            viewCreditorElementsDesktop
        ) : (
            viewOnlyCreditorElementsMobile
        )
    ) : (
        <></>
    );

    return invoiceStore.isEditing ? editModeCreditorElements : viewOnlyCreditorElements;
};

export const CreditorFormFields = Sentry.withProfiler(observer(CreditorFormFieldsBase));
