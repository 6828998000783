import React from "react";
import { create } from "mobx-persist";
import { UIStore } from "./UIStore";
import { NavigationStore } from "./NavigationStore";
import { AuthStore } from "./AuthStore";
import { AssemblyStore } from "./AssemblyStore";
import { TicketStore } from "./TicketStore";
import { TicketListStore } from "./tickets/TicketListStore";
import { ContactPersonSearchStore } from "./tickets/ContactPersonSearchStore";
import { DamageNotificationStore } from "./tickets/DamageNotificationStore";
import { NotificationStore } from "./NotificationStore";
import { KeyOrderNotificationStore } from "./tickets/KeyOrderNotificationStore";
import { QuestionToDocumentNotificationStore } from "./tickets/QuestionToDocumentNotificationStore";
import { AssignOrderModalStore } from "./tickets/AssignOrderModalStore";
import { CloseTicketModalStore } from "./tickets/CloseTicketModalStore";
import { ManualEmailModalStore } from "./tickets/ManualEmailModalStore";
import { AssignTicketModalStore } from "./tickets/AssignTicketModalStore";
import { OrderStore } from "./OrderStore";
import { ServicesStore } from "./services/ServicesStore";
import { UserRatingModalStore } from "./UserRatingModalStore";
import { ServicesActivationProgressModalStore } from "./services/modals/ServicesActivationProgressModalStore";
import { ServicesDeactivationProgressModalStore } from "./services/modals/ServicesDeactivationProgressModalStore";
import { ServicesInfoModalStore } from "./services/modals/ServicesInfoModalStore";
import { DocumentStore } from "./DocumentStore";
import { InvoiceStore } from "./krediflow/InvoiceStore";
import { InvoiceListStore } from "./krediflow/InvoiceListStore";
import { CreditorSearchStore } from "./krediflow/CreditorSearchStore";
import { PaymentAccountSearchStore } from "./krediflow/PaymentAccountSearchStore";
import { RealestateSearchStore } from "./RealestateSearchStore";
import { UnitSearchStore } from "./UnitSearchStore";
import { TicketSearchStore } from "./krediflow/TicketSearchStore";
import { OrderSearchStore } from "./krediflow/OrderSearchStore";
import { AssignInvoiceStore } from "./krediflow/AssignInvoiceStore";
import { AccountingRealestateSearchStore } from "./krediflow/AccountingRealestateSearchStore";
import { ApproveRejectInvoiceStore } from "./krediflow/ApproveRejectInvoiceStore";
import { AccountingAccountSearchStore } from "./krediflow/AccountingAccountSearchStore";
import { AccountingStore } from "./krediflow/AccountingStore";
import { StartWorkflowStore } from "./krediflow/StartWorkflowStore";
import { AccountingCostcenterStore } from "./krediflow/AccountingCostcenterSearchStore";
import { RestartCancelInvoiceStore } from "./krediflow/RestartCancelInvoiceStore";

export const hydrate = create({
    storage: localStorage,
    jsonify: true
});

export class RootStore {
    // General
    uiStore: UIStore;
    navStore: NavigationStore;
    authStore: AuthStore;
    notificationStore: NotificationStore;
    userRatingModalStore: UserRatingModalStore;

    // Ticketing
    ticketStore: TicketStore;
    ticketListStore: TicketListStore;
    contactPersonSearchStore: ContactPersonSearchStore;
    damageNotificationStore: DamageNotificationStore;
    keyOrderNotificationStore: KeyOrderNotificationStore;
    questionToDocumentNotificationStore: QuestionToDocumentNotificationStore;
    assignOrderModalStore: AssignOrderModalStore;
    closeTicketModalStore: CloseTicketModalStore;
    assignTicketModalStore: AssignTicketModalStore;
    manualEmailModalStore: ManualEmailModalStore;

    // KrediFlow
    invoiceStore: InvoiceStore;
    invoiceListStore: InvoiceListStore;
    creditorSearchStore: CreditorSearchStore;
    paymentAccountSearchStore: PaymentAccountSearchStore;
    ticketSearchStore: TicketSearchStore;
    orderSearchStore: OrderSearchStore;
    assignInvoiceStore: AssignInvoiceStore;
    accountingRealestateSearchStore: AccountingRealestateSearchStore;
    approveRejectInvoiceStore: ApproveRejectInvoiceStore;
    restartCancelInvoiceStore: RestartCancelInvoiceStore;
    accountingAccountSearchStore: AccountingAccountSearchStore;
    accountingStore: AccountingStore;
    accountingCostcenterStore: AccountingCostcenterStore;
    startWorkflowStore: StartWorkflowStore;

    // Services / Features
    servicesStore: ServicesStore;
    servicesActivationProgressModalStore: ServicesActivationProgressModalStore;
    servicesDeactivationProgressModalStore: ServicesDeactivationProgressModalStore;
    servicesInfoModalStore: ServicesInfoModalStore;

    assemblyStore: AssemblyStore;
    orderStore: OrderStore;

    documentStore: DocumentStore;

    realestateSearchStore: RealestateSearchStore;
    unitSearchStore: UnitSearchStore;

    constructor() {
        this.uiStore = new UIStore(this);
        this.navStore = new NavigationStore(this);
        this.authStore = new AuthStore(this);
        this.notificationStore = new NotificationStore(this);
        this.userRatingModalStore = new UserRatingModalStore(this);

        this.ticketStore = new TicketStore(this);
        this.ticketListStore = new TicketListStore(this);
        this.contactPersonSearchStore = new ContactPersonSearchStore(this);
        this.damageNotificationStore = new DamageNotificationStore(this);
        this.keyOrderNotificationStore = new KeyOrderNotificationStore(this);
        this.questionToDocumentNotificationStore = new QuestionToDocumentNotificationStore(this);
        this.assignOrderModalStore = new AssignOrderModalStore(this);
        this.closeTicketModalStore = new CloseTicketModalStore(this);
        this.assignTicketModalStore = new AssignTicketModalStore(this);
        this.manualEmailModalStore = new ManualEmailModalStore(this);

        this.assemblyStore = new AssemblyStore(this);
        this.orderStore = new OrderStore(this);

        this.servicesStore = new ServicesStore(this);
        this.servicesActivationProgressModalStore = new ServicesActivationProgressModalStore(this);
        this.servicesDeactivationProgressModalStore = new ServicesDeactivationProgressModalStore(this);
        this.servicesInfoModalStore = new ServicesInfoModalStore(this);

        this.documentStore = new DocumentStore(this);

        this.invoiceStore = new InvoiceStore(this);
        this.invoiceListStore = new InvoiceListStore(this);
        this.creditorSearchStore = new CreditorSearchStore(this);
        this.paymentAccountSearchStore = new PaymentAccountSearchStore(this);
        this.ticketSearchStore = new TicketSearchStore(this);
        this.orderSearchStore = new OrderSearchStore(this);
        this.assignInvoiceStore = new AssignInvoiceStore(this);
        this.accountingRealestateSearchStore = new AccountingRealestateSearchStore(this);
        this.approveRejectInvoiceStore = new ApproveRejectInvoiceStore(this);
        this.restartCancelInvoiceStore = new RestartCancelInvoiceStore(this);
        this.accountingAccountSearchStore = new AccountingAccountSearchStore(this);
        this.accountingCostcenterStore = new AccountingCostcenterStore(this);
        this.accountingStore = new AccountingStore(this);
        this.startWorkflowStore = new StartWorkflowStore(this);

        this.realestateSearchStore = new RealestateSearchStore(this);
        this.unitSearchStore = new UnitSearchStore(this);

        hydrate("@portal", this.authStore).finally(() => this.authStore.setLoaded(true));
        hydrate("@portalUiStore", this.uiStore);
    }
}

export const RootStoreContext = React.createContext<RootStore>(undefined!);
