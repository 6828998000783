import React, { useContext, useCallback } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { RestartInvoiceModal } from "./modal/RestartInvoiceModal";
import { RESTART_CANCEL_MODAL_TYPE } from "src/stores/krediflow/RestartCancelInvoiceStore";

const RestartInvoiceBase = () => {
    const { restartCancelInvoiceStore, invoiceStore } = useContext(RootStoreContext);
    const { t } = useTranslation();

    const displayRestartInvoiceModal = useCallback(() => {
        restartCancelInvoiceStore.setIsModalDisplayed(true);
        restartCancelInvoiceStore.setDisplayedModalType(RESTART_CANCEL_MODAL_TYPE.RESTART);
    }, [restartCancelInvoiceStore]);

    return (
        <div className="mb-2">
            <div className="d-flex align-items-center">
                <UI.Button
                    label={t("screens.kredi_flow.action.restart")}
                    className={"mb-2 default-button-height"}
                    onClick={displayRestartInvoiceModal}
                    hidden={!invoiceStore.currentInvoice?.canCurrentUserRestartOrCancel}
                />
                <RestartInvoiceModal />
            </div>
        </div>
    );
};

export const RestartInvoice = Sentry.withProfiler(observer(RestartInvoiceBase));
