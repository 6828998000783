import React, { useContext, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { GET_AGENDAFILES_BY_ASSEMBLYID } from "src/api/agenda";
import {
    GetAgendaFilesByAssemblyId,
    GetAgendaFilesByAssemblyIdVariables,
    GetAgendaFilesByAssemblyId_assemblyfiles as file_item
} from "src/api/generated/GetAgendaFilesByAssemblyId";
import { useQuery } from "@apollo/client";
import { observer } from "mobx-react-lite";
import { UI, ScreenSize } from "@wwimmo/ui";
import * as Screens from "src/screens";
import styles from "./AssemblyDocuments.module.css";
import { ColorStyle } from "src/utils/Colors";
import { NetworkConfig } from "src/network/NetworkConfig";
import { getDate } from "src/utils/Date";
import DownloadCloud from "src/assets/images/common/DownloadCloud.png";
import { getRoleKey } from "src/network/User";
import { MessageType } from "src/components/notifications/Notifier";

interface AssembliesProps {
    assemblyid: string;
    assemblyTitle: any;
    assemblyDate: any;
}

const AssemblyDocuments = (props: AssembliesProps) => {
    const { t } = useTranslation();
    const { uiStore, authStore } = useContext(RootStoreContext);

    const { data, loading, error } = useQuery<GetAgendaFilesByAssemblyId, GetAgendaFilesByAssemblyIdVariables>(
        GET_AGENDAFILES_BY_ASSEMBLYID,
        {
            variables: {
                assemblyid: props.assemblyid
            }
        }
    );

    const renderItem = useCallback((item: file_item, screenSize: ScreenSize) => {
        const file = item;
        const listRows: any[] = [];

        if (file) {
            const date = getDate(file.filedate);

            let rows: React.ReactElement[] = [];

            rows = [
                <UI.List.Row key={"row-1"}>
                    <UI.List.Cell key={"c-1"} colspan={2} value={file.name} />
                </UI.List.Row>,
                <UI.List.Row key={"row-2"}>
                    <UI.List.Cell key={"c-2"} colspan={1} value={date} className="sublabel" />
                </UI.List.Row>
            ];

            listRows.push(rows);
        }

        let urlLink = NetworkConfig.datafileUrl + file.fileid;

        const icon = (
            <a href={urlLink} className="link d-flex" rel="noopener noreferrer" target="_blank">
                <UI.Icon icon={UI.SVGIcon.Documents} color={ColorStyle("primary")} />
            </a>
        );

        return (
            <UI.List.Item
                className={styles.documentListItems}
                key={file.id}
                screenSize={screenSize}
                rows={listRows}
                icon={icon}
            />
        );
    }, []);

    const printStatusMessage = useCallback(
        (message: string | React.ReactNode, messageType: MessageType) => {
            uiStore.enqueueSnackbar({
                content: message,
                options: {
                    type: messageType,
                    autoClose: messageType === MessageType.ERROR ? false : 3000
                }
            });
        },
        [uiStore]
    );

    const onDownloadAssemblyDocuments = useCallback(async () => {
        if (authStore.user && data) {
            const accessToken = authStore.token;
            const tokenType = "Bearer";
            const role = authStore.user?.role;

            try {
                const fetchResult = await fetch(`${NetworkConfig.downloadAssemblyFiles}/${props.assemblyid}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${tokenType} ${accessToken}`,
                        "x-hasura-role": getRoleKey(role)
                    }
                });

                if (fetchResult.status === 200) {
                    try {
                        const contentDisposition = fetchResult.headers.get("Content-Disposition");
                        // prettier-ignore
                        let fileName = contentDisposition?.split("filename=")[1]?.replaceAll("\"", "");
                        let fileBlob = await fetchResult.blob();
                        const objUrl = window.URL.createObjectURL(fileBlob);
                        const a = document.createElement("a");
                        a.href = objUrl;
                        fileName =
                            fileName ??
                            `${t("screens.realestate.assembly.default_assembly_file_name", {
                                date: props.assemblyDate.toString().replaceAll("-", "_")
                            })}`;
                        fileName = fileName.replace("assemblytitle", props.assemblyTitle.replaceAll(" ", "_"));
                        a.download = fileName;
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                    } catch (error) {
                        printStatusMessage(
                            t("screens.realestate.assembly.error.assembly_file_download_error"),
                            MessageType.ERROR
                        );
                    }
                } else {
                    printStatusMessage(
                        t("screens.realestate.assembly.error.assembly_file_download_error"),
                        MessageType.ERROR
                    );
                }
            } catch (fetchError) {
                printStatusMessage(
                    t("screens.realestate.assembly.error.assembly_file_download_error"),
                    MessageType.ERROR
                );
            }
        }
    }, [
        authStore.token,
        authStore.user,
        t,
        printStatusMessage,
        data,
        props.assemblyDate,
        props.assemblyTitle,
        props.assemblyid
    ]);

    const renderTooltip = useMemo(
        () => (
            <UI.Tooltip id="downloadDocumentTooltip">
                {t("screens.realestate.assembly.download_file_tootlip")}
            </UI.Tooltip>
        ),
        [t]
    );

    if (loading) {
        return <Screens.Loading />;
    }
    if (error) {
        return <Screens.Error message={error.message} networkError={error.networkError} />;
    }
    if (!data) {
        return <Screens.Error message={t("error.nodata").toString()} />;
    }

    const downloadDocumentIcon = (
        <div className={"d-flex align-items-center"}>
            <UI.OverlayTrigger key={"downloadDocumentTooltip"} placement="right" overlay={renderTooltip}>
                <UI.BootstrapButton variant="link" style={{ paddingRight: 0, borderRightWidth: 0 }}>
                    <img
                        src={DownloadCloud}
                        style={{ width: 30, alignSelf: "center" }}
                        onClick={onDownloadAssemblyDocuments}
                        alt=""
                    />
                </UI.BootstrapButton>
            </UI.OverlayTrigger>
        </div>
    );

    return (
        <>
            {data.assemblyfiles.length > 0 ? (
                <UI.Card
                    className={styles.AgendaCard}
                    title={t("screens.realestate.assembly.documents").toString()}
                    icon={downloadDocumentIcon}
                >
                    <UI.List.BasicList
                        screenSize={uiStore.isMobile ? ScreenSize.MOBILE : ScreenSize.DESKTOP}
                        items={data.assemblyfiles}
                        renderItem={renderItem}
                        emptyListMessage={t("screens.realestate.documents.no_documents_found").toString()}
                    />
                </UI.Card>
            ) : undefined}
        </>
    );
};

export default observer(AssemblyDocuments);
