import React, { useContext, useCallback } from "react";
import { UI } from "@wwimmo/ui";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "src/stores/RootStore";
import { useTranslation } from "react-i18next";
import { CancelInvoiceModal } from "./modal/CancelInvoiceModal";
import styles from "./CancelInvoice.module.css";
import { RESTART_CANCEL_MODAL_TYPE } from "src/stores/krediflow/RestartCancelInvoiceStore";

const CancelInvoiceBase = () => {
    const { restartCancelInvoiceStore, invoiceStore } = useContext(RootStoreContext);
    const { t } = useTranslation();

    const displayCancelInvoiceModal = useCallback(() => {
        restartCancelInvoiceStore.setIsModalDisplayed(true);
        restartCancelInvoiceStore.setDisplayedModalType(RESTART_CANCEL_MODAL_TYPE.CANCEL);
    }, [restartCancelInvoiceStore]);

    return (
        <div className="mb-2">
            <div className="d-flex align-items-center">
                <UI.Button
                    label={t("screens.kredi_flow.action.cancel")}
                    className={`mb-2 default-button-height ${styles.CancelButton}`}
                    onClick={displayCancelInvoiceModal}
                    variant="danger"
                    hidden={!invoiceStore.currentInvoice?.canCurrentUserRestartOrCancel}
                />
                <CancelInvoiceModal />
            </div>
        </div>
    );
};

export const CancelInvoice = Sentry.withProfiler(observer(CancelInvoiceBase));
