import React, { useContext, useCallback, FunctionComponent, useState, useEffect, useRef } from "react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import {
    GetAssemblyByID_assemblyfiles,
    GetAssemblyByID_assemblyitems as assemblyitem,
    GetAssemblyByID_assemblystates as assemblystatus,
    GetAssemblyByID_agendaitem_parents
} from "src/api/generated/GetAssemblyByID";
import { UI } from "@wwimmo/ui";
import { useTranslation } from "react-i18next";
import { RootStoreContext } from "src/stores/RootStore";
import { MessageType } from "src/components/notifications/Notifier";
import { useMutation } from "@apollo/client";
import { UPSERT_ASSEMBLIES, INSERT_ASSEMBLY_AGENDAITEMS, UPDATE_ASSEMBLY_FOR_PDF } from "src/api/assembly";
import { UpsertAssembly, UpsertAssemblyVariables } from "src/api/generated/UpsertAssembly";
import {
    InsertAssemblyAgendaitems,
    InsertAssemblyAgendaitemsVariables
} from "src/api/generated/InsertAssemblyAgendaitems";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { ZipAutocompleteInput } from "./autocompleteInputs/ZipAutocompleteInput";
import { CityAutocompleteInput } from "./autocompleteInputs/CityAutocompleteInput";
import { StreetAutocompleteInput } from "./autocompleteInputs/StreetAutocompleteInput";
import { StreetNumberAutocompleteInput } from "./autocompleteInputs/StreetNumberAutocompleteInput";
import { cloneDeep, isEmpty } from "lodash";
import { ColorStyle } from "src/utils/Colors";
import { AssemblyDocuments } from "src/components/assemblies/documents/AssemblyDocuments";
import { AssemblyAgenda } from "src/components/assemblies/agenda/AssemblyAgenda";
import { useHistory } from "react-router-dom";
import { DELETE_ASSEMBLY_BY_ID } from "src/api/assembly";
import { DeleteAssemblyById, DeleteAssemblyByIdVariables } from "src/api/generated/DeleteAssemblyById";
import { selectRoute, Route } from "src/config/routes";
import { useSaveFormByShortcut } from "src/hooks/form/useSaveFormByShortcut";
import { useSaveUnsavedChangesInForm } from "src/hooks/save-unsaved-changes-in-form/useSaveUnsavedChangesInForm";
import { FORM_STATUS } from "src/utils/Form";
import { getDateWithLongMonth } from "src/utils/Date";
import { toJS } from "mobx";
import "./AssembliesEditForm.css";
import { getLang } from "src/utils/i18n";
import { UpdateAssemblyForPDF, UpdateAssemblyForPDFVariables } from "src/api/generated/UpdateAssemblyForPDF";

type AssemblyEditFormProps = {
    assemblyDetails?: assemblyitem;
    assemblyStatus: assemblystatus[];
    assemblyid: string;
    realestateid: string;
    refetchData: any;
    assemblyFiles: GetAssemblyByID_assemblyfiles[];
    agendaItemParents: GetAssemblyByID_agendaitem_parents[];
};

export interface AssemblyEditFormFields {
    id: string;
    realestateid: string;
    remarks: string;
    date: string;
    state: number;
    zip: string;
    city: string;
    street: string;
    housenumber: string;
    title: string;
    locationdetail: string;
    motiondeadline?: Date | null;
}

export enum ERROR_TYPE {
    NONE = 0,
    ZIP = 1,
    CITY = 2,
    STREET = 3,
    STREETNUMBER = 4,
    FLOOR = 5
}

export enum SEARCH_STATE {
    NONE = 0,
    ZIP = 1,
    CITY = 2,
    STREET = 3,
    STREETNUMBER = 4
}

export enum LIST_TYPE {
    ZIP = "zip",
    CITY = "city",
    STREET = "street",
    STREETNUMBER = "streetnumber"
}

export enum ASSEMBLY_STATE {
    PREPARING = "0",
    ACTIVE = "1",
    CLOSED = "9"
}

const AssemblyEditFormBase: FunctionComponent<AssemblyEditFormProps> = (props) => {
    const { t } = useTranslation();
    const { uiStore, authStore, assemblyStore } = useContext(RootStoreContext);
    const history = useHistory();

    const { realestateid, assemblyid, assemblyFiles, agendaItemParents } = props;

    const [isNewAssembly, setIsNewAssembly] = useState<boolean>(props.assemblyDetails ? false : true);

    assemblyStore.setAssembliesEditFormStatus(FORM_STATUS.NONE);

    const [upsertAssemblyMutation] = useMutation<UpsertAssembly, UpsertAssemblyVariables>(UPSERT_ASSEMBLIES);
    const [updateAssemblyMutationForPDF] = useMutation<UpdateAssemblyForPDF, UpdateAssemblyForPDFVariables>(
        UPDATE_ASSEMBLY_FOR_PDF
    );
    const [insertAssemblyAgendaItemsMutation] = useMutation<
        InsertAssemblyAgendaitems,
        InsertAssemblyAgendaitemsVariables
    >(INSERT_ASSEMBLY_AGENDAITEMS);

    const [deleteAssemblyByIdMutation] = useMutation<DeleteAssemblyById, DeleteAssemblyByIdVariables>(
        DELETE_ASSEMBLY_BY_ID,
        {
            onCompleted: () => {
                printStatusMessage(t("screens.realestate.assembly.edit.delete_success"), MessageType.INFO);
            }
        }
    );

    const realestate_schema = yup.object().shape({
        date: yup.string().required(t("labels.required_field").toString()),
        state: yup.string().required(t("labels.required_field").toString()),
        zip: yup.string(),
        city: yup.string(),
        street: yup.string(),
        housenumber: yup.string(),
        title: yup.string().required(t("labels.required_field").toString()),
        locationdetail: yup.string(),
        motiondeadline: yup.string()
    });

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        reset,
        getValues,
        formState: { errors, dirtyFields, isDirty }
    } = useForm<AssemblyEditFormFields>({
        mode: "onBlur",
        resolver: yupResolver(realestate_schema),
        defaultValues: {
            date: props.assemblyDetails?.date || "",
            state: props.assemblyDetails?.state || 0,
            zip: props.assemblyDetails?.zip || "",
            city: props.assemblyDetails?.city || "",
            street: props.assemblyDetails?.street || "",
            housenumber: props.assemblyDetails?.housenumber || "",
            title: props.assemblyDetails?.title || t("screens.realestate.assembly.defaultTitle").toString(),
            locationdetail: props.assemblyDetails?.locationdetail || "",
            motiondeadline: props.assemblyDetails?.motiondeadline || null
        }
    });

    assemblyStore.setAssembliesEditFormHasDirtyFields(!isEmpty(dirtyFields));

    const refSubmitButtom = useRef<HTMLButtonElement>(null);

    const saveForm = useCallback(() => {
        refSubmitButtom?.current?.click();
    }, []);

    useSaveFormByShortcut({ saveFormFunction: saveForm, isFormDirty: isDirty });

    const { saveFormModal, closeSaveFormModalAndGoBack } = useSaveUnsavedChangesInForm({
        saveFormFunction: saveForm,
        currentFormStatus: assemblyStore.assembliesEditFormStatus,
        isFormDirty: isDirty || assemblyStore.agendaItemsHaveChanged
    });

    const watchZip = watch("zip");
    const watchCity = watch("city");
    const watchStreet = watch("street");
    const watchHousenumber = watch("housenumber");
    const watchTitle = watch("title");
    const watchDate = watch("date");

    const setFocus = useCallback((inputElementId: string) => {
        const inputElement: HTMLInputElement | null = document?.getElementById(inputElementId) as HTMLInputElement;

        if (inputElement) {
            var tempEl = document.createElement("input");
            tempEl.style.position = "absolute";
            tempEl.style.top = inputElement.offsetTop + 7 + "px";
            tempEl.style.left = inputElement.offsetLeft + "px";
            tempEl.style.height = "0";
            tempEl.style.opacity = "0";

            document.body.appendChild(tempEl);
            tempEl.focus();

            setTimeout(function () {
                inputElement.focus();
                inputElement.click();

                document.body.removeChild(tempEl);
            }, 100);
        }
    }, []);

    useEffect(() => {
        setFocus("title");
    }, [setFocus]);

    const [zipItems, setZipItems] = useState<string[]>([]);
    const [nameItems, setNameItems] = useState<string[]>([]);
    const [streetItems, setStreetItems] = useState<{ id: string; street: string }[]>([]);
    const [streetNumberItems, setStreetNumberItems] = useState<string[]>([]);
    const [numbersOfSelectedStreet, setNumbersOfSelectedStreet] = useState<string[]>([]);

    const [zipWasSelected, setZipWasSelected] = useState<boolean>(props.assemblyDetails?.zip ? true : false);
    const [cityWasSelected, setCityWasSelected] = useState<boolean>(props.assemblyDetails?.city ? true : false);
    const [streetWasSelected, setStreetWasSelected] = useState<boolean>(props.assemblyDetails?.street ? true : false);
    const [streetNumberWasSelected, setStreetNumberWasSelected] = useState<boolean>(
        props.assemblyDetails?.housenumber ? true : false
    );

    const [currentCityName, setCurrentCityName] = useState<string>("");
    const [currentZip, setCurrentZip] = useState<string>("");
    const [currentStreet, setCurrentStreet] = useState<string>("");
    const [currentStreetNumber, setCurrentStreetNumber] = useState<string>("");

    const [searchType, setSearchType] = useState<SEARCH_STATE>(SEARCH_STATE.NONE);
    const [error, setError] = useState<ERROR_TYPE>(ERROR_TYPE.NONE);

    const [titleAssembly, setTitleAssembly] = useState<string>(t("screens.realestate.assemblies.title").toString());

    const printStatusMessage = useCallback(
        (message: string, msgType: MessageType) => {
            uiStore.enqueueSnackbar({
                content: message,
                options: {
                    type: msgType,
                    autoClose: msgType === MessageType.ERROR ? false : 3000
                }
            });
        },
        [uiStore]
    );

    const handleFormSubmit = useCallback(
        async (formData: AssemblyEditFormFields) => {
            assemblyStore.setAssembliesEditFormStatus(FORM_STATUS.SAVING_FORM);

            if (!formData.motiondeadline) {
                formData = { ...formData, motiondeadline: null };
            }

            try {
                if (isDirty) {
                    const upsertAssemblyDetails: any = {
                        ...formData,
                        id: assemblyid,
                        realestateid: realestateid
                    };

                    await upsertAssemblyMutation({
                        variables: {
                            objects: upsertAssemblyDetails
                        }
                    });

                    if (isNewAssembly) {
                        uiStore.setHasNewAssembliesData(true);
                        setIsNewAssembly(false);
                    }

                    reset(
                        {
                            ...formData,
                            id: assemblyid,
                            realestateid: realestateid
                        },
                        {
                            keepDirty: false,
                            keepDefaultValues: false,
                            keepValues: true
                        }
                    );
                }
                if (assemblyStore.agendaItemsHaveChanged) {
                    assemblyStore.setAssemblyId(assemblyid);
                    const language = getLang();

                    assemblyStore.createInsertAssemblyAgendaitems(language);

                    await insertAssemblyAgendaItemsMutation({
                        variables: {
                            agendaItems: assemblyStore.insertAssemblyAgendaitems,
                            agendaids: assemblyStore.deletedAgendaItemIds
                        }
                    });

                    assemblyStore.setUnsavedAgendaItems([]);
                    const updatedAgendaItems = cloneDeep(toJS(assemblyStore.agendaItems));
                    assemblyStore.setOriginalAgendaItems(updatedAgendaItems);
                    assemblyStore.setCurrentAgendaItems(updatedAgendaItems);
                    assemblyStore.setResetAgendaItems(true);

                    // to call event generatepdf state of assembly will be updated
                    // in case new agendaitem/s is/are inserted or updated
                    await updateAssemblyMutationForPDF({
                        variables: {
                            assemblyid: assemblyid,
                            state: formData.state
                        }
                    });
                }
                assemblyStore.setShowRefetchAssemblyData(true);
                printStatusMessage(t("screens.realestate.assembly.edit.save_success"), MessageType.INFO);

                assemblyStore.generateAssemblyPDF(assemblyid);
            } catch (error: any) {
                printStatusMessage(
                    t("screens.realestate.assembly.edit.save_error", {
                        errorMessage: error.message
                    }),
                    MessageType.ERROR
                );
            } finally {
                assemblyStore.setAssembliesEditFormStatus(FORM_STATUS.NONE);
                closeSaveFormModalAndGoBack();
            }
        },
        [
            reset,
            isNewAssembly,
            realestateid,
            t,
            uiStore,
            printStatusMessage,
            upsertAssemblyMutation,
            assemblyid,
            closeSaveFormModalAndGoBack,
            isDirty,
            insertAssemblyAgendaItemsMutation,
            assemblyStore,
            updateAssemblyMutationForPDF
        ]
    );

    const closeAllLists = useCallback(() => {
        setZipItems([]);
        setNameItems([]);
        setStreetItems([]);
        setStreetNumberItems([]);
    }, []);

    const onResetForm = useCallback(
        (e: any) => {
            e.preventDefault();

            if (isDirty) {
                const zip = props.assemblyDetails?.zip || "";
                const city = props.assemblyDetails?.city || "";
                const street = props.assemblyDetails?.street || "";
                const housenumber = props.assemblyDetails?.housenumber || "";

                if (props.assemblyDetails) {
                    reset({
                        date: props.assemblyDetails?.date || "",
                        state: props.assemblyDetails?.state || 0,
                        zip: zip,
                        city: city,
                        street: street,
                        housenumber: housenumber,
                        title: props.assemblyDetails.title || "",
                        locationdetail: props.assemblyDetails.locationdetail || "",
                        motiondeadline: props.assemblyDetails.motiondeadline || null
                    });

                    closeAllLists();

                    setZipWasSelected(true);
                    setCityWasSelected(true);
                    setStreetWasSelected(true);
                    setStreetNumberWasSelected(true);

                    setCurrentZip(zip);
                    setCurrentCityName(city);
                    setCurrentStreet(street);
                    setCurrentStreetNumber(housenumber);

                    setValue("zip", zip);
                    setValue("city", city);
                    setValue("street", street);
                    setValue("housenumber", housenumber);
                } else {
                    reset({
                        date: "",
                        state: 0,
                        zip: "",
                        city: "",
                        street: "",
                        housenumber: "",
                        title: "",
                        locationdetail: "",
                        motiondeadline: null
                    });
                }
            }
            assemblyStore.setUnsavedAgendaItems([]);

            if (assemblyStore.agendaItemsHaveChanged) {
                assemblyStore.setResetAgendaItems(true);
            }
        },
        [reset, props.assemblyDetails, closeAllLists, setValue, isDirty, assemblyStore]
    );

    const onDeleteAssembly = useCallback(async () => {
        if (isNewAssembly) {
            history.replace(selectRoute(Route.managerAssemblies, authStore.user?.role, { realestateid: realestateid }));
        } else {
            await deleteAssemblyByIdMutation({
                variables: {
                    assemblyid: assemblyid
                }
            });

            uiStore.setHasNewAssembliesData(true);
            history.replace(selectRoute(Route.managerAssemblies, authStore.user?.role, { realestateid: realestateid }));
        }
    }, [authStore.user?.role, history, realestateid, deleteAssemblyByIdMutation, isNewAssembly, assemblyid, uiStore]);

    useEffect(() => {
        if (getValues("date")) {
            setTitleAssembly(`${getValues("title")} am ${getDateWithLongMonth(getValues("date"))}`);
        } else {
            setTitleAssembly(getValues("title"));
        }
    }, [t, getValues]);

    useEffect(() => {
        setTitleAssembly(`${getValues("title")} am ${getDateWithLongMonth(watchDate)}`);
    }, [watchDate, getValues]);

    useEffect(() => {
        if (getValues("date")) {
            setTitleAssembly(`${watchTitle} am ${getDateWithLongMonth(getValues("date"))}`);
        } else {
            setTitleAssembly(watchTitle);
        }
    }, [watchTitle, getValues]);

    useEffect(() => {
        assemblyStore.setIsAssemblyPDFExists(true);
        // eslint-disable-next-line
    }, []);

    const isNotificationButtonDisplayed =
        props.assemblyDetails?.state !== undefined && props.assemblyDetails?.state === Number(ASSEMBLY_STATE.ACTIVE);

    if (assemblyStore.oldAssemblyWithGeneralDocuments === false) {
        if (assemblyFiles.length <= 0) {
            assemblyStore.setIsAssemblyFilesExists(false);
        }
    }

    const tabNavigation = (
        <div className="TabNavigationContainer my-2">
            {assemblyFiles.length > 0 || assemblyStore.isAssemblyFilesExists ? (
                <UI.Tab.Container defaultActiveKey="agendaitems" transition={false}>
                    <UI.Row>
                        <UI.Col sm={3} className="TabNavigationNavCol">
                            <UI.Nav variant="pills" className="flex-column TabNavigationNav">
                                <UI.Nav.Item className="TabNavigationNavItem">
                                    <UI.Nav.Link eventKey="agendaitems" className="TabNavigationNavLink">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <UI.Icon
                                                icon={UI.SVGIcon.ListView}
                                                color={ColorStyle("primary")}
                                                size="medium"
                                                className="TabNavigationNavLinkIcon"
                                            />
                                            <div className="TabNavigationNavLinkText">
                                                {t("screens.realestate.assembly.agenda")}
                                            </div>
                                            <UI.Icon
                                                icon={UI.SVGIcon.ChevronRight}
                                                color={ColorStyle("primary")}
                                                size="small"
                                                className="TabNavigationNavLinkChevron"
                                            />
                                        </div>
                                    </UI.Nav.Link>
                                </UI.Nav.Item>
                                <UI.Nav.Item className="TabNavigationNavItem">
                                    <UI.Nav.Link eventKey="documents" className="TabNavigationNavLinkDocument">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <UI.Icon
                                                icon={UI.SVGIcon.Documents}
                                                color={ColorStyle("primary")}
                                                size="medium"
                                                className="TabNavigationNavLinkIcon"
                                            />
                                            <div className="TabNavigationNavLinkText">
                                                {t("screens.realestate.assembly.general_documents")}
                                            </div>
                                            <UI.Icon
                                                icon={UI.SVGIcon.ChevronRight}
                                                color={ColorStyle("primary")}
                                                size="small"
                                                className="TabNavigationNavLinkChevron"
                                            />
                                        </div>
                                    </UI.Nav.Link>
                                </UI.Nav.Item>
                            </UI.Nav>
                        </UI.Col>
                        <UI.Col sm={9}>
                            <UI.Tab.Content>
                                <UI.Tab.Pane eventKey="agendaitems">
                                    <AssemblyAgenda
                                        assemblyid={assemblyid}
                                        agendaItemParents={agendaItemParents}
                                        realestateid={realestateid}
                                        refetchAgendaItems={props.refetchData}
                                        assemblyFilesExists={true}
                                        assemblydate={props.assemblyDetails?.date}
                                    />
                                </UI.Tab.Pane>
                                <UI.Tab.Pane eventKey="documents">
                                    <AssemblyDocuments assemblyFiles={assemblyFiles} />
                                </UI.Tab.Pane>
                            </UI.Tab.Content>
                        </UI.Col>
                    </UI.Row>
                </UI.Tab.Container>
            ) : (
                <AssemblyAgenda
                    assemblyid={assemblyid}
                    agendaItemParents={agendaItemParents}
                    realestateid={realestateid}
                    refetchAgendaItems={props.refetchData}
                    assemblyFilesExists={false}
                    assemblydate={props.assemblyDetails?.date}
                />
            )}
        </div>
    );

    return (
        <>
            {saveFormModal}
            <UI.Title>{titleAssembly}</UI.Title>
            <UI.Row>
                <UI.Col>
                    <UI.Form autoComplete="nope">
                        <div className="AssemblyFormBasisDataContainer mb-3">
                            <div className="AssemblyBasisDataTitle mb-3">
                                {t("screens.realestate.assembly.basic_data")}
                            </div>
                            <div className={uiStore.isMobile ? undefined : "row"}>
                                <UI.Col>
                                    <UI.Row className="mb-2">
                                        <UI.Col>
                                            <UI.Input
                                                className={dirtyFields.title ? "changed" : ""}
                                                label={`${t("screens.realestate.assembly.title_text")} *`}
                                                {...register("title")}
                                                type="text"
                                                autoComplete="off"
                                                errorMsg={errors.title ? errors.title.message : undefined}
                                            />
                                        </UI.Col>
                                    </UI.Row>
                                    <UI.Row className="mb-2">
                                        <UI.Col>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="date">{`${t(
                                                    "screens.realestate.assembly.date"
                                                )} *`}</label>
                                                <div className="input-group">
                                                    <input
                                                        className={`${dirtyFields.date ? "changed" : ""} form-control`}
                                                        type="datetime-local"
                                                        id="date"
                                                        {...register("date")}
                                                        min={Date.now()}
                                                    />
                                                    {errors.date && errors.date.message ? (
                                                        <div className="d-block invalid-feedback">
                                                            {errors.date.message}
                                                        </div>
                                                    ) : undefined}
                                                </div>
                                            </div>
                                        </UI.Col>
                                    </UI.Row>
                                    <UI.Row>
                                        <UI.Col>
                                            <UI.Input
                                                label={`${t("screens.realestate.assembly.state")} *`}
                                                {...register("state")}
                                                as="select"
                                                id="state"
                                                errorMsg={errors.state ? errors.state.message : undefined}
                                                className={dirtyFields.state ? "changed" : ""}
                                            >
                                                <option value={ASSEMBLY_STATE.PREPARING}>
                                                    {t("screens.realestate.assembly.edit_state.preparing")}
                                                </option>
                                                <option value={ASSEMBLY_STATE.ACTIVE}>
                                                    {t("screens.realestate.assembly.edit_state.active")}
                                                </option>
                                                <option value={ASSEMBLY_STATE.CLOSED}>
                                                    {t("screens.realestate.assembly.edit_state.closed")}
                                                </option>
                                            </UI.Input>
                                        </UI.Col>
                                    </UI.Row>
                                    <UI.Row className="mb-2">
                                        <UI.Col>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="date">{`${t(
                                                    "screens.realestate.assembly.motiondeadline"
                                                )}`}</label>
                                                <div className="input-group">
                                                    <input
                                                        className={`${
                                                            dirtyFields.motiondeadline ? "changed" : ""
                                                        } form-control`}
                                                        type="date"
                                                        id="motiondeadline"
                                                        {...register("motiondeadline")}
                                                    />
                                                </div>
                                            </div>
                                        </UI.Col>
                                    </UI.Row>
                                </UI.Col>
                                <UI.Col>
                                    <UI.Row className="mb-2">
                                        <UI.Col xs={4}>
                                            <ZipAutocompleteInput
                                                setError={setError}
                                                errors={errors}
                                                setSearchType={setSearchType}
                                                zipItems={zipItems}
                                                setZipItems={setZipItems}
                                                setNameItems={setNameItems}
                                                searchType={searchType}
                                                setZipWasSelected={setZipWasSelected}
                                                setCityWasSelected={setCityWasSelected}
                                                closeAllLists={closeAllLists}
                                                setValue={setValue}
                                                register={register}
                                                watchZip={watchZip}
                                                zipWasSelected={zipWasSelected}
                                                setStreetWasSelected={setStreetWasSelected}
                                                setStreetNumberWasSelected={setStreetNumberWasSelected}
                                                currentZip={currentZip}
                                                setCurrentZip={setCurrentZip}
                                                setCurrentCityName={setCurrentCityName}
                                                dirtyFields={dirtyFields}
                                            />
                                        </UI.Col>
                                        <UI.Col xs={8}>
                                            <CityAutocompleteInput
                                                register={register}
                                                errors={errors}
                                                setValue={setValue}
                                                watchCity={watchCity}
                                                setError={setError}
                                                setSearchType={setSearchType}
                                                zipItems={zipItems}
                                                setZipItems={setZipItems}
                                                nameItems={nameItems}
                                                setNameItems={setNameItems}
                                                searchType={searchType}
                                                setZipWasSelected={setZipWasSelected}
                                                setCityWasSelected={setCityWasSelected}
                                                closeAllLists={closeAllLists}
                                                setStreetWasSelected={setStreetWasSelected}
                                                setStreetNumberWasSelected={setStreetNumberWasSelected}
                                                cityWasSelected={cityWasSelected}
                                                streetWasSelected={streetWasSelected}
                                                setCurrentZip={setCurrentZip}
                                                currentCityName={currentCityName}
                                                setCurrentCityName={setCurrentCityName}
                                                dirtyFields={dirtyFields}
                                            />
                                        </UI.Col>
                                        {error === ERROR_TYPE.ZIP ? (
                                            <p className="alert text-danger">
                                                {t("screens.realestate.assembly.error.zip")}
                                            </p>
                                        ) : undefined}
                                        {error === ERROR_TYPE.CITY ? (
                                            <p className="alert text-danger">
                                                {t("screens.realestate.assembly.error.city")}
                                            </p>
                                        ) : undefined}
                                    </UI.Row>
                                    <UI.Row className="mb-2">
                                        <UI.Col xs={9}>
                                            <StreetAutocompleteInput
                                                register={register}
                                                errors={errors}
                                                setValue={setValue}
                                                watch={watch}
                                                watchStreet={watchStreet}
                                                setError={setError}
                                                setSearchType={setSearchType}
                                                searchType={searchType}
                                                setZipWasSelected={setZipWasSelected}
                                                closeAllLists={closeAllLists}
                                                setStreetWasSelected={setStreetWasSelected}
                                                setStreetNumberWasSelected={setStreetNumberWasSelected}
                                                cityWasSelected={cityWasSelected}
                                                streetWasSelected={streetWasSelected}
                                                setNumbersOfSelectedStreet={setNumbersOfSelectedStreet}
                                                streetItems={streetItems}
                                                setStreetItems={setStreetItems}
                                                zipWasSelected={zipWasSelected}
                                                currentStreet={currentStreet}
                                                setCurrentStreet={setCurrentStreet}
                                                dirtyFields={dirtyFields}
                                            />
                                        </UI.Col>
                                        <UI.Col xs={3}>
                                            <StreetNumberAutocompleteInput
                                                register={register}
                                                errors={errors}
                                                setValue={setValue}
                                                watchHousenumber={watchHousenumber}
                                                setError={setError}
                                                setSearchType={setSearchType}
                                                searchType={searchType}
                                                setZipWasSelected={setZipWasSelected}
                                                closeAllLists={closeAllLists}
                                                setStreetWasSelected={setStreetWasSelected}
                                                setStreetNumberWasSelected={setStreetNumberWasSelected}
                                                streetWasSelected={streetWasSelected}
                                                setStreetItems={setStreetItems}
                                                numbersOfSelectedStreet={numbersOfSelectedStreet}
                                                setNumbersOfSelectedStreet={setNumbersOfSelectedStreet}
                                                setStreetNumberItems={setStreetNumberItems}
                                                streetNumberWasSelected={streetNumberWasSelected}
                                                streetNumberItems={streetNumberItems}
                                                dirtyFields={dirtyFields}
                                                getValues={getValues}
                                                currentStreetNumber={currentStreetNumber}
                                                setCurrentStreetNumber={setCurrentStreetNumber}
                                            />
                                            <input type="hidden" />
                                        </UI.Col>
                                    </UI.Row>
                                    {error === ERROR_TYPE.STREET ? (
                                        <p className="alert text-danger">
                                            {t("screens.realestate.assembly.error.street")}
                                        </p>
                                    ) : undefined}
                                    {error === ERROR_TYPE.STREETNUMBER ? (
                                        <p className="alert text-danger">
                                            {t("screens.realestate.assembly.error.streetnumber")}
                                        </p>
                                    ) : undefined}
                                    <UI.Row className="mb-2">
                                        <UI.Col>
                                            <UI.Input
                                                className={dirtyFields.locationdetail ? "changed" : ""}
                                                label={t("screens.realestate.assembly.locationdetail")}
                                                {...register("locationdetail")}
                                                type="text"
                                                autoComplete="off"
                                            />
                                        </UI.Col>
                                    </UI.Row>
                                </UI.Col>
                            </div>
                        </div>
                        {props.assemblyDetails ? (
                            <UI.Row>
                                <UI.Col>{tabNavigation}</UI.Col>
                            </UI.Row>
                        ) : undefined}
                        <div></div>
                        <div className="mb-4">
                            <UI.Button
                                ref={refSubmitButtom}
                                disabled={
                                    (isEmpty(dirtyFields) && !assemblyStore.agendaItemsHaveChanged) ||
                                    assemblyStore.assembliesEditFormStatus === FORM_STATUS.SAVING_FORM
                                }
                                onClick={handleSubmit(handleFormSubmit)}
                                label={t("labels.save")}
                                id="assembly-submit-button"
                                className="AssemblyFormButton"
                            />
                            <UI.Button
                                label={t("labels.discard")}
                                disabled={
                                    (isEmpty(dirtyFields) && !assemblyStore.agendaItemsHaveChanged) ||
                                    assemblyStore.assembliesEditFormStatus === FORM_STATUS.SAVING_FORM
                                }
                                onClick={onResetForm}
                                className={"AssemblyFormButton DiscardButton ml-sm-2 mt-2 mt-sm-0"}
                                variant="outline-primary"
                            />
                            {props.assemblyDetails ? (
                                <UI.ConfirmationDialog
                                    key="AssemblyDeleteDialog"
                                    buttonText={t("labels.delete")}
                                    modalTitle={t("labels.delete")}
                                    confirmationQuestion={t("screens.realestate.assembly.edit.delete_confirmation")}
                                    inProgressText={t("screens.realestate.assembly.edit.delete_in_progress")}
                                    onConfirmation={onDeleteAssembly}
                                    buttonClasses={`AssemblyFormButton DeleteButton mt-2 mt-sm-0 ${
                                        isNotificationButtonDisplayed ? "ml-sm-0 mt-sm-2 mt-lg-0 ml-lg-2" : "ml-sm-2"
                                    }`}
                                    isDangerousOperation
                                />
                            ) : undefined}
                        </div>
                    </UI.Form>
                </UI.Col>
            </UI.Row>
        </>
    );
};

export const AssemblyEditForm = Sentry.withProfiler(observer(AssemblyEditFormBase));
